import { useNavigation } from '@react-navigation/native'
import moment from 'moment'
import React, { useState, useCallback } from 'react'
import FormScreen from '../../form-wizard/FormScreen'
import FormWizard from '../../form-wizard/FormWizard'
import {
  Photo,
  RadioInput,
  SelectInput,
  Selector
} from '../../form-wizard/Inputs'
import faceChoices from '../../data/faceChoices'
import frameChoices from '../../data/frameChoices'
import { useAssessment } from '../../hooks/user/assessment'
import { useUser } from '../../hooks/user'
import Question from '../../form-wizard/Question'

export default () => {
  const [beginTimestamp] = useState(moment().valueOf())
  const { storeAssessment } = useAssessment()
  const { userAttributes } = useUser()

  const navigation = useNavigation()

  const handleSubmit = useCallback(data => {
    storeAssessment(data, 'FRAME', beginTimestamp, 1)
    navigation.navigate('AssessmentHome')
  }, [])

  return (
    <FormWizard onSubmit={handleSubmit}>
      <FormScreen title='Fassungswahl'>
        <Question
          id='wantsSpecificFrame'
          head='Fassungswunsch?'
          description='Lassen Sie uns wissen, welche Fassung Sie wünschen!'
          fieldProps={{
            validate: value => (value ? undefined : 'required')
          }}
        >
          <RadioInput
            options={[
              { id: 'no', text: 'Optiker wählen lassen' },
              { id: 'yes', text: 'Fassung selbst wählen' }
            ]}
          />
        </Question>
      </FormScreen>

      <FormScreen
        title='Fassungsform'
        condition={values => values.wantsSpecificFrame === 'yes'}
      >
        <Question
          id='frameType'
          head='Bitte wählen Sie eine oder mehrere Fassungsformen!'
        >
          <Selector options={frameChoices(userAttributes.gender)} />
        </Question>
      </FormScreen>

      <FormScreen title='Gesichtsform'>
        <Question id='faceType' head='Bitte wählen Sie Ihre Gesichtsform'>
          <Selector radio options={faceChoices} />
        </Question>
      </FormScreen>

      <FormScreen title='Foto'>
        <Question
          id='photo'
          head='Bitte schießen Sie ein Foto von Ihrem Gesicht!'
          fieldProps={{
            validate: value => (value ? undefined : 'required')
          }}
        >
          <Photo cameraType='front' />
        </Question>
      </FormScreen>

      <FormScreen title='Fassungsmaterial'>
        <Question
          id='frameMaterial'
          head='Haben Sie ein bevorzugtes Fassungsmaterial?'
          fieldProps={{
            validate: value => (value ? undefined : 'required')
          }}
        >
          <RadioInput
            options={[
              { id: 'plastic', text: 'Kunststoff' },
              { id: 'metal', text: 'Metall' },
              { id: 'any', text: 'kein Materialwunsch' }
            ]}
          />
        </Question>
      </FormScreen>

      <FormScreen
        title='Farbwunsch'
        condition={values => values.frameMaterial === 'plastic'}
      >
        <Question
          id='frameColorPlastic'
          head='Bitte wählen Sie aus verschiedenen Farben!'
        >
          <SelectInput
            options={[
              { id: 'black', text: 'schwarz' },
              { id: 'brown', text: 'braun' },
              { id: 'gray', text: 'grau' },
              { id: 'transparent', text: 'transparent' },
              { id: 'multi', text: 'bunt' }
            ]}
          />
        </Question>
      </FormScreen>

      <FormScreen
        title='Farbwunsch'
        condition={values => values.frameMaterial === 'metal'}
      >
        <Question
          id='frameColorMetal'
          head='Bitte wählen Sie aus verschiedenen Farben!'
        >
          <SelectInput
            options={[
              { id: 'black', text: 'schwarz' },
              { id: 'brown', text: 'braun' },
              { id: 'gray', text: 'grau' },
              { id: 'gold', text: 'gold' },
              { id: 'silver', text: 'silber' },
              { id: 'rose', text: 'rosé' }
            ]}
          />
        </Question>
      </FormScreen>

      <FormScreen
        title='Farbwunsch'
        condition={values => values.frameMaterial === 'any'}
      >
        <Question
          id='frameColorAny'
          head='Bitte wählen Sie aus verschiedenen Farben!'
        >
          <SelectInput
            options={[
              { id: 'black', text: 'schwarz' },
              { id: 'brown', text: 'braun' },
              { id: 'gray', text: 'grau' },
              { id: 'gold', text: 'gold' },
              { id: 'silver', text: 'silber' },
              { id: 'rose', text: 'rosé' },
              { id: 'transparent', text: 'transparent' },
              { id: 'multi', text: 'bunt' }
            ]}
          />
        </Question>
      </FormScreen>
    </FormWizard>
  )
}
