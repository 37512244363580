import React, { useEffect } from 'react'
import { useUser } from '../../hooks/user'
import theme from '../../styles/theme'
import { StyleSheet, TouchableOpacity, View, Text } from 'react-native'
import { Avatar, Headline } from 'react-native-paper'
import MediaQuery from 'react-native-web-responsive'

export default ({ onPress }) => {
  const {
    userAttributes,
    userPicture,
    fetchUserPicture
  } = useUser()

  useEffect(() => {
    fetchUserPicture()
  }, [userAttributes])

  return (
    <TouchableOpacity style={styles.headerTouchable} onPress={onPress}>
      <View style={styles.view}>
        {userAttributes ? (
          <>
            {userPicture ? (
              <Avatar.Image
                source={{ uri: userPicture }}
                size={55}
                style={styles.avatarImage}
              />
            ) : (
              <Avatar.Icon
                icon='account-circle-outline'
                size={65}
                style={styles.avatarIcon}
              />)}
            <MediaQuery minWidth={850}>
              <View style={{ flex: 1 }}>
                <Text style={styles.name}>
                  {userAttributes.name.trim()}
                </Text>
              </View>
            </MediaQuery>
          </>
        ) : (
          <>
            <Avatar.Icon
              icon='account-circle-outline'
              size={70}
              style={styles.avatarIcon}
            />
            <MediaQuery minWidth={850}>
              <View style={{ flex: 1 }}>
                <Text style={styles.name}>Anmelden</Text>
              </View>
            </MediaQuery>
          </>
        )}
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  view: {
    flex: 1,
    alignSelf: 'flex-end',
    alignItems: 'center',
    flexDirection: 'row'
  },
  headerTouchable: {
    flex: 1,
    alignSelf: 'center',
    flexDirection: 'column',
    height: 70
  },
  avatarIcon: {
    backgroundColor: theme.colors.background,
    marginLeft: -24,
    marginTop: -16,
    marginBottom: -15 // icon is slightly off-center
  },
  avatarImage: {
    marginLeft: 10,
    backgroundColor: theme.colors.background
  },
  name: {
    marginLeft: 14,
    fontSize: 22,
    fontWeight: '400',
    color: '#111'
  }
})
