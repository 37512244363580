import React, { useCallback } from 'react'
import { Linking, View, Image } from 'react-native'
import {
  Text,
  Subheading,
  Paragraph,
  Button,
  scale
} from '../../scaling/ScaledUI'
import theme from '../../styles/theme'

export default props => {
  const { data, width, padding, imagePadding } = props

  // console.log('in preview: ' + JSON.stringify(data, null, 4))

  return (
    <View
      style={{
        flex: 1,
        alignSelf: 'center',
        justifyContent: 'center',
        width: width - padding,
        marginVertical: padding,
        padding: scale(3),
        backgroundColor: '#ffffff',
        shadowColor: '#000',
        shadowOffset: {
          width: 0,
          height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5
      }}
    >
      {data && (
        <>
          {data.image && (<Image source={{ uri: data.image }} style={{ height: width - imagePadding, width: width - imagePadding, alignSelf: 'center', marginBottom: padding }} />)}
          <Subheading style={{ fontWeight: 'bold', textAlign: 'center' }}><Text style={{ fontWeight: 'bold' }}>{data.manufacturer} {data.name}</Text></Subheading>
          {data.price.map((x) => { return <Subheading style={{ textAlign: 'center' }}>Preisschätzung: {x.qty} Stück: {x.price}€</Subheading> })}
        </>
      )}
    </View>
  )
}
