import React from 'react'
import { Image, Linking, TouchableOpacity } from 'react-native'

export default ({ height, width, url, uri, alt, padding }) => {
  const handlePress = () => {
    Linking.openURL(url)
  }

  return (
    <TouchableOpacity onPress={handlePress} style={{ paddingHorizontal: padding, paddingVertical: padding * 0.5 }}>
      <Image
        source={{ uri: uri }}
        style={{ width: width - padding, height: height - padding }}
        accessibilityLabel={alt}
      />
    </TouchableOpacity>
  )
}
