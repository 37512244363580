import React from 'react'
import { Paragraph, Divider } from 'react-native-paper'
import UnderConstruction from '../../components/general/UnderConstruction'

export default () => {
  return (
    <>
      <Paragraph style={{ paddingHorizontal: 60 }}>Um Ihnen ein Brillenglas empfehlen zu können, müssen Sie die Brillenglas-Bedarfsanalyse durchführen.</Paragraph>
      <Divider />
      <UnderConstruction />
    </>
  )
}
