import React from 'react'
import { Image, Linking, TouchableOpacity, View } from 'react-native'
import { scale } from '../../scaling/ScaledUI'
import theme from '../../styles/theme'
import Icon from 'react-native-vector-icons/MaterialCommunityIcons'

export default props => {
  const {
    image,
    url,
    width,
    icon
  } = props

  return (
    <TouchableOpacity
      onPress={() =>
        Linking.openURL(url)}
      style={{ width: width }}
    >
      <View style={{ flex: 1, flexDirection: 'column' }}>
        {image && (
          <View style={{
            flex: 1,
            flexDirection: 'row',
            alignSelf: 'center',
            height: scale(40),
            width: scale(40)
          }}
          >
            <Image
              style={{
                alignSelf: 'center',
                height: scale(40),
                width: scale(40)
              }}
              source={image}
            />
          </View>
        )}
        {icon && (
          <View style={{
            flex: 1,
            flexDirection: 'row',
            alignSelf: 'center'
          }}
          >
            <Icon name={icon} style={{ alignSelf: 'center' }} color={theme.colors.primary} size={scale(45)} />
          </View>
        )}
      </View>
    </TouchableOpacity>
  )
}
