import { createMaterialBottomTabNavigator } from '@react-navigation/material-bottom-tabs'
import React, { useEffect } from 'react'
import { Image } from 'react-native'
import Icon from 'react-native-vector-icons/MaterialCommunityIcons'
import ViSpecsLogo from '../../assets/vi_trans.png'
import theme from '../styles/theme'
// import OpticianNavigator from './OpticianNavigator'
import Contact from '../screens/Contact'
import Home from '../screens/Home'
import { useUser } from '../hooks/user'
import Admin from '../screens/Admin'

const Tab = createMaterialBottomTabNavigator()

export default () => {
  const { userGroups, loadUserGroups } = useUser()

  useEffect(() => {
    loadUserGroups()
  }, [])

  return (
    <Tab.Navigator
      initialRouteName='Home'
      barStyle={{
        backgroundColor: theme.colors.background
      }}
      activeColor={theme.colors.primary}
    >
      <Tab.Screen
        name='Home'
        component={Home}
        options={({ route }) => ({
          tabBarLabel: 'ViSpecs',
          tabBarIcon: ({ color }) => (
            <Image
              style={{ height: 24, width: 24, tintColor: color }}
              source={ViSpecsLogo}
            />
          )
        })}
      />
      {/* {false && ( // disabled in beta
        <Tab.Screen
          name='Optician'
          component={OpticianNavigator}
          options={{
            tabBarLabel: 'digitaler Optiker',
            tabBarIcon: ({ color }) => (
              <Icon name='glasses' color={color} size={24} />
            )
          }}
        />
      )} */}
      {(userGroups && (userGroups.includes('vispecs') || userGroups.includes('admin'))) && (
        <Tab.Screen
          name='Admin'
          component={Admin}
          options={{
            tabBarIcon: ({ color }) => (
              <Icon name='wrench' color={color} size={24} />
            )
          }}
        />
      )}
      <Tab.Screen
        name='Feedback'
        component={Contact}
        options={{
          tabBarIcon: ({ color }) => (
            <Icon
              name='comment-text-multiple-outline'
              color={color}
              size={24}
            />
          )
        }}
      />
    </Tab.Navigator>
  )
}
