import React, { useEffect } from 'react'
import UnderConstruction from '../../components/general/UnderConstruction'
import { useLensesEvaluation } from '../../hooks/general/lensesEvaluation'
import { usePassport } from '../../hooks/user/passport'
import { useAssessment } from '../../hooks/user/assessment'

import { View } from 'react-native'
import DefaultScreen from '../../components/screens/DefaultScreen'
import Blank from '../../components/templates/Blank'
import Hexagon from '../../components/templates/Hexagon'
import PlainSurface from '../../components/Surfaces/PlainSurface'
import { Headline, Paragraph, Text } from 'react-native-paper'
import LenseCarousel from '../../components/Evaluation/LenseCarousel'
import { scale } from '../../scaling/ScaledUI'

export default () => {
  const { lensesPassport, fetchLensesPassport } = usePassport()
  const { lensesAssessment, fetchLensesAssessment } = useAssessment()
  const { evaluatedLenses, evaluateLenses } = useLensesEvaluation()

  useEffect(() => {
    if (!lensesPassport)fetchLensesPassport()
    if (!lensesAssessment)fetchLensesAssessment()
    // console.log(JSON.stringify(lensesAssessment, null, 4))
    if (lensesPassport && lensesAssessment)evaluateLenses(JSON.parse(lensesAssessment.assessmentData), lensesPassport)
  }, [lensesPassport, evaluatedLenses])

  return (
    <DefaultScreen>
      <Hexagon>
        <Blank scrollable ignoreHeader noPadding style={{ width: '100%' }}>
          <View
            style={{
              marginTop: 6,
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <PlainSurface style={{ width: '100%' }}>
              <Headline>Empfehlung von Vispecs</Headline>
              <Paragraph>
            Wir empfehlen anhand ihrer Bedürfnisse folgende Kontaktlinsen:
              </Paragraph>
              {evaluatedLenses && (
                <>
                  {/* <View style={{ flex: 1, flexDirection: 'row', marginBottom: scale(11) }}>
                    <View style={{ flex: 1, flexDirection: 'column' }}>
                      <Text style={{ fontWeight: 'bold' }}>Kategorie: {getCategory(modifiedAssessment.assessment)}</Text>
                      <Text style={{ fontWeight: 'bold', position: 'absolute', right: 0 }}>UVP: {getUvpRange(modifiedAssessment.assessment)}</Text>
                    </View>
                    <Text style={{ fontWeight: 'bold', position: 'absolute', right: 0, marginTop: scale(14) }}>zzgl. Extras</Text>
                  </View> */}
                  <LenseCarousel data={evaluatedLenses} />
                </>
              )}
              {!evaluatedLenses && (
                <View style={{ width: '100%' }}>
                  <LenseCarousel />
                </View>
              )}
            </PlainSurface>
          </View>
        </Blank>
      </Hexagon>
    </DefaultScreen>
  )
}
