import React, { useEffect } from 'react'
import { StyleSheet, View, TouchableOpacity, Platform } from 'react-native'
import { Avatar, Button, Headline, Text } from 'react-native-paper'
import { moderateScale, scale } from 'react-native-size-matters'
import { useUser } from '../hooks/user'
import { PassportProvider } from '../hooks/user/passport'
import PassportCard from './Cards/PassportCard'
import ShareCard from './Cards/ShareCard'
import Dashboard from '../components/templates/Dashboard'
import DefaultScreen from '../components/screens/DefaultScreen'
import AssessmentDashboardCard from './Cards/AssessmentDashboardCard'
import MapCard from './Cards/MapCard'
import LinkButton from '../components/LinkButton'
import { useLinkProps } from '@react-navigation/native'
import EvaluationCard from './Cards/EvaluationCard'

export default () => {
  const {
    signOut,
    userAttributes,
    userPicture,
    getFormattedPhoneNumber,
    fetchUserPicture
  } = useUser()

  const { onPress } = useLinkProps({ to: '/profile' })

  const handleProfilePress = () => {
    onPress()
  }

  useEffect(() => {
    fetchUserPicture()
  }, [userAttributes.userPicture])

  return (
    <DefaultScreen>
      <Dashboard>
        <Dashboard.Header>
          <TouchableOpacity
            style={styles.headerTouchable}
            onPress={handleProfilePress}
          >
            {userPicture ? (
              <Avatar.Image
                source={{ uri: userPicture }}
                size={64}
                style={styles.avatarImage}
              />
            ) : (
              <Avatar.Icon
                icon='account-circle-outline'
                size={scale(96)}
                style={styles.avatarIcon}
              />
            )}
            <View style={{ flex: 1 }}>
              <Headline style={styles.name}>
                {userAttributes.name.trim()}
              </Headline>
              <Text style={styles.phone}>
                {userAttributes.phone_number && getFormattedPhoneNumber()}
              </Text>
            </View>
          </TouchableOpacity>
        </Dashboard.Header>
        <Dashboard.Content>
          <PassportProvider>
            <PassportCard />
          </PassportProvider>
          <View style={{ height: 0, marginVertical: moderateScale(1, 8.0) }} />
          <View style={{ flex: 1, flexDirection: Platform.OS === 'web' ? 'row' : 'column', alignItems: 'center' }}>
            <AssessmentDashboardCard />
            <EvaluationCard />
          </View>
          <View style={{ flex: 1, flexDirection: Platform.OS === 'web' ? 'row' : 'column', alignItems: 'center' }}>
            <MapCard />
            <ShareCard />
          </View>
          <LinkButton icon='information-outline' to='/legal'>
            Impressum &amp; Datenschutzerklärung
          </LinkButton>
          <Button onPress={signOut}>Sign Out</Button>
        </Dashboard.Content>
      </Dashboard>
    </DefaultScreen>
  )
}

const styles = StyleSheet.create({
  headerTouchable: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  name: {
    color: 'white'
  },
  phone: {
    color: 'white',
    fontWeight: '300'
  },
  avatarIcon: {
    marginLeft: scale(-24),
    marginTop: scale(-16),
    marginBottom: scale(-23) // icon is slightly off-center
  },
  avatarImage: {
    marginLeft: scale(-8),
    marginRight: scale(14),
    marginTop: scale(2)
  },
  swiperScreen: {
    marginHorizontal: 12
  },
  padded: {
    paddingHorizontal: 12
  }
})
