import React, { useEffect, useState } from 'react'
import GoogleMap from '../components/Map/GoogleMap'
import DefaultScreen from '../components/screens/DefaultScreen'
import { useMarker } from '../hooks/map/marker'
import { Marker } from 'react-google-maps'
import OpticianCard from '../components/Map/OpticianCard'
import { useUser } from '../hooks/user'

export default () => {
  const [activeMarker, setActiveMarker] = useState()
  const [infoVisible, setInfoVisible] = useState()

  const { markerList, fetchMarkerList } = useMarker()
  const { userMetadata, loadUserMetadata, updateLocalOptician } = useUser()

  useEffect(() => {
    fetchMarkerList()
    loadUserMetadata()
  }, [markerList, userMetadata])

  const handleMarkerClick = (marker) => {
    setActiveMarker(marker)
    setInfoVisible(true)
  }

  const handleSelectOpticianClick = () => {
    updateLocalOptician(activeMarker.id)
  }

  return (
    <DefaultScreen fullscreen>
      <GoogleMap
        googleMapURL='https://maps.googleapis.com/maps/api/js?key=AIzaSyDjNBEldZ_-sEy0eRpRsLNa0h7VwNSd1zo&v=3.exp&libraries=geometry,drawing'
        loadingElement={<div style={{ height: '100%' }} />}
        containerElement={<div style={{ height: '100%', width: '100%' }} />}
        defaultCenter={{ lat: 48.210, lng: 16.363 }}
        mapElement={<div style={{ height: '100%' }} />}
        markers={
          markerList !== undefined && markerList.map(marker => (
            <Marker key={marker.id} position={{ lat: parseFloat(marker.lat), lng: parseFloat(marker.lng) }} onClick={() => handleMarkerClick(marker)} />
          ))
        }
        onClick={() => setInfoVisible(false)}
      />
      {infoVisible && (<OpticianCard id={activeMarker.id} name={activeMarker.name} address={activeMarker.address} onSelect={() => handleSelectOpticianClick()} localOpticianId={userMetadata?.localOpticianId} />)}
    </DefaultScreen>
  )
}
