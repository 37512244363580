import { useNavigation, useRoute } from '@react-navigation/native'
import React, { useContext, useLayoutEffect, useState } from 'react'
import { View } from 'react-native'
import { Portal } from 'react-native-paper'
import Hexagon from '../components/templates/Hexagon'
import theme from '../styles/theme'
import { FormWizardContext } from './FormWizardContext'
import InfoModal from './InfoModal'
import NextButton from './NextButton'
import { IconButton, scale } from '../scaling/ScaledUI'
import DefaultScreen from '../components/screens/DefaultScreen'

export default React.memo(({ children }) => {
  const navigation = useNavigation()
  const route = useRoute()
  const { screens } = useContext(FormWizardContext)
  const index = +route.params.index

  const [infoVisible, setInfoVisible] = useState(false)
  const info = screens[index].props.info

  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight:
        info &&
        (() => (
          <IconButton
            icon='information-outline'
            size={scale(24)}
            color={theme.colors.primary}
            onPress={() => setInfoVisible(true)}
          />
        ))
    })
  })

  return (

    <DefaultScreen hideNavbar>
      <Hexagon offsetHeader>
        {info && (
          <Portal>
            <InfoModal
              info={info}
              visible={infoVisible}
              onDismiss={() => setInfoVisible(false)}
            />
          </Portal>
        )}
        <View
          style={{
            flex: 1,
            justifyContent: 'space-evenly',
            alignItems: 'center'
          }}
        >
          {children}
        </View>
        <NextButton>weiter</NextButton>
      </Hexagon>
    </DefaultScreen>
  )
})
