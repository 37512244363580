import React from 'react'
import { StyleSheet, View, ImageBackground } from 'react-native'
import maxWidth from '../../styles/maxWidth'
import Navbar from '../Nav/Navbar'
import Footer from '../Nav/Footer'
import theme from '../../styles/theme'

export default ({ children, style, hideNavbar, fullscreen, background, isLanding }) => {
  const styles = StyleSheet.create({
    image: {
      flex: 1,
      resizeMode: 'cover',
      justifyContent: 'center'
    },
    outer: {
      flex: 1,
      marginTop: 72.5,
      width: '100%',
      maxWidth: !fullscreen ? (isLanding ? maxWidth.ladingScreen : maxWidth.screen) : undefined,
      alignSelf: 'center',
      alignItems: 'center'
    },
    inner: {
      flex: 1,
      width: '100%',
      maxWidth: !fullscreen ? (isLanding ? maxWidth.landingContent : maxWidth.landingContent) : undefined,
      justifyContent: isLanding ? 'flex-start' : 'center',
      alignItems: 'center'
    },
    card: {
      // backgroundColor: theme.colors.background,
      // shadowColor: '#000',
      // shadowOffset: {
      // width: 0,
      // height: 0
      // },
      // shadowOpacity: 0.1,
      // shadowRadius: 4.65,
      // elevation: 0,
      paddingHorizontal: 15
    }
  })

  return (
    <>
      <ImageBackground source={{ uri: background }} style={styles.image}>
        {!hideNavbar && <Navbar width={true ? maxWidth.ladingScreen : maxWidth.screen} />}
        <View style={[styles.outer]}>
          <View style={[styles.inner, background && styles.card, style]}>{children}</View>
        </View>
      </ImageBackground>
      {!hideNavbar && <Footer width={true ? maxWidth.ladingScreen : maxWidth.screen} />}
    </>
  )
}
