import React from 'react'
import { Image, Platform } from 'react-native'
import { scale, moderateScale } from 'react-native-size-matters'
import OpticianAvatar from '../../assets/chri-avatar.jpg'

export default props => {
  function imgSize () {
    if (Platform.OS === 'web') return 200
    const x = scale(100)
    if (x < 112) return x - 20
    else return scale(110, 1.5)
  }

  return (
    <Image
      source={OpticianAvatar}
      style={{
        width: imgSize(),
        height: imgSize(),
        borderRadius: scale(4),
        marginBottom: moderateScale(10, 2)
      }}
    />
  )
}
