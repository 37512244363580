import React from 'react'
import { Button } from 'react-native-paper'
import { useLinkProps } from '@react-navigation/native'

export default props => {
  const { onPress } = useLinkProps({ to: props.to })

  return (
    <Button
      onPress={onPress}
      mode={props.mode}
      icon={props.icon}
      style={{ ...props.style }}
    >
      {props.children}
    </Button>
  )
}
