import React from 'react'
import { Button, Card, Title, Paragraph } from 'react-native-paper'
import { StyleSheet } from 'react-native'
import { useUser } from '../../hooks/user'

export default (props) => {
  const { user } = useUser()

  return (
    <Card style={styles.card}>
      <Card.Content>
        <Title>{props.name || 'Bitte warten...'}</Title>
        <Paragraph>{props.address || '...'}</Paragraph>
      </Card.Content>
      {user && (
        <Card.Actions style={styles.buttonHolder}>
          <Button mode='contained' disabled={props.localOpticianId === props.id} style={styles.button} onPress={props.onSelect}>{props.localOpticianId === props.id ? 'Ausgewählt' : 'Auswählen'}</Button>
        </Card.Actions>
      )}
    </Card>
  )
}

const styles = StyleSheet.create({
  card: {
    position: 'absolute',
    bottom: 10,
    minWidth: 400,
    minHeight: 150,
    maxWidth: 500,
    maxHeight: 250,
    zIndex: 10,
    alignSelf: 'center'
  },
  buttonHolder: {
    display: 'flex',
    alignItems: 'flex-end'
  },
  button: {
    bottom: 0,
    width: '100%'
  }
})
