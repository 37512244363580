import React, { useState, useEffect } from 'react'
import { Image, ImageBackground, View } from 'react-native'
import Background from '../../../assets/tinted-base.jpg'
import Foreground from '../../../assets/tinted-frame.png'
import RadioInput from './RadioInput'

export default props => {
  const { options, input } = props
  const { value, onChange } = input
  const [option, rangeValue] = value
  const [containerWidth, setContainerWidth] = useState(0)
  const [ratio, setRatio] = useState(2 / 3)
  const [localRangeValue, setLocalRangeValue] = useState(0.2)

  const handleSize = e => {
    setContainerWidth(e.nativeEvent.layout.width)
    const { width, height } = Image.resolveAssetSource(Background)
    setRatio(height / width)
  }

  const setOption = newOption => {
    onChange([newOption, null])
  }

  function handleAnimate () {
    setLocalRangeValue(localRangeValue => localRangeValue + 0.015)
  }

  function getOpacity (v) {
    while (v - 0.85 > 0) v -= 1.3
    if (v < 0) v = 0.85
    return v
  }

  useEffect(() => {
    const timer = setInterval(handleAnimate, 40)

    return () => {
      clearInterval(timer)
    }
  }, [])

  return (
    <View onLayout={handleSize}>
      <RadioInput
        options={options}
        input={{ value: option, onChange: setOption }}
      />
      <ImageBackground
        source={Background}
        style={{
          width: containerWidth,
          height: containerWidth * ratio
        }}
      >
        <View
          style={{
            width: containerWidth,
            height: containerWidth * ratio,
            backgroundColor: options.find(o => o.id === option).color,
            opacity: (getOpacity(localRangeValue) / 0.85) * 0.5
          }}
        />
        <View
          style={{
            zIndex: 2,
            position: 'relative',
            top: -containerWidth * ratio,
            width: containerWidth,
            height: containerWidth * ratio,
            backgroundColor: 'black',
            opacity: (getOpacity(localRangeValue) / 0.85) * 0.5
          }}
        />
        <Image
          source={Foreground}
          style={{
            zIndex: 3,
            position: 'relative',
            top: -2 * containerWidth * ratio,
            width: containerWidth,
            height: containerWidth * ratio
          }}
        />
      </ImageBackground>
    </View>
  )
}
