import React from 'react'
import { TextInput } from 'react-native-paper'

export default props => {
  const { style, input, initialValue, ...inputProps } = props
  const { value, onChange } = input

  return (
    <TextInput
      onLayout={() => onChange(initialValue)}
      style={style}
      value={value}
      onChangeText={onChange}
      keyboardType='default'
      multiline
      numberOfLines={4}
      {...inputProps}
    />
  )
}
