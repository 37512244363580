import { Auth } from '@aws-amplify/auth'
import { API, graphqlOperation } from '@aws-amplify/api'
import Storage from '@aws-amplify/storage'
import React, { createContext, useContext, useState, useCallback } from 'react'
import { parsePhoneNumberFromString } from 'libphonenumber-js/mobile'
import * as mutations from '../../graphql/mutations'
import * as queries from '../../graphql/queries'

// for testing
const USERNAME = 'testuser'
const PASSWORD = 'VtNiXnGUC2iK'

const useProvideUser = () => {
  const [user, setUser] = useState()
  const [loadingAuth, setLoadingAuth] = useState(false)
  const [userPicture, setUserPicture] = useState(null)
  const [userMetadata, setUserMetadata] = useState()
  const [userGroups, setUserGroups] = useState()

  const userAttributes = user?.attributes

  const signIn = () => {
    setLoadingAuth(true)
    Auth.signIn(USERNAME, PASSWORD)
      .then(user => {
        setUser(user)
      })
      .catch(console.error)
      .finally(() => setLoadingAuth(false))
  }

  const signOut = () => {
    Auth.signOut()
      .then(() => {
        setUser()
      })
      .catch(console.error)
  }

  const getFormattedPhoneNumber = () => {
    return parsePhoneNumberFromString(
      userAttributes.phone_number
    ).formatInternational()
  }

  const fetchUserPicture = () => {
    if (userAttributes) {
      Storage.get(userAttributes.picture, { level: 'private' }).then(
        setUserPicture
      )
    }
  }

  const reload = useCallback(() => {
    loadUser()
  }, [])

  const loadUser = () => {
    Auth.currentAuthenticatedUser()
      .then(setUser)
      .catch(() => {
        console.debug('UserProvider: no user currently authenticated')
        setUser(null)
      })
  }

  const updateUserAttributes = (user, values) => {
    Auth.updateUserAttributes(values)
      .then(res => {
        if (res === 'SUCCESS') {
          reload()
          return true
        }
      })
      .catch(console.error)
  }

  const updateLocalOptician = (id) => {
    API.graphql(
      graphqlOperation(mutations.updateUserMetadata, {
        input: {
          id: userAttributes.sub,
          localOpticianId: id
        }
      })
    ).catch(console.error)
  }

  const loadUserMetadata = () => {
    if (!user) return setUserMetadata(undefined)

    API.graphql(
      graphqlOperation(queries.getUserMetadata, {
        id: user.attributes.sub
      })
    )
      .then(({ data }) => {
        if (data.getUserMetadata === null) {
          const initUserMetadata = {
            id: user.attributes.sub
          }
          API.graphql(
            graphqlOperation(mutations.createUserMetadata, {
              input: initUserMetadata
            })
          )
          setUserMetadata(initUserMetadata)
        } else {
          setUserMetadata(data.getUserMetadata)
        }
      })
      .catch(console.error)
  }

  const loadUserGroups = () => {
    if (!user) return setUserGroups(undefined)

    console.debug('UserProvider: loading user groups')

    setUserGroups(user.signInUserSession.accessToken.payload['cognito:groups'])
  }

  return {
    user,
    userAttributes,
    userMetadata,
    userGroups,
    loadingAuth,
    userPicture,
    signIn,
    signOut,
    getFormattedPhoneNumber,
    fetchUserPicture,
    loadUserMetadata,
    updateUserAttributes,
    updateLocalOptician,
    loadUserGroups
  }
}

const UserContext = createContext()

export const UserProvider = ({ children }) => {
  const value = useProvideUser()

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export const useUser = () => useContext(UserContext)
