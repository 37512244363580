import React from 'react'
import ActionCard from '../../components/ActionCard'
import { Linking } from 'react-native'
import Background from '../../../assets/backgrounds/hombre-guapo-tienda-optica/21985lr.jpg'
import { Paragraph } from 'react-native-paper'

export default props => {
  const handlePress = () => {
    const msg =
      'ViSpecs - Der digital Optiker. Lade die App jetzt auf Android: https://play.google.com/store/apps/details?id=com.vispecs.app oder IOS https://apps.apple.com/at/app/vispecs/id1508489528 herunter'
    Linking.openURL('whatsapp://send?text=' + msg)
  }

  return (
    <ActionCard
      title='Vispecs empfehlen'
      background={Background}
      handlePress={handlePress}
    >
      <Paragraph>
        Zufrieden mit Vispecs? Dann empfehlen Sie die App doch ihren Freunden.
      </Paragraph>
    </ActionCard>
  )
}
