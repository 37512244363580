import React from 'react'
import { Text, TouchableOpacity } from 'react-native'
import { useLinkProps } from '@react-navigation/native'

export default ({ children, to, align }) => {
  const { onPress } = useLinkProps({ to: to })
  return (
    <TouchableOpacity
      style={{
        flex: 1,
        alignItems: 'center',
        alignSelf: 'center',
        flexDirection: 'row',
        maxWidth: 400
      }}
      onPress={to && onPress}
    >
      <Text
        style={{
          fontSize: 18,
          color: '#fff',
          fontWeight: '400',
          justifyContent: 'center'
        }}
      >
        {children}
      </Text>
    </TouchableOpacity>
  )
}
