import { createStackNavigator } from '@react-navigation/stack'
import React from 'react'
import AssessmentFrame from '../screens/Assessment/AssessmentFrame'
import AssessmentGlasses from '../screens/Assessment/AssessmentGlasses'
import AssessmentLenses from '../screens/Assessment/AssessmentLenses'
import AssessmentHome from '../screens/AssessmentHome'
import { AssessmentProvider } from '../hooks/user/assessment'

const Stack = createStackNavigator()

export default () => {
  return (
    <AssessmentProvider>
      <Stack.Navigator
        initialRouteName='AssessmentHome'
        headerMode='screen'
        screenOptions={{
          headerTransparent: true
        }}
      >
        <Stack.Screen
          name='AssessmentHome'
          component={AssessmentHome}
          options={{ title: 'Bedarfsanalyse' }}
        />
        <Stack.Screen
          name='AssessmentGlasses'
          component={AssessmentGlasses}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name='AssessmentFrame'
          component={AssessmentFrame}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name='AssessmentLenses'
          component={AssessmentLenses}
          options={{ headerShown: false }}
        />
      </Stack.Navigator>
    </AssessmentProvider>
  )
}
