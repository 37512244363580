import React from 'react'
import ActionCard from '../../components/ActionCard'
import Background from '../../../assets/backgrounds/woman-ophthalmologist-showing-camera-pair-eyeglasses/2952527lr.jpg'
import { Paragraph } from 'react-native-paper'
import { useLinkTo } from '@react-navigation/native'

export default props => {
  const linkTo = useLinkTo()

  const handlePress = () => {
    linkTo('/recommendation')
  }

  return (
    <ActionCard
      title='Unsere Empfehlung'
      subtitle='für ihr nächstes Brillenglas'
      background={Background}
      handlePress={handlePress}
    >
      <Paragraph>
        Aus ihren Ergebnissen der Bedarfsanalyse würden wir Ihnen ein auf Ihre
        Präferenzen zugeschnittenes Brillenglas empfehlen.
      </Paragraph>
    </ActionCard>
  )
}
