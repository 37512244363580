import React, { useState, useEffect } from 'react'
import { View } from 'react-native'
import { useGlassEvaluation } from '../../hooks/general/glassEvaluation'
import { useAssessment } from '../../hooks/user/assessment'
import DefaultScreen from '../../components/screens/DefaultScreen'
import Blank from '../../components/templates/Blank'
import Hexagon from '../../components/templates/Hexagon'
import PlainSurface from '../../components/Surfaces/PlainSurface'
import { Headline, Paragraph, Text } from 'react-native-paper'
import GlassAssessmentManipulator from '../../components/Evaluation/GlassAssessmentManipulator'
import EvaluationCarousel from '../../components/Evaluation/EvaluationCarousel'
import { scale } from '../../scaling/ScaledUI'

export default () => {
  const { evaluatedGlasses, evaluateAssessment, getCategory, getTier, getUvpRange } = useGlassEvaluation()
  const { glassAssessment, formattedGlassAssessment, fetchGlassAssessment } = useAssessment()

  const [modifiedAssessment, setAssessment] = useState()

  useEffect(() => {
    if (!glassAssessment) fetchGlassAssessment()
  }, [])

  useEffect(() => {
    if (!glassAssessment) fetchGlassAssessment()
    if (!modifiedAssessment && formattedGlassAssessment) setAssessment(formattedGlassAssessment)
    if (modifiedAssessment)evaluateAssessment(modifiedAssessment.assessment)
    // console.log('Formatted: ' + JSON.stringify(formattedGlassAssessment, null, 4))
  }, [formattedGlassAssessment, modifiedAssessment, glassAssessment])

  return (
    <DefaultScreen>
      <Hexagon>
        <Blank scrollable ignoreHeader noPadding style={{ width: '100%' }}>
          <View
            style={{
              marginTop: 6,
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >

            {/* {scale(100) > 200 && <ImageChri />} */}
            <PlainSurface style={{ width: '100%' }}>
              <Headline>Empfehlung von Vispecs</Headline>
              <Paragraph>
            Wir empfehlen anhand ihrer Bedürfnisse folgende Brillengläser:
              </Paragraph>
              {/* {props.route.params && props.route.params.extraMessage && (
                <Paragraph>{props.route.params.extraMessage}</Paragraph>
              )} */}
              {evaluatedGlasses && modifiedAssessment && (
                <>
                  <View style={{ flex: 1, flexDirection: 'row', marginBottom: scale(11) }}>
                    <View style={{ flex: 1, flexDirection: 'column' }}>
                      <Text style={{ fontWeight: 'bold' }}>Kategorie: {getCategory(modifiedAssessment.assessment)}</Text>
                      <Text style={{ fontWeight: 'bold', position: 'absolute', right: 0 }}>UVP: {getUvpRange(modifiedAssessment.assessment)}</Text>
                    </View>
                    <Text style={{ fontWeight: 'bold', position: 'absolute', right: 0, marginTop: scale(14) }}>zzgl. Extras</Text>
                  </View>

                  <EvaluationCarousel data={evaluatedGlasses} />
                  {modifiedAssessment && (getTier(modifiedAssessment.assessment) !== '') && (
                    <GlassAssessmentManipulator
                      assessmentData={modifiedAssessment.assessment} width={300} input={{
                        onChange: (value) => {
                          const t = modifiedAssessment
                          t.assessment = value
                          setAssessment(t)
                          evaluateAssessment(t.assessment)
                        }
                      }}
                    />
                  )}
                </>
              )}
              {!evaluatedGlasses && (
                <View style={{ width: '100%' }}>
                  <EvaluationCarousel />
                </View>
              )}
            </PlainSurface>
          </View>
        </Blank>
      </Hexagon>
    </DefaultScreen>
  )
}
