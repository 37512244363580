import markersFielmann from '../data/fielmannList'
import markersHartlauer from '../data/hartlauerList'
import markersMisc from '../data/miscOpticianList'
import markersOpticon from '../data/opticonList'
import markersPearl from '../data/pearlList'
import markersUnited from '../data/unitedList'
import markersWutscher from '../data/wutscherList'

const formatOpticon = list =>
  list.map(entry => {
    return {
      ...entry,
      id: 'opticon_' + entry.id,
      address: entry.address.replace('<br/>', ', ')
    }
  })

const formatMisc = list =>
  list.map(entry => {
    return {
      ...entry,
      id: 'misc_' + entry.id,
      address: entry.address
    }
  })

const formatWutscher = list =>
  list.map(entry => {
    return {
      ...entry,
      id: 'wutscher_' + entry.id,
      address: entry.address
    }
  })

const formatHartlauer = list =>
  list.map(entry => {
    return {
      ...entry,
      id: 'hartlauer_' + entry.id,
      address: entry.address
    }
  })

const formatUnited = list =>
  list.map(entry => {
    return {
      ...entry,
      id: 'united_' + entry.objectId,
      address: `${entry.street}, ${entry.zip} ${entry.city}`
    }
  })

const formatFielmann = list =>
  list.map(entry => {
    return {
      ...entry,
      id: 'fielmann_' + entry.uid,
      address: `${entry.street}, ${entry.zip} ${entry.city}`,
      lat: entry.xCoordinates,
      lng: entry.yCoordinates,
      name: `${entry.firmierung} ${entry.title}`
    }
  })

const formatPearl = list =>
  list.map(entry => {
    return {
      ...entry,
      id: 'pearl_' + entry.storeID,
      address: `${entry.address}, ${entry.POST_CODE} ${entry.CITY}`,
      lat: entry.latitude,
      lng: entry.longitude,
      name: entry.NAME
    }
  })

// {id, lat, lng, name, address}
const markers = [
  formatOpticon(markersOpticon),
  formatWutscher(markersWutscher),
  formatHartlauer(markersHartlauer),
  formatMisc(markersMisc),
  formatUnited(markersUnited),
  formatFielmann(markersFielmann),
  formatPearl(markersPearl)
].flat()

function getAllMarkers () {
  return markers
}

export { getAllMarkers }