import React from 'react'
import { View } from 'react-native'

export default ({ backgroundColor, children, width, position }) => {
  return (
    <View
      style={{ flex: 1, flexDirection: 'row', width: width || '100%', backgroundColor: backgroundColor, position: position }}
    >
      {children}
    </View>
  )
}
