import React, { useState } from 'react'
import { View } from 'react-native'
import theme from '../../styles/theme'
import Slider from '@react-native-community/slider'
import { Subheading } from 'react-native-paper'
import { scale } from '../../scaling/ScaledUI'

export default props => {
  const { options, input, name, initital, size, disabled } = props
  const { onChange } = input
  const [step, setStep] = useState(getInitial())

  function getInitial () {
    // console.log(initital + '    ' + JSON.stringify(options, null, 4))
    if (options.length === 2) {
      if (initital === 'standard') return 0
      if (initital === 'no') return 0
      else return 1
    }
    if (initital === 'standard') return 0
    if (initital === 'bestseller') return 1
    if (initital === 'premium') return 2
    return 0
  }

  const handleSlidingComplete = step => {
    if ((options && options[step] && options[step].id)) { onChange(options[step].id) }
  }

  return (
    <View style={{ flex: 1, flexDirection: 'row' }}>
      <Subheading style={{ flex: 1, alignSelf: 'center' }}>{name}</Subheading>
      <View style={{ flex: 1 }}>
        <View style={{ flex: 1, flexDirection: 'row', width: '100%' }}>
          <Subheading style={{ color: disabled ? theme.colors.disabled : theme.colors.primary }}>
            {(options && options[step] && options[step].text) ? options[step].text : ' '}
          </Subheading>
        </View>
        <Slider
          disabled={disabled}
          style={{ flex: 1, height: scale(50) }}
          value={step}
          onValueChange={setStep}
          onSlidingComplete={handleSlidingComplete}
          minimumTrackTintColor={theme.colors.primary}
          // thumbImage={require('../../../../assets/slider_hollow_60x60.png')}
          thumbTintColor={theme.colors.primary}
          minimumValue={0}
          maximumValue={size - 1}
          step={1}
        />
      </View>
    </View>
  )
}
