import React from 'react'
import { StyleSheet, View, TouchableOpacity, ImageBackground } from 'react-native'
import { IconButton, Headline, Subheading } from 'react-native-paper'
import theme from '../styles/theme'

export default props => {
  const { title, subtitle, children, background, handlePress } = props

  return (
    <View style={styles.background}>
      <ImageBackground source={background} resizeMode='cover' style={{ padding: 0, height: '100%' }} imageStyle={{ opacity: 0.15 }}>
        <TouchableOpacity onPress={handlePress}>
          <View style={styles.content}>
            <View style={{ flex: 1, height: '100%' }}>
              <IconButton
                size={160}
                style={styles.icon}
                color={theme.colors.primary}
                icon='chevron-right'
              />
            </View>
            <View style={{ flex: 3, height: '100%', flexDirection: 'row', alignItems: 'center', paddingRight: 20 }}>
              <View style={{ flex: 1 }}>
                {title && <Headline style={styles.headline}>{title}</Headline>}
                {subtitle && (
                  <Subheading style={styles.subheading}>{subtitle}</Subheading>
                )}
                {children}
              </View>
            </View>
          </View>
        </TouchableOpacity>
      </ImageBackground>
    </View>
  )
}

const styles = StyleSheet.create({
  background: {
    marginVertical: 40,
    width: 650,
    height: 300,
    borderRadius: theme.roundness,
    backgroundColor: theme.colors.background,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 4
    },
    shadowOpacity: 0.3,
    shadowRadius: 4.65,
    elevation: 8,
    margin: 24
  },
  content: {
    padding: 10,
    height: '100%',
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center'
  },
  inner: {
    padding: 10
  },
  image: {
    height: 60,
    width: 60,
    borderRadius: 4
  },
  icon: {
    marginLeft: -42,
    marginTop: -40
  },
  subheading: {
    fontWeight: '300',
    fontSize: 24,
    marginBottom: 15,
    marginTop: 5
  },
  headline: {
    fontSize: 32
  }
})
