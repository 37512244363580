/* global fetch */
import { Storage } from '@aws-amplify/storage'
import Constants from 'expo-constants'
import * as ImagePicker from 'expo-image-picker'
import React, { useState, useEffect } from 'react'
import { Image, StyleSheet, View } from 'react-native'
import uuid from 'react-native-uuid'
import theme from '../../styles/theme'
import * as ImageManipulator from 'expo-image-manipulator'
import {
  scale,
  moderateScale,
  Button,
  IconButton
} from '../../scaling/ScaledUI'

const imagePickerOptions = {
  mediaTypes: ImagePicker.MediaTypeOptions.Images,
  exif: false
}

export default ({ input, manipulateActions }) => {
  const { onChange, value } = input

  const [image, setImage] = useState(null)
  const [width, setWidth] = useState(scale(200))

  useEffect(() => {
    if (!value || image) return

    Storage.get(value, { level: 'private' }).then(setImage)
  }, [value, image])

  const handleSize = e => {
    setWidth(Math.floor(e.nativeEvent.layout.width))
  }

  const handleCamera = async () => {
    const { status } = await ImagePicker.requestCameraPermissionsAsync()
    if (status !== 'granted') return

    return ImagePicker.launchCameraAsync(imagePickerOptions).then(handleResult)
  }

  const handleLibrary = async () => {
    if (Constants.platform.ios) {
      const { status } = await ImagePicker.requestCameraRollPermissionsAsync()
      if (status !== 'granted') return
    }

    return ImagePicker.launchImageLibraryAsync(imagePickerOptions).then(
      handleResult
    )
  }

  const manipulateImage = async imageSource => {
    return manipulateActions
      ? ImageManipulator.manipulateAsync(imageSource.uri, manipulateActions, {
          compress: 0.75
        })
      : imageSource
  }

  const handleResult = result => {
    if (result.cancelled) return

    setImage(result.uri)

    manipulateImage(result)
      .then(imageSource => fetch(imageSource.uri))
      .then(res => res.blob())
      .then(blob => {
        const id = uuid.v1()
        onChange(id + '.jpg')
        return Storage.put(id + '.jpg', blob, {
          level: 'private',
          contentType: 'image/jpeg'
        })
      })
      .catch(console.error)
  }

  return (
    <View style={styles.container}>
      <View style={styles.imageBox} onLayout={handleSize}>
        {image || value ? (
          <Image
            style={{
              ...styles.image,
              width: scale(width - 2)
            }}
            resizeMode='contain'
            source={{ uri: image || value }}
          />
        ) : (
          <IconButton icon='image' color={theme.colors.borders} />
        )}
      </View>
      <View style={styles.actionBox}>
        <Button
          style={styles.button}
          icon='camera'
          mode='outlined'
          onPress={handleCamera}
        >
          Kamera
        </Button>
        <Button
          style={styles.button}
          icon='folder-image'
          mode='outlined'
          onPress={handleLibrary}
        >
          Galerie
        </Button>
      </View>
    </View>
  )
}

const containerHeight = moderateScale(200, 0.7)

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    height: containerHeight,
    marginTop: scale(5)
  },
  imageBox: {
    marginRight: scale(8),
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: scale(1),
    borderColor: theme.colors.borders,
    borderRadius: scale(4)
  },
  image: {
    borderRadius: 4,
    height: containerHeight - 2
  },
  actionBox: {
    marginLeft: moderateScale(8),
    flex: 1,
    justifyContent: 'center'
  },
  button: {
    marginVertical: moderateScale(8)
  }
})
