import React from 'react'
import { View, StyleSheet } from 'react-native'
import theme from '../../styles/theme'
import { scale, moderateScale } from 'react-native-size-matters'

export default ({ children, style }) => {
  return <View style={{ ...styles.container, ...style }}>{children}</View>
}

const styles = StyleSheet.create({
  container: {
    borderWidth: scale(1, 2),
    borderColor: theme.colors.borders,
    borderRadius: theme.roundness,
    overflow: 'hidden',
    backgroundColor: theme.colors.foreground,
    paddingHorizontal: moderateScale(16, 1),
    paddingVertical: moderateScale(16, 1)
  }
})
