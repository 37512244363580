import React, { createContext, useContext, useState } from 'react'
import { getAllMarkers } from '../../services/markerDataFormatter'

const useProvideMarker = () => {
  const [markerList, setMarkerList] = useState([])

  const fetchMarkerList = () => {
    setMarkerList(getAllMarkers)
  }

  const getCallout = () => {
    // toDo
  }

  return { markerList, fetchMarkerList }
}

const MarkerContext = createContext()

export const MarkerProvider = ({ children }) => {
  const value = useProvideMarker()

  return (
    <MarkerContext.Provider value={value}>{children}</MarkerContext.Provider>
  )
}

export const useMarker = () => useContext(MarkerContext)
