import _ from 'lodash'
import React from 'react'
import { View } from 'react-native'
import OptionList from './OptionList'

export default props => {
  const { style, options, input, disabled, ...inputProps } = props
  const { value, onChange } = input

  const handlePress = (e, selectedOption) => {
    const newActive = _.xor(value, [selectedOption])
    onChange(newActive)
  }

  return (
    <View style={style}>
      <OptionList
        disabled={disabled || false}
        options={options}
        selected={value}
        onPress={handlePress}
        {...inputProps}
      />
    </View>
  )
}
