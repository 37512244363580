import { useNavigation, useLinkTo } from '@react-navigation/native'
import React, { useState } from 'react'
import { View } from 'react-native'
import {
  Button,
  Headline,
  Subheading,
  Text,
  Portal,
  Paragraph
} from 'react-native-paper'
import Blank from '../../components/templates/Blank'
import ConfirmModal from '../../components/general/ConfirmModal'

export default props => {
  const navigation = useNavigation()
  const linkTo = useLinkTo()

  const [infoVisible, setInfoVisible] = useState(false)

  const isBeta = true

  const handleMapSearch = () => {
    linkTo('/map')
  }

  const handlePassportPhoto = () => {
    if (props.route.params.passportName === 'Kontaktlinsenpass') {
      navigation.navigate('KonktaktlinsenPhoto', {
        intent: props.route.params.passportName
      })
    } else {
      navigation.navigate('Photo', { intent: props.route.params.passportName })
    }
  }

  const handleManualInput = () => {
    navigation.navigate('PassportEditor', { intent: props.route.params.passportName })
  }

  const handleConversion = () => {
    // navigation.navigate('PassportConversion')
  }

  return (
    <Blank scrollable ignoreHeader>
      <Headline>{props.route.params.passportName} digitalisieren</Headline>
      <Subheading>
        Sie haben bereits einen aktuellen {props.route.params.passportName}?
      </Subheading>
      <Text>
        Machen Sie hier schnell und einfach ein Foto von Ihrem{' '}
        {props.route.params.passportName}
        {props.route.params.passportName === 'Kontaktlinsenpass' && (
          <Text> oder Ihrer Kontaktlinsen-Packung</Text>
        )}
        ! Einer unserer Partneroptiker wird sich dann um die Digitalisierung
        kümmern.
      </Text>
      <Button
        mode='outlined'
        icon='camera'
        disabled={isBeta}
        style={{ marginVertical: 12 }}
        onPress={() => setInfoVisible(true)}
      >
        Foto machen {isBeta ? '(bald verfügbar)' : ''}
      </Button>
      <Text>
        Wenn Sie die Daten selbst abtippen möchten, ist dies selbstverständlich
        auch möglich.
      </Text>
      <Button
        mode='outlined'
        icon='table-edit'
        style={{ marginTop: 12 }}
        onPress={handleManualInput}
      >
        Daten eingeben
      </Button>
      {props.route.params.passportName === 'Kontaktlinsenpass' && (
        <>
          <View style={{ height: 12 }} />
          <Subheading>Brillenpass in Kontaktlinsenpass konvertieren</Subheading>
          <Text>
            Da Sie bereits einen Brillenpass besitzen können wir ihnen die Werte
            für den Kontaktlinsenpass berechnen.
          </Text>
          <Button
            mode='outlined'
            icon='vector-arrange-below'
            disabled={isBeta}
            style={{ marginTop: 12 }}
            onPress={handleConversion}
          >
            Brillenpass konvertieren {isBeta ? '(bald)' : ''}
          </Button>
        </>
      )}
      <View style={{ height: 12 }} />
      <Subheading>
        Sie haben noch keinen oder einen sehr alten{' '}
        {props.route.params.passportName}?
      </Subheading>
      <Text>
        Gerne können Sie während der Öffnungszeiten bei einem unserer
        Partneroptiker vorbeikommen, in dessen Filiale ein neuer digitaler{' '}
        {props.route.params.passportName} nach einer kurzen Untersuchung
        kostenlos für Sie ausgestellt wird.
      </Text>
      <Button
        mode='outlined'
        icon='map-search'
        style={{ marginVertical: 12 }}
        onPress={handleMapSearch}
      >
        Optiker finden
      </Button>
      <Portal>
        <ConfirmModal
          title='Datenweitergabe'
          buttonTitle='Fortfahren'
          visible={infoVisible}
          onDismiss={() => setInfoVisible(false)}
          onConfirm={() => {
            handlePassportPhoto()
            setInfoVisible(false)
          }}
        >
          <Paragraph style={{ textAlign: 'justify' }}>
            „Um die Daten Ihres Brillenpasses vollständig und richtig in unser
            System zu übernehmen, leiten wir Ihr hochgeladenes Foto an einen
            unserer Partner-Optiker weiter. Sobald dieser die Daten in unser
            System übertragen hat, können Sie Ihren digitalen Brillenpass an
            einen Optiker Ihrer Wahl senden.“
          </Paragraph>
        </ConfirmModal>
      </Portal>
    </Blank>
  )
}
