import { createStackNavigator } from '@react-navigation/stack'
import React from 'react'
import MapsScreen from '../screens/Maps'
import settings from './settings'
import Download from '../screens/Download'
import Landing from '../screens/Landing'
import Legal from '../screens/Legal'
import Profile from '../screens/Profile'
import AssessmentNavigator from './AssessmentNavigator'
import { Platform } from 'react-native'
import Contact from '../screens/Contact'
import { useUser } from '../hooks/user'
import LoginScreen from '../screens/Login'
import TabNavigator from './TabNavigator'
import Home from '../screens/Home'
import EvaluationTabNavigator from './EvaluationTabNavigator'
import PassportCreationScreen from '../screens/Passport/PassportCreationScreen'
import PassportUpdate from '../screens/Passport/PassportUpdate'
import PassportEditor from '../screens/Passport/PassportEditor'

const Stack = createStackNavigator()

const isWeb = Platform.OS === 'web' ? { headerShown: false } : undefined

export default () => {
  const { user } = useUser()

  const requireAuth = (component) => {
    return user ? component : LoginScreen
  }

  return (
    <Stack.Navigator {...settings.navigator}>
      <Stack.Screen
        name='Landing'
        options={{ title: 'ViSpecs', ...isWeb }}
        component={Landing}
      />
      <Stack.Screen
        name='Login'
        options={{ title: 'Login', ...isWeb }}
        component={LoginScreen}
      />
      <Stack.Screen
        name='Home'
        options={{ title: 'ViSpecs', headerShown: false }}
        component={requireAuth(Platform.OS === 'web' ? Home : TabNavigator)}
      />
      <Stack.Screen
        name='Survey'
        options={{ title: 'Bedarfsanalyse', headerShown: false }}
        component={requireAuth(AssessmentNavigator)}
      />
      <Stack.Screen
        name='Download'
        options={{ title: 'App herunterladen', ...isWeb }}
        component={Download}
      />
      <Stack.Screen
        name='Legal'
        options={{ title: 'ViSpecs', ...isWeb }}
        component={Legal}
      />
      <Stack.Screen
        name='Profile'
        options={{ title: 'Mein Profil', ...isWeb }}
        component={requireAuth(Profile)}
      />
      <Stack.Screen
        name='Recommendation'
        options={{ title: 'Auswertung', ...isWeb }}
        component={requireAuth(EvaluationTabNavigator)}
      />
      <Stack.Screen
        name='Map'
        options={{ title: 'Optiker-Karte', ...isWeb }}
        component={MapsScreen}
      />
      <Stack.Screen
        name='Contact'
        options={{ title: 'Kontakt', ...isWeb }}
        component={Contact}
      />
      <Stack.Screen
        name='Passport'
        options={{ title: 'Digitaler Pass', ...isWeb }}
        component={PassportCreationScreen}
      />
      <Stack.Screen
        name='PassportUpdate'
        options={{ title: 'Digitaler Pass', headerShown: false }}
        component={requireAuth(PassportUpdate)}
      />
      <Stack.Screen
        name='PassportEditor'
        options={{ title: 'Digitaler Pass', headerShown: false }}
        component={requireAuth(PassportEditor)}
      />
    </Stack.Navigator>
  )
}
