export default [
  {
    id: 97,
    name: 'Optiker Blitz Inh. Ernst Wild',
    address: 'Wollzeile 37<br/>1010 Wien',
    tel: '+43 1 512 54 95',
    email: 'office@optikblitz.at',
    www: 'http://www.optikblitz.at',
    open:
      '<b>Montag - Freitag</b><br />10.00 - 18.00<br />und nach Terminvereinbarung<br /><br />',
    lat: '48.2078209',
    lng: '16.3785663',
    view: 1
  },
  {
    id: 91,
    name: 'Azemoptik',
    address: 'Engerthstra\u00dfe 193/3<br/>1020 Wien',
    tel: '+43 1 720 25 54',
    email: 'office@eyemed.at',
    www: 'http://www.azemoptik.at',
    open:
      '<b>Montag</b><br />12.00 - 20.00<br /><b>Dienstag<br /></b>8.00 - 17.00<br /><b>Mittwoch</b><br />9.00 - 20.00<br /><b>Donnerstag</b><br />8.00 - 18.30<br /><b>Freitag<br /></b>8.00 - 14.00',
    lat: '48.2221375',
    lng: '16.4066822',
    view: 1
  },
  {
    id: 427,
    name: 'Die Sichtbar',
    address: 'Wiedner Hauptstrasse 26<br/>1040 Wien',
    tel: '+43 1 585 63 63',
    email: '1040wien@sichtbar.at',
    www: 'www.sichtbar.at',
    open:
      '<b>\u00d6ffnungszeiten:</b><br />Mo-Fr: 10.00 - 18.00 Uhr<br />Sa.: 10.00 - 14.30 Uhr',
    lat: '48.1959984',
    lng: '16.3669697',
    view: 1
  },
  {
    id: 429,
    name: 'Sichtbar GmbH',
    address: 'Pilgramgasse 6<br/>1050 Wien',
    tel: '+43 1 587 07 38',
    email: '1050wien@sichtbar.at',
    www: 'www.sichtbar.at',
    open:
      '<b>\u00d6ffnungszeiten:</b><br />Mo-Fr.: 10.00 - 19.00 Uhr<br />Samstag geschlossen',
    lat: '48.19183',
    lng: '16.35747',
    view: 3
  },
  {
    id: 415,
    name: 'Seitenblicke Optik',
    address: 'Liechtensteinerstra\u00dfe 82-84<br/>1090 Wien',
    tel: '+43 1 / 317 42 90',
    email: 'office@seitenblicke-optik.com',
    www: 'www.seitenblicke-optik.com',
    open:
      '<b>Montag - Freitag</b><br />10.00-19.00<b><br />Samstag</b><br />nur nach Terminvereinbarung',
    lat: '',
    lng: '',
    view: 1
  },
  {
    id: 64,
    name: 'Optiker Klika Ges.m.b.H.',
    address: 'Favoritenstra\u00dfe 166<br/>1100 Wien',
    tel: '+43 1 60 41 394',
    email: 'klika.optik@aon.at',
    www: 'http://www.klika-optik.at',
    open:
      '<b>Montag - Donnerstag</b><br />9.00 - 12.30 , 13.30 - 18.00<br /><b>Freitag</b><br />9.00 - 18.00<br /><b>Samstag<br /></b>9.00 - 12.00',
    lat: '48.1693376',
    lng: '16.379955',
    view: 1
  },
  {
    id: 225,
    name: 'Optik Rauscher',
    address: 'Simmeringer Hauptstra\u00dfe 134/2/11<br/>1110 Wien',
    tel: '0676 / 420 96 65',
    email: 'optik-rauscher@gmx.at',
    www: 'http://www.optik.rauscher.wien',
    open:
      '<b><b>Montag</b><br /></b>13.00-18.00<b><br />Dienstag</b><br />8.00-13.00<b><b><br />Mittwoch</b><br /></b>14.00-19.00<br /><b><b>Donnerstag</b><br /></b>8.00-13.00',
    lat: '48.1689581',
    lng: '16.4213624',
    view: 1
  },
  {
    id: 422,
    name: 'Der Optiker Zielinski',
    address: 'Simmeringer Platz 1<br/>1110 Wien',
    tel: '+43 1 / 749 22 03',
    email: 'der.optiker@gmx.at',
    www: 'www.der-optiker.at',
    open:
      '<b>Montag - Freitag:</b><br />09.00 - 13.00 / 14.00 - 18.00<br /><b>Samstag:</b><br />09.00 - 13.00&nbsp;<br /><br />Termine auch nach Vereinbarung',
    lat: '48.1694145',
    lng: '16.4207250',
    view: 1
  },
  {
    id: 89,
    name: 'Optik - Kontaktlinsen Marco Fuhrmann',
    address: 'Meidlinger Hauptstra\u00dfe 5<br/>1120 Wien',
    tel: '+43 1 865 91 12 0',
    email: 'office@optiker-fuhrmann.at',
    www: 'http://www.optiker-fuhrmann.at',
    open: '<b>Montag - Freitag<br /></b>10.00 - 13.00, 14.00 - 18.00',
    lat: '48.1825648',
    lng: '16.3300421',
    view: 1
  },
  {
    id: 38,
    name: 'Optiker Prior GesmbH',
    address: 'Auhofstra\u00dfe 142<br/>1130 Wien',
    tel: '+43 1 877 45 45',
    email: 'office@optiker-prior.at',
    www: 'http://www.optiker-prior.at',
    open:
      '<b>Montag - Freitag<br /></b>9.00 - 12.30, 14.15 - 18.00<br /><b>Samstag</b><br />9.00 - 12.30',
    lat: '48.1910772',
    lng: '16.2701182',
    view: 3
  },
  {
    id: 425,
    name: 'H\u00f6rstudio Fischer',
    address: 'Hietzinger Hauptstra\u00dfe 36a<br/>1130 Wien',
    tel: '01 / 966 36 31',
    email: 'ohren@hsfischer.at',
    www: 'www.hsfischer.at',
    open:
      '<b>\u00d6ffnungszeiten:</b><br />Montag - Donnerstag&nbsp;<br />09.00 - 14.00 Uhr',
    lat: '48.1870253',
    lng: '16.2965029',
    view: 2
  },
  {
    id: 428,
    name: 'Sichtbar GmbH',
    address: 'Linzerstrasse 253<br/>1140 Wien',
    tel: '+43 1 914 51 65',
    email: '1140wien@sichtbar.at',
    www: 'www.sichtbar.at',
    open:
      '<b>\u00d6ffnungszeiten:</b><br />Mo-Fr.: 09.00 - 12.30 / 13.30 - 18.00 Uhr<br />Samstag geschlossen',
    lat: '48.1948295',
    lng: '16.2842175',
    view: 3
  },
  {
    id: 87,
    name: 'Thomas Fuhrmann GmbH',
    address: 'Europaplatz 3 - EKZ Ebene 0 - Nr. 0.15<br/>1150 Wien',
    tel: '+43 1 962 15 12',
    email: 'optikwf@speed.at',
    www: 'http://www.optikfuhrmann.at',
    open:
      '<b>Montag - Freitag</b><br />9.00 - 21.00<br /><b>Samstag</b><br />9.00 - 18.00',
    lat: '48.1943829',
    lng: '16.3235143',
    view: 1
  },
  {
    id: 228,
    name: 'Optik Podiwinsky - Sch\u00f6ller Optik KG',
    address: 'Ottakringerstra\u00dfe 114-116<br/>1160 Wien',
    tel: '+43 1 486 3157',
    email: 'office@optikpodiwinsky.at',
    www: '',
    open:
      '<b>Montag - Freitag</b><br />9.00 - 12.30 &amp; 13.30 - 18.00<br /><b>Samstag<br /></b>9.00 - 12.30',
    lat: '48.2135857',
    lng: '16.3231901',
    view: 1
  },
  {
    id: 125,
    name: 'Azemoptik',
    address: 'Neulerchenfelderstra\u00dfe 14/2<br/>1160 Wien',
    tel: '+43 1 403 09 54',
    email: 'office@eyemed.at',
    www: 'http://www.azemoptik.at',
    open:
      '<b>Montag/Dienstag</b><br />13.30 - 19.00<br /><b>Mittwoch/Donnerstag<br /></b>8.30 - 14.30, 15.30 - 18.00<b></b>',
    lat: '48.2110366',
    lng: '16.3369632',
    view: 1
  },
  {
    id: 77,
    name: 'Optiker Grubm\u00fcller Hannes Bohrn GesmbH',
    address: 'D\u00f6blinger Hauptstra\u00dfe 41<br/>1190 Wien',
    tel: '+43 1 369 28 00',
    email: 'office@optik-grubmueller.at',
    www: 'http://www.optik-grubmueller.at',
    open:
      '<b>Montag - Freitag</b><br />8.30 - 18.00<br /><b>Samstag</b><br />9.00 - 12.00',
    lat: '48.2381801',
    lng: '16.3541826',
    view: 1
  },
  {
    id: 121,
    name: 'Azemoptik',
    address: 'Stadlauer Stra\u00dfe 62<br/>1220 Wien',
    tel: '+43 1 280 40 03',
    email: '1220@azemoptik.at',
    www: 'http://www.azemoptik.at',
    open:
      '<b>Montag</b><br />12.00 - 18.30<br /><b>Di/Mi/Do/Fr</b><br />9.30 - 18.30<br /><b>Samstag<br /></b>10.00 - 15.00',
    lat: '48.2308883',
    lng: '16.4578732',
    view: 1
  },
  {
    id: 417,
    name: '',
    address: 'Stadlauer Stra\u00dfe 9<br/>1220 Wien',
    tel: '+43 1 / 282 11 76',
    email: 'info@sehwerkstatt.at',
    www: 'www.sehwerkstatt.at',
    open:
      '<b>Montag, Dienstag, Donnerstag, Freitag:</b><br />09.00 - 12.30, 14.00 - 18.00&nbsp;<br /><b>Mittwoch:</b><br />14:00 - 20.00&nbsp;<br /><b>Samstag:</b><br />09.00 - 13.00',
    lat: '',
    lng: '',
    view: 1
  },
  {
    id: 56,
    name: 'Eco Optik',
    address: 'Gesslgasse 1b<br/>1230 Wien',
    tel: '+43 1 889 84 04',
    email: 'office@eco.opitk.at',
    www: 'http://www.ecooptik.at',
    open:
      '<b>Montag - Freitag</b><br />8.30 - 12.30, 14.00 - 18.00<br /><b>Samstag</b><br />9.00 - 12.00 ',
    lat: '48.1499912',
    lng: '16.2688629',
    view: 1
  },
  {
    id: 86,
    name: 'Thomas Fuhrmann GmbH',
    address: 'Breitenfurter Stra\u00dfe 360-368<br/>1230 Wien',
    tel: '+43 1 865 91 12 0',
    email: 'optikwf@speed.at',
    www: 'http://www.optikfuhrmann.at',
    open:
      '<b>Montag - Freitag</b><br />8.30 - 12.30, 14.00 - 18.00<br /><b>Samstag</b><br />9.00 - 12.00',
    lat: '48.1360399',
    lng: '16.2815103',
    view: 1
  },
  {
    id: 130,
    name: 'Optik Zentrum P.O.D.E.R GmbH',
    address: 'A. Baumgarnterstra\u00dfe 44 / Top 9<br/>1230 Wien',
    tel: '+43 1 662 80 22',
    email: 'alterlaa@optikzentrum.at',
    www: 'http://www.optikzentrum.at',
    open:
      '<b>Montag - Freitag </b><br />9.00 - 18.00<br /><b>Samstag</b><br />9.00 - 12.30',
    lat: '48.1511568',
    lng: '16.3147799',
    view: 1
  },
  {
    id: 421,
    name: 'Optik Zentrum P.O.D.E.R.',
    address: 'A. Baumgartnerstra\u00dfe 44 / Top 9<br/>1230 Wien',
    tel: '+43 1 / 662 80 22',
    email: 'alterlaa@optikzentrum.at',
    www: '',
    open:
      '<b>\u00d6ffnungszeiten:</b><br /><br />Montag - Freitag&nbsp;<br />09.00 - 18.00 Uhr<br /><br />Samstag<br />09.00 - 12.30',
    lat: '',
    lng: '',
    view: 1
  },
  {
    id: 65,
    name: 'Kelterer GmbH & Co KG',
    address: 'Hauptstra\u00dfe 29-31<br/>2000 Stockerau',
    tel: '+43 2266 62 9 70',
    email: 'optik@kelterer.at',
    www: 'http://www.kelterer.at',
    open:
      '<b>Montag - Freitag</b><br />8.30 - 18.00<br /><b>Samstag</b><br />8.30 - 12.30',
    lat: '48.3853789',
    lng: '16.2119715',
    view: 1
  },
  {
    id: 163,
    name: 'Optik Kelterer',
    address: 'Sparkassengasse 19<br/>2020 Hollabrunn',
    tel: '+43 2952 32 59',
    email: '',
    www: 'http://www.kelterer.at',
    open:
      '<b>Montag - Freitag</b><br />9.00 - 12.00, 14.00 - 18.00<br /><b>Samstag</b><br />9.00 - 12.00',
    lat: '48.565202',
    lng: '16.0788262',
    view: 1
  },
  {
    id: 217,
    name: 'GeKo Optic',
    address: 'Hauptstra\u00dfe 36-38<br/>2102 Bisamberg',
    tel: '+43 664 144 31 13',
    email: 'koch@geko-optik.at',
    www: 'http://www.geko-optic.at',
    open:
      '<strong>Dienstag, Donnerstag, Freitag</strong><br />9.00 - 18.00<strong><br />Samstag</strong><br />9.00 - 12.00',
    lat: '48.333425',
    lng: '16.4037465',
    view: 1
  },
  {
    id: 70,
    name: 'Optik Janner GmbH',
    address: 'Roseggerstra\u00dfe 45<br/>2130 Mistelbach',
    tel: '+43 2572 21 65 20',
    email: 'office@optik-janner.at',
    www: 'http://www.optik-janner.at',
    open: '<b>Montag - Freitag</b><br />8.30 - 17.30<b></b>',
    lat: '48.5666434',
    lng: '16.5869724',
    view: 1
  },
  {
    id: 71,
    name: 'Optik Janner GmbH',
    address: 'Hafnerstra\u00dfe 5<br/>2130 Mistelbach',
    tel: '+43 2572 21 65',
    email: 'h5@optik-janner.at',
    www: 'http://www.optik-janner.at',
    open:
      '<b>Montag \u2013 Freitag</b><br />8.00 \u2013 17.30<br /><b>Samstag<br /></b>8.30 - 13.00',
    lat: '48.5681715',
    lng: '16.5724049',
    view: 3
  },
  {
    id: 18,
    name: 'Optik Thalhammer GmbH',
    address: 'Stadtplatz 46<br/>2136 Laa/Thaya',
    tel: '+43 2522 74 37',
    email: 'office@optik-thalhammer.at',
    www: '',
    open:
      '<b>Montag - Freitag</b><br />8.00 - 12.00, 14.00 - 17.30<br /><b>Samstag</b><br />8.00 - 12.00',
    lat: '48.7211701',
    lng: '16.3847851',
    view: 1
  },
  {
    id: 69,
    name: 'Optik Jurka GesmbH',
    address: 'Bahnstra\u00dfe 63<br/>2230 G\u00e4nserndorf',
    tel: '+43 2282 38 91',
    email: 'info@optik-jurka.at',
    www: 'http://www.optik-jurka.at',
    open:
      '<b>Montag - Freitag</b><br />8.30 - 18.00<br /><b>Samstag</b><br />8.30 - 12.00',
    lat: '48.3400424',
    lng: '16.727147',
    view: 3
  },
  {
    id: 103,
    name: 'Optik Bastel GmbH',
    address: 'Hauptplatz 1<br/>2460 Bruck/Leitha',
    tel: '+43 2162 65 0 63',
    email: 'optik-bastel@kabsi.at',
    www: 'http://www.optik-bastel.at',
    open:
      '<b>Montag - Freitag</b><br />8.00 - 18.00<br /><b>Samstag</b><br />8.00 - 12.00',
    lat: '48.0254283',
    lng: '16.7786086',
    view: 3
  },
  {
    id: 129,
    name: 'Optik Freisinger',
    address: 'Badnerstra\u00dfe 2a<br/>2540 Bad V\u00f6slau',
    tel: '+43 2252 76 4 77',
    email: 'andreas@optik-freisinger.at',
    www: 'http://www.optik-freisinger.at',
    open:
      '<b>Montag - Freitag<br /></b>8.30 - 12.30, 14.00 - 18.00<b><br />Samstag<br /></b>9.00 - 12.30<b><br /></b>',
    lat: '47.966115',
    lng: '16.2143776',
    view: 1
  },
  {
    id: 416,
    name: 'Michael Sihn',
    address: 'Hauptstra\u00dfe 71<br/>2763 Pernitz',
    tel: '+43 2632 / 726 256',
    email: 'office@sehwerkstatt.at',
    www: 'www.sehwerkstatt.at',
    open:
      '<span style="color: rgb(24, 16, 24); font-family: Verdana, Arial, Helvetica, sans-serif; font-size: 13px; "><b>Montag, Dienstag, Mittwoch:</b></span><br style="margin: 0px; padding: 0px; color: rgb(24, 16, 24); font-family: Verdana, Arial, Helvetica, sans-serif; font-size: 13px; " /><span style="color: rgb(24, 16, 24); font-family: Verdana, Arial, Helvetica, sans-serif; font-size: 13px; ">09.00 Uhr - 12.00<br />14.00 Uhr - 18.00</span>',
    lat: '',
    lng: '',
    view: 2
  },
  {
    id: 418,
    name: 'Sehwerkstatt',
    address: 'Hauptstra\u00dfe 69<br/>2763 Pernitz',
    tel: '+43 2632 / 74 206',
    email: 'info@sehwerkstatt.at',
    www: 'www.sehwerkstatt.at',
    open:
      '<b>Montag, Dienstag, Mittwoch, Freitag:</b><br />08.00 - 12.00, 14.00 - 18.00<br /><b>Donnerstag:</b><br />08.00 - 12.00<br /><b>Samstag:&nbsp;</b><br />08.00 - 12.00',
    lat: '',
    lng: '',
    view: 1
  },
  {
    id: 102,
    name: 'Optik Oase',
    address: 'Schulgasse 165<br/>2823 Pitten',
    tel: '+43 2627 82 189',
    email: 'office@optikoase.at',
    www: 'http://www.optikoase.at',
    open:
      '<b>Montag - Mittwoch &amp; Freitag</b><br />9.00 - 12.00, 14.00 - 18.00<br /><b>Donnerstag &amp; Samstag</b><br />9.00 - 12.00',
    lat: '47.7152194',
    lng: '16.1862424',
    view: 1
  },
  {
    id: 72,
    name: 'Optiker H\u00f6fer GmbH',
    address: 'Kremser Landstra\u00dfe 16<br/>3100 St. P\u00f6lten',
    tel: '+43 2742 36 20 24',
    email: 'st.poelten@optiker-hoefer.at',
    www: 'http://www.optiker-hoefer.at',
    open:
      '<b>Montag - Freitag</b><br />9.00 - 18.00<br /><b>Samstag<br /></b>9.00 - 12.00',
    lat: '48.2100791',
    lng: '15.625331',
    view: 3
  },
  {
    id: 73,
    name: 'Optiker H\u00f6fer GmbH',
    address: 'Kirchenplatz 2<br/>3130 Herzogenburg',
    tel: '+43 2782 82 1 55',
    email: 'herzogenburg@optiker-hoefer.at',
    www: 'http://www.optiker-hoefer.at',
    open:
      '<b>Montag - Freitag</b><br />8.00 - 18.00<br /><b>Samstag</b><br />8.00 - 12.00',
    lat: '48.2864915',
    lng: '15.6956725',
    view: 3
  },
  {
    id: 60,
    name: 'Gerhard Konlechner e.U.',
    address: 'Obere Hauptstra\u00dfe 8<br/>3150 Wilhelmsburg',
    tel: '+43 2746 23 69',
    email: 'konoptik@aon.at',
    www: 'http://www.optikkonlechner.at',
    open:
      '<b>Montag - Freitag<br /></b>8.00 - 12.00, 14.00 - 18.00<br /><b>Samstag</b><br />8.00 - 12.00',
    lat: '48.1042853',
    lng: '15.6050917',
    view: 1
  },
  {
    id: 164,
    name: 'Optik Fuchs',
    address: 'Schulstra\u00dfe 3<br/>3240 Mank',
    tel: '+43 2755 80 0 10',
    email: 'optik-fuchs-mank@a1business.at',
    www: 'http://www.optik-fuchs.at',
    open:
      '<b>Montag - Freitag<br /></b>8.00 - 12.00, 14.00 - 18.00<b><br />Samstag<br /></b>8.00 - 12.00<b><br /></b>',
    lat: '48.1103253',
    lng: '15.34132',
    view: 3
  },
  {
    id: 81,
    name: 'Forster & Teufl Glanzwerk OG',
    address: 'Wienerstra\u00dfe 8<br/>3300 Amstetten',
    tel: '+43 7472 24 6 14',
    email: 'thomas.teufl@glanzwerk.at',
    www: 'http://www.glanzwerk.at',
    open:
      '<b>Montag - Freitag</b><br />9.00 - 18.00<br /><b>Samstag</b><br />9.00 - 12.00',
    lat: '48.122549',
    lng: '14.875761',
    view: 1
  },
  {
    id: 117,
    name: 'Nikolaus Optik',
    address: 'Linzerstra\u00dfe 29<br/>3350 Haag',
    tel: '+43 7434 44 24 5',
    email: 'nikolaus.haider61@gmail.com',
    www: 'http://www.nikolaus-optik.at',
    open:
      '<b>Montag - Dienstag</b><br />Termine nach Vereinbarung<br /><b>Mittwoch - Freitag</b><br />8.30 - 12.00, 14.00 - 18.00<br /><b>Samstag</b><br />9.00 - 12.00',
    lat: '48.1128887',
    lng: '14.5616449',
    view: 1
  },
  {
    id: 90,
    name: 'Optik Fuchs',
    address: 'Regensburgerstra\u00dfe 29<br/>3380 P\u00f6chlarn',
    tel: '+43 2757 89 67',
    email: 'optik-fuchs@aon.at',
    www: 'http://www.optik-fuchs.at',
    open:
      '<b>Montag - Freitag</b><br />8.00 - 12.00, 14.00 - 18.00<br /><b>Samstag</b><br />8.00 - 12.00',
    lat: '48.2118096',
    lng: '15.2063543',
    view: 3
  },
  {
    id: 106,
    name: 'Optic Matejscheck GmbH',
    address: 'Bahnhofstra\u00dfe 6<br/>3382 Loosdorf',
    tel: '+43 2754 30 156',
    email: 'ernst@durchblick-optic.at',
    www: 'http://www.brille-loosdorf.at',
    open:
      '<b>Montag - Freitag</b><br />8.30 - 12.00, 14.30 - 18.00<br /><b>Samstag</b><br />8.30 - 12.00',
    lat: '48.1997356',
    lng: '15.4006697',
    view: 1
  },
  {
    id: 223,
    name: 'Sehwerkstatt by Trautenberg',
    address: 'Rathausplatz 2<br/>3400 Klosterneuburg',
    tel: '+43 2243 30 352',
    email: 'c.trautenberg@sehwerkstatt.at',
    www: 'www.optiker-klosterneuburg.at',
    open:
      '<b>Montag<br /></b>9.00 - 12.30<br /> <b>Dienstag - Freitag</b><br />9.00 - 12.30 und 14.00 - 18.00<br /> <b>Samstag</b><br />9.00 - 12.00',
    lat: '48.304709',
    lng: '16.3251386',
    view: 1
  },
  {
    id: 12,
    name: 'Optik Tscherny',
    address: 'Hauptplatz 12-14 EKZ Rosenarcade<br/>3430 Tulln',
    tel: '+43 2272 81 3 22',
    email: 'office@tscherny.at',
    www: 'http://www.tscherny.at',
    open:
      '<b>Montag - Freitag</b><br />9.00 - 19.00<br /><b>Samstag</b><br />9.00 - 18.00',
    lat: '48.3314949',
    lng: '16.060737',
    view: 1
  },
  {
    id: 144,
    name: 'Augenoptik Friedl',
    address: 'Pfarrgasse 5<br/>3580 Horn',
    tel: '+43 2982 31 56',
    email: 'office@optikfriedl.at',
    www: 'http://www.optikfriedl.at',
    open:
      '<b>Montag - Freitag</b><br />8.30 - 12.00, 14.00 - 18.00<br /><b>Samstag</b><br />9.00 - 12.00',
    lat: '48.6646435',
    lng: '15.6582901',
    view: 1
  },
  {
    id: 93,
    name: 'Optik Eder',
    address: 'Niederleuthner Stra\u00dfe 5<br/>3830 Waidhofen/Thaya',
    tel: '+43 2842 52 2 78',
    email: 'buero@optik-eder.at',
    www: 'http://www.optik-eder.at',
    open:
      '<b>Montag - Freitag</b><br />8.00 - 12.00, 14.00 - 18.00<br /><b>Samstag</b><br />9.00 - 12.00',
    lat: '48.8139511',
    lng: '15.2861165',
    view: 3
  },
  {
    id: 20,
    name: 'Optik Steiner',
    address: 'Bahnhofstra\u00dfe 63<br/>3950 Gm\u00fcnd',
    tel: '+43 2852 52 7 37',
    email: 'optik.steiner@gmx.at',
    www: '',
    open:
      '<b>Montag - Freitag</b><br />8.00 - 12.00, 14.00 - 18.00<br /><b>Samstag</b><br />8.00 - 12.00',
    lat: '48.7659011',
    lng: '14.9854578',
    view: 3
  },
  {
    id: 133,
    name: 'Optik Hochwimmer',
    address: 'Rainerstra\u00dfe 10<br/>4020 Linz',
    tel: '+43 732 66 33 66',
    email: 'office@optik-hochwimmer.at',
    www: 'http://www.optik-hochwimmer.at',
    open:
      '<b>Montag - Freitag</b><br />9.00 - 18.00<br /><b>Samstag</b><br />9.00 - 12.00',
    lat: '48.2973382',
    lng: '14.2902009',
    view: 1
  },
  {
    id: 135,
    name: 'STIL Augenoptik & H\u00f6rger\u00e4te GmbH',
    address: 'Einsteinstra\u00dfe 5<br/>4020 Linz',
    tel: '+43 732 341 201',
    email: 'office@stil-optik.at',
    www: '',
    open:
      '<b>Montag - Freitag</b><br /> 8.30 - 12.30, 14.00 - 18.00<br /><b>Samstag</b><br />9.00 - 12.00',
    lat: '48.2763192',
    lng: '14.2981731',
    view: 3
  },
  {
    id: 83,
    name: 'Geier - Optik Gesellschaft m.b.H.',
    address: 'Hauptstra\u00dfe 34<br/>4040 Linz',
    tel: '+43 732 77 24 00',
    email: 'office@geier-optik.at',
    www: 'http://www.geier-optik.at',
    open:
      '<b>Montag - Freitag</b><br />9.00 - 18.00<br /><b>Samstag</b><br />9.00 - 13.00',
    lat: '48.3125694',
    lng: '14.2824529',
    view: 1
  },
  {
    id: 8,
    name: 'Optik Wochermeier e.U.',
    address: 'Linzerstra\u00dfe 1<br/>4050 Traun',
    tel: '+43 660 225 2260',
    email: 'wochermeier@gmail.com',
    www: 'http://www.optik-wochermeier.at',
    open: '<b>Montag - Freitag</b><br />8.30 - 12.00, 14.00 - 18.00',
    lat: '48.2204271',
    lng: '14.2393968',
    view: 3
  },
  {
    id: 17,
    name: 'Augenoptik Jagsch',
    address: 'Mayrhansenstra\u00dfe 8<br/>4060 Leonding',
    tel: '+43 732 / 67 39 92',
    email: 'office@optiker-leonding.at',
    www: 'http://www.optiker-leonding.at',
    open:
      '<b>Montag - Freitag</b><br />9.00 - 12.00, 14.00 - 18.00<br /><b>Samstag</b><br />09:00 - 12:00',
    lat: '48.2782',
    lng: '14.2495',
    view: 1
  },
  {
    id: 11,
    name: 'Klaus Vincourek GmbH',
    address: 'Schmiedstra\u00dfe 15<br/>4070 Eferding',
    tel: '+43 7272 23 48',
    email: 'eferding@vincourek.at',
    www: 'http://www.vincourek.at',
    open:
      '<b>Montag - Freitag</b><br />08.30 - 12.00, 14.00 - 18.00<br /><b>Samstag<br /></b>08.30 - 12.00',
    lat: '48.308544',
    lng: '14.0219007',
    view: 1
  },
  {
    id: 162,
    name: 'STIL Augenoptik & H\u00f6rger\u00e4te GmbH',
    address: 'Markt 10<br/>4113 St. Martin im M\u00fchlkreis',
    tel: '+43 7232 38 400',
    email: 'office.st.martin@stil-optik.at',
    www: 'http://www.stil-optik.at',
    open: '<b>Montag - Freitag</b><br />9.00 - 12.30, 14.00 - 18.00',
    lat: '48.4166473',
    lng: '14.0390159',
    view: 3
  },
  {
    id: 134,
    name: 'Optik Mayer',
    address: 'Hauptplatz 23<br/>4190 Bad Leonfelden',
    tel: '+43 7213 20 5 20',
    email: 'info@optik-mayer.at',
    www: 'http://www.optikmayer.at',
    open:
      '<b>Montag - Freitag</b><br />8.00 - 12.30, 14.00 - 18.00<br /><b>Samstag</b><br />8.00 - 12.00',
    lat: '48.5216734',
    lng: '14.2942305',
    view: 1
  },
  {
    id: 49,
    name: 'Oppenborn Sehen und H\u00f6ren GmbH',
    address: 'Gaisbacher Stra\u00dfe 6<br/>4210 Gallneukirchen',
    tel: '+43 7235 62 4 56',
    email: 'office@oppenborn.at',
    www: 'http://www.oppenborn.at/',
    open:
      '<b>Montag - Freitag</b><br />8.00 - 12.00, 14.00 - 18.00<br /><b>Freitag<br /></b>8.00 - 18.00<br /><b>Samstag<br /></b>8.30 - 12.00',
    lat: '48.3539939',
    lng: '14.4172581',
    view: 3
  },
  {
    id: 128,
    name: 'PR Optics',
    address: 'Linzer Stra\u00dfe 16<br/>4221 Steyregg',
    tel: '+43 732 64 04 08',
    email: 'office@pr-optics.at',
    www: 'http://www.pr-optics.at',
    open:
      '<b>Mo/Di/Mi</b><br />9.00 - 12.00, 14.00 - 18.00<br /><b>Donnerstag<br /></b>9.00 - 12.00, 14.00 - 18.30<br /><b>Samstag</b><br />9.00 - 12.00',
    lat: '48.2829547',
    lng: '14.3642979',
    view: 1
  },
  {
    id: 48,
    name: 'Brillen - Kontaktlinsen Oppenborn GesmbH',
    address: 'Tragweinerstra\u00dfe 22<br/>4230 Pregarten',
    tel: '+43 7236 24 76',
    email: 'office@oppenborn-pregarten.at',
    www: 'http://www.optik-oppenborn.at',
    open:
      '<b>Montag - Freitag</b><br />8.15 - 12.00, 14.00 - 18.00<br /><b>Samstag</b><br />8.15 - 12.00',
    lat: '48.3545173',
    lng: '14.5324452',
    view: 1
  },
  {
    id: 127,
    name: 'Der Optik F\u00fcrst',
    address: 'Pfarrgasse 5<br/>4240 Freistadt',
    tel: '+43 7942 72 6 96',
    email: 'office@optik-fuerst.at',
    www: 'http://www.optik-fuerst.at',
    open:
      '<b>Montag - Freitag</b><br />8.30 - 12.00, 14.00 - 18.00<br /><b>Samstag</b><br />8.30 - 12.00',
    lat: '48.5109173',
    lng: '14.5041738',
    view: 1
  },
  {
    id: 19,
    name: 'Suppan Optik mit Pers\u00f6nlichkeit Optik und Kontaktlinsen',
    address: 'Naarnerstra\u00dfe 1<br/>4320 Perg',
    tel: '+43 7262 54 6 30',
    email: 'office@perg-optik.at',
    www: 'http://www.perg-optik.at',
    open:
      '<b>Montag - Freitag</b><br />8.30 - 12.00, 14.00 - 18.00<br /><b>Samstag</b><br /> 9.00 - 12.00',
    lat: '48.2488293',
    lng: '14.6344428',
    view: 1
  },
  {
    id: 16,
    name: 'Optik Theuer',
    address: 'Kreuznerstra\u00dfe 9<br/>4360 Grein',
    tel: '+43 7268 77 70',
    email: 'optik-theuer@aon.at',
    www: '',
    open:
      '<b>Montag/Dienstag/Freitag</b><br />8.30 - 12.00, 14.30 - 18.00<br /><b>Mittwoch</b><br />geschlossen<br /><b>Donnerstag<br /></b>8.30 - 12.00, 15..00 - 19.00<br /><b>Samstag</b><br />8.30 - 12.00',
    lat: '48.227161',
    lng: '14.8540532',
    view: 1
  },
  {
    id: 120,
    name: 'A&O Madengruber GmbH',
    address: 'Hubergutstra\u00dfe 14c<br/>4400 Steyr',
    tel: '+43 7252 50 1 88',
    email: 'office@aundo-madengruber.at',
    www: 'http://www.aundo-madengruber.at',
    open:
      '<b>Montag - Freitag</b><br />8.30 - 12.30, 14.30 - 18.00<br /><b>Samstag</b><br />8.30 - 12.00',
    lat: '48.0293495',
    lng: '14.4258814',
    view: 3
  },
  {
    id: 123,
    name: 'Petermandl G&K GmbH',
    address: 'Pachergasse 9<br/>4400 Steyr',
    tel: '+43 7252 53 5 06',
    email: 'office@optik-petermandl.at',
    www: 'http://www.optik-petermandl.at',
    open:
      '<b>Montag - Freitag</b><br />8.15 - 12.00, 14.00 - 18.00<br /><b>Samstag</b><br />9.00 - 12.00',
    lat: '48.0414627',
    lng: '14.4245633',
    view: 3
  },
  {
    id: 153,
    name: 'A&O Madengruber GmbH',
    address: 'Eisenstra\u00dfe 101<br/>4460 Losenstein',
    tel: '+43 7255 20 0 46',
    email: 'losenstein@aundo-madengruber.at',
    www: 'http://www.aundo-madengruber.at',
    open:
      '<b>Montag/Dienstag/Donnerstag</b><br /> 08.30 - 12.30<br /><b>Mittwoch/Freitag</b><br />08.30 - 12.30, 14.30 - 18.00',
    lat: '47.9209027',
    lng: '14.4400046',
    view: 3
  },
  {
    id: 92,
    name: 'Optik Eichholzer',
    address: 'Hauptplatz 27<br/>4470 Enns',
    tel: '+43 7223 84 1 95',
    email: 'enns@optik-eichholzer.at',
    www: '',
    open:
      '<b>Montag - Freitag</b><br />8.30 - 12.00, 14.00 - 18.00<br /><b>Samstag</b><br />8.30 - 12.00',
    lat: '48.2135097',
    lng: '14.479725',
    view: 1
  },
  {
    id: 39,
    name: 'PR Optics',
    address: 'Geranienstra\u00dfe 6<br/>4481 Asten',
    tel: '+43 7224 657 31',
    email: 'office@pr-optics.at',
    www: 'http://www.pr-optics.at',
    open:
      '<b>Montag - Freitag</b><br />9.00 - 13.30, 15.00 - 18.30<br /><b>Samstag</b><br />9.00 - 12.00',
    lat: '48.2175784',
    lng: '14.4226939',
    view: 3
  },
  {
    id: 35,
    name: 'Optik Haselsteiner',
    address: 'Hauptplatz 19<br/>4540 Bad Hall',
    tel: '+43 7258 26 76',
    email: 'badhall@optik-haselsteiner.at',
    www: '',
    open:
      '<b>Montag - Freitag</b><br />8.30 - 12.00, 14.00 - 18.00<br /><b>Samstag</b><br />8.30 - 12.00',
    lat: '48.0347173',
    lng: '14.209844',
    view: 1
  },
  {
    id: 161,
    name: 'Appl Optik & H\u00f6rakustik',
    address: 'Hauptplatz 20<br/>4540 Bad Hall',
    tel: '+43 7258 33 3 10',
    email: 'office@optik-appl.at',
    www: 'http://www.optik-appl.at',
    open:
      '<b>Montag - Freitag</b><br />8.30 - 12.00, 14.00 - 18.00<br /><b>Dienstag, Donnerstag</b><br /> nach Terminvereinbarung von 18.00- 20.00<br /><b>Samstag </b><br />nach Terminvereinbarung von 9.00 - 11.00',
    lat: '48.0341633',
    lng: '14.2098285',
    view: 2
  },
  {
    id: 79,
    name: 'Glieder Brillen- und Optik GmbH',
    address: 'Hauptplatz 7<br/>4560 Kirchdorf/Krems',
    tel: '+43 7582 62 4 48 0',
    email: 'office@optikglieder.at',
    www: 'http://www.optikglieder.at',
    open:
      '<b>Montag - Freitag</b><br />8.30 - 12.00, 14.00 - 18.00<br /><b>Samstag<br /></b>9.00 - 12.00',
    lat: '47.9052029',
    lng: '14.1228643',
    view: 1
  },
  {
    id: 108,
    name: 'Aigner Optik',
    address: 'Hauptstra\u00dfe 33<br/>4580 Windischgarsten',
    tel: '+43 7562 55 19',
    email: 'aigner-optik@A1.net',
    www: '',
    open:
      '<b>Montag/Dienstag/Donnerstag/Freitag</b><br />8.30 - 12.00 und 14.00 - 18.00<br /><b>Mittwoch<br /></b>8.30 - 12.00 und 14.30 - 18.00<br /><b>Samstag</b><br />8.30 - 12.00',
    lat: '47.7222176',
    lng: '14.3254635',
    view: 1
  },
  {
    id: 25,
    name: 'Optik Schwabegger KG',
    address: 'Ringstra\u00dfe 10<br/>4600 Wels',
    tel: '+43 7242 44 7 91',
    email: 'office@schwabegger.at',
    www: 'http://www.schwabegger.at',
    open:
      '<b>Montag - Freitag</b><br />9.00 - 13.00, 14.00 - 18.00<br /><b>Samstag<br /></b>9.00 - 12.30',
    lat: '48.1581573',
    lng: '14.0240407',
    view: 3
  },
  {
    id: 88,
    name: 'Optik Schwabegger',
    address: 'Linzerstra\u00dfe 15<br/>4614 Marchtrenk',
    tel: '+43 7243 50 4 56',
    email: 'marchtrenk@schwabegger.at',
    www: 'http://www.schwabegger.at',
    open:
      '<b>Montag - Freitag</b><br />8.30 - 12.30, 14.00 - 18.00<br /><b>Samstag</b><br />8.30 - 12.00',
    lat: '48.1907648',
    lng: '14.1138604',
    view: 1
  },
  {
    id: 10,
    name: 'Klaus Vincourek GmbH',
    address: 'Marktplatz 12<br/>4650 Lambach',
    tel: '+43 7245 32 2 93',
    email: 'lambach@vincourek.at',
    www: 'http://www.vincourek.at',
    open:
      '<b>Montag - Freitag</b><br />8.00 - 12.00, 14.00 - 18.00<br /><b>Samstag<br /></b>8.00 - 12.00',
    lat: '48.091564',
    lng: '13.8732314',
    view: 1
  },
  {
    id: 26,
    name: 'Optik Schwabegger KG',
    address: 'Marktplatz 10<br/>4650 Lambach',
    tel: '+43 7245 28 3 76',
    email: 'lambach@schwabegger.at',
    www: 'http://www.schwabegger.at',
    open:
      '<b>Montag - Freitag</b><br />8.30 - 12.00, 14.00 - 18.00<br /><b>Samstag</b><br />8.30 - 12.00',
    lat: '48.0906477',
    lng: '13.8731845',
    view: 3
  },
  {
    id: 95,
    name: 'Creativ Optik Plakolm',
    address: 'Kapellenweg 2<br/>4655 Vorchdorf',
    tel: '+43 7614 74 96',
    email: 'vorchdorf@plakolm.at',
    www: 'http://www.plakolm.at',
    open:
      '<b>Mo/Di/Do/Fr</b><br />9.00 - 12.30, 14.00 - 18.00<br /><b>Mittwoch</b><br />9.00 - 12.00<br /><b>Samstag</b><br />9.00 - 12.00',
    lat: '48.0038665',
    lng: '13.9235841',
    view: 3
  },
  {
    id: 122,
    name: 'Zopf Optik-Uhren-Schmuck',
    address: 'Dr.-Mitterbauer-Str. 2c<br/>4663 Laakirchen',
    tel: '+43 7613 29 83',
    email: 'zopf.optik@gmx.at',
    www: 'http://www.zopf-optik.at',
    open:
      '<b>Montag - Freitag</b><br />8.00 - 12.00, 14.30 - 18.00<br /><b>Samstag</b><br />8.00 - 12.00',
    lat: '47.9819371',
    lng: '13.8229698',
    view: 1
  },
  {
    id: 407,
    name: 'Optik Schwabegger KG',
    address: 'Badstra\u00dfe 7<br/>4701 Bad Schallerbach',
    tel: '07249 / 4863 4',
    email: 'schallerbach@schwabegger.at',
    www: '',
    open:
      '<strong>Montag-Freitag </strong><br />8.30 - 12.00, 14.00 - 18.00<br /><strong>Samstag</strong><br />8.30 - 12.00',
    lat: '48.2305672',
    lng: '13.9201983',
    view: 1
  },
  {
    id: 51,
    name: 'Optik Haselsteiner GmbH',
    address: 'Stra\u00dfe des 21. April 1<br/>4800 Attnang-Puchheim',
    tel: '+43 7674 63 108',
    email: 'attnang@optik-haselsteiner.at',
    www: '',
    open:
      '<b>Montag - Freitag</b><br />8.30 - 12.00, 14.00 - 18.00<br /><b>Samstag</b><br />8.30 - 12.00',
    lat: '48.0111604',
    lng: '13.7209761',
    view: 1
  },
  {
    id: 124,
    name: 'Optik Hahn GesmbH',
    address: 'Bahnhofstra\u00dfe 15<br/>4802 Ebensee',
    tel: '+43 6133 52 51',
    email: 'office@optik-hahn.at',
    www: 'http://www.optik-hahn.at',
    open:
      '<b>Montag/Dienstag/Donnerstag</b><br />8.00 - 12.00, 14.30 - 18.00<b><br />Freitag<br /></b>8.00 - 12.00, 14.00 - 18.00<b><br />Mittwoch/Samstag</b><br />8.00 - 12.00<br /><br />',
    lat: '47.8082409',
    lng: '13.7723858',
    view: 1
  },
  {
    id: 109,
    name: 'Optik Aichinger OG',
    address: 'Kirchengasse 9<br/>4810 Gmunden',
    tel: '+43 7612 64 6 49',
    email: 'office@optik-aichingerkg.at',
    www: 'http://www.optik-aichinger.com',
    open:
      '<b>Montag - Freitag</b><br />8.00 - 18.00<br /><b>Samstag</b><br />8.30 - 12.30',
    lat: '47.9188909',
    lng: '13.7992235',
    view: 1
  },
  {
    id: 85,
    name: 'Optik Hahn GesmbH',
    address: 'Wirerstra\u00dfe 2<br/>4820 Bad Ischl',
    tel: '+43 6132 23 6 17',
    email: 'office@optik-hahn.at',
    www: 'http://www.optik-hahn.at',
    open:
      '<b>Montag - Freitag<br /></b>8.30 - 12.30, 14.00 - 18.00<br /><b>Samstag<br /></b>8.30 - 12.00',
    lat: '47.7110492',
    lng: '13.6211033',
    view: 1
  },
  {
    id: 136,
    name: 'Brillen Zwerger Inh. Seeber',
    address: 'Kreuzplatz 3<br/>4820 Bad Ischl',
    tel: '+43 6132 23 3 92',
    email: 'brillen.zwerger@inode.at',
    www: '',
    open:
      '<b>Montag - Freitag</b><br />9.00 - 12.00, 14.00 - 18.00<br /><b>Samstag</b><br />9.00 - 12.00',
    lat: '47.7131443',
    lng: '13.6205899',
    view: 1
  },
  {
    id: 436,
    name: 'Riedl Meisteroptik',
    address: 'Untere Marktstra\u00dfe 19/6<br/>4822 Bad Goisern',
    tel: '+43 650 / 280 92 26',
    email: 'office@riedl-meisteroptik.at',
    www: 'www.riedl-meisteroptik.at',
    open:
      '<b>\u00d6ffnungszeiten:</b><br />Dienstag - Freitag<br />08:30 - 12:00 Uhr / 14:00 - 18:00 Uhr<br />Samstag:<br />08:30 - 12:00 Uhr<br /><br /><b>NEUER\u00d6FFNUNG AM 01.OKTOBER 2019</b>',
    lat: '',
    lng: '',
    view: 1
  },
  {
    id: 146,
    name: 'pro akustik Andreas Schiefermayer',
    address: 'Hauptstra\u00dfe 7b/1<br/>4861 Sch\u00f6rfling',
    tel: '+43 7622 22 00 7',
    email: 'office@proakustik.at',
    www: 'http://www.proakustik.at',
    open:
      '<b>Montag - Donnerstag</b><br />8.00 - 12.00, 13.00 - 17.00<br /><b>Freitag</b><br />8.00 - 13.00',
    lat: '47.946236',
    lng: '13.594841',
    view: 2
  },
  {
    id: 119,
    name: 'Optik:Sehblick',
    address: 'Atterseestra\u00dfe 22<br/>4863 Seewalchen',
    tel: '+43 7662 24 74',
    email: 'office@optik-sehblick.at',
    www: 'http://www.optik-sehblick.at',
    open:
      '<b>Dienstag - Freitag</b><br />8.00 - 12.30, 14.00 - 18.00<br /><b>Samstag</b><br />8.00 - 12.30',
    lat: '47.9495478',
    lng: '13.5943517',
    view: 1
  },
  {
    id: 42,
    name: 'Creativ Optik Plakolm',
    address: 'Marktplatz 21<br/>4873 Frankenburg',
    tel: '+43 7683 82 66',
    email: 'frankenburg@plakolm.at',
    www: 'http://www.plakolm.at',
    open:
      '<b>Mo/Di/Do/Fr</b><br />8.30 - 12.00, 14.00 - 18.00<br /><b>Mi/Sa</b><br />8.30 - 12.00<br /> ',
    lat: '48.0677916',
    lng: '13.4917837',
    view: 3
  },
  {
    id: 61,
    name: 'klotz Sonnenbrille.at',
    address: 'Europastra\u00dfe 1<br/>5018 Salzburg',
    tel: '+43 662 43 60 33',
    email: 'info@klotz-optik.at',
    www: 'http://www.sonnenbrille.at',
    open:
      '<b>Montag - Donnerstag</b><br />9.00 - 19.30<br /><b>Freitag</b><br />9.00 - 21.00<br /><b>Samstag</b><br />9.00 - 18.00',
    lat: '47.8164907',
    lng: '13.0087174',
    view: 1
  },
  {
    id: 27,
    name: 'Schrammel Optik',
    address: 'Friedensstra\u00dfe 2<br/>5020 Salzburg',
    tel: '+43 662 62 66 06',
    email: 'stephan@schrammeloptik.com',
    www: 'http://www.schrammeloptik.com',
    open:
      '<b>Montag - Freitag</b><br />9.00 - 13.00, 14.00 - 18.00<br /><b>Samstag</b><br />9-00 - 14.00',
    lat: '47.7846825',
    lng: '13.0628631',
    view: 1
  },
  {
    id: 98,
    name: 'Blickpunkt Optik',
    address: 'Rainerstra\u00dfe 3 (Salzburg Congress)<br/>5020 Salzburg',
    tel: '+43 662 88 12 19',
    email: 'office@blickpunkt-optik.at',
    www: 'http://www.blickpunkt-optik.at',
    open:
      '<b>Montag - Freitag<br /></b>8.30 - 18.00<br /><b>Samstag<br /></b>9.00 - 12.00',
    lat: '47.8070049',
    lng: '13.0416281',
    view: 1
  },
  {
    id: 151,
    name: 'Augenoptik Kebsak GmbH',
    address: 'Maxglaner Hauptstra\u00dfe 23<br/>5020 Salzburg',
    tel: '+43 662 82 00 14',
    email: 'info@kebsak.at',
    www: 'http://www.kebsak.at',
    open:
      '<b>Montag - Freitag</b><br />8.30 - 18.00<br /><b>Samstag</b><br />9.00 - 12.00',
    lat: '47.7970313',
    lng: '13.0233056',
    view: 2
  },
  {
    id: 62,
    name: 'klotz Optik',
    address: 'Linzer Bundesstra\u00dfe 43a<br/>5023 Salzburg/Gnigl',
    tel: '+43 6232 65 20 00',
    email: 'info@klotz-optik.at',
    www: 'http://www.sonnenbrille.at',
    open:
      '<b>Montag - Freitag</b><br />8.30 - 12.00, 14.00 - 18.00<br /><b>Samstag</b><br />8.30 - 12.00',
    lat: '47.8160112',
    lng: '13.0699035',
    view: 1
  },
  {
    id: 44,
    name: 'Optik Peterschelka KG',
    address:
      'Salzburger Stra\u00dfe 56 (Galerie Oberndorf)<br/>5110 Oberndorf bei Salzburg',
    tel: '+43 6272 77 60',
    email: 'peterschelka.oberndorf@aon.at',
    www: 'http://www.optik-peterschelka-oberndorf.at',
    open:
      '<b>Montag - Freitag</b><br />8.30 -18.00<br /><b>Samstag</b><br />8.30 - 12.00',
    lat: '47.9420257',
    lng: '12.9433887',
    view: 1
  },
  {
    id: 63,
    name: 'klotz Optik',
    address: 'Rainerstra\u00dfe 5<br/>5310 Mondsee',
    tel: '+43 6232 40 12',
    email: 'info@klotz-optik.at',
    www: 'http://www.sonnenbrille.at',
    open:
      '<b>Montag - Freitag</b><br />8.30 - 12.00, 14.00 - 18.00<br /><b>Samstag</b><br />8.30 - 12.30',
    lat: '47.8548201',
    lng: '13.349413',
    view: 1
  },
  {
    id: 141,
    name: 'Optik Wehrberger',
    address: 'Markt 16<br/>5441 Abtenau',
    tel: '+43 6243 33 10',
    email: 'optik.wehrberger@sbg.at',
    www: 'http://www.deroptiker.com',
    open:
      '<b>Montag -&nbsp; Freitag</b><br />8.15 - 12.00, 14.00 - 18.00<br /><b>Samstag<br /></b>8.15 - 12.00',
    lat: '47.5629242',
    lng: '13.3484293',
    view: 1
  },
  {
    id: 54,
    name: 'Robert Nauer GmbH',
    address: 'Bahnhofstra\u00dfe 26<br/>5500 Bischofshofen',
    tel: '+43 6462 25 23 - 0',
    email: 'nauer@sbg.at',
    www: 'http://www.optik-nauer.at/',
    open:
      '<b>Montag - Freitag</b><br />8.45 - 12.30, 14.00 - 18.00<br /><b>Samstag</b><br />8.45 - 12.30, 13.30 - 17.00',
    lat: '47.4168339',
    lng: '13.2189236',
    view: 1
  },
  {
    id: 74,
    name: 'Optik Hofer e.U.',
    address: 'Zinsgasse 8<br/>5580 Tamsweg',
    tel: '+43 6474 61 04',
    email: 'optik.hofer@aon.at',
    www: '',
    open:
      '<b>Montag - Freitag</b><br />8.15 - 12.15, 14.15 - 18.15<br /><b>Samstag</b><br />8.15 - 12.00',
    lat: '47.1303489',
    lng: '13.8096975',
    view: 1
  },
  {
    id: 58,
    name: 'Optik Krabb',
    address: 'Hauptstra\u00dfe 35<br/>5600 St. Johann/Pongau',
    tel: '+43 6412 4291',
    email: 'optik@krabb.at',
    www: 'http://www.optik-krabb.at',
    open:
      '<b>Montag - Freitag</b><br />8.30 - 12.30, 14.00 - 18.00<br /><b>Samstag</b><br />9.00 - 12.00',
    lat: '47.3485077',
    lng: '13.2024755',
    view: 1
  },
  {
    id: 53,
    name: 'Robert Nauer GmbH',
    address: 'Salzleckerstra\u00dfe 16<br/>5620 Schwarzach',
    tel: '+43 6415 65 84',
    email: 'optik@nauer-schwarzach.at',
    www: 'http://www.optik-nauer.at/',
    open:
      '<b>Montag - Freitag</b><br />8.30 - 12.15, 14.00 - 18.00<br /><b>Samstag</b><br />8.30 - 12.00',
    lat: '47.3208788',
    lng: '13.1518693',
    view: 1
  },
  {
    id: 41,
    name: 'Optik Pl\u00f6rer',
    address: 'Bahnhofstra\u00dfe 6<br/>5700 Zell am See',
    tel: '+43 6542 72 8 17',
    email: 'optik@ploerer.at',
    www: 'http://www.ploerer.at',
    open:
      '<b>Montag - Freitag</b><br />9.00 - 18.00<br /><b>Samstag<br /></b>9.00 - 13.00',
    lat: '47.3224356',
    lng: '12.7977985',
    view: 3
  },
  {
    id: 68,
    name: 'Optik Kabiersch',
    address: 'Salzmannstra\u00dfe 13<br/>5700 Zell am See',
    tel: '+43 6542 73 713',
    email: 'office@kabiersch.at',
    www: 'http://www.kabiersch.at',
    open: '<b>Montag - Freitag</b><br />9.00 - 18.00',
    lat: '47.3238222',
    lng: '12.7995717',
    view: 1
  },
  {
    id: 149,
    name: 'Sehen & H\u00f6ren Maurer GmbH',
    address: 'Zeller Stra\u00dfe 9<br/>5730 Mittersill',
    tel: '+43 6562 47 81',
    email: 'inge.maurer@sbg.at',
    www: 'http://www.optik-maurer.at',
    open:
      '<b>Montag - Freitag</b><br />8.30 - 12.00, 14.00 - 18.00<br /><b>Samstag</b><br />8.30 - 12.00',
    lat: '47.2831805',
    lng: '12.482487',
    view: 2
  },
  {
    id: 50,
    name: 'Ohlicher GmbH & Co KG',
    address: 'Leogangerstra\u00dfe 18 EKZ Interspar<br/>5760 Saalfelden',
    tel: '+43 6582 70 2 40',
    email: 'info-ekz@ohlicher.at',
    www: 'http://www.ohlicher.at',
    open:
      '<b>Montag - Freitag</b><br />9.00 - 19.30<br /><b>Samstag</b><br />9.00 - 18.00',
    lat: '47.4273901',
    lng: '12.8411445',
    view: 1
  },
  {
    id: 52,
    name: 'Ohlicher GmbH & Co KG',
    address: 'Almer Stra\u00dfe 2<br/>5760 Saalfelden',
    tel: '+43 6582 75 9 80',
    email: 'info@ohlicher.at',
    www: 'http://www.ohlicher.at',
    open:
      '<b>Montag - Freitag</b><br />9.00 - 18.00<br /><b>Samstag</b><br />10.00 - 16.00',
    lat: '47.4266111',
    lng: '12.8468982',
    view: 3
  },
  {
    id: 21,
    name: 'Stastny Optik',
    address: 'Defreggerstra\u00dfe 8<br/>6020 Innsbruck',
    tel: '+43 512 394 859',
    email: 'a.stastny@stastny-optik.at',
    www: 'http://www.stastny-optik.at',
    open:
      '<b>Montag - Donnerstag</b><br />8.30 - 12.00, 13.00 - 18.00<br /><b>Freitag<br /></b>8.30 - 18.00<b><br /></b><b>Samstag<br /></b>8.30 - 12.00',
    lat: '47.2643017',
    lng: '11.4072039',
    view: 1
  },
  {
    id: 84,
    name: 'Optik Gaertner',
    address: 'Leopoldstra\u00dfe 16<br/>6020 Innsbruck',
    tel: '+43 512 57 59 74',
    email: 'info@optik-gaertner.at',
    www: 'http://www.optik-gaertner.at',
    open:
      '<b>Montag - Freitag<br /></b>9.00 - 18.00<br /><b>Samstag<br /></b>9.00 - 12.00',
    lat: '47.2612958',
    lng: '11.3946632',
    view: 1
  },
  {
    id: 155,
    name: 'Gaertner Auditiv 2',
    address: 'Heiliggeiststra\u00dfe 4<br/>6020 Innsbruck',
    tel: '+43 512 57 01 59',
    email: 'auditiv@gaertner-innsbruck.at',
    www: 'http://www.optik-gaertner.at',
    open:
      '<b>Montag - Donnerstag</b><br />8.30 - 12.30, 13:30 - 17:30<br /><b>Freitag</b><br />8.30 - 12.30, 13:30 - 16:00',
    lat: '47.2612219',
    lng: '11.3960913',
    view: 2
  },
  {
    id: 156,
    name: 'Gaertner Auditiv',
    address: 'Heiliggeiststra\u00dfe 1a<br/>6020 Innsbruck',
    tel: '+43 512 93 53 95',
    email: 'auditiv@gaertner-innsbruck.at',
    www: 'http://www.optik-gaertner.at',
    open: '<b>Montag - Freitag</b><br />8.30 - 17.30',
    lat: '47.2615644',
    lng: '11.3961458',
    view: 2
  },
  {
    id: 350,
    name: 'Optik Kreutner',
    address: 'Achenseestra\u00dfe 25<br/>6200 Jenbach',
    tel: '+43 5244 622 14',
    email: 'info@optik-kreutner.at',
    www: 'http://www.optik-kreutner.at',
    open:
      '<b>Montag - Freitag</b><br />9.00 - 12.15 &amp; 14.30 - 18.00<br /><b>Mittwoch</b><br />Nachmittag geschlossen<br /><b>Samstag</b><br />9.00 - 12.15',
    lat: '47.390448',
    lng: '11.7733011',
    view: 1
  },
  {
    id: 34,
    name: 'Rieder Optik Ges.m.b.H.',
    address: 'Herrenhausplatz 5/G1<br/>6230 Brixlegg',
    tel: '+43 5337 63 338',
    email: 'optik.rieder@aon.at',
    www: '',
    open:
      '<b>Montag - Freitag</b><br />8.30 - 12.30, 14.30 - 18.00<br /><b>Samstag</b><br />8:30 - 12.30',
    lat: '47.4286628',
    lng: '11.8768698',
    view: 1
  },
  {
    id: 107,
    name: 'Albrecht Otto',
    address: 'Krankenhausgasse 6<br/>6330 Kufstein',
    tel: '+43 5372 64 0 32',
    email: 'office@albrechtoptik.at',
    www: 'http://www.albrechtoptik.at',
    open:
      '<b>Montag - Freitag</b><br />8.30 - 12.00, 14.00 - 18.00<br /><b>Samstag</b><br />09.00 - 12.00',
    lat: '47.5836131',
    lng: '12.1713771',
    view: 3
  },
  {
    id: 40,
    name: 'Pohl - Optik',
    address: 'Hauptstra\u00dfe 29<br/>6433 Oetz',
    tel: '+43 5252 22 19',
    email: 'info@pohloptik.at',
    www: 'http://www.pohloptik.at',
    open:
      '<b>Montag/Dienstag/Donnerstag/Freitag</b><br />8.30 - 12.00, 14.00 - 18.00<br /><b>Mittwoch</b><br />8.30 - 12.00',
    lat: '47.2040869',
    lng: '10.8924592',
    view: 1
  },
  {
    id: 82,
    name: 'Optik Gezzele Gesellschaft m.b.H. & Co KG',
    address: 'Obermarkt 26<br/>6600 Reutte',
    tel: '+43 5672 62 7 08',
    email: 'info@gezzele.at',
    www: 'http://www.gezzele.at',
    open:
      '<b>Montag - Freitag</b><br />8.30 - 12.00, 14.00 - 18.00<br /><b>Samstag</b><br />9.00 - 12.00',
    lat: '47.4862455',
    lng: '10.7194982',
    view: 3
  },
  {
    id: 22,
    name: 'Hingucker - Das Sch\u00f6ne Sehen',
    address: 'Werdenbergerstra\u00dfe 17<br/>6700 Bludenz',
    tel: '+43 5552 31 2 30',
    email: 'schau@hingucker.at',
    www: 'www.hingucker.at',
    open:
      '<b>Montag - Freitag</b><br />9.00 - 12.30, 13.30 - 18.00<br /><b>Samstag</b><br />9.00 - 12.30',
    lat: '47.1556274',
    lng: '9.8203543',
    view: 1
  },
  {
    id: 100,
    name: 'Bitsche Optik GmbH',
    address: 'Bahnhofstra\u00dfe 4<br/>6700 Bludenz',
    tel: '+43 5552 66 4 66 0',
    email: 'office@bitsche-optik.at',
    www: 'http://www.bitsche-optik.at',
    open:
      '<b>Montag - Freitag<br /></b>8.30 - 12.30, 13.30 - 18.00<br /><b>Samstag<br /></b>8.30 - 12.30',
    lat: '47.1560622',
    lng: '9.818233',
    view: 3
  },
  {
    id: 160,
    name: 'Bitsche H\u00f6rakustik Bitsche Optik GmbH',
    address: 'Walgaustra\u00dfe 18<br/>6712 Th\u00fcringen',
    tel: '+43 5550 21 536',
    email: 'office@bitsche-hoeren,at',
    www: 'http://www.bitsche-hoeren.at',
    open:
      '<b>Montag - Freitag</b><br />8.30 - 12.00<br />Nachmittag nach Vereinbarung',
    lat: '47.1982049',
    lng: '9.7671608',
    view: 2
  },
  {
    id: 47,
    name: 'MO Optik e.U.',
    address: 'Silbertalerstra\u00dfe 4<br/>6780 Schruns',
    tel: '+43 5556 72 373',
    email: 'stephan.derkits@mo-optik.at',
    www: 'http://www.mo-optik.at',
    open:
      '<b>Montag - Mittwoch &amp; Freitag</b><br />9.00 - 12.00, 14.30 - 18.00<br /><b>Donnerstag</b><br />9.00 - 12.00<br /><b>Samstag</b><br />9.00 - 12.00<br />und nach Terminvereinbarung',
    lat: '47.0807211',
    lng: '9.9181044',
    view: 1
  },
  {
    id: 426,
    name: 'H\u00f6ratelier Mathis e.U.',
    address: 'Schlossergasse 4<br/>6800 Feldkirch',
    tel: '05522 / 388 41',
    email: 'feldkirch@hoeratelier.at',
    www: 'www.hoeratelier.at',
    open:
      '<b>\u00d6ffnungszeiten:<br /><br />Montag bis Freitag</b><br />8.30 - 12.30 Uhr<br />14.00 - 17.30 Uhr<br />+ Termine nach Vereinbarung\r\n<b>zus\u00e4tzliche Tel.:&nbsp;</b><br />0676 / 65 35 810',
    lat: '47.237600',
    lng: '9.596980',
    view: 1
  },
  {
    id: 59,
    name: 'Optik Hausenbichler',
    address: 'Hauptstra\u00dfe 2a<br/>6840 G\u00f6tzis',
    tel: '+43 5523 62 1 77',
    email: 'info@optikhaus.at',
    www: 'www.optikhaus.at',
    open:
      '<b>Montag - Freitag</b><br />8.15 - 12.00, 14.00 - 18.00<br /><b>Samstag</b><br />8.15 - 12.00',
    lat: '47.3326112',
    lng: '9.6438622',
    view: 3
  },
  {
    id: 139,
    name: 'Optik Weirather',
    address: 'Marktstra\u00dfe 24<br/>6845 Hohenems',
    tel: '+43 5576 72 3 37',
    email: 'optik.weirather@aon.at',
    www: '',
    open:
      '<b>Montag - Freitag</b><br />8.00 - 12.00, 14.00 - 18.00<br /><b>Samstag</b><br />8.00 - 12.00',
    lat: '47.3647253',
    lng: '9.6897068',
    view: 1
  },
  {
    id: 116,
    name:
      'Bitsche Optik GmbH Fachgesch\u00e4ft f\u00fcr Augenoptik und H\u00f6rakustik',
    address: 'Bahnhofstra\u00dfe 3<br/>6850 Dornbirn',
    tel: '+43 5572 38 67 80',
    email: 'dornbirn@bitsche-optik.at',
    www: 'http://www.bitsche-optik.at',
    open:
      '<b>Montag - Freitag</b><br /> 08.30 - 12.30, 13.30 - 18.00<br /><b>Samstag</b><br />08.30 - 12.30',
    lat: '47.4140337',
    lng: '9.7406553',
    view: 3
  },
  {
    id: 413,
    name: 'H\u00f6ratelier Mathis e.U.',
    address: 'Klostergasse 2<br/>6850 Dornbirn',
    tel: '05572 / 931 808',
    email: 'dornbirn@hoeratelier.at',
    www: 'http://www.hoeratelier.at',
    open:
      '<h4><p style=""><b style="">\u00d6ffnungszeiten:<br /><br />Montag bis Donnerstag</b><br /><span style="font-weight: 400;">8.30 - 12.30 Uhr</span><br /><span style="font-weight: 400;">14.00 - 17.30 Uhr<br /></span>Freitag<br /><span style="font-weight: 400;">08.30 - 12.30 Uhr</span></p><p style="font-weight: 400; ">+ Termine nach Vereinbarung<br /><br /><b>zus\u00e4tzliche Tel.:&nbsp;</b><br />0676 / 65 35 810</p></h4>',
    lat: '47.415560',
    lng: '9.743370',
    view: 2
  },
  {
    id: 224,
    name: 'Augenoptik Ender Uhren Schmuck',
    address: 'Reichshofstra\u00dfe 2<br/>6890 Lustenau',
    tel: '+43 5577 82 0 98',
    email: 'markus.ender@optik-ender.at',
    www: 'http://www.optik-ender.at',
    open:
      '<b>Montag, Dienstag, Mittwoch, Freitag<br /></b>8.30 - 12.00 und 14.00 - 18.00<b><br />Donnerstag, Samstag<br /></b>8.30 - 12.00<b><br /></b>',
    lat: '47.4259882',
    lng: '9.6512267',
    view: 1
  },
  {
    id: 9,
    name: 'David Sehen & H\u00f6ren',
    address: 'Im Dorf 2<br/>6922 Wolfurt',
    tel: '+43 5574 83 110',
    email: 'wolfurt@optikzone.com',
    www: 'http://www.wolfurt.optikzone.com',
    open:
      '<b>MO, DI, MI, FR</b><br />8.15 - 12.00, 14.00 - 18.00<br /><b>DO, SA</b><br />8.15 - 12.00',
    lat: '47.4734339',
    lng: '9.7524077',
    view: 3
  },
  {
    id: 118,
    name: 'Friesenecker Optik & H\u00f6rakustik',
    address: 'Montfortplatz 2<br/>6923 Lauterach',
    tel: '+43 5574 25 49 0',
    email: 'info@friesenecker-optik.at',
    www: 'http://www.friesenecker-optik.at',
    open:
      '<b>Dienstag - Freitag<br /></b>9.00 - 13.00, 14.00 - 18.00<br /><b>Samstag<br /></b>9.00 - 12.00<b><br /></b>und nach Terminvereinbarung',
    lat: '47.4773756',
    lng: '9.7318588',
    view: 3
  },
  {
    id: 94,
    name: 'DAVID Optik & H\u00f6rakustik',
    address: 'Kirchstra\u00dfe 18<br/>6971 Hard',
    tel: '+43 5574 78 3 08',
    email: 'hard@optik-david.at',
    www: 'http://www.optik-david.at',
    open:
      '<b>Montag/Dienstag/Mittwoch/Freitag</b><br />8.00 - 12.00, 14.00 - 18.00<br /><b>Donnerstag</b><br />8.00 - 12.00<br /><b>Samstag</b><br />8.00 - 12.00',
    lat: '47.492126',
    lng: '9.688877',
    view: 1
  },
  {
    id: 76,
    name: 'H. W. Hengge KG',
    address: 'Hauptstra\u00dfe 27<br/>7000 Eisenstadt',
    tel: '+43 2682 62 8 02',
    email: 'optik.hengge@bkf.at',
    www: 'http://www.optik-hengge.at',
    open:
      '<b>Montag - Freitag</b><br /> 9.00 - 18.00<br /><b>Samstag</b><br /> 9.00 - 13.00<br />und nach Terminvereinbarung',
    lat: '47.8464808',
    lng: '16.5234225',
    view: 1
  },
  {
    id: 67,
    name: 'Ruster Optik Lederer e.U.',
    address: 'Joseph Haydngasse 4<br/>7071 Rust',
    tel: '+43 2685 64 01',
    email: 'lederer@rusteroptik.at',
    www: 'http://www.rusteroptik.at',
    open:
      '<b>Montag/Dienstag/Mittwoch/Freitag</b><br />9.00 - 13.00, 15.00 - 18.00<br /><b>Donnerstag<br /></b>9.00 - 12.00<br /><b>Samstag<br /></b>9.00 - 12.00',
    lat: '47.8010751',
    lng: '16.6760221',
    view: 1
  },
  {
    id: 24,
    name: 'See Optik GmbH',
    address: 'Hauptplatz 49<br/>7100 Neusiedl/See',
    tel: '+43 2167 20 100',
    email: 'office@see-optik.at',
    www: 'http://www.see-optik.at',
    open:
      '<b>Montag - Freitag</b><br />8.00 - 12.30, 14.30 - 18.00<br /><b>Samstag</b><br />9.00 - 12.30',
    lat: '47.9497416',
    lng: '16.8409335',
    view: 1
  },
  {
    id: 75,
    name: 'Helmuth Hengge Gesellschaft m.b.H.',
    address: 'Hauptstra\u00dfe 11<br/>7350 Oberpullendorf',
    tel: '+43 2612 42 4 00',
    email: 'optik.hengge@aon.at',
    www: '',
    open:
      '<b>Montag/Dienstag/Mittwoch/Freitag</b><br />8.30 - 12.30; 14.00 - 18.00<br /><b>Donnerstag<br /></b>8.30 - 12.30<br /><b>Samstag<br /></b>9.00 - 12.00',
    lat: '47.5012046',
    lng: '16.5054905',
    view: 1
  },
  {
    id: 114,
    name: 'Optik Achatz GmbH',
    address: 'Bahnhofstra\u00dfe 1<br/>7400 Oberwart',
    tel: '+43 3352 32 2 47',
    email: 'city@optikachatz.at',
    www: 'http://www.optik-achatz.at',
    open:
      '<b>Montag/Mittwoch/Freitag<br /></b>8.00 - 12.00, 12.30 - 18.00<b><br />Dienstag/Donnerstag<br /></b>8.30 - 12.00, 12.30 - 18.00<b><br />Samstag<br /></b>9.00 - 12.00<b><br /></b>',
    lat: '47.2891646',
    lng: '16.2109611',
    view: 1
  },
  {
    id: 220,
    name: 'Optik Achatz',
    address: 'Europastra\u00dfe 1<br/>7400 Oberwart',
    tel: '+43 3352 34 5 15',
    email: 'office@optikachatz.at',
    www: 'http://www.optik-achatz.at',
    open:
      '<b>Montag - Freitag<br /></b>9.00 - 19.00<b><br />Samstag<br /></b>9.00 - 18.00<b><br /></b>',
    lat: '47.2868726',
    lng: '16.1878164',
    view: 1
  },
  {
    id: 218,
    name: 'Alles im Blick OG',
    address: 'Hamerlinggasse 8<br/>8010 Graz',
    tel: '+43 660 75 29 358',
    email: 'optik@allesimblick.at',
    www: 'https://www.allesimblick.at/',
    open: '',
    lat: '47.0686176',
    lng: '15.442715',
    view: 1
  },
  {
    id: 222,
    name: 'H\u00f6rger\u00e4te Pock',
    address: 'Andritzer Reichsstr. 42<br/>8045 Graz',
    tel: '+43 316 225 292',
    email: 'office@hoergeraete-pock.at',
    www: 'http://www.hoergeraete-pock.at',
    open:
      '<b>Montag - Samstag<br /></b>8.30 - 12.30<br /><b>Montag, Dienstag, Donnerstag </b><br />14.00 - 17.00\r\n<div></div>',
    lat: '47.1039244',
    lng: '15.4217366',
    view: 2
  },
  {
    id: 147,
    name: 'Ekkhard Neuser GmbH',
    address: 'Hauptstra\u00dfe 113<br/>8141 Premst\u00e4tten',
    tel: '+43 3136 53 3 66',
    email: 'neuser@neuser.at',
    www: 'http://www.neuser.at/',
    open:
      '<b>Montag - Freitag</b><br />8.30 - 12.15, 14.30 - 18.30<br /><b>Samstag<br /></b>9.00 - 12.00',
    lat: '46.9750612',
    lng: '15.4038617',
    view: 2
  },
  {
    id: 111,
    name: 'Optik Achatz GmbH',
    address: 'Birkfelderstra\u00dfe 51<br/>8160 Weiz',
    tel: '+43 3172 46 5 74',
    email: 'weiz@optik-achatz.at',
    www: 'http://www.optik-achatz.at',
    open: '<b>Montag-Freitag:</b><br />9.00 - 12.30, 13.30 - 18.00',
    lat: '47.2166854',
    lng: '15.6261996',
    view: 1
  },
  {
    id: 112,
    name: 'Optik Achatz GmbH',
    address: 'Nr. 66<br/>8212 Pischelsdorf',
    tel: '+43 3113 40 4 07',
    email: 'brillen@optikachatz.at',
    www: 'http://www.optik-achatz.at',
    open:
      '<b>Montag - Freitag</b><br />9.00 - 12.00, 14.00 - 18.00<br /><b>Samstag</b><br /> 9.00 - 12.00',
    lat: '47.171069',
    lng: '15.805894',
    view: 1
  },
  {
    id: 115,
    name: 'Optik Achatz GmbH',
    address: 'Im Hatric 11<br/>8230 Hartberg',
    tel: '+43 3332 62 4 09',
    email: 'office@optikachatz.at',
    www: 'http://www.optik-achatz.at',
    open:
      '<b>Montag - Freitag</b><br />9.00 - 18.00<b><br />Samstag</b><br />9.00 - 17.00<br /><br />',
    lat: '47.2805006',
    lng: '15.9690387',
    view: 3
  },
  {
    id: 80,
    name: 'H\u00d6Rwerkstatt Helga Gotschuli',
    address: 'Marktplatz 1<br/>8501 Lieboch',
    tel: '+43 3136 61 241',
    email: 'lieboch@hoerwerkstatt.at',
    www: 'http://www.hoerwerkstatt.at',
    open:
      '<b>Montag</b><br />8.30 - 12.30<br /><b>Dienstag - Donnerstag</b><br />8.30 - 12.30, 14.30 - 18.00<br /><b>Freitag<br /></b>8.30 - 12.30',
    lat: '46.973298',
    lng: '15.3370171',
    view: 2
  },
  {
    id: 154,
    name: 'H\u00f6rwerkstatt Helga Gotschuli',
    address: 'Glash\u00fcttenstra\u00dfe 46a<br/>8530 Deutschlandsberg',
    tel: '+43 3462 32 8 50',
    email: 'deutschlandsberg@hoerwerkstatt.at',
    www: 'http://www.hoerwerkstatt.at',
    open: '<b>Montag - Freitag</b><br />8.30 - 12.30, 14.00 - 17.30',
    lat: '46.8096843',
    lng: '15.2043491',
    view: 2
  },
  {
    id: 150,
    name: 'H\u00f6rstudio Lasnik',
    address: 'Hauptstra\u00dfe 52<br/>8582 Rosental',
    tel: '+43 3142 22 9 90',
    email: 'hoeren@lasnik.at',
    www: 'http://www.lasnik.at',
    open:
      '<b>Montag - Freitag</b><br />8.30 - 12.00, 14.30 - 18.00<br /><b>Samstag</b><br />8.30 - 12.00',
    lat: '47.0531',
    lng: '15.1182912',
    view: 2
  },
  {
    id: 46,
    name: 'Opticshop',
    address: 'Koloman-Wallisch-Platz 21<br/>8600 Bruck/Mur',
    tel: '+43 3862 52 4 76',
    email: 'c.winkler@opticshop.at',
    www: 'http://www.opticshop.com',
    open:
      '<b>Montag - Freitag</b><br />8.30 - 18.00<br /><b>Samstag</b><br />9.00 - 13.00',
    lat: '47.4103074',
    lng: '15.2698112',
    view: 1
  },
  {
    id: 37,
    name: 'Optik Rabl',
    address: 'Herrengasse 8<br/>8720 Knittelfeld',
    tel: '+43 3512 82 5 87',
    email: 'office@optik-rabl.at',
    www: 'http://www.optik-rabl.at',
    open:
      '<b>Montag - Freitag</b><br />8.00 - 12.00, 14.30 - 18.00<br /><b>Samstag</b><br />8.00 - 12.00',
    lat: '47.214797',
    lng: '14.8283223',
    view: 3
  },
  {
    id: 36,
    name: 'Optik Rabl',
    address: 'Bahnhofstra\u00dfe 35<br/>8740 Zeltweg',
    tel: '+43 3512 22 1 75',
    email: 'office@optik-rabl.at',
    www: 'http://www.optik-rabl.at',
    open:
      '<b>Montag - Freitag</b><br />8.00 - 12.00, 14.30 - 18.00<br /><b>Samstag</b><br />8.00 - 12.00',
    lat: '47.1901715',
    lng: '14.755438',
    view: 3
  },
  {
    id: 23,
    name: 'Optik Soudat KEG',
    address: 'Rathausplatz 2<br/>8940 Liezen',
    tel: '+43 3612 23 0 54',
    email: 'soudat@aon.at',
    www: '',
    open:
      '<b>Montag - Freitag</b><br />8.30 - 12.30, 13.30 - 18.00<br /><b>Samstag</b><br />9.00 - 12.00',
    lat: '47.5680609',
    lng: '14.2420859',
    view: 3
  },
  {
    id: 15,
    name: 'Optik Tieber GesmbH',
    address: 'Hauptplatz 43<br/>8970 Schladming',
    tel: '+43 3687 22 2 36',
    email: 'office@optik-tieber.at',
    www: 'http://www.optik-tieber.at',
    open:
      '<b>Montag - Freitag</b><br />8.30 - 18.00<br /><b>Samstag</b><br />8.30 - 12.00<br /><br />Wintersaison<br /><b>Samstag</b><br />8.30 - 18.00',
    lat: '47.391841',
    lng: '13.6900921',
    view: 1
  },
  {
    id: 78,
    name: 'Optik Gerald Grill',
    address: 'Ischler Stra\u00dfe 70<br/>8990 Bad Aussee',
    tel: '+43 3622 52 6 70',
    email: 'optik.grill@aon.at',
    www: 'http://www.optik-grill.at',
    open:
      '<b>Dienstag - Freitag<br /></b>8.00 - 12.00, 14.30 - 18.00<br /><b>Samstag<br /></b>8.30 - 12.00',
    lat: '47.6095073',
    lng: '13.7813691',
    view: 1
  },
  {
    id: 96,
    name: 'Optik Brandst\u00e4tter GmbH',
    address: 'Pernhartgasse 6<br/>9020 Klagenfurt',
    tel: '+43 463 55 3 25',
    email: 'office@optik-brandstaetter.at',
    www: 'http://www.optik-brandstaetter.at',
    open:
      '<b>Montag-Freitag</b><br />9.00 - 18.00<br /><b>Samstag</b><br />9.00 - 12.30',
    lat: '46.6237636',
    lng: '14.3059468',
    view: 3
  },
  {
    id: 132,
    name: 'Optik Buffa',
    address: 'Kramergasse 2<br/>9020 Klagenfurt',
    tel: '+43 463 51 12 76',
    email: 'office@buffa.at',
    www: 'http://www.buffa.at',
    open:
      '<b>Montag - Freitag </b><br />9.00 - 18.00<br /><b>Samstag</b><br /> 9.00 - 14.00',
    lat: '46.624316',
    lng: '14.3074018',
    view: 1
  },
  {
    id: 152,
    name: 'H\u00f6rwerkstatt Helga Gotschuli',
    address: 'Lidmanskygasse 8<br/>9020 Klagenfurt',
    tel: '+43 463 51 54 53',
    email: 'klagenfurt@hoerwerkstatt.at',
    www: 'http://www.hoerwerkstatt.at',
    open:
      '<b>Montag - Freitag</b><br />8.30 - 12.30, 14.00 - 17.30<br /><br />',
    lat: '46.622392',
    lng: '14.3082226',
    view: 2
  },
  {
    id: 99,
    name: 'Blick.Optik Inh. Bernhard Maringer',
    address: 'V\u00f6lkermarkterstra\u00dfe 38<br/>9300 St. Veit/Glan',
    tel: '+43 4212 28 2 99',
    email: 'office@blick-punkt-optik.at',
    www: 'http://www.blick-punkt-optik.at',
    open:
      '<b>Montag - Freitag</b><br />9.00 - 19.00<br /><b>Samstag</b><br />8.30 - 18.00',
    lat: '46.7650828',
    lng: '14.3708652',
    view: 1
  },
  {
    id: 29,
    name: 'H\u00d6Rwerkstatt Helga Gotschuli',
    address: 'Herrengasse 8<br/>9400 Wolfsberg',
    tel: '+43 4352 37 410',
    email: 'wolfsberg@hoerwerkstatt.at',
    www: 'http://www.hoerwerkstatt.at',
    open: '<b>Montag - Freitag</b><br />8.30 - 12.30, 14.00 - 17.30',
    lat: '46.8414548',
    lng: '14.8425513',
    view: 2
  },
  {
    id: 138,
    name: 'Uhren Schmuck Optik Zieser GmbH',
    address: 'Hauptstra\u00dfe 49<br/>9545 Radenthein',
    tel: '+43 4246 21 68',
    email: 'office@zieser.at',
    www: 'http://www.zieser.at',
    open:
      '<b>Montag - Freitag</b><br />8.00 - 12.00, 14.00 - 18.00<br /><b>Samstag</b><br />8.00 - 12.00',
    lat: '46.8004584',
    lng: '13.7033292',
    view: 1
  },
  {
    id: 32,
    name: 'Optik Schiffer',
    address: '10. Oktober Stra\u00dfe 8<br/>9560 Feldkirchen',
    tel: '+43 4276 35 90',
    email: 'optik.schiffer@aon.at',
    www: 'http://www.optik-schiffer.at',
    open:
      '<b>Montag - Freitag</b><br />8.30 - 12.30, 14.30 - 18.00<br /><b>Samstag</b><br />9.00 - 12.00',
    lat: '46.7230913',
    lng: '14.0955927',
    view: 1
  },
  {
    id: 28,
    name: 'Optik Schiffer',
    address: 'Tiroler Stra\u00dfe 4<br/>9800 Spittal/Drau',
    tel: '+43 4762 32 80',
    email: 'optik.schiffer@aon.at',
    www: 'http://www.optik-schiffer.at',
    open: '<b>Montag - Freitag</b><br />8.30 - 12.30, 14.30 - 18.00',
    lat: '46.7983774',
    lng: '13.4955842',
    view: 3
  },
  {
    id: 30,
    name: 'Optik Schiffer',
    address: 'Tiroler Stra\u00dfe 14<br/>9800 Spittal/Drau',
    tel: '+43 4762 61 3 88',
    email: 'rudolf.maier@optik-schiffer.at',
    www: 'http://www.optik-schiffer.at',
    open:
      '<b>Montag - Freitag</b><br />8.00 - 13.00, 14.00 - 18.00<br /><b>Samstag</b><br />9.00 - 12.00',
    lat: '46.7989935',
    lng: '13.4945426',
    view: 1
  },
  {
    id: 31,
    name: 'Optik Schiffer',
    address: 'Hauptplatz 18<br/>9853 Gm\u00fcnd',
    tel: '+43 4732 38 98',
    email: 'optik.schiffer@aon.at',
    www: 'http://www.optik-schiffer.at',
    open:
      '<b>Montag - Freitag:</b><br />8.30 - 12.30, 14.30 - 18.00<b><br />jeden </b><b>ersten</b><b> Samstag im Monat:</b><br />9.00 - 12.00',
    lat: '46.9068772',
    lng: '13.5348306',
    view: 1
  },
  {
    id: 14,
    name: 'Uhren Schmuck Optik Trost',
    address: 'Hintermarkt 1<br/>9971 Matrei in Osttirol',
    tel: '+43 4875 68 40',
    email: 'trost@aon.at',
    www: '',
    open:
      '<b>Montag - Freitag</b><br />8.30 - 12.00 , 14.00 - 18.00<br /><b>Samstag</b><br />8.30 - 12.00',
    lat: '47.0014055',
    lng: '12.5379851',
    view: 1
  }
]
