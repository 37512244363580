import React from 'react'
import { StyleSheet, View } from 'react-native'
import { Modal } from 'react-native-paper'
import VispecsSurface from '../components/Surfaces/VispecsSurface'
import { scale, Button, Text } from '../scaling/ScaledUI'

export default ({ info, visible, onDismiss, onConfirm, title }) => {
  return (
    <Modal visible={visible} onDismiss={onDismiss}>
      <View style={styles.container}>
        <VispecsSurface title={title || 'Information'} showLogo noFlex>
          <Text>{info}</Text>
          {typeof onConfirm === 'function' && (
            <Button style={styles.button} mode='contained' onPress={onConfirm}>
              <Text style={{ color: '#fff' }}>Bestätigen</Text>
            </Button>
          )}
        </VispecsSurface>
      </View>
    </Modal>
  )
}
const styles = StyleSheet.create({
  container: {
    margin: scale(12)
  },
  button: {
    marginTop: scale(12)
  }
})
