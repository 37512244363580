import React, { useEffect } from 'react'
import DefaultScreen from '../components/screens/DefaultScreen'
import Blank from '../components/templates/Blank'
import Markdown from 'react-native-easy-markdown'
import { useLegal } from '../hooks/general/legal'
import { Divider } from 'react-native-paper'
import { moderateScale } from '../scaling/ScaledUI'
import theme from '../styles/theme'
import { StyleSheet, Platform } from 'react-native'

export default () => {
  const { imprint, privacy, fetchLegal } = useLegal()

  useEffect(() => {
    fetchLegal()
  }, [])

  return (
    <DefaultScreen>
      <Blank ignoreHeader scrollable={Platform.OS !== 'web'}>
        <Markdown
          markdownStyles={Platform.OS === 'web' ? undefined : styles}
          useDefaultStyles
        >
          {imprint}
        </Markdown>
        <Divider style={{ marginTop: 40 }} />
        <Markdown
          markdownStyles={Platform.OS === 'web' ? undefined : styles}
          useDefaultStyles
        >
          {privacy}
        </Markdown>
      </Blank>
    </DefaultScreen>
  )
}

const text = { fontSize: moderateScale(12) }

const styles = StyleSheet.create({
  h1: { fontSize: moderateScale(20) },
  h2: { fontSize: moderateScale(17) },
  h3: { fontSize: moderateScale(15) },
  h4: { fontSize: moderateScale(13) },
  h5: { fontSize: moderateScale(11) },
  h6: { fontSize: moderateScale(8) },
  text: text,
  link: { color: theme.colors.primary, textDecorationLine: 'underline' }
})
