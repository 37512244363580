import React, { useEffect } from 'react'
import { StyleSheet, View, Platform } from 'react-native'
import { Colors, DataTable, Text, IconButton } from 'react-native-paper'
import { moderateScale } from 'react-native-size-matters'
import { useUser } from '../../hooks/user'
import { usePassport } from '../../hooks/user/passport'
import PassportMissing from './PassportMissing'
import { useNavigation } from '@react-navigation/native'

export default props => {
  const { userAttributes } = useUser()
  const { fetchPassports, glassesPassport, lensesPassport } = usePassport()
  const navigation = useNavigation()

  useEffect(() => {
    fetchPassports()
  }, [userAttributes])

  const passport =
    props.passportName === 'Brillenpass' ? glassesPassport : lensesPassport

  const handleEdit = () => {
    navigation.navigate('PassportUpdate')
  }

  const format = val => {
    if (val === 0) return '0'
    if (!val) return ''
    return (Math.sign(val) === 1 ? '+' : '') + val.toFixed(2)
  }

  const formatNoSign = val => {
    if (val === 0) return '0'
    if (!val) return ''
    return val.toString()
  }

  function getReplacementCycle (rc) {
    let text = ''
    if (rc === 'LENSESDAILY') text = 'täglich'
    else if (rc === 'LENSESWEEKLY') text = 'wöchentlich'
    else if (rc === 'LENSESMONTHLY') text = 'monatlich'
    return text
  }

  if (!passport) {
    return <PassportMissing passportName={props.passportName} />
  }

  return (
    <View>
      {passport && props.passportName === 'Brillenpass' && (
        <DataTable style={styles.table}>
          <DataTable.Header style={styles.row}>
            <DataTable.Cell style={styles.cell} />
            <DataTable.Cell style={styles.cell} numeric>
              <Text style={styles.cellText}>Sph</Text>
            </DataTable.Cell>
            <DataTable.Cell style={styles.cell} numeric>
              <Text style={styles.cellText}>Cyl</Text>
            </DataTable.Cell>
            <DataTable.Cell style={styles.cell} numeric>
              <Text style={styles.cellText}>A</Text>
            </DataTable.Cell>
            {(passport.rightData.add != null ||
              passport.leftData.add != null) && (
              <DataTable.Cell style={styles.cell} numeric>
                  <Text style={styles.cellText}>Add</Text>
                </DataTable.Cell>
            )}
            {(passport.rightData.height != null ||
              passport.leftData.height != null) && (
              <DataTable.Cell style={styles.cell} numeric>
                  <Text style={styles.cellText}>H</Text>
                </DataTable.Cell>
            )}
          </DataTable.Header>
          <DataTable.Row style={styles.row}>
            <DataTable.Cell style={styles.cell}>
              <Text style={styles.cellText}>Re</Text>
            </DataTable.Cell>
            <DataTable.Cell style={styles.cell} numeric>
              <Text style={styles.cellText}>
                {format(passport.rightData.sphere)}
              </Text>
            </DataTable.Cell>
            <DataTable.Cell style={styles.cell} numeric>
              <Text style={styles.cellText}>
                {format(passport.rightData.cylinder)}
              </Text>
            </DataTable.Cell>
            <DataTable.Cell style={styles.cell} numeric>
              <Text style={styles.cellText}>
                {passport.rightData.axis + '°'}
              </Text>
            </DataTable.Cell>
            {(passport.rightData.add != null ||
              passport.leftData.add != null) && (
              <DataTable.Cell style={styles.cell} numeric>
                  <Text style={styles.cellText}>
                  {format(passport.rightData.add)}
                </Text>
                </DataTable.Cell>
            )}
            {(passport.rightData.height != null ||
              passport.leftData.height != null) && (
              <DataTable.Cell style={styles.cell} numeric>
                  <Text style={styles.cellText}>
                  {formatNoSign(passport.rightData.height.toFixed(1))}
                </Text>
                </DataTable.Cell>
            )}
          </DataTable.Row>
          <DataTable.Row style={styles.row}>
            <DataTable.Cell style={styles.cell}>
              <Text style={styles.cellText}>Li</Text>
            </DataTable.Cell>
            <DataTable.Cell style={styles.cell} numeric>
              <Text style={styles.cellText}>
                {format(passport.leftData.sphere)}
              </Text>
            </DataTable.Cell>
            <DataTable.Cell style={styles.cell} numeric>
              <Text style={styles.cellText}>
                {format(passport.leftData.cylinder)}
              </Text>
            </DataTable.Cell>
            <DataTable.Cell style={styles.cell} numeric>
              <Text style={styles.cellText}>
                {passport.leftData.axis + '°'}
              </Text>
            </DataTable.Cell>
            {(passport.rightData.add != null ||
              passport.leftData.add != null) && (
              <DataTable.Cell style={styles.cell} numeric>
                  <Text style={styles.cellText}>
                  {format(passport.leftData.add)}
                </Text>
                </DataTable.Cell>
            )}
            {(passport.rightData.height != null ||
              passport.leftData.height != null) && (
              <DataTable.Cell style={styles.cell} numeric>
                  <Text style={styles.cellText}>
                  {formatNoSign(passport.leftData.height.toFixed(1))}
                </Text>
                </DataTable.Cell>
            )}
          </DataTable.Row>
          <DataTable.Row style={styles.row}>
            {passport.rightData.pd && passport.leftData.pd && (
              <>
                <DataTable.Cell style={styles.cell}>
                  <Text style={styles.cellText}>PD</Text>
                </DataTable.Cell>
                <DataTable.Cell style={styles.cell} numeric>
                  <Text style={styles.cellText}>
                    {passport.rightData.pd.toFixed(1)} /{' '}
                    {passport.leftData.pd.toFixed(1)}
                  </Text>
                </DataTable.Cell>
              </>
            )}
            <IconButton
              style={{ alignSelf: 'center', paddingLeft: 16 }}
              icon='table-edit'
              color={Colors.white}
              size={25}
              onPress={handleEdit}
            />
          </DataTable.Row>
        </DataTable>
      )}
      {passport && props.passportName === 'Kontaktlinsenpass' && (
        <DataTable style={styles.table}>
          <DataTable.Header style={styles.row}>
            <DataTable.Cell style={styles.cell} numeric />
            <DataTable.Cell style={styles.cell} numeric>
              <Text style={styles.cellText}>Sph</Text>
            </DataTable.Cell>
            <DataTable.Cell style={styles.cell} numeric>
              <Text style={styles.cellText}>Dia</Text>
            </DataTable.Cell>
            <DataTable.Cell style={styles.cell} numeric>
              <Text style={styles.cellText}>Bc</Text>
            </DataTable.Cell>
            <DataTable.Cell style={styles.cell} numeric>
              <Text style={styles.cellText}>Cyl</Text>
            </DataTable.Cell>
            <DataTable.Cell style={styles.cell} numeric>
              <Text style={styles.cellText}>A</Text>
            </DataTable.Cell>
            {(passport.rightData.add != null ||
              passport.leftData.add != null) && (
              <DataTable.Cell style={styles.cell} numeric>
                  <Text style={styles.cellText}>Add</Text>
                </DataTable.Cell>
            )}
          </DataTable.Header>
          <DataTable.Row style={styles.row}>
            <DataTable.Cell style={styles.cell}>
              <Text style={styles.cellText}>Re</Text>
            </DataTable.Cell>
            <DataTable.Cell style={styles.cell} numeric>
              <Text style={styles.cellText}>
                {format(passport.rightData.sphere)}
              </Text>
            </DataTable.Cell>
            <DataTable.Cell style={styles.cell} numeric>
              <Text style={styles.cellText}>
                {format(passport.rightData.diameter)}
              </Text>
            </DataTable.Cell>
            <DataTable.Cell style={styles.cell} numeric>
              <Text style={styles.cellText}>
                {format(passport.rightData.radius)}
              </Text>
            </DataTable.Cell>
            <DataTable.Cell style={styles.cell} numeric>
              <Text style={styles.cellText}>
                {format(passport.rightData.cylinder)}
              </Text>
            </DataTable.Cell>
            <DataTable.Cell style={styles.cell} numeric>
              <Text style={styles.cellText}>
                {passport.rightData.axis + '°'}
              </Text>
            </DataTable.Cell>
            {(passport.rightData.add != null ||
              passport.leftData.add != null) && (
              <DataTable.Cell style={styles.cell} numeric>
                  <Text style={styles.cellText}>
                  {format(passport.rightData.add)}
                </Text>
                </DataTable.Cell>
            )}
          </DataTable.Row>
          <DataTable.Row style={styles.row}>
            <DataTable.Cell style={styles.cell}>
              <Text style={styles.cellText}>Li</Text>
            </DataTable.Cell>
            <DataTable.Cell style={styles.cell} numeric>
              <Text style={styles.cellText}>
                {format(passport.leftData.sphere)}
              </Text>
            </DataTable.Cell>
            <DataTable.Cell style={styles.cell} numeric>
              <Text style={styles.cellText}>
                {format(passport.leftData.diameter)}
              </Text>
            </DataTable.Cell>
            <DataTable.Cell style={styles.cell} numeric>
              <Text style={styles.cellText}>
                {format(passport.leftData.radius)}
              </Text>
            </DataTable.Cell>
            <DataTable.Cell style={styles.cell} numeric>
              <Text style={styles.cellText}>
                {format(passport.leftData.cylinder)}
              </Text>
            </DataTable.Cell>
            <DataTable.Cell style={styles.cell} numeric>
              <Text style={styles.cellText}>
                {passport.leftData.axis + '°'}
              </Text>
            </DataTable.Cell>
            {(passport.rightData.add != null ||
              passport.leftData.add != null) && (
              <DataTable.Cell style={styles.cell} numeric>
                  <Text style={styles.cellText}>
                  {format(passport.leftData.add)}
                </Text>
                </DataTable.Cell>
            )}
          </DataTable.Row>
          <DataTable.Row style={styles.row}>
            <DataTable.Cell style={styles.cell}>
              <Text style={styles.cellText}>Austauschzyklus</Text>
            </DataTable.Cell>
            <DataTable.Cell style={styles.cell} numeric>
              <Text style={styles.cellText}>
                {getReplacementCycle(passport.type)}
              </Text>
            </DataTable.Cell>
            <IconButton
              style={{ alignSelf: 'center', paddingLeft: 16 }}
              icon='table-edit'
              color={Colors.white}
              size={25}
              onPress={() => navigation.navigate('PassportUpdate')}
            />
          </DataTable.Row>
        </DataTable>
      )}
      {/* {passportUpload && passportUpload.status === 'NEW' && (
        <Text>
          Ihr {props.passportName} wird in Kürze von einem unserer
          ViSpecs-Partneroptiker für Sie digitalisiert.
        </Text>
      )} */}
    </View>
  )
}

const styles = StyleSheet.create({
  table: {
    backgroundColor: '#ffffff22'
  },
  row: {
    height: Platform.OS === 'web' ? 54 : moderateScale(32, 0.7),
    minHeight: null,
    marginHorizontal: Platform.OS === 'web' ? 6 : moderateScale(1, 12)
  },
  cell: {
    paddingVertical: Platform.OS === 'web' ? 6 : 0
  },
  cellText: {
    color: 'white',
    fontSize: Platform.OS === 'web' ? 20 : moderateScale(13, 0.7)
  }
})
