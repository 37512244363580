const formatValues = (val) => {
  switch (val) {
    case 'no':
      return 'none'
    default:
      return val
  }
}

const formatAssessmentDataV1 = (entry) => {
  return {
    hasGlasses: [formatValues(entry.hasGlasses), 'Verwendet Brille'],
    lastExamYear: [
      formatValues(entry.lastExamYear),
      'Letzte Sehstärkenbestimmung'
    ],
    glassesProblem: [
      JSON.stringify(entry.glassesProblem, null, 4),
      'Probleme mit der Brille'
    ],
    smartphoneDuration: [
      formatValues(entry.smartphoneDuration),
      'Zeit am Smartphone'
    ],
    computerDuration: [
      formatValues(entry.computerDuration),
      'Zeit am Computer'
    ],
    nearProblems: [formatValues(entry.nearProblems), 'Probleme auf der Nähe'],
    farProblems: [formatValues(entry.farProblems), 'Probleme bei der Ferne'],
    wantsAntireflective: [
      formatValues(entry.wantsAntireflective),
      'Bevorzugt Entspiegelung'
    ],
    singleVisionTier: [
      formatValues(entry.singleVisionTier),
      'Bevorzugt Einstärken'
    ],
    varifocalTier: [formatValues(entry.varifocalTier), 'Bevorzugt Gleitsicht'],
    workstationTier: [
      formatValues(entry.workstationTier),
      'Bevorzugt Arbeitsplatzglas'
    ],
    wantsDigital: [
      entry.wantsDigital === 'yes' ? 'yes' : null,
      'Bevorzugt Digital'
    ],
    wantsSunProtection: [
      formatValues(entry.wantsSunProtection),
      'Bevorzugt Sonnenschutz'
    ],
    sunglassesChoice: [
      formatValues(entry.sunglassesChoice) !== 'none'
        ? formatValues(entry.sunglassesChoice)
        : null,
      'Gewählter Sonnenschutz'
    ],
    applyBlueprotection: [
      entry.smartphoneDuration + entry.computerDuration > 4 ? 'yes' : 'no',
      'Bevorzugt Blaulichtfilter'
    ],
    tint: [entry.tint, 'Sonnenschutz Tönungswert']
  }
}

const processJson = (data) => {
  data.assessmentData = JSON.parse(data.assessmentData)
  return data
}

const formatAssessmentV1 = (entry) => {
  return {
    userId: [entry.userId, 'Benuter ID'],
    createdAt: [entry.createdAt, 'Erstellzeitpunkt'],
    version: [entry.version, 'Version'],
    type: [entry.type, 'Typ'],
    assessment: formatAssessmentDataV1(processJson(entry).assessmentData)
  }
}

const formatAssessment = (assessment, version) => {
  if (true || version.toFixed(0) === '1') return formatAssessmentV1(assessment)
}

export { formatAssessment }
