import moment from 'moment'
import React from 'react'
import { TouchableOpacity, View, StyleSheet } from 'react-native'
import theme from '../../styles/theme'
import { useNavigation } from '@react-navigation/native'
import { IconButton, Text, scale } from '../../scaling/ScaledUI'

export default ({ children, navigateTo, doneDate }) => {
  const navigation = useNavigation()

  return (
    <TouchableOpacity
      onPress={() => navigation.navigate(navigateTo)}
      style={styles.container}
    >
      <View style={styles.textContainer}>
        <Text style={styles.title}>{children}</Text>
        {doneDate && (
          <Text style={styles.subTitle}>
            {moment(doneDate).fromNow()} durchgeführt
          </Text>
        )}
      </View>
      <IconButton color={theme.colors.primary} icon='chevron-right' />
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    borderWidth: scale(1),
    borderColor: theme.colors.borders,
    borderRadius: theme.roundness,
    marginVertical: scale(8),
    height: scale(40)
  },
  textContainer: {
    flex: scale(1),
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  title: {
    textTransform: 'uppercase',
    color: theme.colors.primary,
    fontSize: scale(16)
  },
  subTitle: {
    fontSize: scale(12)
  }
})
