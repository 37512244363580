const consentMd = {
  content: `
  # ZUSTIMMUNGSERKLÄRUNG

  Ich willige ein, dass meine persönlichen Daten, nämlich Name; Geschlecht; Telefonnummer; Geburtsjahr; Standortdaten; Kamera- und Speicherdaten; Profilbild; Daten und Ablichtung des Brillenpasses; Ergebnisse der Optiker-Bedarfsanalyse von der ViSpecs GmbH verarbeitet werden, um mir das Suchen von Optikern zu erleichtern; den Kontakt zwischen mir und Optikern herzustellen; meine Bedürfnisse in Bezug auf Optikerleistungen zu erheben; die Übermittlung der Daten meines Brillenpasses an meinen Optiker zu vereinfachen; mir den Optikerwechsel zu vereinfachen.
  
  Diese Einwilligung(en) kann ich jederzeit per E-Mail an datenschutz@vispecs.com oder per Post an ViSpecs GmbH, Kirchengasse 76, 2722 Weikersdorf am Steinfelde, widerrufen. Ich nehme zur Kenntnis, dass durch den Widerruf der Einwilligung die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt wird.
  `
}
const imprintMd = {
  content: `
  # IMPRESSUM

(Informationen gemäß §5 ECG, §14 UGB u. §25 MedienG)

> ViSpecs GmbH
> Kirchengasse 76
> 2722 Weikersdorf am Steinfelde

E-Mail: patrick.schlager@vispecs.com

Geschäftsführender Gesellschafter: Patrick Schlager

Rechtsform: Gesellschaft m.b.H.
Landesgericht Wiener Neustadt

Firmenbuchnummer: FN 527066y

UID: ATU75207456

Unternehmensgegenstand: Online Vorab-Beratung und Betreuung für optische Sehbehelfe

Behörde gemäß ECG: Magistrat der Stadt Wiener Neustadt

Medieninhaber und Herausgeber: ViSpecs GmbH

Gewerberechtliche Vorschriften: Gewerbeordnung 1994 BGBl 194/1994 (abrufbar unter: https://www.ris.bka.gv.at)

Website/App: Informationen, Neuigkeiten und Termine über ViSpecs GmbH

Die ViSepcs GmbH ist Mitglied der Wirtschaftskammer Niederösterreich.
`
}

const privacyMd = {
  content: `
# DATENSCHUTZERKLÄRUNG

Diese App wird von der ViSpecs GmbH, Kirchengasse 76, 2722 Weikersdorf am Steinfelde, (im Folgenden „ViSpecs“) betrieben. ViSpecs ist die Wahrung Ihrer Privatsphäre besonders wichtig. Deshalb beachtet ViSpecs die gesetzlichen Datenschutz-Bestimmungen und informiert Sie nachstehend, wie Ihre personenbezogenen Daten verarbeitet werden.

## Welche Daten werden verarbeitet?

ViSpecs verarbeitet nachfolgende personenbezogene Daten, die Sie an ViSpecs etwa durch die Ihre Angaben bei der Anmeldung oder beim Hochladen Ihres Brillenpasses übermitteln:

- angegebener Name;
- angegebenes Geschlecht;
- angegebene Telefonnummer;
- angegebenes Geburtsjahr;
- Standortdaten (sofern aktiviert);
- Kamera- und Speicherdaten (sofern genutzt);
- Profilbild (sofern hochgeladen);
- Daten und Ablichtung des Brillenpasses (sofern hochgeladen);
- Ergebnisse der Optiker-Bedarfsanalyse (sofern erstellt).

Es besteht keine Verpflichtung, uns diese Daten vollständig, richtig oder überhaupt zur Verfügung zu stellen. Allerdings stehen Ihnen ohne diese Daten einige Funktionen der App nicht oder nur eingeschränkt zur Verfügung.

## Zu welchen Zwecken werden die Daten verarbeitet?

ViSpecs verarbeitet Ihre personenbezogenen Daten zu nachstehenden Zwecken:

- um Ihnen das Suchen von Optikern zu erleichtern;
- um den Kontakt zwischen Ihnen und Optikern herzustellen;
- um Ihre Bedürfnisse in Bezug auf Optikerleistungen zu erheben;
- um die Übermittlung der Daten Ihres Brillenpasses an Ihren Optiker zu vereinfachen;
- um Ihnen den Optikerwechsel zu vereinfachen.

## Auf welchen Rechtsgrundlagen erfolgt Verarbeitung?

Die rechtliche Grundlage für die Verarbeitung Ihrer personenbezogenen Daten ist Ihre ausdrückliche Zustimmung (Art 6 Abs 1 lit a DSGVO).

## An wen werden die Daten übermittelt?

Zu den oben genannten Zwecken werden wir Ihre personenbezogenen Daten an folgende Empfänger übermitteln:

- von uns eingesetzte IT-Dienstleister;
- von Ihnen ausgewählte Optiker.

## Wie lange werden die Daten gespeichert?

Wir werden Ihre Daten grundsätzlich für die Dauer des Bestehens Ihres Nutzerkontos speichern. Eine längere Speicherung erfolgt nur, soweit rechtliche Verpflichtungen dies notwendig machen.

## Welche Rechte bestehen im Zusammenhang mit personenbezogenen Daten?

Sie sind unter anderem berechtigt, (1.) zu überprüfen, ob und welche personenbezogenen Daten wir über Sie gespeichert haben und Kopien dieser Daten zu erhalten, (2.) die Berichtigung, Ergänzung oder das Löschen Ihrer personenbezogenen Daten, die falsch sind oder nicht rechtskonform verarbeitet werden, zu verlangen, (3.) von uns zu verlangen, die Verarbeitung Ihrer personenbezogenen Daten einzuschränken, und (4.) unter bestimmten Umständen der Verarbeitung Ihrer personenbezogenen Daten zu widersprechen oder die für das Verarbeiten zuvor gegebene Einwilligung zu widerrufen, (5.) Datenübertragbarkeit zu verlangen, (6.) die Identität von Dritten, an welche Ihre personenbezogenen Daten übermittelt werden, zu kennen und (7.) bei der zuständigen Behörde Beschwerde zu erheben.

## Wie kann man mit ViSepcs Kontakt aufnehmen?

Sollten Sie zu der Verarbeitung Ihrer personenbezogenen Daten Fragen oder Anliegen haben, wenden Sie sich bitte an uns:

> ViSpecs GmbH
> Kirchengasse 76
> 2722 Weikersdorf am Steinfelde
> office@vispecs.com

Alternativ können Sie sich auch gerne an unseren Datenschutzbeauftragten wenden:

> Datenschutzbeauftragter der ViSpecs GmbH
> Kirchengasse 76
> 2722 Weikersdorf am Steinfelde
> datenschutz@vispecs.com

Zuletzt aktualisiert am 2020-07-18.
`
}

export { consentMd, imprintMd, privacyMd }
