import React, { createContext, useContext, useState } from 'react'
import * as queries from '../../graphql/queries'
import * as subscriptions from '../../graphql/subscriptions'
import { API, graphqlOperation } from '@aws-amplify/api'
import { useUser } from './index'

const useProvidePassport = () => {
  const [glassesPassport, setGlassesPassport] = useState()
  const [lensesPassport, setLensesPassport] = useState()
  const { userAttributes } = useUser()

  const fetchGlassesPassport = () => {
    fetchPassport('Brillenpass')
  }

  const fetchLensesPassport = () => {
    fetchPassport('Kontaktlinsenpass')
  }

  const fetchPassports = () => {
    Promise.all([fetchGlassesPassport(), fetchLensesPassport()]).then(() => {
    })
    const passportSubscription = subscribePassport()

    return () => {
      passportSubscription.unsubscribe()
    }
  }

  const fetchPassport = (type) => {
    return API.graphql(
      graphqlOperation(queries.listPassports, {
        userId: userAttributes.sub
      })
    ).then(({ data }) => {
      for (let i = data.listPassports.items.length - 1; i >= 0; i--) {
        if (
          type === 'Brillenpass' &&
          data.listPassports.items[i].type === 'GLASSES'
        ) {
          setGlassesPassport(data.listPassports.items[i])
          break
        } else if (
          type === 'Kontaktlinsenpass' &&
          data.listPassports.items[i].type !== 'GLASSES'
        ) {
          setLensesPassport(data.listPassports.items[i])
          break
        }
      }
    }).catch(console.error)
  }

  const subscribePassport = () => {
    return API.graphql(
      graphqlOperation(subscriptions.onCreatePassport, {
        userId: userAttributes.sub
      })
    ).subscribe({
      next: fetchPassports // fixme
    })
  }

  return { glassesPassport, lensesPassport, fetchGlassesPassport, fetchLensesPassport, fetchPassports }
}

const PassportContext = createContext()

export const PassportProvider = ({ children }) => {
  const value = useProvidePassport()

  return <PassportContext.Provider value={value}>{children}</PassportContext.Provider>
}

export const usePassport = () => useContext(PassportContext)
