// import 'react-native-gesture-handler' // must be top
import Amplify, { I18n } from '@aws-amplify/core'
import { NavigationContainer } from '@react-navigation/native'
import moment from 'moment'
import 'moment/locale/de-at'
import React from 'react'
import { Provider as PaperProvider } from 'react-native-paper'
import { enableScreens } from 'react-native-screens'
import awsconfig from './src/aws-exports'
import { UserProvider } from './src/hooks/user'
import Router from './src/router'
import theme from './src/styles/theme'
import linking from './src/router/linking'
import Landing from './src/screens/Landing'
import { LegalProvider } from './src/hooks/general/legal'
import { MarkerProvider } from './src/hooks/map/marker'
import { Platform } from 'react-native'
import Login from './src/screens/Login'
import { GlassEvaluationProvider } from './src/hooks/general/glassEvaluation'
import { AssessmentProvider } from './src/hooks/user/assessment'
import { PassportProvider } from './src/hooks/user/passport'
import LensesEvaluation from './src/screens/Evaluation/LensesEvaluation'
import { LensesEvaluationProvider } from './src/hooks/general/lensesEvaluation'

Amplify.configure(awsconfig)
I18n.setLanguage('de')
moment.locale('de-at')
console.disableYellowBox = true
console.ignoredYellowBox = ['Setting a timer']

enableScreens()

global.Buffer = global.Buffer || require('buffer').Buffer

export default () => {
  return (
    <UserProvider>
      <MarkerProvider>
        <LegalProvider>
          <AssessmentProvider>
            <PassportProvider>
              <LensesEvaluationProvider>
                <GlassEvaluationProvider>
                  <PaperProvider theme={theme}>
                    <NavigationContainer
                      linking={linking}
                      fallback={Platform.OS === 'web' ? <Landing /> : <Login />}
                      theme={theme}
                    >
                      <Router />
                    </NavigationContainer>
                  </PaperProvider>
                </GlassEvaluationProvider>
              </LensesEvaluationProvider>
            </PassportProvider>
          </AssessmentProvider>
        </LegalProvider>
      </MarkerProvider>
    </UserProvider>
  )
}
