import React, { createContext, useContext, useState, useEffect } from 'react'

const useProvideGlassEvaluation = () => {
  const [evaluatedGlasses, setEvaluatedGlasses] = useState()
  const [glassData, setGlassData] = useState(getGlassData)

  useEffect(() => {
    getGlassData()
  }, [])

  const evaluateAssessment = assessment => {
    // console.log('Data: ' + JSON.stringify(assessment, null, 4))
    setEvaluatedGlasses(getGlasses(assessment, glassData))
  }

  const getShuffledGlasses = () => {
    return shuffle(evaluatedGlasses)
  }

  function getCategory (assessment) {
    if (!assessment) return ''
    if (assessment.varifocalTier[0]) return 'Gleitsichtglas'
    if (assessment.wantsDigital[0] && assessment.wantsDigital[0] !== 'no') return 'Digitalglas'
    if (assessment.workstationTier[0]) return 'Arbeitsplatzglas'
    if (assessment.singleVisionTier[0]) return 'Einstärkenglas'
    // Keine Sehprobleme
    return 'Planglas'
  }

  function getTier (assessment) {
    if (!assessment) return ''
    if (assessment.varifocalTier[0]) return assessment.varifocalTier[0]
    if (assessment.wantsDigital[0] && assessment.wantsDigital[0] !== 'no') return assessment.wantsDigital[0]
    if (assessment.workstationTier[0]) return assessment.workstationTier[0]
    if (assessment.singleVisionTier[0]) return assessment.singleVisionTier[0]
    return ''
  }

  function getUvpRange (assessment) {
    if (!assessment) return ''
    const c = getCategory(assessment)
    const t = getTier(assessment)
    let r = [0, 0]
    switch (c + ', ' + t) {
      case 'Einstärkenglas, standard':
        r = [40, 100]
        break
      case 'Einstärkenglas, bestseller':
        r = [170, 435]
        break
      case 'Einstärkenglas, premium':
        r = [290, 470]
        break
      case 'Digitalglas, yes' || 'Digitalglas, bestseller' || 'Digitalglas, standard' || 'Digitalglas, premium':
        r = [440, 600]
        break
      case 'Arbeitsplatzglas, standard':
        r = [400, 500]
        break
      case 'Arbeitsplatzglas, bestseller':
        r = [444, 560]
        break
      case 'Arbeitsplatzglas, premium':
        r = [490, 740]
        break
      case 'Gleitsichtglas, standard':
        r = [200, 400]
        break
      case 'Gleitsichtglas, bestseller':
        r = [405, 850]
        break
      case 'Gleitsichtglas, premium':
        r = [440, 975]
        break
      case 'Planglas, ':
        r = [70, 130]
        break
      default:
        return ' '
    }
    return r[0].toFixed(2).replace('.', ',') + ' - ' + r[1].toFixed(2).replace('.', ',') + '€'
  }

  function getGlassData () {
    // eslint-disable-next-line
    fetch(
      'https://vispecs-glasses.s3.eu-central-1.amazonaws.com/assessment-glasses_v2.json'
    )
      .then(response => {
        return response.json()
      })
      .then(responseJson => {
        setGlassData(responseJson)
      })
  }

  function assessmentRuleEnforcer (a) {
    if (!a) return a
    const tier = getTier(a)
    if (tier === '') return a
    if (
      a.wantsAntireflective[0] === 'none' &&
      (a.wantsDigital[0] ||
        a.workstationTier[0] ||
        tier === 'bestseller' ||
        tier === 'premium')
    ) {
      a.wantsAntireflective[0] = 'standard'
    }
    if (
      a.wantsAntireflective[0] === 'premium' &&
      a.applyBlueprotection[0] === 'yes' &&
      (tier === 'bestseller' || tier === 'premium')
    ) {
      a.wantsAntireflective[0] = 'premium'
    }

    return a
  }

  function formatSun (o) {
    if (!o) return ''
    let col = o[0]
    switch (col) {
      case 'gray':
        col = 'Grau'
        break
      case 'grey':
        col = 'Grau'
        break
      case 'brown':
        col = 'Braun'
        break
      case 'green':
        col = 'Grau'
        break
      default:
        break
    }
    if (o[1] === null) {
      return col
    }
    return col + ' ' + (o[1] * 100).toFixed(0) + '%'
  }

  function getGlassFromManufacturer (
    GlassData,
    manufacturer,
    type,
    tier,
    antireflective,
    sunglassesChoice,
    sunglassesExtra,
    blueprotection
  ) {
    if (
      !GlassData ||
      !GlassData[manufacturer] ||
      !GlassData[manufacturer][type] ||
      !GlassData[manufacturer][type][tier] ||
      (antireflective &&
        !GlassData[manufacturer].antireflectiveTier[antireflective]) ||
      (sunglassesChoice &&
        GlassData[manufacturer].sunglassesChoice[sunglassesChoice] === null)
    ) {
      return null
    } else {
      return {
        Manufacturer: manufacturer,
        Image: GlassData[manufacturer].image,
        Name: GlassData[manufacturer][type][tier][0],
        Uvp:
          GlassData[manufacturer][type][tier][1] +
          (antireflective
            ? GlassData[manufacturer].antireflectiveTier[antireflective][1]
            : 0) +
          (sunglassesChoice
            ? GlassData[manufacturer].sunglassesChoice[sunglassesChoice][type]
            : 0) +
          GlassData[manufacturer].blueprotection[tier],
        Antireflective: antireflective
          ? GlassData[manufacturer].antireflectiveTier[antireflective][0]
          : null,
        SunglassesChoice: sunglassesChoice
          ? GlassData[manufacturer].sunglassesChoice[sunglassesChoice].name +
            ' ' +
            formatSun(sunglassesExtra)
          : null,
        Blueprotection: blueprotection,
        Url:
          GlassData[manufacturer][type][tier] &&
          GlassData[manufacturer][type][tier].length >= 3
            ? GlassData[manufacturer][type][tier][2]
            : null
      }
    }
  }

  function getGlasses (assessment, GlassData) {
    const glass = []
    // setDemo(!demo)
    // console.log('##############################\nGot input: ')
    if (!assessment || !GlassData || assessment === {}) return glass
    assessment = assessmentRuleEnforcer(assessment)

    let type = null
    let tier = null
    if (assessment.singleVisionTier && assessment.singleVisionTier[0]) {
      type = 'singleVisionTier'
      tier = assessment.singleVisionTier[0]
    } else if (assessment.varifocalTier && assessment.varifocalTier[0]) {
      type = 'varifocalTier'
      tier = assessment.varifocalTier[0]
    } else if (
      assessment.wantsDigital &&
      assessment.wantsDigital[0] &&
      assessment.wantsDigital[0] !== 'no'
    ) {
      type = 'digitalTier'
      tier =
        assessment.wantsDigital[0] === 'yes'
          ? 'bestseller'
          : assessment.wantsDigital[0]
    } else {
    }
    if (type && tier) {
      Object.keys(GlassData).map((m, i) => {
        const g = getGlassFromManufacturer(
          GlassData,
          m,
          type,
          tier,
          assessment.wantsAntireflective[0],
          assessment.sunglassesChoice[0],
          assessment.tint[0],
          assessment.applyBlueprotection[0] === 'yes'
        )
        if (g !== null) glass.push(g)
      })
    } else {
      const sunglassesChoice = assessment.sunglassesChoice[0]
      const sunglassesExtra = assessment.tint[0]
      // console.log(JSON.stringify(assessment.sunglassesChoice, null, 4))
      glass.push({
        Message:
          'Aufgrund Ihrer Angaben benötigen Sie keine Sehhilfe. Wir empfehlen jedoch ein Standard Glas mit integriertem Blaulichtfilter um Ihre Augen optimal zu schützen. ',
        Image:
          'https://vispecs-glasses.s3.eu-central-1.amazonaws.com/Images/Diverse_logo.png',
        Name: 'Planglas',
        Antireflective: 'Blueprotection',
        SunglassesChoice:
          sunglassesChoice && sunglassesChoice !== 'none'
            ? GlassData.Zeiss.sunglassesChoice[sunglassesChoice].name +
              ' ' +
              formatSun(sunglassesExtra)
            : null
      })
    }

    if (glass.length === 0) {
      glass.push({
        Name: 'Es tut uns leid, wir konnten kein passendes Glas für Sie finden.'
      })
    }
    // console.log('Glasses: ' + JSON.stringify(glass, null, 4))
    return glass
  }

  function shuffle (a) {
    if (!a) return a
    var j, x, i
    for (i = a.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1))
      x = a[i]
      a[i] = a[j]
      a[j] = x
    }
    return a
  }

  return { evaluatedGlasses, getShuffledGlasses, evaluateAssessment, getCategory, getTier, getUvpRange }
}

const GlassEvaluationContext = createContext()

export const GlassEvaluationProvider = ({ children }) => {
  const value = useProvideGlassEvaluation()

  return (
    <GlassEvaluationContext.Provider value={value}>
      {children}
    </GlassEvaluationContext.Provider>
  )
}

export const useGlassEvaluation = () => useContext(GlassEvaluationContext)
