import React from 'react'
import GlassesPassportForm from './Forms/GlassesPassportForm'
import LensesPassportForm from './Forms/LensesPassportForm'

export default props => {
  return (
    <>
      {
        props.route.params.intent === 'Brillenpass' && (
          <GlassesPassportForm passport={props.route.params.passport} />
        )
      }
      {
        props.route.params.intent === 'Kontaktlinsenpass' && (
          <LensesPassportForm passport={props.route.params.passport} />
        )
      }
    </>
  )
}
