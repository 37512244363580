import React, { useCallback } from 'react'
import { Linking, View, Image } from 'react-native'
import {
  Text,
  Subheading,
  Paragraph,
  Button,
  scale
} from '../../scaling/ScaledUI'
import theme from '../../styles/theme'

export default props => {
  const { glass, width, padding, imagePadding } = props
  // console.log('My glass: ' + JSON.stringify(glass, null, 4))

  const handlePress = useCallback(async () => {
    // console.log('My glass: ' + JSON.stringify(glass, null, 4))
    const url = glass.Url
      ? glass.Url
      : 'https://google.com/search?q=' +
        encodeURI((glass.Manufacturer !== 'Diverse Hersteller' ? (glass.Manufacturer + ' ') : '') + glass.Name).replace('+', '%2B')
    const supported = await Linking.canOpenURL(url)

    if (supported) {
      await Linking.openURL(url)
    } else {
    }
  })

  return (
    <View
      style={{
        flex: 1,
        alignSelf: 'center',
        justifyContent: 'center',
        width: width - padding,
        marginVertical: padding,
        padding: scale(3),
        backgroundColor: '#ffffff',
        shadowColor: '#000',
        shadowOffset: {
          width: 0,
          height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5
      }}
    >
      {glass.Image && (<Image source={{ uri: glass.Image }} style={{ height: width - imagePadding, width: width - imagePadding, alignSelf: 'center', marginBottom: padding }} />)}
      {glass.Manufacturer === 'Diverse Hersteller' && (<Text style={{ textAlign: 'center' }}>Diverse Hersteller bieten dies Glas an</Text>)}
      {glass.Name && (<Subheading style={{ fontWeight: 'bold', textAlign: 'center' }}>{(glass.Manufacturer && glass.Manufacturer !== 'Diverse Hersteller') ? glass.Manufacturer : ' '} {glass.Name}</Subheading>)}
      {(!glass.Name && glass.Manufacturer) && (<Subheading style={{ fontWeight: 'bold', textAlign: 'center' }}>{glass.Manufacturer && (<Text style={{ fontWeight: 'bold' }}>{glass.Manufacturer}</Text>)}</Subheading>)}
      {glass.Antireflective && (<Paragraph style={{ textAlign: 'center' }}>Entspiegelung: {glass.Antireflective}</Paragraph>)}
      {glass.SunglassesChoice && glass.SunglassesChoice !== 'Keiner' && (<Paragraph style={{ textAlign: 'center' }}>Sonnenschutz: {glass.SunglassesChoice}</Paragraph>)}
      {glass.Blueprotection && (<Paragraph style={{ textAlign: 'center' }}>Blaulichtschutz</Paragraph>)}
      {glass.Manufacturer !== 'Diverse Hersteller' && glass.Antireflective !== 'Standard Entspiegelung' && glass.Uvp && glass.Uvp !== null && (<Subheading style={{ textAlign: 'center', fontWeight: 'bold' }}>Preisschätzung: {glass.Uvp.toString()}€</Subheading>)}
      {glass.Manufacturer && glass.Manufacturer !== 'Diverse Hersteller' && (<Button mode='text' onPress={handlePress}><Text style={{ color: theme.colors.primary }}>mehr erfahren</Text></Button>)}
    </View>
  )
}
