import React from 'react'
import DefaultScreen from '../components/screens/DefaultScreen'
import Blank from '../components/templates/Blank'
import { Form, Field } from 'react-final-form'
import theme from '../styles/theme'
import LinkButton from '../components/LinkButton'
import { Linking, Picker, View } from 'react-native'
import moment from 'moment'
import { FORM_ERROR } from 'final-form'
import { parsePhoneNumberFromString } from 'libphonenumber-js/min'
import {
  Button,
  Divider,
  Subheading,
  Text,
  TextInput
} from 'react-native-paper'
import * as Yup from 'yup'

// ############ todo ############
// import { IntegerInput, Photo } from '../../form-wizard/Inputs'
// import LocalOptician from './components/LocalOptician'

import { useUser } from '../hooks/user'
import { moderateScale, scale } from '../scaling/ScaledUI'
import { useLinkProps } from '@react-navigation/native'

const validationSchema = Yup.object({
  name: Yup.string()
    .trim()
    .required(),
  email: Yup.string().email(),
  gender: Yup.string()
    .oneOf(['male', 'female', 'other'])
    .required(),
  birthdate: Yup.date()
    .max(moment())
    .min('1900-01-01')
    .required(),
  address: Yup.string().trim(),
  picture: Yup.string()
})

export default () => {
  const { userAttributes, updateUserAttributes } = useUser()

  const { onPress } = useLinkProps({ to: '/home' })

  const updateProfile = values => {
    updateUserAttributes(values).then(success => {
      if (success) onPress()
    })
  }

  return (
    <DefaultScreen>
      <Blank ignoreHeader>
        <Form
          onSubmit={updateProfile}
          validate={values => {
            return validationSchema
              .validate(values)
              .then(() => undefined)
              .catch(() => FORM_ERROR)
          }}
          initialValues={userAttributes}
        >
          {props => (
            <>
              <Subheading>Name</Subheading>
              <Field name='name'>
                {props => (
                  <TextInput
                    value={props.input.value}
                    onChange={props.input.onChange}
                  />
                )}
              </Field>
              <Subheading>Geschlecht</Subheading>
              <Field name='gender'>
                {props => (
                  <Picker
                    selectedValue={props.input.value}
                    onValueChange={(itemValue, itemIndex) =>
                      props.input.onChange(itemValue)}
                    itemStyle={{
                      fontSize: moderateScale(14, 0.7),
                      height: moderateScale(50, 0.7)
                    }}
                  >
                    <Picker.Item label='männlich' value='male' />
                    <Picker.Item label='weiblich' value='female' />
                    <Picker.Item label='anderes / keine Angabe' value='other' />
                  </Picker>
                )}
              </Field>
              <Subheading>Geburtsjahr</Subheading>
              {/* <Field name='birthdate'>
                {props => (
                  <IntegerInput
                    disabled
                    input={{
                      value: parseInt(props.input.value.substring(0, 4)),
                      onChange: value => props.input.onChange(value + '-01-01')
                    }}
                  />
                )}
              </Field> */}
              <Subheading>Telefonnummer</Subheading>
              <TextInput
                disabled
                value={
                  userAttributes.phone_number &&
                  parsePhoneNumberFromString(
                    userAttributes.phone_number
                  ).formatInternational()
                }
              />
              <Subheading>E-Mail-Addresse</Subheading>
              <Field name='email'>
                {props => (
                  <TextInput
                    value={props.input.value}
                    onChange={props.input.onChange}
                  />
                )}
              </Field>
              <Subheading>Postanschrift</Subheading>
              <Field name='address'>
                {props => (
                  <TextInput
                    multiline
                    value={props.input.value}
                    onChange={props.input.onChange}
                  />
                )}
              </Field>
              <LinkButton
                style={{ marginVertical: moderateScale(8) }}
                mode='outlined'
                to='/passport/update'
              >
                <Text style={{ color: theme.colors.primary }}>
                  Optische Daten aktualisieren
                </Text>
              </LinkButton>
              <Subheading>Profilbild</Subheading>
              {/* <Field name='picture'>
                {props => (
                  <Photo
                    input={{ ...props.input }}
                    manipulateActions={[
                      {
                        resize: { width: 200 }
                      }
                    ]}
                  />
                )}
              </Field> */}
              <View style={{ flexDirection: 'row', marginHorizontal: -4 }}>
                <Button
                  disabled={props.pristine || props.invalid || props.submitting}
                  style={{ flex: 1, marginTop: 8, marginHorizontal: 4 }}
                  mode='contained'
                  onPress={props.handleSubmit}
                >
                  <Text
                    style={{
                      color:
                        props.pristine || props.invalid || props.submitting
                          ? theme.colors.disabled
                          : '#fff'
                    }}
                  >
                    speichern
                  </Text>
                </Button>
                <Button
                  disabled={props.pristine || props.submitting}
                  style={{ flex: 1, marginTop: 8, marginHorizontal: 4 }}
                  mode='outlined'
                  onPress={() => props.form.reset()}
                >
                  <Text
                    style={{
                      color:
                        props.pristine || props.submitting
                          ? theme.colors.disabled
                          : theme.colors.primary
                    }}
                  >
                    zurücksetzen
                  </Text>
                </Button>
              </View>
            </>
          )}
        </Form>
        <Divider
          style={{
            marginVertical: moderateScale(10),
            backgroundColor: '#666666'
          }}
        />
        {/* <Subheading>Mein Optiker</Subheading> */}
        {/* <LocalOptician /> */}
        <Divider
          style={{
            marginVertical: moderateScale(16),
            backgroundColor: '#666666'
          }}
        />
        <Button
          style={{ flex: 1, marginHorizontal: scale(4) }}
          mode='outlined'
          onPress={() =>
            Linking.openURL('mailto:patrick.schlager@vispecs.com?subject=Datenschutzanfrage')}
        >
          <Text style={{ color: theme.colors.primary }}>
            Datenschutzanfrage
          </Text>
        </Button>
        <LinkButton icon='information-outline' to='/legal'>
          <Text style={{ color: theme.colors.primary }}>
            Impressum &amp; Datenschutzerklärung
          </Text>
        </LinkButton>
      </Blank>
    </DefaultScreen>
  )
}
