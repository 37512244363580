/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:53ac04cf-a0b7-4e19-b607-541aeb244b6b",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_REoyK11Hp",
    "aws_user_pools_web_client_id": "2e61jv8oofnjo46fv29q826ts8",
    "oauth": {},
    "aws_mobile_analytics_app_id": "282a1c202af2466293a4477ea31d148d",
    "aws_mobile_analytics_app_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://vwmfgyuysvf6jiofetqap74hwu.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "vispecs58528aafba334c58810e06488816238e125505-poc",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
