export default [
  {
    uid: 1,
    pid: 91,
    number: '001-0101',
    title: 'Ahaus',
    description: '',
    informations: '',
    street: 'Markt 26',
    zip: '48683',
    citypart: '',
    addition: '',
    fon: '02561-5895',
    x: '02561-5410',
    commonName: '',
    xCoordinates: '52.076027',
    yCoordinates: '7.00749',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Ahaus',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann Augenoptik AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 3,
    pid: 91,
    number: '001-0103',
    title: 'Bonn I, Kölnstraße',
    description: '',
    informations: '',
    street: 'Kölnstraße 433',
    zip: '53117',
    citypart: 'Auerberg',
    addition: '',
    fon: '0228-674249',
    fax: '0228-678179',
    commonName: '',
    xCoordinates: '50.751561',
    yCoordinates: '7.075126',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Bonn',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'fielmann-optic Fielmann GmbH + Co. KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 5,
    pid: 91,
    number: '001-0105',
    title: 'Brunsbüttel',
    description: '',
    informations: '',
    street: 'Koogstraße 67-71',
    zip: '25541',
    citypart: '',
    addition: '',
    fon: '04852-4422',
    fax: '04852-3448',
    commonName: '',
    xCoordinates: '53.896604',
    yCoordinates: '9.14033',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Brunsbüttel',
    country: 'Deutschland',
    state: 'Schleswig-Holstein',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 7,
    pid: 91,
    number: '001-0107',
    title: 'Datteln',
    description: '',
    informations: '',
    street: 'Castroper Straße 24',
    zip: '45711',
    citypart: '',
    addition: '',
    fon: '02363-33939',
    fax: '02363-35212',
    commonName: '',
    xCoordinates: '51.650589',
    yCoordinates: '7.338395',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Datteln',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 11,
    pid: 91,
    number: '001-0110',
    title: 'Eckernförde',
    description: '',
    informations: '',
    street: 'St. Nicolai Straße 23 - 25',
    zip: '24340',
    citypart: '',
    addition: '',
    fon: '04351-5832',
    fax: '04351-3357',
    commonName: '',
    xCoordinates: '54.47221',
    yCoordinates: '9.83498',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Eckernförde',
    country: 'Deutschland',
    state: 'Schleswig-Holstein',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 13,
    pid: 91,
    number: '001-0112',
    title: 'Gelsenkirchen I, Hochstraße',
    description: '',
    informations: '',
    street: 'Hochstraße 5',
    zip: '45894',
    citypart: 'Buer',
    addition: '',
    fon: '0209-379181',
    fax: '0209-397678',
    commonName: '',
    xCoordinates: '51.5780372',
    yCoordinates: '7.0548391',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Gelsenkirchen',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. Buer OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 15,
    pid: 91,
    number: '001-0114',
    title: 'Hannover I, Hildesheimer Str.',
    description: '',
    informations: '',
    street: 'Hildesheimer Straße 7',
    zip: '30169',
    citypart: 'Zentrum',
    addition: '',
    fon: '0511-855443',
    fax: '0511-853718',
    commonName: '',
    xCoordinates: '52.368218',
    yCoordinates: '9.744353',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Hannover',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 17,
    pid: 91,
    number: '001-0116',
    title: 'Hildesheim',
    description: '',
    informations: '',
    street: 'Bahnhofsallee 2',
    zip: '31134',
    citypart: '',
    addition: '',
    fon: '05121-36555',
    fax: '05121-37518',
    commonName: '',
    xCoordinates: '52.1549455',
    yCoordinates: '9.9562284',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Hildesheim',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 19,
    pid: 91,
    number: '001-0102',
    title: 'Bielefeld I, Potsdamer Str.',
    description: '',
    informations: '',
    street: 'Potsdamer Straße 9',
    zip: '33719',
    citypart: 'Heepen',
    addition: '',
    fon: '0521-332868',
    fax: '0521-335182',
    commonName: '',
    xCoordinates: '52.02747',
    yCoordinates: '8.603599',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Bielefeld',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 21,
    pid: 91,
    number: '001-0104',
    title: 'Braunschweig I, Casparistraße',
    description: '',
    informations: '',
    street: 'Casparistr. 4 - 6',
    zip: '38100',
    citypart: '',
    addition: '',
    fon: '0531-44147',
    fax: '0531-18799',
    commonName: '',
    xCoordinates: '52.2662129',
    yCoordinates: '10.5240579',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Braunschweig',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 23,
    pid: 91,
    number: '001-0106',
    title: 'Cuxhaven',
    description: '',
    informations: '',
    street: 'Nordersteinstraße 8',
    zip: '27472',
    citypart: '',
    addition: '',
    fon: '04721-21884',
    fax: '04721-21764',
    commonName: '',
    xCoordinates: '53.859229',
    yCoordinates: '8.699648',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Cuxhaven',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 25,
    pid: 91,
    number: '001-0108',
    title: 'Dülmen',
    description: '',
    informations: '',
    street: 'Marktstraße 3',
    zip: '48249',
    citypart: '',
    addition: '',
    fon: '02594-6585',
    fax: '02594-85613',
    commonName: '',
    xCoordinates: '51.830791',
    yCoordinates: '7.277499',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Dülmen',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 27,
    pid: 91,
    number: '001-0113',
    title: 'Hamburg-Volksdorf',
    description: '',
    informations: '',
    street: 'Weiße Rose 10',
    zip: '22359',
    citypart: 'Volksdorf',
    addition: '',
    fon: '040-6038850',
    fax: '040-6031360',
    commonName: '',
    xCoordinates: '53.6481358',
    yCoordinates: '10.1646469',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Hamburg',
    country: 'Deutschland',
    state: 'Hamburg',
    firmierung: 'Fielmann AG & Co-Volksdorf OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 29,
    pid: 91,
    number: '001-0115',
    title: 'Helmstedt',
    description: '',
    informations: '',
    street: 'Neumärker Straße 1a-3',
    zip: '38350',
    citypart: '',
    addition: '',
    fon: '05351-6583',
    fax: '05351-4347',
    commonName: 'Marktpassage',
    xCoordinates: '52.227613',
    yCoordinates: '11.009859',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Helmstedt',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 31,
    pid: 91,
    number: '001-0117',
    title: 'Lohne',
    description: '',
    informations: '',
    street: 'Deichstr. 4',
    zip: '49393',
    citypart: '',
    addition: '',
    fon: '04442-3955',
    fax: '04442-6863',
    commonName: '',
    xCoordinates: '52.6649852',
    yCoordinates: '8.233846',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Lohne',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'fielmann-optic Fielmann GmbH + Co. KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 33,
    pid: 91,
    number: '001-0119',
    title: 'Münster I, An der Rothenburg',
    description: '',
    informations: '',
    street: 'Rothenburg 43/44',
    zip: '48143',
    citypart: '',
    addition: '',
    fon: '0251-58420',
    fax: '0251-511361',
    commonName: '',
    xCoordinates: '51.9607253',
    yCoordinates: '7.6261237',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Münster',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. An der Rothenburg OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 35,
    pid: 91,
    number: '001-0120',
    title: 'Münster II, Klosterstr.',
    description: '',
    informations: '',
    street: 'Klosterstraße 53',
    zip: '48143',
    citypart: '',
    addition: '',
    fon: '0251-47629',
    fax: '0251-56957',
    commonName: '',
    xCoordinates: '51.957388',
    yCoordinates: '7.628766',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Münster',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. Klosterstraße OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 37,
    pid: 91,
    number: '001-0121',
    title: 'Münster III, Bodelschwinghstr.',
    description: '',
    informations: '',
    street: 'Bodelschwinghstraße 15',
    zip: '48165',
    citypart: 'Hiltrup',
    addition: '',
    fon: '02501-6061',
    fax: '02501-24481',
    commonName: '',
    xCoordinates: '51.906002',
    yCoordinates: '7.642746',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Münster',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. Hiltrup OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 39,
    pid: 91,
    number: '001-0123',
    title: 'Paderborn',
    description: '',
    informations: '',
    street: 'Westernstraße 38',
    zip: '33098',
    citypart: '',
    addition: '',
    fon: '05251-21045',
    fax: '05251-23643',
    commonName: '',
    xCoordinates: '51.716005',
    yCoordinates: '8.747827',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Paderborn',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 41,
    pid: 91,
    number: '001-0125',
    title: 'Reutlingen I, Wilhelmstr.',
    description: '',
    informations: '',
    street: 'Wilhelmstr. 65',
    zip: '72764',
    citypart: '',
    addition: '',
    fon: '07121-334567',
    fax: '07121-300804',
    commonName: '',
    xCoordinates: '48.4924047',
    yCoordinates: '9.2116809',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Reutlingen',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 43,
    pid: 91,
    number: '001-0127',
    title: 'Rottweil',
    description: '',
    informations: '',
    street: 'Königstraße 35',
    zip: '78628',
    citypart: '',
    addition: '',
    fon: '0741-23531',
    fax: '0741-13664',
    commonName: '',
    xCoordinates: '48.1624544',
    yCoordinates: '8.6318264',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Rottweil',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann Augenoptik AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 45,
    pid: 91,
    number: '001-0129',
    title: 'Wilhelmshaven',
    description: '',
    informations: '',
    street: 'Marktstraße 46',
    zip: '26382',
    citypart: '',
    addition: '',
    fon: '04421-21097',
    fax: '04421-23561',
    commonName: '',
    xCoordinates: '53.519878',
    yCoordinates: '8.114849',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Wilhelmshaven',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 49,
    pid: 91,
    number: '001-0132',
    title: 'Hamburg-Bramfeld',
    description: '',
    informations: '',
    street: 'Bramfelder Chaussee 269',
    zip: '22177',
    citypart: 'Bramfeld',
    addition: '',
    fon: '040-6428090',
    fax: '040-6428435',
    commonName: '',
    xCoordinates: '53.615617',
    yCoordinates: '10.075976',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Hamburg',
    country: 'Deutschland',
    state: 'Hamburg',
    firmierung: 'Fielmann AG & Co. Bramfeld KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 51,
    pid: 91,
    number: '001-0134',
    title: 'Olsberg',
    description: '',
    informations: '',
    street: 'Markt 1',
    zip: '59939',
    citypart: '',
    addition: '',
    fon: '02962-3643',
    fax: '02962-6335',
    commonName: '',
    xCoordinates: '51.353627',
    yCoordinates: '8.489944',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Olsberg',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 53,
    pid: 91,
    number: '001-0136',
    title: 'Troisdorf',
    description: '',
    informations: '',
    street: 'Pfarrer-Kenntemich-Platz 7',
    zip: '53840',
    citypart: '',
    addition: '',
    fon: '02241-72430',
    fax: '02241-73641',
    commonName: '',
    xCoordinates: '50.813191',
    yCoordinates: '7.16112',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Troisdorf',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 55,
    pid: 91,
    number: '001-0124',
    title: 'Rendsburg',
    description: '',
    informations: '',
    street: 'Torstraße 1',
    zip: '24768',
    citypart: '',
    addition: '',
    fon: '04331-27715',
    fax: '04331-28490',
    commonName: '',
    xCoordinates: '54.306327',
    yCoordinates: '9.661921',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Rendsburg',
    country: 'Deutschland',
    state: 'Schleswig-Holstein',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 57,
    pid: 91,
    number: '001-0128',
    title: 'Waltrop',
    description: '',
    informations: '',
    street: 'Hagelstraße 5 - 7',
    zip: '45731',
    citypart: '',
    addition: '',
    fon: '02309-71809',
    fax: '02309-74268',
    commonName: '',
    xCoordinates: '51.6243374',
    yCoordinates: '7.3963501',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Waltrop',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 59,
    pid: 91,
    number: '001-0135',
    title: 'Wedel I, Bahnhofstr.',
    description: '',
    informations: '',
    street: 'Bahnhofstraße 38 - 40',
    zip: '22880',
    citypart: '',
    addition: '',
    fon: '04103-2698',
    fax: '04103-82368',
    commonName: '',
    xCoordinates: '53.5774915',
    yCoordinates: '9.7051999',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Wedel',
    country: 'Deutschland',
    state: 'Schleswig-Holstein',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 61,
    pid: 91,
    number: '001-0137',
    title: 'Ahrensburg',
    description: '',
    informations: '',
    street: 'Rondeel 8',
    zip: '22926',
    citypart: '',
    addition: '',
    fon: '04102-31210',
    fax: '04102-1750',
    commonName: '',
    xCoordinates: '53.672781',
    yCoordinates: '10.238946',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Ahrensburg',
    country: 'Deutschland',
    state: 'Schleswig-Holstein',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 63,
    pid: 91,
    number: '001-0139',
    title: 'Villingen I, Bickenstraße',
    description: '',
    informations: '',
    street: 'Bickenstraße 17',
    zip: '78050',
    citypart: 'Villingen',
    addition: '',
    fon: '07721-1333',
    fax: '07721-56770',
    commonName: '',
    xCoordinates: '48.05981',
    yCoordinates: '8.46093',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Villingen-Schwenningen',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 65,
    pid: 91,
    number: '001-0140',
    title: 'Haßloch',
    description: '',
    informations: '',
    street: 'Rathausplatz 4',
    zip: '67454',
    citypart: '',
    addition: '',
    fon: '06324-1767',
    fax: '06324-82351',
    commonName: '',
    xCoordinates: '49.363004',
    yCoordinates: '8.257015',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Haßloch',
    country: 'Deutschland',
    state: 'Rheinland-Pfalz',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 67,
    pid: 91,
    number: '001-0142',
    title: 'Hamburg-Langenhorn',
    description: '',
    informations: '',
    street: 'Langenhorner Chaussee 692',
    zip: '22419',
    citypart: 'Langenhorn',
    addition: '',
    fon: '040-5278464',
    fax: '040-5278504',
    commonName: '',
    xCoordinates: '53.68099',
    yCoordinates: '10.001074',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Hamburg',
    country: 'Deutschland',
    state: 'Hamburg',
    firmierung: 'Fielmann AG & Co. Ochsenzoll OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 69,
    pid: 91,
    number: '001-0144',
    title: 'Hamburg-Farmsen',
    description: '',
    informations: '',
    street: 'Berner Heerweg 173/175',
    zip: '22159',
    citypart: 'Farmsen',
    addition: '',
    fon: '040-6430572',
    fax: '040-6436397',
    commonName: 'EKT Farmsen',
    xCoordinates: '53.605446',
    yCoordinates: '10.118471',
    opening: false,
    opening_box: false,
    openingText:
      '<p>Die neue Fielmann-Niederlassung in Hamburg-Farmsen, EKT Farmsen.</p><p><a href="https://www.fielmann.de/?id=559">Kommen Sie am 4. September zum großen Eröffnungsfest</a>.</p>',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Hamburg',
    country: 'Deutschland',
    state: 'Hamburg',
    firmierung: 'fielmann Farmsen Fielmann GmbH & Co. KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 71,
    pid: 91,
    number: '001-0146',
    title: 'Coesfeld',
    description: '',
    informations: '',
    street: 'Letter Straße 3',
    zip: '48653',
    citypart: '',
    addition: '',
    fon: '02541-82488',
    fax: '02541-85286',
    commonName: '',
    xCoordinates: '51.944124',
    yCoordinates: '7.166653',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Coesfeld',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 73,
    pid: 91,
    number: '001-0138',
    title: 'Heide',
    description: '',
    informations: '',
    street: 'Friedrichstraße 34',
    zip: '25746',
    citypart: '',
    addition: '',
    fon: '0481-63163',
    fax: '0481-65683',
    commonName: '',
    xCoordinates: '54.1947218',
    yCoordinates: '9.0969185',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Heide',
    country: 'Deutschland',
    state: 'Schleswig-Holstein',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 75,
    pid: 91,
    number: '001-0141',
    title: 'Lengerich',
    description: '',
    informations: '',
    street: 'Altstadt 17',
    zip: '49525',
    citypart: '',
    addition: '',
    fon: '05481-37728',
    fax: '05481-38356',
    commonName: '',
    xCoordinates: '52.1894755',
    yCoordinates: '7.8496612',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '4108',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '4108',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Lengerich',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 77,
    pid: 91,
    number: '001-0143',
    title: 'Varel',
    description: '',
    informations: '',
    street: 'Hindenburgstraße 4',
    zip: '26316',
    citypart: '',
    addition: '',
    fon: '04451-82277',
    fax: '04451-6573',
    commonName: '',
    xCoordinates: '53.396171',
    yCoordinates: '8.138112',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Varel',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'fielmann-optic Fielmann GmbH & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 79,
    pid: 91,
    number: '001-0145',
    title: 'Diepholz',
    description: '',
    informations: '',
    street: 'Lange Straße 43',
    zip: '49356',
    citypart: '',
    addition: '',
    fon: '05441-6881',
    fax: '05441-7942',
    commonName: '',
    xCoordinates: '52.606037',
    yCoordinates: '8.370554',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Diepholz',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann Augenoptik AG & Co. KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 81,
    pid: 91,
    number: '001-0147',
    title: 'Hamburg-Othmarschen',
    description: '',
    informations: '',
    street: 'Waitzstraße 12',
    zip: '22607',
    citypart: 'Othmarschen',
    addition: '',
    fon: '040-892652',
    fax: '040-891600',
    commonName: '',
    xCoordinates: '53.559686',
    yCoordinates: '9.884649',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Hamburg',
    country: 'Deutschland',
    state: 'Hamburg',
    firmierung: 'Fielmann AG & Co. Othmarschen OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 83,
    pid: 91,
    number: '001-0149',
    title: 'Gronau',
    description: '',
    informations: '',
    street: 'Neustraße 25',
    zip: '48599',
    citypart: '',
    addition: '',
    fon: '02562-24904',
    fax: '02562-1666',
    commonName: '',
    xCoordinates: '52.2111027',
    yCoordinates: '7.0257908',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Gronau',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 85,
    pid: 91,
    number: '001-0150',
    title: 'Husum',
    description: '',
    informations: '',
    street: 'Markt 2',
    zip: '25813',
    citypart: '',
    addition: '',
    fon: '04841-4050',
    fax: '04841-81426',
    commonName: '',
    xCoordinates: '54.4768218',
    yCoordinates: '9.050949',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Husum',
    country: 'Deutschland',
    state: 'Schleswig-Holstein',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 87,
    pid: 91,
    number: '001-0152',
    title: 'Meschede',
    description: '',
    informations: '',
    street: 'Kaiser-Otto-Platz 5',
    zip: '59872',
    citypart: '',
    addition: '',
    fon: '0291-2139',
    fax: '0291-82129',
    commonName: '',
    xCoordinates: '51.3461443',
    yCoordinates: '8.2833854',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Meschede',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 89,
    pid: 91,
    number: '001-0154',
    title: 'Kiel I, Holstenstraße',
    description: '',
    informations: '',
    street: 'Holstenstraße 19',
    zip: '24103',
    citypart: 'Zentrum',
    addition: '',
    fon: '0431-91343',
    fax: '0431-95849',
    commonName: '',
    xCoordinates: '54.3222699',
    yCoordinates: '10.137927',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Kiel',
    country: 'Deutschland',
    state: 'Schleswig-Holstein',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 91,
    pid: 91,
    number: '001-0148',
    title: 'Oer-Erkenschwick',
    description: '',
    informations: '',
    street: 'Ludwigstraße 15',
    zip: '45739',
    citypart: '',
    addition: '',
    fon: '02368-52482',
    fax: '02368-57569',
    commonName: '',
    xCoordinates: '51.639639',
    yCoordinates: '7.264476',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Oer-Erkenschwick',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 93,
    pid: 91,
    number: '001-0151',
    title: 'Düsseldorf I, Hauptstr.',
    description: '',
    informations: '',
    street: 'Hauptstraße 7',
    zip: '40597',
    citypart: 'Benrath',
    addition: '',
    fon: '0211-712447',
    fax: '0211-7184673',
    commonName: '',
    xCoordinates: '51.16271',
    yCoordinates: '6.873925',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Düsseldorf',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 95,
    pid: 91,
    number: '001-0153',
    title: 'Hamburg-Wandsbek',
    description: '',
    informations: '',
    street: 'Wandsbeker Marktstraße 57',
    zip: '22041',
    citypart: 'Wandsbek',
    addition: '',
    fon: '040-6522233',
    fax: '040-682412',
    commonName: '',
    xCoordinates: '53.571584',
    yCoordinates: '10.064918',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Hamburg',
    country: 'Deutschland',
    state: 'Hamburg',
    firmierung: 'Fielmann AG & Co. Wandsbek OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 97,
    pid: 91,
    number: '001-0155',
    title: 'Jever',
    description: '',
    informations: '',
    street: 'Kaakstraße 1',
    zip: '26441',
    citypart: '',
    addition: '',
    fon: '04461-71666',
    fax: '04461-72960',
    commonName: '',
    xCoordinates: '53.57405',
    yCoordinates: '7.904522',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Jever',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann Augenoptik AG& Co.OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 99,
    pid: 91,
    number: '001-0157',
    title: 'Wolfenbüttel',
    description: '',
    informations: '',
    street: 'Lange Herzogstraße 2',
    zip: '38300',
    citypart: '',
    addition: '',
    fon: '05331-27326',
    fax: '05331-27791',
    commonName: '',
    xCoordinates: '52.163293',
    yCoordinates: '10.535403',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Wolfenbüttel',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 101,
    pid: 91,
    number: '001-0159',
    title: 'Vechta',
    description: '',
    informations: '',
    street: 'Große Straße 62',
    zip: '49377',
    citypart: '',
    addition: '',
    fon: '04441-81771',
    fax: '04441-84134',
    commonName: '',
    xCoordinates: '52.729115',
    yCoordinates: '8.287032',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Vechta',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 103,
    pid: 91,
    number: '001-0160',
    title: 'Minden',
    description: '',
    informations: '',
    street: 'Bäckerstraße 24',
    zip: '32423',
    citypart: '',
    addition: '',
    fon: '0571-26634',
    fax: '0571-28277',
    commonName: '',
    xCoordinates: '52.289879',
    yCoordinates: '8.918441',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Minden',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 105,
    pid: 91,
    number: '001-0166',
    title: 'Rheinbach',
    description: '',
    informations: '',
    street: 'Vor dem Dreeser Tor 15',
    zip: '53359',
    citypart: '',
    addition: '',
    fon: '02226-13666',
    fax: '02226-12407',
    commonName: '',
    xCoordinates: '50.627012',
    yCoordinates: '6.945864',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Rheinbach',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 107,
    pid: 91,
    number: '001-0168',
    title: 'Flensburg',
    description: '',
    informations: '',
    street: 'Holm 49/51',
    zip: '24937',
    citypart: '',
    addition: '',
    fon: '0461-13661',
    fax: '0461-27992',
    commonName: '',
    xCoordinates: '54.783646',
    yCoordinates: '9.436145',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Flensburg',
    country: 'Deutschland',
    state: 'Schleswig-Holstein',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 111,
    pid: 91,
    number: '001-0158',
    title: 'Osnabrück',
    description: '',
    informations: '',
    street: 'Große Straße 3',
    zip: '49074',
    citypart: '',
    addition: '',
    fon: '0541-21280',
    fax: '0541-27365',
    commonName: '',
    xCoordinates: '52.273135',
    yCoordinates: '8.04862',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Osnabrück',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 113,
    pid: 91,
    number: '001-0161',
    title: 'Günzburg',
    description: '',
    informations: '',
    street: 'Marktplatz 19',
    zip: '89312',
    citypart: '',
    addition: '',
    fon: '08221-32824',
    fax: '08221-34616',
    commonName: '',
    xCoordinates: '48.455353',
    yCoordinates: '10.276457',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Günzburg',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 115,
    pid: 91,
    number: '001-0163',
    title: 'Bochum I, Kortumstr.',
    description: '',
    informations: '',
    street: 'Kortumstraße 93',
    zip: '44787',
    citypart: 'Innenstadt',
    addition: '',
    fon: '0234-681323',
    fax: '0234-66435',
    commonName: '',
    xCoordinates: '51.482691',
    yCoordinates: '7.21697',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Bochum',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 117,
    pid: 91,
    number: '001-0165',
    title: 'Schleswig',
    description: '',
    informations: '',
    street: 'Stadtweg 28',
    zip: '24837',
    citypart: '',
    addition: '',
    fon: '04621-29850',
    fax: '04621-29380',
    commonName: '',
    xCoordinates: '54.516772',
    yCoordinates: '9.564111',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Schleswig',
    country: 'Deutschland',
    state: 'Schleswig-Holstein',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 119,
    pid: 91,
    number: '001-0167',
    title: 'Hamburg-City',
    description: '',
    informations: '',
    street: 'Jungfernstieg 40',
    zip: '20354',
    citypart: 'Innenstadt',
    addition: '',
    fon: '040-300506-0',
    fax: '040-300506-32',
    commonName: '',
    xCoordinates: '53.5543787',
    yCoordinates: '9.9902137',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Hamburg',
    country: 'Deutschland',
    state: 'Hamburg',
    firmierung: 'Fielmann AG & Co Rathaus OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 121,
    pid: 91,
    number: '001-0169',
    title: 'Itzehoe',
    description: '',
    informations: '',
    street: 'Feldschmiede 34',
    zip: '25524',
    citypart: '',
    addition: '',
    fon: '04821-5055',
    fax: '04821-3279',
    commonName: '',
    xCoordinates: '53.9249443',
    yCoordinates: '9.5170686',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Itzehoe',
    country: 'Deutschland',
    state: 'Schleswig-Holstein',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 123,
    pid: 91,
    number: '001-0170',
    title: 'Hamburg Barmbek -Nord',
    description: '',
    informations: '',
    street: 'Fuhlsbüttler Straße 122',
    zip: '22305',
    citypart: 'Barmbek-Nord',
    addition: '',
    fon: '040-6917495',
    fax: '040-618391',
    commonName: '',
    xCoordinates: '53.589899',
    yCoordinates: '10.045626',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Hamburg',
    country: 'Deutschland',
    state: 'Hamburg',
    firmierung: 'Fielmann AG & Co. oHG Barmbek',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 125,
    pid: 91,
    number: '001-0172',
    title: 'Hamburg-Schnelsen',
    description: '',
    informations: '',
    street: 'Frohmestraße 46',
    zip: '22457',
    citypart: 'Schnelsen',
    addition: '',
    fon: '040-5591911',
    fax: '040-5500040',
    commonName: '',
    xCoordinates: '53.632193',
    yCoordinates: '9.92099',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Hamburg',
    country: 'Deutschland',
    state: 'Hamburg',
    firmierung: 'Fielmann AG & Co. oHG Schnelsen',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 127,
    pid: 91,
    number: '001-0173',
    title: 'Brake',
    description: '',
    informations: '',
    street: 'Am Ahrenshof 2',
    zip: '26919',
    citypart: '',
    addition: '',
    fon: '04401-72407',
    fax: '04401-7397',
    commonName: '',
    xCoordinates: '53.31293',
    yCoordinates: '8.482559',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Brake',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann Augenoptik AG & Co. KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 129,
    pid: 91,
    number: '001-0177',
    title: 'Oldenburg/Holstein',
    description: '',
    informations: '',
    street: 'Kuhtorstr. 14',
    zip: '23758',
    citypart: '',
    addition: '',
    fon: '04361-1036',
    fax: '04361-3909',
    commonName: '',
    xCoordinates: '54.29146',
    yCoordinates: '10.88851',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Oldenburg in Holstein',
    country: 'Deutschland',
    state: 'Schleswig-Holstein',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 131,
    pid: 91,
    number: '001-0179',
    title: 'Goslar',
    description: '',
    informations: '',
    street: 'Fischemäkerstraße 13',
    zip: '38640',
    citypart: '',
    addition: '',
    fon: '05321-20097',
    fax: '05321-41153',
    commonName: '',
    xCoordinates: '51.90698',
    yCoordinates: '10.42844',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Goslar',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 133,
    pid: 91,
    number: '001-0180',
    title: 'Aurich',
    description: '',
    informations: '',
    street: 'Marktplatz 28',
    zip: '26603',
    citypart: '',
    addition: '',
    fon: '04941-4455',
    fax: '04941-64332',
    commonName: '',
    xCoordinates: '53.4699109',
    yCoordinates: '7.4819409',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Aurich',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann Augenoptik AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 135,
    pid: 91,
    number: '001-0182',
    title: 'Hamburg-Niendorf',
    description: '',
    informations: '',
    street: 'Tibarg 19',
    zip: '22459',
    citypart: 'Niendorf',
    addition: '',
    fon: '040-581195',
    fax: '040-587338',
    commonName: '',
    xCoordinates: '53.6207655',
    yCoordinates: '9.9507793',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '4120',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '4120',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Hamburg',
    country: 'Deutschland',
    state: 'Hamburg',
    firmierung: 'Fielmann AG & Co. oHG Niendorf',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 137,
    pid: 91,
    number: '001-0184',
    title: 'Emden',
    description: '',
    informations: '',
    street: 'Zwischen beiden Märkten 2-4',
    zip: '26721',
    citypart: '',
    addition: '',
    fon: '04921-21430',
    fax: '04921-21599',
    commonName: '',
    xCoordinates: '53.36756',
    yCoordinates: '7.2049',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Emden',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 139,
    pid: 91,
    number: '001-0186',
    title: 'Bremen II, Gerhard-Rohlfs-Str.',
    description: '',
    informations: '',
    street: 'Gerhard-Rohlfs-Straße 73',
    zip: '28757',
    citypart: 'Vegesack',
    addition: '',
    fon: '0421-661085',
    fax: '0421-666841',
    commonName: '',
    xCoordinates: '53.172152',
    yCoordinates: '8.619756',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Bremen',
    country: 'Deutschland',
    state: 'Bremen',
    firmierung: 'Fielmann AG & Co. Vegesack OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 141,
    pid: 91,
    number: '001-0191',
    title: 'Kiel II, Schönberger Str.',
    description: '',
    informations: '',
    street: 'Schönberger Straße 84',
    zip: '24148',
    citypart: 'Wellingdorf',
    addition: '',
    fon: '0431-728404',
    fax: '0431-726418',
    commonName: '',
    xCoordinates: '54.325894',
    yCoordinates: '10.175356',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Kiel',
    country: 'Deutschland',
    state: 'Schleswig-Holstein',
    firmierung: 'Fielmann AG & Co. oHG Wellingdorf',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 143,
    pid: 91,
    number: '001-0197',
    title: 'Bremerhaven II, B.-Smidt-Str.',
    description: '',
    informations: '',
    street: 'Bürgermeister-Smidt-Str. 108',
    zip: '27568',
    citypart: 'Zentrum',
    addition: '',
    fon: '0471-4829610',
    fax: '0471-4829619',
    commonName: '',
    xCoordinates: '53.547186',
    yCoordinates: '8.575823',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Bremerhaven',
    country: 'Deutschland',
    state: 'Bremen',
    firmierung: 'Fielmann Augenoptik AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 145,
    pid: 91,
    number: '001-0174',
    title: 'Bremen I, Obernstraße',
    description: '',
    informations: '',
    street: 'Obernstraße 32',
    zip: '28195',
    citypart: 'Zentrum',
    addition: '',
    fon: '0421-320585',
    fax: '0421-328592',
    commonName: '',
    xCoordinates: '53.07701',
    yCoordinates: '8.80517',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Bremen',
    country: 'Deutschland',
    state: 'Bremen',
    firmierung: 'Fielmann AG & Co. Obernstraße OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 147,
    pid: 91,
    number: '001-0176',
    title: 'Delmenhorst II, Lange Straße',
    description: '',
    informations: '',
    street: 'Lange Straße 35',
    zip: '27749',
    citypart: '',
    addition: '',
    fon: '04221-17128',
    fax: '04221-18272',
    commonName: '',
    xCoordinates: '53.04849',
    yCoordinates: '8.63136',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Delmenhorst',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 149,
    pid: 91,
    number: '001-0178',
    title: 'Wolfsburg',
    description: '',
    informations: '',
    street: 'Porschestraße 39',
    zip: '38440',
    citypart: '',
    addition: '',
    fon: '05361-21951',
    fax: '05361-23950',
    commonName: '',
    xCoordinates: '52.4232104',
    yCoordinates: '10.7884488',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Wolfsburg',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 151,
    pid: 91,
    number: '001-0181',
    title: 'Hamburg-Bergedorf I, Sachsent.',
    description: '',
    informations: '',
    street: 'Sachsentor 21',
    zip: '21029',
    citypart: 'Bergedorf',
    addition: '',
    fon: '040-7211145',
    fax: '040-7219550',
    commonName: '',
    xCoordinates: '53.48827',
    yCoordinates: '10.2128',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Hamburg',
    country: 'Deutschland',
    state: 'Hamburg',
    firmierung: 'Fielmann AG & Co. Bergedorf OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 153,
    pid: 91,
    number: '001-0183',
    title: 'Papenburg',
    description: '',
    informations: '',
    street: 'Hauptkanal Links 32',
    zip: '26871',
    citypart: '',
    addition: '',
    fon: '04961-3695',
    fax: '04961-3948',
    commonName: '',
    xCoordinates: '53.0833297',
    yCoordinates: '7.3908213',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Papenburg',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann Augenoptik AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 155,
    pid: 91,
    number: '001-0185',
    title: 'Achim',
    description: '',
    informations: '',
    street: 'Bremer Straße 1 b',
    zip: '28832',
    citypart: '',
    addition: '',
    fon: '04202-8087',
    fax: '04202-3159',
    commonName: '',
    xCoordinates: '53.0126608',
    yCoordinates: '9.029795',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Achim',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 157,
    pid: 91,
    number: '001-0187',
    title: 'Peine',
    description: '',
    informations: '',
    street: 'Gröpern 11',
    zip: '31224',
    citypart: '',
    addition: '',
    fon: '05171-3038',
    fax: '05171-18756',
    commonName: '',
    xCoordinates: '52.320391',
    yCoordinates: '10.230264',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Peine',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 159,
    pid: 91,
    number: '001-0189',
    title: 'Neumünster',
    description: '',
    informations: '',
    street: 'Großflecken 12',
    zip: '24534',
    citypart: '',
    addition: '',
    fon: '04321-43393',
    fax: '04321-42655',
    commonName: '',
    xCoordinates: '54.072499',
    yCoordinates: '9.985698',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Neumünster',
    country: 'Deutschland',
    state: 'Schleswig-Holstein',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 161,
    pid: 91,
    number: '001-0190',
    title: 'Lübeck',
    description: '',
    informations: '',
    street: 'Breite Straße 33',
    zip: '23552',
    citypart: '',
    addition: '',
    fon: '0451-71464',
    fax: '0451-704073',
    commonName: '',
    xCoordinates: '53.86916',
    yCoordinates: '10.68741',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Lübeck',
    country: 'Deutschland',
    state: 'Schleswig-Holstein',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 163,
    pid: 91,
    number: '001-0201',
    title: 'Hamburg-Harburg I',
    description: '',
    informations: '',
    street: 'Sand 35',
    zip: '21073',
    citypart: 'Harburg',
    addition: '',
    fon: '040-777689',
    fax: '040-7650406',
    commonName: '',
    xCoordinates: '53.4613913',
    yCoordinates: '9.9791002',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Hamburg',
    country: 'Deutschland',
    state: 'Hamburg',
    firmierung: 'Fielmann AG & Co. Harburg Sand OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 165,
    pid: 91,
    number: '001-0203',
    title: 'Uelzen',
    description: '',
    informations: '',
    street: 'Veerßer Straße 16',
    zip: '29525',
    citypart: '',
    addition: '',
    fon: '0581-74938',
    fax: '0581-17048',
    commonName: '',
    xCoordinates: '52.964088',
    yCoordinates: '10.559414',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Uelzen',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 167,
    pid: 91,
    number: '001-0210',
    title: 'Seevetal',
    description: '',
    informations: '',
    street: 'Glüsinger Straße 20',
    zip: '21217',
    citypart: 'Meckelfeld',
    addition: '',
    fon: '040-7683630',
    fax: '040-7686227',
    commonName: '',
    xCoordinates: '53.4203265',
    yCoordinates: '10.0211698',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Seevetal',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 169,
    pid: 91,
    number: '001-0212',
    title: 'Buxtehude',
    description: '',
    informations: '',
    street: 'Lange Straße 16',
    zip: '21614',
    citypart: '',
    addition: '',
    fon: '04161-52999',
    fax: '04161-52384',
    commonName: '',
    xCoordinates: '53.476239',
    yCoordinates: '9.699731',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Buxtehude',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 171,
    pid: 91,
    number: '001-0214',
    title: 'Hamburg-Eimsbüttel',
    description: '',
    informations: '',
    street: 'Osterstraße 120',
    zip: '20255',
    citypart: 'Eimsbüttel',
    addition: '',
    fon: '040-4903930',
    fax: '040-400848',
    commonName: '',
    xCoordinates: '53.57629',
    yCoordinates: '9.951103',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Hamburg',
    country: 'Deutschland',
    state: 'Hamburg',
    firmierung: 'Fielmann AG & Co. Eimsbüttel OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 173,
    pid: 91,
    number: '001-0216',
    title: 'Mölln',
    description: '',
    informations: '',
    street: 'Hauptstraße 85',
    zip: '23879',
    citypart: '',
    addition: '',
    fon: '04542-89920',
    fax: '04542-86552',
    commonName: '',
    xCoordinates: '53.62936',
    yCoordinates: '10.690331',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Mölln',
    country: 'Deutschland',
    state: 'Schleswig-Holstein',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 175,
    pid: 91,
    number: '001-0218',
    title: 'Winsen',
    description: '',
    informations: '',
    street: 'Rathausstraße 5',
    zip: '21423',
    citypart: '',
    addition: '',
    fon: '04171-62299',
    fax: '04171-62790',
    commonName: '',
    xCoordinates: '53.363142',
    yCoordinates: '10.207295',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Winsen',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 177,
    pid: 91,
    number: '001-0225',
    title: 'Wuppertal III, W.-Brandt-Platz',
    description: '',
    informations: '',
    street: 'Willy-Brandt-Platz 1',
    zip: '42105',
    citypart: 'Elberfeld',
    addition: '',
    fon: '0202-442995',
    fax: '0202-451236',
    commonName: '',
    xCoordinates: '51.258795',
    yCoordinates: '7.145674',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Wuppertal',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. Elberfeld OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 179,
    pid: 91,
    number: '001-0227',
    title: 'Leer',
    description: '',
    informations: '',
    street: 'Mühlenstraße 75',
    zip: '26789',
    citypart: '',
    addition: '',
    fon: '0491-66363',
    fax: '0491-66364',
    commonName: '',
    xCoordinates: '53.230215',
    yCoordinates: '7.457068',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Leer',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 181,
    pid: 91,
    number: '001-0192',
    title: 'Laatzen',
    description: '',
    informations: '',
    street: 'Marktplatz 9-11',
    zip: '30880',
    citypart: '',
    addition: '',
    fon: '0511-827474',
    fax: '0511-86659831',
    commonName: 'Leine-Center',
    xCoordinates: '52.3073129',
    yCoordinates: '9.8132417',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Laatzen',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann Augenoptik AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 183,
    pid: 91,
    number: '001-0196',
    title: 'Bremerhaven I, Grashoffstr.',
    description: '',
    informations: '',
    street: 'Grashoffstraße 28',
    zip: '27570',
    citypart: 'Geestemünde',
    addition: '',
    fon: '0471-9262220',
    fax: '0471-9262223',
    commonName: '',
    xCoordinates: '53.5326507',
    yCoordinates: '8.5931522',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Bremerhaven',
    country: 'Deutschland',
    state: 'Bremen',
    firmierung: 'Fielmann Augenoptik AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 185,
    pid: 91,
    number: '001-0198',
    title: 'Bremerhaven III, Hafenstraße',
    description: '',
    informations: '',
    street: 'Hafenstraße 141',
    zip: '27576',
    citypart: 'Lehe',
    addition: '',
    fon: '0471-9545640',
    fax: '0471-9545643',
    commonName: '',
    xCoordinates: '53.55976',
    yCoordinates: '8.58565',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Bremerhaven',
    country: 'Deutschland',
    state: 'Bremen',
    firmierung: 'Fielmann Augenoptik AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 187,
    pid: 91,
    number: '001-0204',
    title: 'Gifhorn',
    description: '',
    informations: '',
    street: 'Steinweg 67',
    zip: '38518',
    citypart: '',
    addition: '',
    fon: '05371-12390',
    fax: '05371-50071',
    commonName: '',
    xCoordinates: '52.483573',
    yCoordinates: '10.54575',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Gifhorn',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 189,
    pid: 91,
    number: '001-0206',
    title: 'Hamburg-Ottensen',
    description: '',
    informations: '',
    street: 'Ottenser Hauptstraße 10',
    zip: '22765',
    citypart: 'Ottensen',
    addition: '',
    fon: '040-393353',
    fax: '040-3903483',
    commonName: '',
    xCoordinates: '53.5523015',
    yCoordinates: '9.9325765',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Hamburg',
    country: 'Deutschland',
    state: 'Hamburg',
    firmierung: 'Fielmann AG & Co. Ottensen OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 191,
    pid: 91,
    number: '001-0211',
    title: 'Glinde',
    description: '',
    informations: '',
    street: 'Markt 6',
    zip: '21509',
    citypart: '',
    addition: '',
    fon: '040-7111211',
    fax: '040-7111925',
    commonName: '',
    xCoordinates: '53.541507',
    yCoordinates: '10.213594',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Glinde',
    country: 'Deutschland',
    state: 'Schleswig-Holstein',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 193,
    pid: 91,
    number: '001-0217',
    title: 'Stade',
    description: '',
    informations: '',
    street: 'Holzstraße 10',
    zip: '21682',
    citypart: '',
    addition: '',
    fon: '04141-2188',
    fax: '04141-44415',
    commonName: '',
    xCoordinates: '53.600302',
    yCoordinates: '9.477098',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Stade',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 195,
    pid: 91,
    number: '001-0226',
    title: 'Mülheim II, Hans-Böckler-Platz',
    description: '',
    informations: '',
    street: 'Hans-Böckler-Platz 8',
    zip: '45468',
    citypart: '',
    addition: '',
    fon: '0208-390775',
    fax: '0208-381095',
    commonName: 'Forum',
    xCoordinates: '51.429019',
    yCoordinates: '6.885128',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Mülheim an der Ruhr',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 197,
    pid: 91,
    number: '001-0228',
    title: 'Beckum',
    description: '',
    informations: '',
    street: 'Nordstraße 20',
    zip: '59269',
    citypart: '',
    addition: '',
    fon: '02521-7357',
    fax: '02521-5387',
    commonName: '',
    xCoordinates: '51.756024',
    yCoordinates: '8.040725',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Beckum',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 199,
    pid: 91,
    number: '001-0229',
    title: 'Herford',
    description: '',
    informations: '',
    street: 'Bäckerstraße 13/15',
    zip: '32052',
    citypart: '',
    addition: '',
    fon: '05221-55933',
    fax: '05221-55391',
    commonName: '',
    xCoordinates: '52.114119',
    yCoordinates: '8.669524',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Herford',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 201,
    pid: 91,
    number: '001-0232',
    title: 'Nienburg',
    description: '',
    informations: '',
    street: 'Georgstraße 8',
    zip: '31582',
    citypart: '',
    addition: '',
    fon: '05021-62200',
    fax: '05021-2074',
    commonName: '',
    xCoordinates: '52.6393144',
    yCoordinates: '9.2066942',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Nienburg',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 203,
    pid: 91,
    number: '001-0236',
    title: 'Memmingen',
    description: '',
    informations: '',
    street: 'Kramerstraße 24',
    zip: '87700',
    citypart: '',
    addition: '',
    fon: '08331-48135',
    fax: '08331-2572',
    commonName: '',
    xCoordinates: '47.9844143',
    yCoordinates: '10.182018',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Memmingen',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 205,
    pid: 91,
    number: '001-0238',
    title: 'Verden (Aller)',
    description: '',
    informations: '',
    street: 'Große Straße 54',
    zip: '27283',
    citypart: '',
    addition: '',
    fon: '04231-84386',
    fax: '04231-84376',
    commonName: '',
    xCoordinates: '52.921347',
    yCoordinates: '9.22887',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Verden (Aller)',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 207,
    pid: 91,
    number: '001-0241',
    title: 'Düsseldorf IV, Friedrichstr.',
    description: '',
    informations: '',
    street: 'Friedrichstraße 53',
    zip: '40217',
    citypart: 'Friedrichstadt',
    addition: '',
    fon: '0211-374169',
    fax: '0211-370732',
    commonName: '',
    xCoordinates: '51.2161463',
    yCoordinates: '6.7766955',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Düsseldorf',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. Friedrichstraße OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 209,
    pid: 91,
    number: '001-0247',
    title: 'Düsseldorf VI, Rethelstr.',
    description: '',
    informations: '',
    street: 'Rethelstraße 147',
    zip: '40237',
    citypart: 'Düsseltal',
    addition: '',
    fon: '0211-661443',
    fax: '0211-664963',
    commonName: '',
    xCoordinates: '51.2382168',
    yCoordinates: '6.8001179',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Düsseldorf',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. Rethelstraße OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 211,
    pid: 91,
    number: '001-0250',
    title: 'Ansbach',
    description: '',
    informations: '',
    street: 'Martin-Luther-Platz 8',
    zip: '91522',
    citypart: 'Neustadt',
    addition: '',
    fon: '0981-3793',
    fax: '0981-14168',
    commonName: '',
    xCoordinates: '49.302973',
    yCoordinates: '10.572008',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Ansbach',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 213,
    pid: 91,
    number: '001-0252',
    title: 'Augsburg II, Bgm.-Fischer-Str.',
    description: '',
    informations: '',
    street: 'Bürgerm.-Fischer-Straße 12',
    zip: '86150',
    citypart: '',
    addition: '',
    fon: '0821-155600',
    fax: '0821-515221',
    commonName: '',
    xCoordinates: '48.366619',
    yCoordinates: '10.895539',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Augsburg',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. im Centrum OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 215,
    pid: 91,
    number: '001-0254',
    title: 'Bayreuth',
    description: '',
    informations: '',
    street: 'Maximilianstraße 19',
    zip: '95444',
    citypart: '',
    addition: '',
    fon: '0921-69977',
    fax: '0921-56510',
    commonName: '',
    xCoordinates: '49.943861',
    yCoordinates: '11.575898',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Bayreuth',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 217,
    pid: 91,
    number: '001-0233',
    title: 'Rotenburg (Wümme)',
    description: '',
    informations: '',
    street: 'Große Str. 4',
    zip: '27356',
    citypart: '',
    addition: '',
    fon: '04261-3193',
    fax: '04261-2857',
    commonName: '',
    xCoordinates: '53.1092575',
    yCoordinates: '9.4002086',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Rotenburg (Wümme)',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 219,
    pid: 91,
    number: '001-0235',
    title: 'Hamburg-Rahlstedt',
    description: '',
    informations: '',
    street: 'Schweriner Straße 7',
    zip: '22143',
    citypart: 'Rahlstedt',
    addition: '',
    fon: '040-6777028',
    fax: '040-6777029',
    commonName: '',
    xCoordinates: '53.6053432',
    yCoordinates: '10.1573459',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Hamburg',
    country: 'Deutschland',
    state: 'Hamburg',
    firmierung: 'Fielmann AG & Co. Rahlstedt OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 221,
    pid: 91,
    number: '001-0239',
    title: 'Lünen',
    description: '',
    informations: '',
    street: 'Lange Straße 34',
    zip: '44532',
    citypart: 'Lünen-Nord',
    addition: '',
    fon: '02306-53047',
    fax: '02306-56572',
    commonName: '',
    xCoordinates: '51.613606',
    yCoordinates: '7.523764',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Lünen',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 223,
    pid: 91,
    number: '001-0244',
    title: 'Düsseldorf VII, Nordstraße',
    description: '',
    informations: '',
    street: 'Nordstr. 45',
    zip: '40477',
    citypart: 'Derendorf',
    addition: '',
    fon: '0211-499410',
    fax: '0211-4982455',
    commonName: '',
    xCoordinates: '51.2375547',
    yCoordinates: '6.7797075',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Düsseldorf',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. Derendorf OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 225,
    pid: 91,
    number: '001-0259',
    title: 'Düsseldorf III, Schadowstraße',
    description: '',
    informations: '',
    street: 'Schadowstraße 63',
    zip: '40212',
    citypart: 'Stadtmitte',
    addition: '',
    fon: '0211-133077',
    fax: '0211-329117',
    commonName: '',
    xCoordinates: '51.226632',
    yCoordinates: '6.784742',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Düsseldorf',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. im Centrum KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 227,
    pid: 91,
    number: '001-0262',
    title: 'Erlangen II, Nürnberger Str.',
    description: '',
    informations: '',
    street: 'Nürnberger Straße 13',
    zip: '91052',
    citypart: '',
    addition: '',
    fon: '09131-25451',
    fax: '09131-27182',
    commonName: '',
    xCoordinates: '49.593244',
    yCoordinates: '11.005183',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Erlangen',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. im Centrum OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 229,
    pid: 91,
    number: '001-0264',
    title: 'Fürth',
    description: '',
    informations: '',
    street: 'Schwabacher Str. 36',
    zip: '90762',
    citypart: '',
    addition: '',
    fon: '0911-777015',
    fax: '0911-748225',
    commonName: '',
    xCoordinates: '49.4735618',
    yCoordinates: '10.9880189',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Fürth',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 231,
    pid: 91,
    number: '001-0266',
    title: 'Hanau',
    description: '',
    informations: '',
    street: 'Nürnberger Straße 23',
    zip: '63450',
    citypart: '',
    addition: '',
    fon: '06181-24617',
    fax: '06181-252602',
    commonName: '',
    xCoordinates: '50.13225',
    yCoordinates: '8.920009',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Hanau',
    country: 'Deutschland',
    state: 'Hessen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 233,
    pid: 91,
    number: '001-0271',
    title: 'Moers',
    description: '',
    informations: '',
    street: 'Homberger Straße 27',
    zip: '47441',
    citypart: '',
    addition: '',
    fon: '02841-23584',
    fax: '02841-18482',
    commonName: '',
    xCoordinates: '51.450787',
    yCoordinates: '6.631875',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Moers',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 235,
    pid: 91,
    number: '001-0256',
    title: 'Coburg',
    description: '',
    informations: '',
    street: 'Mohrenstraße 34',
    zip: '96450',
    citypart: '',
    addition: '',
    fon: '09561-92880',
    fax: '09561-90442',
    commonName: '',
    xCoordinates: '50.2603259',
    yCoordinates: '10.9640299',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Coburg',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 237,
    pid: 91,
    number: '001-0261',
    title: 'Duisburg III, Jägerstr.',
    description: '',
    informations: '',
    street: 'Jägerstraße 72',
    zip: '47166',
    citypart: 'Hamborn',
    addition: '',
    fon: '0203-400181',
    fax: '0203-409322',
    commonName: '',
    xCoordinates: '51.4902535',
    yCoordinates: '6.7716064',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Duisburg',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. Hamborn OHG',
    importantMessageHeader: 'Wichtige Information!',
    importantMessageText:
      'Aktuell wird unsere Niederlassung in der Jägerstraße 72 umgebaut. Der Verkauf findet seit dem 15.04.20 in der Niederlassung Königstraße 50 statt. Unsere Augenoptiker sind unter der Telefonnummer 0203-25673 für Sie erreichbar.<br /><br />Um Ihre Gesundheit und die unserer Mitarbeiter zu schützen, erfolgt der Betrieb gemäß unseren <a href="/coronavirus/">Hygienestandards</a>. Daher ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bringen Sie bitte wenn möglich Ihren eigenen Mund-Nasen-Schutz mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: true,
    emergencyData: {
      header: 'Wichtige Information!',
      text:
        'Aktuell wird unsere Niederlassung in der Jägerstraße 72 umgebaut. Der Verkauf findet seit dem 15.04.20 in der Niederlassung Königstraße 50 statt. Unsere Augenoptiker sind unter der Telefonnummer 0203-25673 für Sie erreichbar.<br /><br />Um Ihre Gesundheit und die unserer Mitarbeiter zu schützen, erfolgt der Betrieb gemäß unseren <a href="/coronavirus/">Hygienestandards</a>. Daher ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bringen Sie bitte wenn möglich Ihren eigenen Mund-Nasen-Schutz mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 239,
    pid: 91,
    number: '001-0263',
    title: 'Erlangen I, Weiße Herzstr.',
    description: '',
    informations: '',
    street: 'Weiße Herzstraße 1',
    zip: '91054',
    citypart: '',
    addition: '',
    fon: '09131-26495',
    fax: '09131-28263',
    commonName: '',
    xCoordinates: '49.5962848',
    yCoordinates: '11.0060559',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Erlangen',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 241,
    pid: 91,
    number: '001-0265',
    title: 'Gelsenkirchen II, Bahnhofstr.',
    description: '',
    informations: '',
    street: 'Bahnhofstraße 74-76',
    zip: '45879',
    citypart: 'Altstadt',
    addition: '',
    fon: '0209-21191',
    fax: '0209-142989',
    commonName: '',
    xCoordinates: '51.5066471',
    yCoordinates: '7.100437',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Gelsenkirchen',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. im Centrum KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 243,
    pid: 91,
    number: '001-0267',
    title: 'Hof',
    description: '',
    informations: '',
    street: 'Ludwigstraße 81',
    zip: '95028',
    citypart: '',
    addition: '',
    fon: '09281-86610',
    fax: '09281-85465',
    commonName: '',
    xCoordinates: '50.319641',
    yCoordinates: '11.917473',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Hof',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 245,
    pid: 91,
    number: '001-0269',
    title: 'Kulmbach',
    description: '',
    informations: '',
    street: 'Langgasse 20 - 22',
    zip: '95326',
    citypart: '',
    addition: '',
    fon: '09221-4388',
    fax: '09221-84570',
    commonName: '',
    xCoordinates: '50.10756',
    yCoordinates: '11.4557',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Kulmbach',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 247,
    pid: 91,
    number: '001-0270',
    title: 'Leverkusen',
    description: '',
    informations: '',
    street: 'Wiesdorfer Platz 15',
    zip: '51373',
    citypart: '',
    addition: '',
    fon: '0214-45786',
    fax: '0214-46656',
    commonName: '',
    xCoordinates: '51.032096',
    yCoordinates: '6.986246',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Leverkusen',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 249,
    pid: 91,
    number: '001-0274',
    title: 'Neuss',
    description: '',
    informations: '',
    street: 'Krefelder Straße 57',
    zip: '41460',
    citypart: '',
    addition: '',
    fon: '02131-272380',
    fax: '02131-275738',
    commonName: '',
    xCoordinates: '51.2010802',
    yCoordinates: '6.6869182',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Neuss',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 251,
    pid: 91,
    number: '001-0276',
    title: 'Nürnberg III, Breite Gasse',
    description: '',
    informations: '',
    street: 'Breite Gasse 64-66',
    zip: '90402',
    citypart: '',
    addition: '',
    fon: '0911-227160',
    fax: '0911-224583',
    commonName: '',
    xCoordinates: '49.4502216',
    yCoordinates: '11.0734681',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Nürnberg',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. Nürnberg Lorenz OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 253,
    pid: 91,
    number: '001-0273',
    title: 'München I, Sonnenstr.',
    description: '',
    informations: '',
    street: 'Sonnenstraße 1',
    zip: '80331',
    citypart: 'Zentrum',
    addition: '',
    fon: '089-593108',
    fax: '089-591737',
    commonName: 'Stachus',
    xCoordinates: '48.1385468',
    yCoordinates: '11.5657728',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'München',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 255,
    pid: 91,
    number: '001-0275',
    title: 'Nürnberg IV, Breitscheidstraße',
    description: '',
    informations: '',
    street: 'Breitscheidstraße 5',
    zip: '90459',
    citypart: 'Südstadt',
    addition: '',
    fon: '0911-436057',
    fax: '0911-4467027',
    commonName: '',
    xCoordinates: '49.441264',
    yCoordinates: '11.081039',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Nürnberg',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. Nürnberg-Süd KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 257,
    pid: 91,
    number: '001-0277',
    title: 'Nürnberg I, Hauptmarkt',
    description: '',
    informations: '',
    street: 'Hauptmarkt 10',
    zip: '90403',
    citypart: 'Altstadt',
    addition: '',
    fon: '0911-223902',
    fax: '0911-226154',
    commonName: '',
    xCoordinates: '49.453498',
    yCoordinates: '11.07745',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Nürnberg',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. am Hauptmarkt OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 259,
    pid: 91,
    number: '001-0282',
    title: 'Oberhausen I, Marktstr.',
    description: '',
    informations: '',
    street: 'Marktstraße 94',
    zip: '46045',
    citypart: '',
    addition: '',
    fon: '0208-26727',
    fax: '0208-23856',
    commonName: '',
    xCoordinates: '51.4689786',
    yCoordinates: '6.8552825',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Oberhausen',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. Oberhausen OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 261,
    pid: 91,
    number: '001-0288',
    title: 'Würzburg',
    description: '',
    informations: '',
    street: 'Kaiserstr. 26',
    zip: '97070',
    citypart: '',
    addition: '',
    fon: '0931-55766',
    fax: '0931-18852',
    commonName: '',
    xCoordinates: '49.7994837',
    yCoordinates: '9.9347222',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Würzburg',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 263,
    pid: 91,
    number: '001-0299',
    title: 'Herten',
    description: '',
    informations: '',
    street: 'Ewaldstraße 12',
    zip: '45699',
    citypart: '',
    addition: '',
    fon: '02366-87505',
    fax: '02366-87506',
    commonName: '',
    xCoordinates: '51.592876',
    yCoordinates: '7.139512',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Herten',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 265,
    pid: 91,
    number: '001-0301',
    title: 'Meppen',
    description: '',
    informations: '',
    street: 'Markt 27',
    zip: '49716',
    citypart: '',
    addition: '',
    fon: '05931-12831',
    fax: '05931-13122',
    commonName: '',
    xCoordinates: '52.69171',
    yCoordinates: '7.29188',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Meppen',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 267,
    pid: 91,
    number: '001-0303',
    title: 'Hamm',
    description: '',
    informations: '',
    street: 'Weststraße 48',
    zip: '59065',
    citypart: '',
    addition: '',
    fon: '02381-15081',
    fax: '02381-15344',
    commonName: '',
    xCoordinates: '51.6806255',
    yCoordinates: '7.8144726',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Hamm',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 269,
    pid: 91,
    number: '001-0305',
    title: 'Hannover II, Engelbosteler D.',
    description: '',
    informations: '',
    street: 'Engelbosteler Damm 66',
    zip: '30167',
    citypart: 'Nordstadt',
    addition: '',
    fon: '0511-7000333',
    fax: '0511-709773',
    commonName: '',
    xCoordinates: '52.387142',
    yCoordinates: '9.723805',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Hannover',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann AG & Co. Nordstadt OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 271,
    pid: 91,
    number: '001-0281',
    title: 'Nürnberg II, Glogauer Str.',
    description: '',
    informations: '',
    street: 'Glogauer Straße 30 - 38',
    zip: '90473',
    citypart: 'Langwasser',
    addition: '',
    fon: '0911-808212',
    fax: '0911-8938612',
    commonName: 'Franken-Center',
    xCoordinates: '49.40356',
    yCoordinates: '11.13478',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Nürnberg',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. Nürnberg-Langwasser OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 273,
    pid: 91,
    number: '001-0285',
    title: 'Schweinfurt',
    description: '',
    informations: '',
    street: 'Georg-Wichtermann-Platz 10',
    zip: '97421',
    citypart: '',
    addition: '',
    fon: '09721-27212',
    fax: '09721-185084',
    commonName: '',
    xCoordinates: '50.0444922',
    yCoordinates: '10.2316806',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Schweinfurt',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 275,
    pid: 91,
    number: '001-0300',
    title: 'Buchholz',
    description: '',
    informations: '',
    street: 'Breite Straße 15',
    zip: '21244',
    citypart: '',
    addition: '',
    fon: '04181-6044',
    fax: '04181-39121',
    commonName: '',
    xCoordinates: '53.3279614',
    yCoordinates: '9.8791045',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Buchholz',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 277,
    pid: 91,
    number: '001-0304',
    title: 'Bielefeld II, Oberntorwall',
    description: '',
    informations: '',
    street: 'Oberntorwall 25',
    zip: '33602',
    citypart: 'Zentrum',
    addition: '',
    fon: '0521-173089',
    fax: '0521-175961',
    commonName: '',
    xCoordinates: '52.022895',
    yCoordinates: '8.532491',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Bielefeld',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. Jahnplatz OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 279,
    pid: 91,
    number: '001-0306',
    title: 'Greven',
    description: '',
    informations: '',
    street: 'Königstr. 2',
    zip: '48268',
    citypart: '',
    addition: '',
    fon: '02571-4151',
    fax: '02571-52511',
    commonName: '',
    xCoordinates: '52.0921712',
    yCoordinates: '7.6129409',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Greven',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 281,
    pid: 91,
    number: '001-0308',
    title: 'Lingen (Ems)',
    description: '',
    informations: '',
    street: 'Am Markt 9-10',
    zip: '49808',
    citypart: '',
    addition: '',
    fon: '0591-3571',
    fax: '0591-48746',
    commonName: '',
    xCoordinates: '52.522125',
    yCoordinates: '7.3159656',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Lingen (Ems)',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 283,
    pid: 91,
    number: '001-0331',
    title: 'Ahlen',
    description: '',
    informations: '',
    street: 'Oststraße 51',
    zip: '59227',
    citypart: '',
    addition: '',
    fon: '02382-83482',
    fax: '02382-83484',
    commonName: '',
    xCoordinates: '51.7630208',
    yCoordinates: '7.8916355',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Ahlen',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 285,
    pid: 91,
    number: '001-0333',
    title: 'Bocholt',
    description: '',
    informations: '',
    street: 'Neutorplatz 2-4',
    zip: '46395',
    citypart: '',
    addition: '',
    fon: '02871-17536',
    fax: '02871-6988',
    commonName: '',
    xCoordinates: '51.8358115',
    yCoordinates: '6.6153849',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Bocholt',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 287,
    pid: 91,
    number: '001-0335',
    title: 'Dorsten',
    description: '',
    informations: '',
    street: 'Lippestraße 9',
    zip: '46282',
    citypart: '',
    addition: '',
    fon: '02362-27909',
    fax: '02362-43896',
    commonName: '',
    xCoordinates: '51.66158',
    yCoordinates: '6.9631486',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Dorsten',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 289,
    pid: 91,
    number: '001-0307',
    title: 'Dillingen',
    description: '',
    informations: '',
    street: 'Königstraße 16',
    zip: '89407',
    citypart: '',
    addition: '',
    fon: '09071-8141',
    fax: '09071-8672',
    commonName: '',
    xCoordinates: '48.577421',
    yCoordinates: '10.494263',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Dillingen',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 291,
    pid: 91,
    number: '001-0309',
    title: 'Düsseldorf X, Luegallee',
    description: '',
    informations: '',
    street: 'Luegallee 107',
    zip: '40545',
    citypart: 'Oberkassel',
    addition: '',
    fon: '0211-570242',
    fax: '0211-570243',
    commonName: '',
    xCoordinates: '51.2313023',
    yCoordinates: '6.7493593',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Düsseldorf',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. Oberkassel OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 293,
    pid: 91,
    number: '001-0330',
    title: 'Langenfeld',
    description: '',
    informations: '',
    street: 'Marktplatz 1',
    zip: '40764',
    citypart: '',
    addition: '',
    fon: '02173-77273',
    fax: '02173-82739',
    commonName: '',
    xCoordinates: '51.110113',
    yCoordinates: '6.94967',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Langenfeld',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 295,
    pid: 91,
    number: '001-0334',
    title: 'Dinslaken',
    description: '',
    informations: '',
    street: 'Neustraße 44',
    zip: '46535',
    citypart: '',
    addition: '',
    fon: '02064-70457',
    fax: '02064-55242',
    commonName: '',
    xCoordinates: '51.562594',
    yCoordinates: '6.73677',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Dinslaken',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 297,
    pid: 91,
    number: '001-0336',
    title: 'Düren',
    description: '',
    informations: '',
    street: 'Wirteltorplatz 6',
    zip: '52349',
    citypart: '',
    addition: '',
    fon: '02421-10240',
    fax: '02421-13722',
    commonName: '',
    xCoordinates: '50.80489',
    yCoordinates: '6.48299',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Düren',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 299,
    pid: 91,
    number: '001-0341',
    title: 'Hagen',
    description: '',
    informations: '',
    street: 'Elberfelder Straße 48',
    zip: '58095',
    citypart: '',
    addition: '',
    fon: '02331-26393',
    fax: '02331-15329',
    commonName: '',
    xCoordinates: '51.35915',
    yCoordinates: '7.46799',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Hagen',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 301,
    pid: 91,
    number: '001-0343',
    title: 'Köln I, Barbarossaplatz',
    description: '',
    informations: '',
    street: 'Barbarossaplatz 4',
    zip: '50674',
    citypart: 'Neustadt-Süd',
    addition: '',
    fon: '0221-211117',
    fax: '0221-216781',
    commonName: '',
    xCoordinates: '50.92903',
    yCoordinates: '6.942657',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Köln',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. Barbarossaplatz OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 303,
    pid: 91,
    number: '001-0345',
    title: 'Köln III, Ebertplatz',
    description: '',
    informations: '',
    street: 'Neusser Str. 3',
    zip: '50670',
    citypart: 'Neustadt-Nord',
    addition: '',
    fon: '0221-134101',
    fax: '0221-132401',
    commonName: '',
    xCoordinates: '50.9512701',
    yCoordinates: '6.9566723',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Köln',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. Ebertplatz KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 305,
    pid: 91,
    number: '001-0349',
    title: 'Wesel',
    description: '',
    informations: '',
    street: 'Viehtor 20',
    zip: '46483',
    citypart: '',
    addition: '',
    fon: '0281-21299',
    fax: '0281-23011',
    commonName: '',
    xCoordinates: '51.65755',
    yCoordinates: '6.61517',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Wesel',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 307,
    pid: 91,
    number: '001-0340',
    title: 'Geldern',
    description: '',
    informations: '',
    street: 'Issumer Straße 23 - 25',
    zip: '47608',
    citypart: '',
    addition: '',
    fon: '02831-6215',
    fax: '02831-88835',
    commonName: '',
    xCoordinates: '51.518157',
    yCoordinates: '6.3232971',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Geldern',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 309,
    pid: 91,
    number: '001-0342',
    title: 'Herne I , Hauptstraße',
    description: '',
    informations: '',
    street: 'Hauptstraße 235',
    zip: '44649',
    citypart: 'Wanne-Eickel',
    addition: '',
    fon: '02325-71513',
    fax: '02325-72811',
    commonName: '',
    xCoordinates: '51.530145',
    yCoordinates: '7.159238',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Herne',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 311,
    pid: 91,
    number: '001-0344',
    title: 'Köln II, Venloer Straße',
    description: '',
    informations: '',
    street: 'Venloer Straße 369',
    zip: '50823',
    citypart: 'Ehrenfeld',
    addition: '',
    fon: '0221-515928',
    fax: '0221-529994',
    commonName: '',
    xCoordinates: '50.949734',
    yCoordinates: '6.91709',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Köln',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. Venloer Straße OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 313,
    pid: 91,
    number: '001-0346',
    title: 'Ratingen',
    description: '',
    informations: '',
    street: 'Oberstraße 15',
    zip: '40878',
    citypart: '',
    addition: '',
    fon: '02102-28226',
    fax: '02102-22281',
    commonName: '',
    xCoordinates: '51.297891',
    yCoordinates: '6.851365',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Ratingen',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 315,
    pid: 91,
    number: '001-0348',
    title: 'Bochum II, Oststr.',
    description: '',
    informations: '',
    street: 'Oststraße 36',
    zip: '44866',
    citypart: 'Wattenscheid',
    addition: '',
    fon: '02327-15900',
    fax: '02327-86047',
    commonName: '',
    xCoordinates: '51.4805333',
    yCoordinates: '7.1359344',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Bochum',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. Wattenscheid KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 317,
    pid: 91,
    number: '001-0353',
    title: 'Rheine I, Emsstraße 27',
    description: '',
    informations: '',
    street: 'Emsstraße 27',
    zip: '48431',
    citypart: '',
    addition: '',
    fon: '05971-3214',
    fax: '05971-3788',
    commonName: '',
    xCoordinates: '52.278397',
    yCoordinates: '7.440191',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Rheine',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 319,
    pid: 91,
    number: '001-0355',
    title: 'Bad Oldesloe',
    description: '',
    informations: '',
    street: 'Mühlenstraße 9',
    zip: '23843',
    citypart: '',
    addition: '',
    fon: '04531-7929',
    fax: '04531-12465',
    commonName: '',
    xCoordinates: '53.8092419',
    yCoordinates: '10.3761419',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Bad Oldesloe',
    country: 'Deutschland',
    state: 'Schleswig-Holstein',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 321,
    pid: 91,
    number: '001-0357',
    title: 'Remscheid II, Allee-Center',
    description: '',
    informations: '',
    street: 'Alleestraße 74',
    zip: '42853',
    citypart: '',
    addition: '',
    fon: '02191-23723',
    fax: '02191-23647',
    commonName: 'Allee-Center',
    xCoordinates: '51.1823068',
    yCoordinates: '7.1869164',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Remscheid',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 323,
    pid: 91,
    number: '001-0359',
    title: 'Norderstedt',
    description: '',
    informations: '',
    street: 'Europaallee 4',
    zip: '22850',
    citypart: '',
    addition: '',
    fon: '040-5232462',
    fax: '040-5232341',
    commonName: '',
    xCoordinates: '53.6822993',
    yCoordinates: '9.9877366',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Norderstedt',
    country: 'Deutschland',
    state: 'Schleswig-Holstein',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 325,
    pid: 91,
    number: '001-0350',
    title: 'Wuppertal IV, Werth',
    description: '',
    informations: '',
    street: 'Werth 8',
    zip: '42275',
    citypart: 'Barmen',
    addition: '',
    fon: '0202-592131',
    fax: '0202-594802',
    commonName: '',
    xCoordinates: '51.270749',
    yCoordinates: '7.198034',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Wuppertal',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. Barmen OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 327,
    pid: 91,
    number: '001-0352',
    title: 'Nordhorn',
    description: '',
    informations: '',
    street: 'Hauptstr. 46',
    zip: '48529',
    citypart: '',
    addition: '',
    fon: '05921-2228',
    fax: '05921-16687',
    commonName: '',
    xCoordinates: '52.436732',
    yCoordinates: '7.070417',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Nordhorn',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 329,
    pid: 91,
    number: '001-0354',
    title: 'Solingen II, Hauptstr.',
    description: '',
    informations: '',
    street: 'Hauptstraße 50',
    zip: '42651',
    citypart: '',
    addition: '',
    fon: '0212-201808',
    fax: '0212-201805',
    commonName: '',
    xCoordinates: '51.1716631',
    yCoordinates: '7.0857657',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Solingen',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. im Centrum OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 331,
    pid: 91,
    number: '001-0356',
    title: 'Lemgo',
    description: '',
    informations: '',
    street: 'Mittelstraße 76',
    zip: '32657',
    citypart: '',
    addition: '',
    fon: '05261-16655',
    fax: '05261-16750',
    commonName: '',
    xCoordinates: '52.028604',
    yCoordinates: '8.899607',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Lemgo',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 333,
    pid: 91,
    number: '001-0363',
    title: 'Menden',
    description: '',
    informations: '',
    street: 'Hochstraße 20',
    zip: '58706',
    citypart: '',
    addition: '',
    fon: '02373-18103',
    fax: '02373-18089',
    commonName: '',
    xCoordinates: '51.4365397',
    yCoordinates: '7.7965178',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Menden',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 335,
    pid: 91,
    number: '001-0365',
    title: 'Iserlohn',
    description: '',
    informations: '',
    street: 'Wermingser Straße 31',
    zip: '58636',
    citypart: '',
    addition: '',
    fon: '02371-836817',
    fax: '02371-836818',
    commonName: '',
    xCoordinates: '51.373976',
    yCoordinates: '7.70105',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Iserlohn',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 337,
    pid: 91,
    number: '001-0367',
    title: 'Mönchengladbach II, Marktstr.',
    description: '',
    informations: '',
    street: 'Marktstraße 27',
    zip: '41236',
    citypart: 'Rheydt',
    addition: '',
    fon: '02166-48294',
    fax: '02166-612654',
    commonName: '',
    xCoordinates: '51.167483',
    yCoordinates: '6.44417',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Mönchengladbach',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. Rheydt oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 339,
    pid: 91,
    number: '001-0370',
    title: 'Kaiserslautern',
    description: '',
    informations: '',
    street: 'Fackelstraße 29',
    zip: '67655',
    citypart: '',
    addition: '',
    fon: '0631-3606640',
    fax: '0631-3606641',
    commonName: '',
    xCoordinates: '49.4442793',
    yCoordinates: '7.766391',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Kaiserslautern',
    country: 'Deutschland',
    state: 'Rheinland-Pfalz',
    firmierung: 'Fielmann AG & CO. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 341,
    pid: 91,
    number: '001-0372',
    title: 'Landau',
    description: '',
    informations: '',
    street: 'Kronstr. 37',
    zip: '76829',
    citypart: '',
    addition: '',
    fon: '06341-20057',
    fax: '06341-88957',
    commonName: '',
    xCoordinates: '49.1969967',
    yCoordinates: '8.1140277',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Landau',
    country: 'Deutschland',
    state: 'Rheinland-Pfalz',
    firmierung: 'fielmann Fielmann GmbH',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 343,
    pid: 91,
    number: '001-0360',
    title: 'Witten II, Bahnhofstraße',
    description: '',
    informations: '',
    street: 'Bahnhofstraße 48',
    zip: '58452',
    citypart: '',
    addition: '',
    fon: '02302-27274',
    fax: '02302-27270',
    commonName: '',
    xCoordinates: '51.438025',
    yCoordinates: '7.331714',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Witten',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann Augenoptik im Centrum AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 345,
    pid: 91,
    number: '001-0362',
    title: 'Würselen',
    description: '',
    informations: '',
    street: 'Kaiserstraße 76',
    zip: '52146',
    citypart: '',
    addition: '',
    fon: '02405-3131',
    fax: '02405-18739',
    commonName: '',
    xCoordinates: '50.8219493',
    yCoordinates: '6.1303863',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Würselen',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 347,
    pid: 91,
    number: '001-0364',
    title: 'Unna',
    description: '',
    informations: '',
    street: 'Bahnhofstr. 24',
    zip: '59423',
    citypart: '',
    addition: '',
    fon: '02303-14634',
    fax: '02303-15251',
    commonName: '',
    xCoordinates: '51.5364158',
    yCoordinates: '7.6909749',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Unna',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 351,
    pid: 91,
    number: '001-0371',
    title: 'Frankenthal',
    description: '',
    informations: '',
    street: 'Speyerer Str. 1-3',
    zip: '67227',
    citypart: '',
    addition: '',
    fon: '06233-21014',
    fax: '06233-24529',
    commonName: '',
    xCoordinates: '49.5347813',
    yCoordinates: '8.354137',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Frankenthal',
    country: 'Deutschland',
    state: 'Rheinland-Pfalz',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 353,
    pid: 91,
    number: '001-0373',
    title: 'Bretten',
    description: '',
    informations: '',
    street: 'Weißhofer Straße 69',
    zip: '75015',
    citypart: '',
    addition: '',
    fon: '07252-87182',
    fax: '07252-87534',
    commonName: '',
    xCoordinates: '49.0351467',
    yCoordinates: '8.7140747',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Bretten',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & CO. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 355,
    pid: 91,
    number: '001-0375',
    title: 'Mannheim I, Planken',
    description: '',
    informations: '',
    street: 'Planken O 7/13',
    zip: '68161',
    citypart: '',
    addition: '',
    fon: '0621-153490',
    fax: '0621-153491',
    commonName: '',
    xCoordinates: '49.4846474',
    yCoordinates: '8.4733527',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Mannheim',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 357,
    pid: 91,
    number: '001-0379',
    title: 'Hamburg-Poppenbüttel',
    description: '',
    informations: '',
    street: 'Heegbarg 31',
    zip: '22391',
    citypart: 'Poppenbüttel',
    addition: '',
    fon: '040-6020065',
    fax: '040-6020115',
    commonName: 'Alstertal-Einkaufszentrum AEZ',
    xCoordinates: '53.65456',
    yCoordinates: '10.090439',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Hamburg',
    country: 'Deutschland',
    state: 'Hamburg',
    firmierung: 'Fielmann AG & Co. im Alstertal-Einkaufszentrum OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 359,
    pid: 91,
    number: '001-0380',
    title: 'Schwerin I, Marienplatz',
    description: '',
    informations: '',
    street: 'Marienplatz 5 - 6',
    zip: '19053',
    citypart: '',
    addition: '',
    fon: '0385-512719',
    fax: '0385-512432',
    commonName: 'Schloßpark-Center',
    xCoordinates: '53.6282574',
    yCoordinates: '11.4083171',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '4116',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '4116',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Schwerin',
    country: 'Deutschland',
    state: 'Mecklenburg-Vorpommern',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 361,
    pid: 91,
    number: '001-0378',
    title: 'München II, Leopoldstr.',
    description: '',
    informations: '',
    street: 'Leopoldstraße 46',
    zip: '80802',
    citypart: 'Schwabing',
    addition: '',
    fon: '089-391604',
    fax: '089-349644',
    commonName: '',
    xCoordinates: '48.157315',
    yCoordinates: '11.584813',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'München',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. Leopoldstraße OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 363,
    pid: 91,
    number: '001-0381',
    title: 'Wismar',
    description: '',
    informations: '',
    street: 'Hinter dem Rathaus 19',
    zip: '23966',
    citypart: '',
    addition: '',
    fon: '03841-283561',
    fax: '03841-213265',
    commonName: '',
    xCoordinates: '53.8921633',
    yCoordinates: '11.4659267',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Wismar',
    country: 'Deutschland',
    state: 'Mecklenburg-Vorpommern',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 365,
    pid: 91,
    number: '001-0383',
    title: 'Hannover IV, Lister Meile',
    description: '',
    informations: '',
    street: 'Lister Meile 72',
    zip: '30161',
    citypart: 'Oststadt',
    addition: '',
    fon: '0511-392121',
    fax: '0511-392832',
    commonName: '',
    xCoordinates: '52.387358',
    yCoordinates: '9.749202',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Hannover',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann AG & Co. Lister Meile OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 367,
    pid: 91,
    number: '001-0385',
    title: 'Frankfurt/Oder',
    description: '',
    informations: '',
    street: 'Karl-Marx-Straße 10',
    zip: '15230',
    citypart: '',
    addition: '',
    fon: '0335-321014',
    fax: '0335-321036',
    commonName: '',
    xCoordinates: '52.344876',
    yCoordinates: '14.551008',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Frankfurt-Oder',
    country: 'Deutschland',
    state: 'Brandenburg',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 369,
    pid: 91,
    number: '001-0387',
    title: 'Greiz',
    description: '',
    informations: '',
    street: 'Markt 11',
    zip: '07973',
    citypart: '',
    addition: '',
    fon: '03661-3160',
    fax: '03661-670987',
    commonName: '',
    xCoordinates: '50.657103',
    yCoordinates: '12.200484',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Greiz',
    country: 'Deutschland',
    state: 'Thüringen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 371,
    pid: 91,
    number: '001-0389',
    title: 'Westerland',
    description: '',
    informations: '',
    street: 'Friedrichstraße 6',
    zip: '25980',
    citypart: '',
    addition: '',
    fon: '04651-29637',
    fax: '04651-29537',
    commonName: '',
    xCoordinates: '54.9077976',
    yCoordinates: '8.305776',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Sylt - OT Westerland',
    country: 'Deutschland',
    state: 'Schleswig-Holstein',
    firmierung: 'Fielmann AG & Co. KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 373,
    pid: 91,
    number: '001-0390',
    title: 'Senftenberg',
    description: '',
    informations: '',
    street: 'Kreuzstraße 23',
    zip: '01968',
    citypart: '',
    addition: '',
    fon: '03573-2448',
    fax: '03573-791013',
    commonName: '',
    xCoordinates: '51.519692',
    yCoordinates: '14.002652',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Senftenberg',
    country: 'Deutschland',
    state: 'Brandenburg',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 375,
    pid: 91,
    number: '001-0392',
    title: 'Bautzen',
    description: '',
    informations: '',
    street: 'Reichenstraße 7',
    zip: '02625',
    citypart: '',
    addition: '',
    fon: '03591-44421',
    fax: '03591-42437',
    commonName: '',
    xCoordinates: '51.18129',
    yCoordinates: '14.42516',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Bautzen',
    country: 'Deutschland',
    state: 'Sachsen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 377,
    pid: 91,
    number: '001-0394',
    title: 'Pinneberg',
    description: '',
    informations: '',
    street: 'Fahltskamp 9',
    zip: '25421',
    citypart: '',
    addition: '',
    fon: '04101-22853',
    fax: '04101-208023',
    commonName: '',
    xCoordinates: '53.660619',
    yCoordinates: '9.80098',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Pinneberg',
    country: 'Deutschland',
    state: 'Schleswig-Holstein',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 379,
    pid: 91,
    number: '001-0382',
    title: 'Hannover III, Blumenauerstraße',
    description: '',
    informations: '',
    street: 'Deisterstr. 13',
    zip: '30449',
    citypart: 'Linden',
    addition: '',
    fon: '0511-451445',
    fax: '0511-455285',
    commonName: 'Schwarzer Bär',
    xCoordinates: '52.3670967',
    yCoordinates: '9.7200177',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Hannover',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann AG & Co. Schwarzer Bär OHG',
    importantMessageHeader: 'Vorübergehend geschlossen',
    importantMessageText:
      'Liebe Kundinnen und Kunden, um Sie und uns vor dem&nbsp;Coronavirus zu schützen, können wir zur Zeit nicht wie gewohnt für Sie da sein. Diese Niederlassung bleibt vorübergehend geschlossen.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!<br /><br />Sie haben in der Zwischenzeit Fragen? Unser Kundenservice beantwortet diese gerne unter der kostenlosen Rufnummer: 0800 – 3435626.',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Vorübergehend geschlossen',
      text:
        'Liebe Kundinnen und Kunden, um Sie und uns vor dem&nbsp;Coronavirus zu schützen, können wir zur Zeit nicht wie gewohnt für Sie da sein. Diese Niederlassung bleibt vorübergehend geschlossen.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!<br /><br />Sie haben in der Zwischenzeit Fragen? Unser Kundenservice beantwortet diese gerne unter der kostenlosen Rufnummer: 0800 – 3435626.',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 381,
    pid: 91,
    number: '001-0384',
    title: 'Greifswald',
    description: '',
    informations: '',
    street: 'Lange Straße 94',
    zip: '17489',
    citypart: '',
    addition: '',
    fon: '03834-897497',
    fax: '03834-899295',
    commonName: '',
    xCoordinates: '54.096186',
    yCoordinates: '13.38074',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Greifswald',
    country: 'Deutschland',
    state: 'Mecklenburg-Vorpommern',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 383,
    pid: 91,
    number: '001-0386',
    title: 'Rudolstadt',
    description: '',
    informations: '',
    street: 'Markt 15',
    zip: '07407',
    citypart: '',
    addition: '',
    fon: '03672-424201',
    fax: '03672-424202',
    commonName: '',
    xCoordinates: '50.720144',
    yCoordinates: '11.34034',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Rudolstadt',
    country: 'Deutschland',
    state: 'Thüringen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 385,
    pid: 91,
    number: '001-0391',
    title: 'Rostock I, Kröpeliner Straße',
    description: '',
    informations: '',
    street: 'Kröpeliner Straße 58',
    zip: '18055',
    citypart: 'Zentrum',
    addition: '',
    fon: '0381-452650',
    fax: '0381-452651',
    commonName: '',
    xCoordinates: '54.088528',
    yCoordinates: '12.131846',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Rostock',
    country: 'Deutschland',
    state: 'Mecklenburg-Vorpommern',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 387,
    pid: 91,
    number: '001-0393',
    title: 'Stuttgart I, Königstraße',
    description: '',
    informations: '',
    street: 'Königstraße 68',
    zip: '70173',
    citypart: 'Zentrum',
    addition: '',
    fon: '0711-7223530',
    fax: '0711-72235329',
    commonName: '',
    xCoordinates: '48.7744259',
    yCoordinates: '9.174269',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Stuttgart',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & Co. KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 389,
    pid: 91,
    number: '001-0395',
    title: 'Zwickau',
    description: '',
    informations: '',
    street: 'Hauptstraße 35/37',
    zip: '08056',
    citypart: '',
    addition: '',
    fon: '0375-292855',
    fax: '0375-292857',
    commonName: '',
    xCoordinates: '50.71982',
    yCoordinates: '12.4962',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Zwickau',
    country: 'Deutschland',
    state: 'Sachsen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 391,
    pid: 91,
    number: '001-0399',
    title: 'Gera',
    description: '',
    informations: '',
    street: 'Humboldtstr. 2a/ Ecke Sorge',
    zip: '07545',
    citypart: '',
    addition: '',
    fon: '0365-52013',
    fax: '0365-53101',
    commonName: '',
    xCoordinates: '50.8811285',
    yCoordinates: '12.0812992',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '2798',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Gera',
    country: 'Deutschland',
    state: 'Thüringen',
    firmierung: 'Fielmann AG & Co. KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 393,
    pid: 91,
    number: '001-0400',
    title: 'Magdeburg I, Breiter Weg',
    description: '',
    informations: '',
    street: 'Breiter Weg 173',
    zip: '39104',
    citypart: 'Mitte',
    addition: '',
    fon: '0391-5619817',
    fax: '0391-5619661',
    commonName: '',
    xCoordinates: '52.1304975',
    yCoordinates: '11.6352565',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Magdeburg',
    country: 'Deutschland',
    state: 'Sachsen-Anhalt',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 395,
    pid: 91,
    number: '001-0402',
    title: 'Suhl',
    description: '',
    informations: '',
    street: 'Steinweg 23',
    zip: '98527',
    citypart: '',
    addition: '',
    fon: '03681-727175',
    fax: '03681-728530',
    commonName: '',
    xCoordinates: '50.60907',
    yCoordinates: '10.69171',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Suhl',
    country: 'Deutschland',
    state: 'Thüringen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 397,
    pid: 91,
    number: '001-0396',
    title: 'Stendal',
    description: '',
    informations: '',
    street: 'Breite Straße 6',
    zip: '39576',
    citypart: '',
    addition: '',
    fon: '03931-217070',
    fax: '03931-216105',
    commonName: '',
    xCoordinates: '52.60447',
    yCoordinates: '11.863187',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Stendal',
    country: 'Deutschland',
    state: 'Sachsen-Anhalt',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 399,
    pid: 91,
    number: '001-0398',
    title: 'Gotha',
    description: '',
    informations: '',
    street: 'Marktstraße 9',
    zip: '99867',
    citypart: '',
    addition: '',
    fon: '03621-29332',
    fax: '03621-403329',
    commonName: '',
    xCoordinates: '50.949791',
    yCoordinates: '10.702572',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Gotha',
    country: 'Deutschland',
    state: 'Thüringen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 401,
    pid: 91,
    number: '001-0401',
    title: 'Freiberg',
    description: '',
    informations: '',
    street: 'Burgstraße 5',
    zip: '09599',
    citypart: 'Zentrum',
    addition: '',
    fon: '03731-22480',
    fax: '03731-22674',
    commonName: '',
    xCoordinates: '50.917916',
    yCoordinates: '13.342817',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Freiberg',
    country: 'Deutschland',
    state: 'Sachsen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 405,
    pid: 91,
    number: '001-0405',
    title: 'Dessau I, Poststraße',
    description: '',
    informations: '',
    street: 'Poststraße 6',
    zip: '06844',
    citypart: 'Dessau',
    addition: '',
    fon: '0340-214763',
    fax: '0340-2203015',
    commonName: '',
    xCoordinates: '51.8371354',
    yCoordinates: '12.2450951',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Dessau-Roßlau',
    country: 'Deutschland',
    state: 'Sachsen-Anhalt',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 407,
    pid: 91,
    number: '001-0407',
    title: 'Bamberg',
    description: '',
    informations: '',
    street: 'Grüner Markt 5',
    zip: '96047',
    citypart: '',
    addition: '',
    fon: '0951-203327',
    fax: '0951-202748',
    commonName: '',
    xCoordinates: '49.892649',
    yCoordinates: '10.888162',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Bamberg',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 409,
    pid: 91,
    number: '001-0409',
    title: 'Erfurt',
    description: '',
    informations: '',
    street: 'Anger 54',
    zip: '99084',
    citypart: 'Zentrum',
    addition: '',
    fon: '0361-5668850',
    fax: '0361-5668883',
    commonName: '',
    xCoordinates: '50.9752899',
    yCoordinates: '11.0326413',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Erfurt',
    country: 'Deutschland',
    state: 'Thüringen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 411,
    pid: 91,
    number: '001-0410',
    title: 'Jena',
    description: '',
    informations: '',
    street: 'Markt 18',
    zip: '07743',
    citypart: '',
    addition: '',
    fon: '03641-443700',
    fax: '03641-443701',
    commonName: '',
    xCoordinates: '50.92765',
    yCoordinates: '11.58836',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Jena',
    country: 'Deutschland',
    state: 'Thüringen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 413,
    pid: 91,
    number: '001-0412',
    title: 'Wittenberge',
    description: '',
    informations: '',
    street: 'Bahnstraße 28',
    zip: '19322',
    citypart: '',
    addition: '',
    fon: '03877-79826',
    fax: '03877-60394',
    commonName: '',
    xCoordinates: '52.997419',
    yCoordinates: '11.758778',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Wittenberge',
    country: 'Deutschland',
    state: 'Brandenburg',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 415,
    pid: 91,
    number: '001-0404',
    title: 'Weimar',
    description: '',
    informations: '',
    street: 'Schillerstraße 17',
    zip: '99423',
    citypart: '',
    addition: '',
    fon: '03643-902953',
    fax: '03643-53928',
    commonName: '',
    xCoordinates: '50.979337',
    yCoordinates: '11.32668',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Weimar',
    country: 'Deutschland',
    state: 'Thüringen',
    firmierung: 'Fielmann AG & Co. KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 417,
    pid: 91,
    number: '001-0406',
    title: 'Siegen I, Kölner Straße',
    description: '',
    informations: '',
    street: 'Kölner Straße 52',
    zip: '57072',
    citypart: 'Zentrum-Oberstadt',
    addition: '',
    fon: '0271-55366',
    fax: '0271-53676',
    commonName: '',
    xCoordinates: '50.8747',
    yCoordinates: '8.021539',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Siegen',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 419,
    pid: 91,
    number: '001-0408',
    title: 'Dortmund',
    description: '',
    informations: '',
    street: 'Westenhellweg 67',
    zip: '44137',
    citypart: 'Zentrum',
    addition: '',
    fon: '0231-534524-0',
    fax: '0231-534524-29',
    commonName: '',
    xCoordinates: '51.514462',
    yCoordinates: '7.461074',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Dortmund',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 421,
    pid: 91,
    number: '001-0411',
    title: 'Meißen',
    description: '',
    informations: '',
    street: 'Kleinmarkt 2',
    zip: '01662',
    citypart: '',
    addition: '',
    fon: '03521-452400',
    fax: '03521-452463',
    commonName: '',
    xCoordinates: '51.162777',
    yCoordinates: '13.471659',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Meißen',
    country: 'Deutschland',
    state: 'Sachsen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 423,
    pid: 91,
    number: '001-0417',
    title: 'Schwerin II, Mecklenburgstr.',
    description: '',
    informations: '',
    street: 'Mecklenburgstraße 22',
    zip: '19053',
    citypart: '',
    addition: '',
    fon: '0385-5574336',
    fax: '0385-5574337',
    commonName: '',
    xCoordinates: '53.628395',
    yCoordinates: '11.412771',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Schwerin',
    country: 'Deutschland',
    state: 'Mecklenburg-Vorpommern',
    firmierung: 'Fielmann AG & Co. im Centrum OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 425,
    pid: 91,
    number: '001-0419',
    title: 'Eisenach',
    description: '',
    informations: '',
    street: 'Karlstraße 11',
    zip: '99817',
    citypart: '',
    addition: '',
    fon: '03691-785346',
    fax: '03691-785345',
    commonName: '',
    xCoordinates: '50.97527',
    yCoordinates: '10.3211',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Eisenach',
    country: 'Deutschland',
    state: 'Thüringen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 427,
    pid: 91,
    number: '001-0420',
    title: 'Chemnitz I, Markt',
    description: '',
    informations: '',
    street: 'Markt 5',
    zip: '09111',
    citypart: 'Zentrum',
    addition: '',
    fon: '0371-6761482',
    fax: '0371-671305',
    commonName: '',
    xCoordinates: '50.831809',
    yCoordinates: '12.919343',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Chemnitz',
    country: 'Deutschland',
    state: 'Sachsen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 429,
    pid: 91,
    number: '001-0422',
    title: 'Stralsund',
    description: '',
    informations: '',
    street: 'Ossenreyerstraße 31',
    zip: '18439',
    citypart: '',
    addition: '',
    fon: '03831-280076',
    fax: '03831-280077',
    commonName: '',
    xCoordinates: '54.3125526',
    yCoordinates: '13.0897831',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Stralsund',
    country: 'Deutschland',
    state: 'Mecklenburg-Vorpommern',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 431,
    pid: 91,
    number: '001-0424',
    title: 'Cottbus',
    description: '',
    informations: '',
    street: 'Spremberger Straße 10',
    zip: '03046',
    citypart: '',
    addition: '',
    fon: '0355-700419',
    fax: '0355-702101',
    commonName: '',
    xCoordinates: '51.7589184',
    yCoordinates: '14.3337039',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Cottbus',
    country: 'Deutschland',
    state: 'Brandenburg',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 433,
    pid: 91,
    number: '001-0414',
    title: 'Tuttlingen',
    description: '',
    informations: '',
    street: 'Bahnhofstraße 17',
    zip: '78532',
    citypart: '',
    addition: '',
    fon: '07461-14750',
    fax: '07461-14962',
    commonName: '',
    xCoordinates: '47.983902',
    yCoordinates: '8.816624',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Tuttlingen',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann Augenoptik AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 435,
    pid: 91,
    number: '001-0416',
    title: 'Aschersleben',
    description: '',
    informations: '',
    street: 'Taubenstraße 3',
    zip: '06449',
    citypart: '',
    addition: '',
    fon: '03473-2066',
    fax: '03473-806560',
    commonName: '',
    xCoordinates: '51.755163',
    yCoordinates: '11.456255',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Aschersleben',
    country: 'Deutschland',
    state: 'Sachsen-Anhalt',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 437,
    pid: 91,
    number: '001-0418',
    title: 'Lutherstadt Wittenberg',
    description: '',
    informations: '',
    street: 'Collegienstraße 90/91',
    zip: '06886',
    citypart: '',
    addition: '',
    fon: '03491-414832',
    fax: '03491-414833',
    commonName: '',
    xCoordinates: '51.865883',
    yCoordinates: '12.6449877',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Lutherstadt Wittenberg',
    country: 'Deutschland',
    state: 'Sachsen-Anhalt',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 439,
    pid: 91,
    number: '001-0421',
    title: 'Potsdam',
    description: '',
    informations: '',
    street: 'Brandenburger Str. 47a',
    zip: '14467',
    citypart: '',
    addition: '',
    fon: '0331-2804902',
    fax: '0331-2804903',
    commonName: '',
    xCoordinates: '52.3999139',
    yCoordinates: '13.0537578',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Potsdam',
    country: 'Deutschland',
    state: 'Brandenburg',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 441,
    pid: 91,
    number: '001-0423',
    title: 'Rathenow',
    description: '',
    informations: '',
    street: 'Berliner Straße 76',
    zip: '14712',
    citypart: '',
    addition: '',
    fon: '03385-511595',
    fax: '03385-503325',
    commonName: '',
    xCoordinates: '52.606464',
    yCoordinates: '12.33911',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Rathenow',
    country: 'Deutschland',
    state: 'Brandenburg',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 443,
    pid: 91,
    number: '001-0427',
    title: 'Halle I, Leipziger Straße',
    description: '',
    informations: '',
    street: 'Leipziger Straße 102',
    zip: '06108',
    citypart: 'Zentrum',
    addition: '',
    fon: '0345-2025683',
    fax: '0345-2021847',
    commonName: '',
    xCoordinates: '51.4819329',
    yCoordinates: '11.9716708',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Halle',
    country: 'Deutschland',
    state: 'Sachsen-Anhalt',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 445,
    pid: 91,
    number: '001-0429',
    title: 'Neubrandenburg I, Turmstraße',
    description: '',
    informations: '',
    street: 'Turmstraße 17 - 19',
    zip: '17033',
    citypart: '',
    addition: '',
    fon: '0395-5822016',
    fax: '0395-5822430',
    commonName: '',
    xCoordinates: '53.55741',
    yCoordinates: '13.26434',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Neubrandenburg',
    country: 'Deutschland',
    state: 'Mecklenburg-Vorpommern',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 447,
    pid: 91,
    number: '001-0430',
    title: 'Leipzig II, Markt',
    description: '',
    informations: '',
    street: 'Markt 17',
    zip: '04109',
    citypart: 'Zentrum',
    addition: '',
    fon: '0341-9605281',
    fax: '0341-9605852',
    commonName: '',
    xCoordinates: '51.339278',
    yCoordinates: '12.375506',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Leipzig',
    country: 'Deutschland',
    state: 'Sachsen',
    firmierung: 'Fielmann AG & Co. am Markt OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 449,
    pid: 91,
    number: '001-0432',
    title: 'Kassel I, Obere Königsstraße',
    description: '',
    informations: '',
    street: 'Obere Königsstr. 37 a',
    zip: '34117',
    citypart: '',
    addition: '',
    fon: '0561-711118',
    fax: '0561-711127',
    commonName: '',
    xCoordinates: '51.314158',
    yCoordinates: '9.4948721',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Kassel',
    country: 'Deutschland',
    state: 'Hessen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 451,
    pid: 91,
    number: '001-0426',
    title: 'Göttingen',
    description: '',
    informations: '',
    street: 'Weender Straße 51',
    zip: '37073',
    citypart: '',
    addition: '',
    fon: '0551-487191',
    fax: '0551-487192',
    commonName: '',
    xCoordinates: '51.535338',
    yCoordinates: '9.934802',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Göttingen',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 453,
    pid: 91,
    number: '001-0428',
    title: 'Nordenham',
    description: '',
    informations: '',
    street: 'Friedrich-Ebert-Straße 7',
    zip: '26954',
    citypart: '',
    addition: '',
    fon: '04731-22727',
    fax: '04731-22728',
    commonName: '',
    xCoordinates: '53.48525',
    yCoordinates: '8.48406',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Nordenham',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann Augenoptik AG & Co. KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 455,
    pid: 91,
    number: '001-0431',
    title: 'Brandenburg an der Havel',
    description: '',
    informations: '',
    street: 'Hauptstraße 23',
    zip: '14776',
    citypart: '',
    addition: '',
    fon: '03381-524090',
    fax: '03381-522266',
    commonName: '',
    xCoordinates: '52.4100017',
    yCoordinates: '12.5606885',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Brandenburg an der Havel',
    country: 'Deutschland',
    state: 'Brandenburg',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 457,
    pid: 91,
    number: '001-0433',
    title: 'Lüdenscheid',
    description: '',
    informations: '',
    street: 'Wilhelmstraße 33',
    zip: '58511',
    citypart: '',
    addition: '',
    fon: '02351-28428',
    fax: '02351-27947',
    commonName: 'Stern-Center',
    xCoordinates: '51.216367',
    yCoordinates: '7.631579',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Lüdenscheid',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 459,
    pid: 91,
    number: '001-0435',
    title: 'Dresden I, Webergasse',
    description: '',
    informations: '',
    street: 'Webergasse 1',
    zip: '01067',
    citypart: 'Zentrum',
    addition: '',
    fon: '0351-4968042',
    fax: '0351-4968095',
    commonName: 'Altmarkt-Galerie',
    xCoordinates: '51.0495142',
    yCoordinates: '13.7356442',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Dresden',
    country: 'Deutschland',
    state: 'Sachsen',
    firmierung: 'Fielmann AG & Co. Dresden Altstadt OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 461,
    pid: 91,
    number: '001-0437',
    title: 'Sangerhausen',
    description: '',
    informations: '',
    street: 'Göpenstraße 18',
    zip: '06526',
    citypart: '',
    addition: '',
    fon: '03464-570294',
    fax: '03464-570995',
    commonName: '',
    xCoordinates: '51.474838',
    yCoordinates: '11.297934',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Sangerhausen',
    country: 'Deutschland',
    state: 'Sachsen-Anhalt',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 463,
    pid: 91,
    number: '001-0439',
    title: 'Mühlhausen',
    description: '',
    informations: '',
    street: 'Steinweg 8-10',
    zip: '99974',
    citypart: '',
    addition: '',
    fon: '03601-444102',
    fax: '03601-443408',
    commonName: '',
    xCoordinates: '51.209906',
    yCoordinates: '10.4575226',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Mühlhausen',
    country: 'Deutschland',
    state: 'Thüringen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 465,
    pid: 91,
    number: '001-0440',
    title: 'Hamburg-Billstedt',
    description: '',
    informations: '',
    street: 'Billstedter Platz 39 k',
    zip: '22111',
    citypart: 'Billstedt',
    addition: '',
    fon: '040-7331717',
    fax: '040-7331211',
    commonName: 'Billstedt-Center',
    xCoordinates: '53.54186',
    yCoordinates: '10.1052601',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Hamburg',
    country: 'Deutschland',
    state: 'Hamburg',
    firmierung: 'Fielmann AG & Co. Billstedt KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 467,
    pid: 91,
    number: '001-0442',
    title: 'Koblenz I, Hohenfelderstr.',
    description: '',
    informations: '',
    street: 'Hohenfelder Straße 22',
    zip: '56068',
    citypart: '',
    addition: '',
    fon: '0261-14003',
    fax: '0261-14008',
    commonName: 'Löhr-Center',
    xCoordinates: '50.359419',
    yCoordinates: '7.591393',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Koblenz',
    country: 'Deutschland',
    state: 'Rheinland-Pfalz',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 469,
    pid: 91,
    number: '001-0436',
    title: 'Gütersloh',
    description: '',
    informations: '',
    street: 'Berliner Straße 16',
    zip: '33330',
    citypart: '',
    addition: '',
    fon: '05241-15746',
    fax: '05241-15747',
    commonName: '',
    xCoordinates: '51.905929',
    yCoordinates: '8.378365',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Gütersloh',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 471,
    pid: 91,
    number: '001-0438',
    title: 'Berlin I, Bölschestr.',
    description: '',
    informations: '',
    street: 'Bölschestr. 114',
    zip: '12587',
    citypart: 'Friedrichshagen',
    addition: '',
    fon: '030-6418868',
    fax: '030-6418869',
    commonName: '',
    xCoordinates: '52.4503958',
    yCoordinates: '13.6244054',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '4112',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '4112',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Berlin',
    country: 'Deutschland',
    state: 'Berlin',
    firmierung: 'Fielmann AG & Co. Friedrichshagen OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 473,
    pid: 91,
    number: '001-0441',
    title: 'Dresden II, Bautzner Str.',
    description: '',
    informations: '',
    street: 'Bautzner Straße 27',
    zip: '01099',
    citypart: 'Neustadt',
    addition: '',
    fon: '0351-8045493',
    fax: '0351-8014729',
    commonName: '',
    xCoordinates: '51.063108',
    yCoordinates: '13.7520876',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Dresden',
    country: 'Deutschland',
    state: 'Sachsen',
    firmierung: 'Fielmann AG & Co. Dresden Neustadt OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 475,
    pid: 91,
    number: '001-0445',
    title: 'Zeitz',
    description: '',
    informations: '',
    street: 'Roßmarkt 9',
    zip: '06712',
    citypart: '',
    addition: '',
    fon: '03441-214124',
    fax: '03441-250833',
    commonName: '',
    xCoordinates: '51.05018',
    yCoordinates: '12.135994',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Zeitz',
    country: 'Deutschland',
    state: 'Sachsen-Anhalt',
    firmierung: 'Fielmann AG & Co. KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 477,
    pid: 91,
    number: '001-0447',
    title: 'Finsterwalde',
    description: '',
    informations: '',
    street: 'Leipziger Straße 1',
    zip: '03238',
    citypart: '',
    addition: '',
    fon: '03531-701482',
    fax: '03531-701483',
    commonName: '',
    xCoordinates: '51.631526',
    yCoordinates: '13.708093',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Finsterwalde',
    country: 'Deutschland',
    state: 'Brandenburg',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 479,
    pid: 91,
    number: '001-0449',
    title: 'Döbeln',
    description: '',
    informations: '',
    street: 'Breite Straße 17',
    zip: '04720',
    citypart: '',
    addition: '',
    fon: '03431-711100',
    fax: '03431-711101',
    commonName: '',
    xCoordinates: '51.120939',
    yCoordinates: '13.119479',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Döbeln',
    country: 'Deutschland',
    state: 'Sachsen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 483,
    pid: 91,
    number: '001-0452',
    title: 'Berlin XI, Marzahner Promenade',
    description: '',
    informations: '',
    street: 'Marzahner Promenade 1 a',
    zip: '12679',
    citypart: 'Marzahn',
    addition: '',
    fon: '030-9339178',
    fax: '030-9339185',
    commonName: 'Eastgate',
    xCoordinates: '52.5439482',
    yCoordinates: '13.5446989',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Berlin',
    country: 'Deutschland',
    state: 'Berlin',
    firmierung: 'Fielmann AG & Co. Marzahn OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 485,
    pid: 91,
    number: '001-0454',
    title: 'Berlin IV, Schloßstraße',
    description: '',
    informations: '',
    street: 'Schloßstraße 28',
    zip: '12163',
    citypart: 'Steglitz',
    addition: '',
    fon: '030-79702950',
    fax: '030-79702951',
    commonName: '',
    xCoordinates: '52.459116',
    yCoordinates: '13.322497',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Berlin',
    country: 'Deutschland',
    state: 'Berlin',
    firmierung: 'Fielmann AG & Co. Steglitz OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 487,
    pid: 91,
    number: '001-0446',
    title: 'Berlin II, Schönhauser Allee',
    description: '',
    informations: '',
    street: 'Schönhauser Allee 70 c',
    zip: '10437',
    citypart: 'Prenzlauer Berg',
    addition: '',
    fon: '030-4469365',
    fax: '030-4469366',
    commonName: '',
    xCoordinates: '52.5469181',
    yCoordinates: '13.4137126',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Berlin',
    country: 'Deutschland',
    state: 'Berlin',
    firmierung: 'Fielmann AG & Co. Prenzlauer Berg OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 489,
    pid: 91,
    number: '001-0448',
    title: 'Altenburg',
    description: '',
    informations: '',
    street: 'Markt 27',
    zip: '04600',
    citypart: '',
    addition: '',
    fon: '03447-504263',
    fax: '03447-504932',
    commonName: '',
    xCoordinates: '50.985457',
    yCoordinates: '12.433096',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Altenburg',
    country: 'Deutschland',
    state: 'Thüringen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 491,
    pid: 91,
    number: '001-0451',
    title: 'Eisenhüttenstadt',
    description: '',
    informations: '',
    street: 'Lindenallee 56',
    zip: '15890',
    citypart: '',
    addition: '',
    fon: '03364-413626',
    fax: '03364-413627',
    commonName: '',
    xCoordinates: '52.1507',
    yCoordinates: '14.632566',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Eisenhüttenstadt',
    country: 'Deutschland',
    state: 'Brandenburg',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 493,
    pid: 91,
    number: '001-0453',
    title: 'Berlin III, Kottbusser Damm',
    description: '',
    informations: '',
    street: 'Kottbusser Damm 32',
    zip: '10967',
    citypart: 'Kreuzberg',
    addition: '',
    fon: '030-6923381',
    fax: '030-6930176',
    commonName: '',
    xCoordinates: '52.488981',
    yCoordinates: '13.424276',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Berlin',
    country: 'Deutschland',
    state: 'Berlin',
    firmierung: 'Fielmann AG & Co. Kreuzberg KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 495,
    pid: 91,
    number: '001-0455',
    title: 'Wernigerode',
    description: '',
    informations: '',
    street: 'Breite Straße 14',
    zip: '38855',
    citypart: '',
    addition: '',
    fon: '03943-632366',
    fax: '03943-632742',
    commonName: '',
    xCoordinates: '51.833881',
    yCoordinates: '10.785763',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Wernigerode',
    country: 'Deutschland',
    state: 'Sachsen-Anhalt',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 497,
    pid: 91,
    number: '001-0459',
    title: 'Hoyerswerda',
    description: '',
    informations: '',
    street: 'Dietrich-Bonhoeffer-Straße 6',
    zip: '02977',
    citypart: '',
    addition: '',
    fon: '03571-406628',
    fax: '03571-406629',
    commonName: '',
    xCoordinates: '51.4362839',
    yCoordinates: '14.2567593',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Hoyerswerda',
    country: 'Deutschland',
    state: 'Sachsen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 499,
    pid: 91,
    number: '001-0460',
    title: 'Neuwied',
    description: '',
    informations: '',
    street: 'Mittelstraße 31',
    zip: '56564',
    citypart: '',
    addition: '',
    fon: '02631-24111',
    fax: '02631-25798',
    commonName: '',
    xCoordinates: '50.4286724',
    yCoordinates: '7.4577525',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Neuwied',
    country: 'Deutschland',
    state: 'Rheinland-Pfalz',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 501,
    pid: 91,
    number: '001-0462',
    title: 'Berlin IX, Turmstraße',
    description: '',
    informations: '',
    street: 'Turmstraße 44',
    zip: '10551',
    citypart: 'Moabit',
    addition: '',
    fon: '030-3956426',
    fax: '030-3956461',
    commonName: '',
    xCoordinates: '52.527025',
    yCoordinates: '13.334596',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Berlin',
    country: 'Deutschland',
    state: 'Berlin',
    firmierung: 'Fielmann AG & Co. Moabit KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 503,
    pid: 91,
    number: '001-0464',
    title: 'Halberstadt',
    description: '',
    informations: '',
    street: 'Fischmarkt 4a',
    zip: '38820',
    citypart: '',
    addition: '',
    fon: '03941-605182',
    fax: '03941-605183',
    commonName: '',
    xCoordinates: '51.89541',
    yCoordinates: '11.05314',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Halberstadt',
    country: 'Deutschland',
    state: 'Sachsen-Anhalt',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 505,
    pid: 91,
    number: '001-0456',
    title: 'Berlin X, Karl-Marx-Str.',
    description: '',
    informations: '',
    street: 'Karl-Marx-Straße 151',
    zip: '12043',
    citypart: 'Neukölln',
    addition: '',
    fon: '030-6885168',
    fax: '030-6885274',
    commonName: '',
    xCoordinates: '52.476269',
    yCoordinates: '13.439602',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Berlin',
    country: 'Deutschland',
    state: 'Berlin',
    firmierung: 'Fielmann AG & Co. Neukölln KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 507,
    pid: 91,
    number: '001-0458',
    title: 'Schwedt',
    description: '',
    informations: '',
    street: 'Vierradener Straße 38',
    zip: '16303',
    citypart: '',
    addition: '',
    fon: '03332-524606',
    fax: '03332-524607',
    commonName: '',
    xCoordinates: '53.056948',
    yCoordinates: '14.286251',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Schwedt',
    country: 'Deutschland',
    state: 'Brandenburg',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 509,
    pid: 91,
    number: '001-0461',
    title: 'Berlin V, Breite Straße',
    description: '',
    informations: '',
    street: 'Breite Straße 15',
    zip: '13187',
    citypart: 'Pankow',
    addition: '',
    fon: '030-4857483',
    fax: '030-4867139',
    commonName: '',
    xCoordinates: '52.5705847',
    yCoordinates: '13.4064346',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Berlin',
    country: 'Deutschland',
    state: 'Berlin',
    firmierung: 'Fielmann AG & Co. Pankow OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 511,
    pid: 91,
    number: '001-0463',
    title: 'Höxter',
    description: '',
    informations: '',
    street: 'Marktstraße 27',
    zip: '37671',
    citypart: '',
    addition: '',
    fon: '05271-38954',
    fax: '05271-35607',
    commonName: '',
    xCoordinates: '51.775798',
    yCoordinates: '9.38159',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Höxter',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann Augenoptik AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 513,
    pid: 91,
    number: '001-0465',
    title: 'Soest',
    description: '',
    informations: '',
    street: 'Brüderstraße 38',
    zip: '59494',
    citypart: '',
    addition: '',
    fon: '02921-33418',
    fax: '02921-33419',
    commonName: '',
    xCoordinates: '51.575115',
    yCoordinates: '8.104912',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Soest',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 515,
    pid: 91,
    number: '001-0467',
    title: 'Berlin VIII, Reichsstraße',
    description: '',
    informations: '',
    street: 'Reichsstraße 104',
    zip: '14052',
    citypart: 'Westend',
    addition: '',
    fon: '030-3062099',
    fax: '030-3062090',
    commonName: '',
    xCoordinates: '52.510711',
    yCoordinates: '13.269733',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Berlin',
    country: 'Deutschland',
    state: 'Berlin',
    firmierung: 'Fielmann AG & Co. Westend OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 517,
    pid: 91,
    number: '001-0469',
    title: 'Viersen',
    description: '',
    informations: '',
    street: 'Hauptstr. 28',
    zip: '41747',
    citypart: '',
    addition: '',
    fon: '02162-18325',
    fax: '02162-18360',
    commonName: '',
    xCoordinates: '51.257775',
    yCoordinates: '6.3884104',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Viersen',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 519,
    pid: 91,
    number: '001-0470',
    title: 'Berlin XII, Tempelhofer Damm',
    description: '',
    informations: '',
    street: 'Tempelhofer Damm 182/184',
    zip: '12099',
    citypart: 'Tempelhof',
    addition: '',
    fon: '030-7526047',
    fax: '030-7526048',
    commonName: '',
    xCoordinates: '52.46058',
    yCoordinates: '13.384439',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Berlin',
    country: 'Deutschland',
    state: 'Berlin',
    firmierung: 'Fielmann AG & Co. Tempelhof OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 521,
    pid: 91,
    number: '001-0472',
    title: 'Elmshorn',
    description: '',
    informations: '',
    street: 'Königstraße 47',
    zip: '25335',
    citypart: '',
    addition: '',
    fon: '04121-21944',
    fax: '04121-25313',
    commonName: '',
    xCoordinates: '53.75307',
    yCoordinates: '9.65441',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Elmshorn',
    country: 'Deutschland',
    state: 'Schleswig-Holstein',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 523,
    pid: 91,
    number: '001-0466',
    title: 'Berlin VI, Brückenstraße',
    description: '',
    informations: '',
    street: 'Brückenstraße 4',
    zip: '12439',
    citypart: 'Treptow/Schöneweide',
    addition: '',
    fon: '030-6310931',
    fax: '030-6310982',
    commonName: '',
    xCoordinates: '52.457171',
    yCoordinates: '13.511008',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Berlin',
    country: 'Deutschland',
    state: 'Berlin',
    firmierung: 'Fielmann AG & Co. Schöneweide OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 525,
    pid: 91,
    number: '001-0471',
    title: 'Cloppenburg',
    description: '',
    informations: '',
    street: 'Lange Straße 59',
    zip: '49661',
    citypart: '',
    addition: '',
    fon: '04471-2866',
    fax: '04471-3098',
    commonName: '',
    xCoordinates: '52.844361',
    yCoordinates: '8.038074',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Cloppenburg',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 527,
    pid: 91,
    number: '001-0473',
    title: 'Plauen',
    description: '',
    informations: '',
    street: 'Postplatz 3',
    zip: '08523',
    citypart: '',
    addition: '',
    fon: '03741-228041',
    fax: '03741-228042',
    commonName: '',
    xCoordinates: '50.497482',
    yCoordinates: '12.135943',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Plauen',
    country: 'Deutschland',
    state: 'Sachsen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 529,
    pid: 91,
    number: '001-0475',
    title: 'Mainz',
    description: '',
    informations: '',
    street: 'Stadthausstraße 2',
    zip: '55116',
    citypart: 'Zentrum',
    addition: '',
    fon: '06131-221299',
    fax: '06131-221279',
    commonName: '',
    xCoordinates: '50.0008816',
    yCoordinates: '8.2692168',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Mainz',
    country: 'Deutschland',
    state: 'Rheinland-Pfalz',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 531,
    pid: 91,
    number: '001-0477',
    title: 'Aalen',
    description: '',
    informations: '',
    street: 'Mittelbachstr. 2-6',
    zip: '73430',
    citypart: '',
    addition: '',
    fon: '07361-6352',
    fax: '07361-6372',
    commonName: '',
    xCoordinates: '48.83832',
    yCoordinates: '10.0934',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Aalen',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 533,
    pid: 91,
    number: '001-0479',
    title: 'Schwäbisch Gmünd',
    description: '',
    informations: '',
    street: 'Marktplatz 33',
    zip: '73525',
    citypart: '',
    addition: '',
    fon: '07171-63591',
    fax: '07171-63490',
    commonName: '',
    xCoordinates: '48.801145',
    yCoordinates: '9.7965288',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Schwäbisch Gmünd',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 535,
    pid: 91,
    number: '001-0480',
    title: 'Idar-Oberstein',
    description: '',
    informations: '',
    street: 'Hauptstraße 393',
    zip: '55743',
    citypart: '',
    addition: '',
    fon: '06781-21166',
    fax: '06781-22155',
    commonName: '',
    xCoordinates: '49.703741',
    yCoordinates: '7.3265708',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Idar-Oberstein',
    country: 'Deutschland',
    state: 'Rheinland-Pfalz',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 537,
    pid: 91,
    number: '001-0482',
    title: 'Detmold',
    description: '',
    informations: '',
    street: 'Lange Straße 12',
    zip: '32756',
    citypart: '',
    addition: '',
    fon: '05231-37992',
    fax: '05231-38669',
    commonName: '',
    xCoordinates: '51.933452',
    yCoordinates: '8.87809',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Detmold',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 539,
    pid: 91,
    number: '001-0484',
    title: 'Pirmasens',
    description: '',
    informations: '',
    street: 'Hauptstraße 42',
    zip: '66953',
    citypart: '',
    addition: '',
    fon: '06331-31607',
    fax: '06331-41685',
    commonName: '',
    xCoordinates: '49.1992025',
    yCoordinates: '7.604372',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Pirmasens',
    country: 'Deutschland',
    state: 'Rheinland-Pfalz',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 541,
    pid: 91,
    number: '001-0474',
    title: 'Zittau',
    description: '',
    informations: '',
    street: 'Markt 7',
    zip: '02763',
    citypart: '',
    addition: '',
    fon: '03583-704414',
    fax: '03583-704415',
    commonName: '',
    xCoordinates: '50.89581',
    yCoordinates: '14.80697',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Zittau',
    country: 'Deutschland',
    state: 'Sachsen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 543,
    pid: 91,
    number: '001-0476',
    title: 'Regensburg I, Domplatz',
    description: '',
    informations: '',
    street: 'Domplatz 4',
    zip: '93047',
    citypart: '',
    addition: '',
    fon: '0941-53081',
    fax: '0941-53928',
    commonName: '',
    xCoordinates: '49.019088',
    yCoordinates: '12.097896',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Regensburg',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 545,
    pid: 91,
    number: '001-0478',
    title: 'Annaberg-Buchholz',
    description: '',
    informations: '',
    street: 'Buchholzer Straße 15 a',
    zip: '09456',
    citypart: '',
    addition: '',
    fon: '03733-24975',
    fax: '03733-24976',
    commonName: '',
    xCoordinates: '50.5783456',
    yCoordinates: '13.0022993',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Annaberg-Buchholz',
    country: 'Deutschland',
    state: 'Sachsen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 547,
    pid: 91,
    number: '001-0481',
    title: 'Ibbenbüren',
    description: '',
    informations: '',
    street: 'Große Straße 14',
    zip: '49477',
    citypart: '',
    addition: '',
    fon: '05451-971087',
    fax: '05451-971088',
    commonName: '',
    xCoordinates: '52.277152',
    yCoordinates: '7.715694',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Ibbenbüren',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 549,
    pid: 91,
    number: '001-0485',
    title: 'Völklingen',
    description: '',
    informations: '',
    street: 'Rathausstraße 17',
    zip: '66333',
    citypart: '',
    addition: '',
    fon: '06898-21525',
    fax: '06898-21557',
    commonName: '',
    xCoordinates: '49.249175',
    yCoordinates: '6.853878',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Völklingen',
    country: 'Deutschland',
    state: 'Saarland',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 551,
    pid: 91,
    number: '001-0487',
    title: 'Wiesbaden',
    description: '',
    informations: '',
    street: 'Langgasse 3',
    zip: '65183',
    citypart: 'Zentrum',
    addition: '',
    fon: '0611-307614',
    fax: '0611-308093',
    commonName: '',
    xCoordinates: '50.0829574',
    yCoordinates: '8.2391737',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Wiesbaden',
    country: 'Deutschland',
    state: 'Hessen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 553,
    pid: 91,
    number: '001-0489',
    title: 'Bernburg',
    description: '',
    informations: '',
    street: 'Lindenstraße 20 e',
    zip: '06406',
    citypart: '',
    addition: '',
    fon: '03471-625115',
    fax: '03471-625119',
    commonName: '',
    xCoordinates: '51.7923814',
    yCoordinates: '11.744103',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Bernburg',
    country: 'Deutschland',
    state: 'Sachsen-Anhalt',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 555,
    pid: 91,
    number: '001-0490',
    title: 'Köln IV, Frankfurter Straße',
    description: '',
    informations: '',
    street: 'Frankfurter Straße 34 a',
    zip: '51065',
    citypart: 'Mülheim',
    addition: '',
    fon: '0221-613728',
    fax: '0221-613763',
    commonName: '',
    xCoordinates: '50.9603265',
    yCoordinates: '7.0068846',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Köln',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. Mülheim OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 557,
    pid: 91,
    number: '001-0494',
    title: 'Trier',
    description: '',
    informations: '',
    street: 'Fleischstraße 28',
    zip: '54290',
    citypart: '',
    addition: '',
    fon: '0651-78007',
    fax: '0651-78164',
    commonName: '',
    xCoordinates: '49.7546783',
    yCoordinates: '6.637714',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Trier',
    country: 'Deutschland',
    state: 'Rheinland-Pfalz',
    firmierung: 'Fielmann Augenoptik AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 559,
    pid: 91,
    number: '001-0486',
    title: 'Frankfurt/Main I, Königst. Str',
    description: '',
    informations: '',
    street: 'Königsteiner Straße 1',
    zip: '65929',
    citypart: 'Höchst',
    addition: '',
    fon: '069-311157',
    fax: '069-311236',
    commonName: '',
    xCoordinates: '50.100495',
    yCoordinates: '8.549727',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Frankfurt am Main',
    country: 'Deutschland',
    state: 'Hessen',
    firmierung: 'Fielmann AG & Co. Höchst OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 561,
    pid: 91,
    number: '001-0488',
    title: 'Darmstadt II, Schuchardstr.',
    description: '',
    informations: '',
    street: 'Schuchardstraße 14',
    zip: '64283',
    citypart: '',
    addition: '',
    fon: '06151-20693',
    fax: '06151-20604',
    commonName: '',
    xCoordinates: '49.8713264',
    yCoordinates: '8.6525893',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Darmstadt',
    country: 'Deutschland',
    state: 'Hessen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 563,
    pid: 91,
    number: '001-0491',
    title: 'Strausberg',
    description: '',
    informations: '',
    street: 'Große Straße 59',
    zip: '15344',
    citypart: '',
    addition: '',
    fon: '03341-309529',
    fax: '03341-309530',
    commonName: '',
    xCoordinates: '52.579927',
    yCoordinates: '13.881656',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Strausberg',
    country: 'Deutschland',
    state: 'Brandenburg',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 565,
    pid: 91,
    number: '001-0493',
    title: 'Norden',
    description: '',
    informations: '',
    street: 'Neuer Weg 113',
    zip: '26506',
    citypart: '',
    addition: '',
    fon: '04931-992480',
    fax: '04931-992481',
    commonName: '',
    xCoordinates: '53.595227',
    yCoordinates: '7.208795',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Norden',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann Augenoptik AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 567,
    pid: 91,
    number: '001-0495',
    title: 'Berlin XIII, Frankfurter Allee',
    description: '',
    informations: '',
    street: 'Frankfurter Allee 71 - 77',
    zip: '10247',
    citypart: 'Friedrichshain',
    addition: '',
    fon: '030-42812031',
    fax: '030-42812032',
    commonName: '',
    xCoordinates: '52.5151271',
    yCoordinates: '13.4686847',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Berlin',
    country: 'Deutschland',
    state: 'Berlin',
    firmierung: 'Fielmann AG & Co. Friedrichshain OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 569,
    pid: 91,
    number: '001-0497',
    title: 'Recklinghausen',
    description: '',
    informations: '',
    street: 'Löhrhof 1',
    zip: '45657',
    citypart: '',
    addition: '',
    fon: '02361-186901',
    fax: '02361-186525',
    commonName: 'Palais Vest',
    xCoordinates: '51.613486',
    yCoordinates: '7.200834',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Recklinghausen',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 571,
    pid: 91,
    number: '001-0499',
    title: 'Oranienburg',
    description: '',
    informations: '',
    street: 'Bernauer Str. 43',
    zip: '16515',
    citypart: '',
    addition: '',
    fon: '03301-535224',
    fax: '03301-535225',
    commonName: '',
    xCoordinates: '52.7561033',
    yCoordinates: '13.24389',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Oranienburg',
    country: 'Deutschland',
    state: 'Brandenburg',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 573,
    pid: 91,
    number: '001-0501',
    title: 'Wittmund',
    description: '',
    informations: '',
    street: 'Norderstraße 19',
    zip: '26409',
    citypart: '',
    addition: '',
    fon: '04462-6352',
    fax: '04462-1252',
    commonName: '',
    xCoordinates: '53.5767178',
    yCoordinates: '7.7813655',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Wittmund',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann Augenoptik AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 575,
    pid: 91,
    number: '001-0503',
    title: 'Eschweiler',
    description: '',
    informations: '',
    street: 'Grabenstraße 78 - 80',
    zip: '52249',
    citypart: '',
    addition: '',
    fon: '02403-889489',
    fax: '02403-889490',
    commonName: '',
    xCoordinates: '50.8178701',
    yCoordinates: '6.2658372',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Eschweiler',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 577,
    pid: 91,
    number: '001-0496',
    title: 'Andernach',
    description: '',
    informations: '',
    street: 'Markt 17',
    zip: '56626',
    citypart: '',
    addition: '',
    fon: '02632-48183',
    fax: '02632-493164',
    commonName: '',
    xCoordinates: '50.4390416',
    yCoordinates: '7.3991556',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Andernach',
    country: 'Deutschland',
    state: 'Rheinland-Pfalz',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 579,
    pid: 91,
    number: '001-0498',
    title: 'Burg',
    description: '',
    informations: '',
    street: 'Schartauer Straße 3',
    zip: '39288',
    citypart: '',
    addition: '',
    fon: '03921-988403',
    fax: '03921-988404',
    commonName: '',
    xCoordinates: '52.270715',
    yCoordinates: '11.855743',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Burg',
    country: 'Deutschland',
    state: 'Sachsen-Anhalt',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 581,
    pid: 91,
    number: '001-0500',
    title: 'Salzgitter',
    description: '',
    informations: '',
    street: 'In den Blumentriften 1',
    zip: '38226',
    citypart: '',
    addition: '',
    fon: '05341-44405',
    fax: '05341-44495',
    commonName: '',
    xCoordinates: '52.153507',
    yCoordinates: '10.331047',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Salzgitter',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann AG & Co. KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 583,
    pid: 91,
    number: '001-0502',
    title: 'Esens',
    description: '',
    informations: '',
    street: 'Herdestraße 2',
    zip: '26427',
    citypart: '',
    addition: '',
    fon: '04971-3230',
    fax: '04971-3850',
    commonName: '',
    xCoordinates: '53.646889',
    yCoordinates: '7.610887',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Esens',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann Augenoptik AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 585,
    pid: 91,
    number: '001-0504',
    title: 'Eberswalde',
    description: '',
    informations: '',
    street: 'An der Friedensbrücke 22',
    zip: '16225',
    citypart: '',
    addition: '',
    fon: '03334-239759',
    fax: '03334-239772',
    commonName: '',
    xCoordinates: '52.8339472',
    yCoordinates: '13.8202942',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Eberswalde',
    country: 'Deutschland',
    state: 'Brandenburg',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 587,
    pid: 91,
    number: '001-0508',
    title: 'Köln V, Rhein-Center',
    description: '',
    informations: '',
    street: 'Aachener Straße 1253',
    zip: '50858',
    citypart: 'Weiden',
    addition: '',
    fon: '02234-47031',
    fax: '02234-47032',
    commonName: 'Rhein-Center',
    xCoordinates: '50.937881',
    yCoordinates: '6.834925',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Köln',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. oHG Rhein-Center',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 589,
    pid: 91,
    number: '001-0511',
    title: 'Neustadt a.d. Weinstraße',
    description: '',
    informations: '',
    street: 'Hauptstraße 31',
    zip: '67433',
    citypart: '',
    addition: '',
    fon: '06321-929994',
    fax: '06321-929993',
    commonName: '',
    xCoordinates: '49.351226',
    yCoordinates: '8.136824',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Neustadt a.d. Weinstraße',
    country: 'Deutschland',
    state: 'Rheinland-Pfalz',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 591,
    pid: 91,
    number: '001-0513',
    title: 'Stuttgart II, Marktstraße',
    description: '',
    informations: '',
    street: 'Marktstraße 45',
    zip: '70372',
    citypart: 'Bad Cannstatt',
    addition: '',
    fon: '0711-557410',
    fax: '0711-557725',
    commonName: '',
    xCoordinates: '48.805077',
    yCoordinates: '9.213712',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Stuttgart',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & Co. Bad Cannstatt OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 593,
    pid: 91,
    number: '001-0515',
    title: 'Bünde',
    description: '',
    informations: '',
    street: 'Eschstraße 17',
    zip: '32257',
    citypart: '',
    addition: '',
    fon: '05223-184744',
    fax: '05223-184745',
    commonName: '',
    xCoordinates: '52.197262',
    yCoordinates: '8.583209',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Bünde',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 595,
    pid: 91,
    number: '001-0509',
    title: 'Berlin XIX, Weißensee',
    description: '',
    informations: '',
    street: 'Berliner Allee 85',
    zip: '13088',
    citypart: 'Weißensee',
    addition: '',
    fon: '030-929019660',
    fax: '030-929019689',
    commonName: '',
    xCoordinates: '52.549701',
    yCoordinates: '13.456472',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Berlin',
    country: 'Deutschland',
    state: 'Berlin',
    firmierung: 'Fielmann AG & Co. Weißensee OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 597,
    pid: 91,
    number: '001-0510',
    title: 'Fürstenwalde',
    description: '',
    informations: '',
    street: 'Eisenbahnstraße 22',
    zip: '15517',
    citypart: '',
    addition: '',
    fon: '03361-301320',
    fax: '03361-301321',
    commonName: '',
    xCoordinates: '52.3620046',
    yCoordinates: '14.0621942',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Fürstenwalde',
    country: 'Deutschland',
    state: 'Brandenburg',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 599,
    pid: 91,
    number: '001-0512',
    title: 'Albstadt-Ebingen',
    description: '',
    informations: '',
    street: 'Marktstraße 10',
    zip: '72458',
    citypart: '',
    addition: '',
    fon: '07431-591179',
    fax: '07431-591180',
    commonName: '',
    xCoordinates: '48.212766',
    yCoordinates: '9.027213',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Albstadt-Ebingen',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 601,
    pid: 91,
    number: '001-0514',
    title: 'Frankfurt/Main II, Roßmarkt',
    description: '',
    informations: '',
    street: 'Roßmarkt 15',
    zip: '60311',
    citypart: 'Zentrum',
    addition: '',
    fon: '069-295043',
    fax: '069-295044',
    commonName: '',
    xCoordinates: '50.1122071',
    yCoordinates: '8.6777265',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Frankfurt am Main',
    country: 'Deutschland',
    state: 'Hessen',
    firmierung: 'Fielmann AG & Co. Roßmarkt OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 603,
    pid: 91,
    number: '001-0516',
    title: 'Homburg',
    description: '',
    informations: '',
    street: 'Eisenbahnstraße 31',
    zip: '66424',
    citypart: '',
    addition: '',
    fon: '06841-3004',
    fax: '06841-3005',
    commonName: '',
    xCoordinates: '49.3239396',
    yCoordinates: '7.3404199',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '4124',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '4124',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Homburg-Saar',
    country: 'Deutschland',
    state: 'Saarland',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 605,
    pid: 91,
    number: '001-0518',
    title: 'Pirna',
    description: '',
    informations: '',
    street: 'Schmiedestraße 32',
    zip: '01796',
    citypart: '',
    addition: '',
    fon: '03501-529940',
    fax: '03501-529941',
    commonName: '',
    xCoordinates: '50.96188',
    yCoordinates: '13.939652',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Pirna',
    country: 'Deutschland',
    state: 'Sachsen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 607,
    pid: 91,
    number: '001-0521',
    title: 'Lörrach',
    description: '',
    informations: '',
    street: 'Tumringer Straße 188',
    zip: '79539',
    citypart: '',
    addition: '',
    fon: '07621-14515',
    fax: '07621-14543',
    commonName: '',
    xCoordinates: '47.6134159',
    yCoordinates: '7.660982',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Lörrach',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 609,
    pid: 91,
    number: '001-0523',
    title: 'Nordhausen',
    description: '',
    informations: '',
    street: 'Bahnhofstraße 12 - 13',
    zip: '99734',
    citypart: '',
    addition: '',
    fon: '03631-992115',
    fax: '03631-992142',
    commonName: '',
    xCoordinates: '51.49382',
    yCoordinates: '10.79042',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Nordhausen',
    country: 'Deutschland',
    state: 'Thüringen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 611,
    pid: 91,
    number: '001-0525',
    title: 'München IV, Weißenburger Str.',
    description: '',
    informations: '',
    street: 'Weißenburger Straße 21',
    zip: '81667',
    citypart: 'Haidhausen',
    addition: '',
    fon: '089-485372',
    fax: '089-485311',
    commonName: '',
    xCoordinates: '48.12858',
    yCoordinates: '11.598221',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'München',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. Haidhausen OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 613,
    pid: 91,
    number: '001-0517',
    title: 'Frankfurt/Main III, Berger Str',
    description: '',
    informations: '',
    street: 'Berger Straße 171',
    zip: '60385',
    citypart: 'Bornheim',
    addition: '',
    fon: '069-4691230',
    fax: '069-4691216',
    commonName: '',
    xCoordinates: '50.125204',
    yCoordinates: '8.705828',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Frankfurt am Main',
    country: 'Deutschland',
    state: 'Hessen',
    firmierung: 'Fielmann AG & Co. Bornheim KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 615,
    pid: 91,
    number: '001-0519',
    title: 'Gladbeck',
    description: '',
    informations: '',
    street: 'Hochstraße 36',
    zip: '45964',
    citypart: '',
    addition: '',
    fon: '02043-23431',
    fax: '02043-25739',
    commonName: '',
    xCoordinates: '51.573508',
    yCoordinates: '6.993536',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Gladbeck',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 617,
    pid: 91,
    number: '001-0520',
    title: 'Güstrow II, Pferdemarkt',
    description: '',
    informations: '',
    street: 'Pferdemarkt 16',
    zip: '18273',
    citypart: '',
    addition: '',
    fon: '03843-682000',
    fax: '03843-682005',
    commonName: '',
    xCoordinates: '53.795297',
    yCoordinates: '12.173622',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Güstrow',
    country: 'Deutschland',
    state: 'Mecklenburg-Vorpommern',
    firmierung: 'Fielmann AG & Co. Pferdemarkt OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 619,
    pid: 91,
    number: '001-0522',
    title: 'München III, Pasing-Arcaden',
    description: '',
    informations: '',
    street: 'Pasinger Bahnhofsplatz 5',
    zip: '81241',
    citypart: 'Pasing',
    addition: '',
    fon: '089-89620966',
    fax: '089-89620965',
    commonName: 'Pasing Arcaden',
    xCoordinates: '48.148515',
    yCoordinates: '11.463573',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'München',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. Pasing OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 621,
    pid: 91,
    number: '001-0524',
    title: 'Rastatt',
    description: '',
    informations: '',
    street: 'Kaiserstraße 21',
    zip: '76437',
    citypart: '',
    addition: '',
    fon: '07222-33003',
    fax: '07222-33010',
    commonName: '',
    xCoordinates: '48.85664',
    yCoordinates: '8.2031544',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Rastatt',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 623,
    pid: 91,
    number: '001-0526',
    title: 'Ingolstadt',
    description: '',
    informations: '',
    street: 'Moritzstraße 3',
    zip: '85049',
    citypart: '',
    addition: '',
    fon: '0841-34560',
    fax: '0841-33793',
    commonName: '',
    xCoordinates: '48.763821',
    yCoordinates: '11.424234',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Ingolstadt',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 625,
    pid: 91,
    number: '001-0528',
    title: 'Worms',
    description: '',
    informations: '',
    street: 'Kämmererstraße 9 - 13',
    zip: '67547',
    citypart: '',
    addition: '',
    fon: '06241-24295',
    fax: '06241-26427',
    commonName: '',
    xCoordinates: '49.6310209',
    yCoordinates: '8.3617469',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Worms',
    country: 'Deutschland',
    state: 'Rheinland-Pfalz',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 627,
    pid: 91,
    number: '001-0531',
    title: 'Zweibrücken',
    description: '',
    informations: '',
    street: 'Hauptstraße 59',
    zip: '66482',
    citypart: '',
    addition: '',
    fon: '06332-73005',
    fax: '06332-73006',
    commonName: '',
    xCoordinates: '49.24918',
    yCoordinates: '7.36172',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Zweibrücken',
    country: 'Deutschland',
    state: 'Rheinland-Pfalz',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 629,
    pid: 91,
    number: '001-0533',
    title: 'Neuruppin',
    description: '',
    informations: '',
    street: 'Karl-Marx-Straße 87',
    zip: '16816',
    citypart: '',
    addition: '',
    fon: '03391-358605',
    fax: '03391-358620',
    commonName: '',
    xCoordinates: '52.925981',
    yCoordinates: '12.807112',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Neuruppin',
    country: 'Deutschland',
    state: 'Brandenburg',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 631,
    pid: 91,
    number: '001-0527',
    title: 'Saarlouis',
    description: '',
    informations: '',
    street: 'Französische Straße 8',
    zip: '66740',
    citypart: '',
    addition: '',
    fon: '06831-460520',
    fax: '06831-460525',
    commonName: '',
    xCoordinates: '49.314668',
    yCoordinates: '6.7514743',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Saarlouis',
    country: 'Deutschland',
    state: 'Saarland',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 633,
    pid: 91,
    number: '001-0529',
    title: 'Riesa',
    description: '',
    informations: '',
    street: 'Hauptstraße 95',
    zip: '01587',
    citypart: '',
    addition: '',
    fon: '03525-733202',
    fax: '03525-733203',
    commonName: '',
    xCoordinates: '51.305148',
    yCoordinates: '13.30258',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Riesa',
    country: 'Deutschland',
    state: 'Sachsen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 635,
    pid: 91,
    number: '001-0530',
    title: 'Lippstadt',
    description: '',
    informations: '',
    street: 'Lange Straße 48',
    zip: '59555',
    citypart: '',
    addition: '',
    fon: '02941-3738',
    fax: '02941-3767',
    commonName: '',
    xCoordinates: '51.67308',
    yCoordinates: '8.3469',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Lippstadt',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 637,
    pid: 91,
    number: '001-0532',
    title: 'Singen',
    description: '',
    informations: '',
    street: 'August-Ruf-Straße 16',
    zip: '78224',
    citypart: '',
    addition: '',
    fon: '07731-61223',
    fax: '07731-61085',
    commonName: '',
    xCoordinates: '47.761595',
    yCoordinates: '8.839064',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Singen',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 639,
    pid: 91,
    number: '001-0534',
    title: 'Hameln',
    description: '',
    informations: '',
    street: 'Bäckerstraße 20',
    zip: '31785',
    citypart: '',
    addition: '',
    fon: '05151-959222',
    fax: '05151-959786',
    commonName: '',
    xCoordinates: '52.102191',
    yCoordinates: '9.356242',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Hameln',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 641,
    pid: 91,
    number: '001-0538',
    title: 'Berlin XV, Baumschulenstraße',
    description: '',
    informations: '',
    street: 'Baumschulenstraße 18',
    zip: '12437',
    citypart: 'Treptow',
    addition: '',
    fon: '030-5328382',
    fax: '030-5328342',
    commonName: '',
    xCoordinates: '52.465695',
    yCoordinates: '13.487166',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Berlin',
    country: 'Deutschland',
    state: 'Berlin',
    firmierung: 'Fielmann AG & Co. Treptow OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 643,
    pid: 91,
    number: '001-0541',
    title: 'Grevenbroich',
    description: '',
    informations: '',
    street: 'Kölner Straße 4 - 6',
    zip: '41515',
    citypart: '',
    addition: '',
    fon: '02181-231164',
    fax: '02181-231165',
    commonName: '',
    xCoordinates: '51.0882151',
    yCoordinates: '6.5898369',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Grevenbroich',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 645,
    pid: 91,
    number: '001-0543',
    title: 'Offenbach',
    description: '',
    informations: '',
    street: 'Frankfurter Straße 34/36',
    zip: '63065',
    citypart: '',
    addition: '',
    fon: '069-82360331',
    fax: '069-82375193',
    commonName: '',
    xCoordinates: '50.104797',
    yCoordinates: '8.760789',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Offenbach',
    country: 'Deutschland',
    state: 'Hessen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 647,
    pid: 91,
    number: '001-0545',
    title: 'München V, Tal',
    description: '',
    informations: '',
    street: 'Tal 23 - 25',
    zip: '80331',
    citypart: 'Altstadt-Lehel',
    addition: '',
    fon: '089-29163100',
    fax: '089-29163101',
    commonName: 'Tal',
    xCoordinates: '48.1359237',
    yCoordinates: '11.5802662',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'München',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. Tal OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 649,
    pid: 91,
    number: '001-0535',
    title: 'Gummersbach',
    description: '',
    informations: '',
    street: 'Kaiserstraße 22',
    zip: '51643',
    citypart: '',
    addition: '',
    fon: '02261-27802',
    fax: '02261-27825',
    commonName: '',
    xCoordinates: '51.02733',
    yCoordinates: '7.565698',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Gummersbach',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 651,
    pid: 91,
    number: '001-0537',
    title: 'Berlin XVI, Breite Straße',
    description: '',
    informations: '',
    street: 'Breite Straße 22',
    zip: '13597',
    citypart: 'Spandau',
    addition: '',
    fon: '030-35302800',
    fax: '030-35302801',
    commonName: '',
    xCoordinates: '52.536763',
    yCoordinates: '13.205423',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Berlin',
    country: 'Deutschland',
    state: 'Berlin',
    firmierung: 'Fielmann AG & Co. Spandau OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 653,
    pid: 91,
    number: '001-0539',
    title: 'Hattingen',
    description: '',
    informations: '',
    street: 'Heggerstraße 23',
    zip: '45525',
    citypart: '',
    addition: '',
    fon: '02324-950316',
    fax: '02324-950317',
    commonName: '',
    xCoordinates: '51.399746',
    yCoordinates: '7.185163',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Hattingen',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 655,
    pid: 91,
    number: '001-0540',
    title: 'Bremen III, Hans-Bredow-Straße',
    description: '',
    informations: '',
    street: 'Hans-Bredow-Straße 19',
    zip: '28307',
    citypart: 'Osterholz',
    addition: '',
    fon: '0421-488091',
    fax: '0421-488092',
    commonName: 'Weserpark',
    xCoordinates: '53.049143',
    yCoordinates: '8.959001',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Bremen',
    country: 'Deutschland',
    state: 'Bremen',
    firmierung: 'Fielmann AG & Co. Weserpark OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 657,
    pid: 91,
    number: '001-0542',
    title: 'Bad Homburg',
    description: '',
    informations: '',
    street: 'Louisenstraße 87',
    zip: '61348',
    citypart: '',
    addition: '',
    fon: '06172-928902',
    fax: '06172-928901',
    commonName: '',
    xCoordinates: '50.22585',
    yCoordinates: '8.618573',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Bad Homburg',
    country: 'Deutschland',
    state: 'Hessen',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 659,
    pid: 91,
    number: '001-0544',
    title: 'Magdeburg II, Halberstädter',
    description: '',
    informations: '',
    street: 'Halberstädter Straße 100',
    zip: '39112',
    citypart: 'Sudenburg',
    addition: '',
    fon: '0391-6229045',
    fax: '0391-6229046',
    commonName: '',
    xCoordinates: '52.111453',
    yCoordinates: '11.603316',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Magdeburg',
    country: 'Deutschland',
    state: 'Sachsen-Anhalt',
    firmierung: 'Fielmann AG & Co. Sudenburg OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 661,
    pid: 91,
    number: '001-0546',
    title: 'Luckenwalde',
    description: '',
    informations: '',
    street: 'Breite Straße 32',
    zip: '14943',
    citypart: '',
    addition: '',
    fon: '03371-622316',
    fax: '03371-622317',
    commonName: '',
    xCoordinates: '52.087913',
    yCoordinates: '13.172262',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Luckenwalde',
    country: 'Deutschland',
    state: 'Brandenburg',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 663,
    pid: 91,
    number: '001-0548',
    title: 'Neunkirchen',
    description: '',
    informations: '',
    street: 'Saarpark-Center/Stummstraße 2',
    zip: '66538',
    citypart: '',
    addition: '',
    fon: '06821-140725',
    fax: '06821-140726',
    commonName: 'Saarpark-Center',
    xCoordinates: '49.348156',
    yCoordinates: '7.174182',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Neunkirchen',
    country: 'Deutschland',
    state: 'Saarland',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 665,
    pid: 91,
    number: '001-0551',
    title: 'Ilmenau',
    description: '',
    informations: '',
    street: 'Straße des Friedens 8',
    zip: '98693',
    citypart: '',
    addition: '',
    fon: '03677-204873',
    fax: '03677-204874',
    commonName: '',
    xCoordinates: '50.684957',
    yCoordinates: '10.913904',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Ilmenau',
    country: 'Deutschland',
    state: 'Thüringen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 667,
    pid: 91,
    number: '001-0547',
    title: 'Schönebeck',
    description: '',
    informations: '',
    street: 'Salzer Straße 8',
    zip: '39218',
    citypart: '',
    addition: '',
    fon: '03928-410835',
    fax: '03928-410836',
    commonName: '',
    xCoordinates: '52.018652',
    yCoordinates: '11.736339',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Schönebeck',
    country: 'Deutschland',
    state: 'Sachsen-Anhalt',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 669,
    pid: 91,
    number: '001-0549',
    title: 'Lutherstadt Eisleben',
    description: '',
    informations: '',
    street: 'Markt 54',
    zip: '06295',
    citypart: '',
    addition: '',
    fon: '03475-747815',
    fax: '03475-747816',
    commonName: '',
    xCoordinates: '51.528076',
    yCoordinates: '11.546393',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Lutherstadt Eisleben',
    country: 'Deutschland',
    state: 'Sachsen-Anhalt',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 671,
    pid: 91,
    number: '001-0550',
    title: 'Fulda',
    description: '',
    informations: '',
    street: 'Marktstraße 14',
    zip: '36037',
    citypart: '',
    addition: '',
    fon: '0661-9709875',
    fax: '0661-9709876',
    commonName: '',
    xCoordinates: '50.5508285',
    yCoordinates: '9.6771022',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Fulda',
    country: 'Deutschland',
    state: 'Hessen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 673,
    pid: 91,
    number: '001-0552',
    title: 'Göppingen',
    description: '',
    informations: '',
    street: 'Marktstraße 9',
    zip: '73033',
    citypart: '',
    addition: '',
    fon: '07161-969773',
    fax: '07161-969774',
    commonName: '',
    xCoordinates: '48.7030098',
    yCoordinates: '9.6534724',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Göppingen',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 675,
    pid: 91,
    number: '001-0554',
    title: 'Ludwigsburg I, Kirchstraße',
    description: '',
    informations: '',
    street: 'Kirchstraße 2',
    zip: '71634',
    citypart: '',
    addition: '',
    fon: '07141-904621',
    fax: '07141-904622',
    commonName: '',
    xCoordinates: '48.8965161',
    yCoordinates: '9.1905117',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Ludwigsburg',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & Co. OhG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 677,
    pid: 91,
    number: '001-0558',
    title: 'Basel I, Marktplatz',
    description: '',
    informations: '',
    street: 'Marktplatz 16',
    zip: '4001',
    citypart: '',
    addition: '',
    fon: '061 266 16 20',
    fax: '061 266 16 29',
    commonName: '',
    xCoordinates: '47.5576234',
    yCoordinates: '7.588105',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16ccc970ac8102ce1d643',
    appointmentTimifyType: true,
    city: 'Basel',
    country: 'Schweiz',
    state: 'Basel-Stadt',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 679,
    pid: 91,
    number: '001-0561',
    title: 'Zürich I, Bahnhofstraße',
    description: '',
    informations: '',
    street: 'Bahnhofstrasse 83',
    zip: '8001',
    citypart: 'Zentrum',
    addition: '',
    fon: '043 888 67 67',
    fax: '043 888 67 68',
    commonName: '',
    xCoordinates: '47.3760735',
    yCoordinates: '8.5391153',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16cccc8290c0fa864616f',
    appointmentTimifyType: true,
    city: 'Zürich',
    country: 'Schweiz',
    state: 'Zürich',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 681,
    pid: 91,
    number: '001-0563',
    title: 'Langenthal',
    description: '',
    informations: '',
    street: 'Marktgasse 17',
    zip: '4900',
    citypart: '',
    addition: '',
    fon: '062 923 99 10',
    fax: '062 923 99 11',
    commonName: '',
    xCoordinates: '47.2116303',
    yCoordinates: '7.7889618',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16e6a63ab66101aa9018c',
    appointmentTimifyType: true,
    city: 'Langenthal',
    country: 'Schweiz',
    state: 'Bern',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 683,
    pid: 91,
    number: '001-0565',
    title: 'Biel',
    description: '',
    informations: '',
    street: 'Nidaugasse 14',
    zip: '2502',
    citypart: '',
    addition: '',
    fon: '032 321 75 90',
    fax: '032 321 75 91',
    commonName: '',
    xCoordinates: '47.1398499',
    yCoordinates: '7.2454661',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16ccc63ab66101aa90114',
    appointmentTimifyType: true,
    city: 'Biel',
    country: 'Schweiz',
    state: 'Bern',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 685,
    pid: 91,
    number: '001-0553',
    title: 'Lahr',
    description: '',
    informations: '',
    street: 'Marktplatz 5',
    zip: '77933',
    citypart: '',
    addition: '',
    fon: '07821-983160',
    fax: '07821-983161',
    commonName: '',
    xCoordinates: '48.340889',
    yCoordinates: '7.872306',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Lahr',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 687,
    pid: 91,
    number: '001-0557',
    title: 'Luzern I, Weggisgasse',
    description: '',
    informations: '',
    street: 'Weggisgasse 36-38',
    zip: '6004',
    citypart: '',
    addition: '',
    fon: '041 417 44 50',
    fax: '041 417 44 51',
    commonName: '',
    xCoordinates: '47.0527427',
    yCoordinates: '8.3054608',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16cccc8290c0fa8646139',
    appointmentTimifyType: true,
    city: 'Luzern',
    country: 'Schweiz',
    state: 'Luzern',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 689,
    pid: 91,
    number: '001-0559',
    title: 'Zofingen',
    description: '',
    informations: '',
    street: 'Vordere Hauptgasse 16',
    zip: '4800',
    citypart: 'Altstadt',
    addition: '',
    fon: '062 785 20 00',
    fax: '062 785 20 01',
    commonName: '',
    xCoordinates: '47.2900291',
    yCoordinates: '7.9440145',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16cccd1d00e10173befb9',
    appointmentTimifyType: true,
    city: 'Zofingen',
    country: 'Schweiz',
    state: 'Aargau',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 691,
    pid: 91,
    number: '001-0560',
    title: 'Bern I, Waisenhausplatz',
    description: '',
    informations: '',
    street: 'Waisenhausplatz 1',
    zip: '3011',
    citypart: '',
    addition: '',
    fon: '031 310 28 50',
    fax: '031 310 28 51',
    commonName: '',
    xCoordinates: '46.9483302',
    yCoordinates: '7.4438604',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16ccce15925100d496033',
    appointmentTimifyType: true,
    city: 'Bern',
    country: 'Schweiz',
    state: 'Bern',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 693,
    pid: 91,
    number: '001-0562',
    title: 'Zug',
    description: '',
    informations: '',
    street: 'Bahnhofstrasse 32',
    zip: '6300',
    citypart: '',
    addition: '',
    fon: '041 728 07 50',
    fax: '041 728 07 51',
    commonName: '',
    xCoordinates: '47.1705309',
    yCoordinates: '8.516064',
    opening: false,
    opening_box: false,
    openingText:
      'Die neue Fielmann-Niederlassung in Zug, Bahnhofstrasse 32.\r\nKommen Sie am 18. Januar zum grossen Eröffnungsfest.',
    openingLink: '3072',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16ccc63ab66101aa90110',
    appointmentTimifyType: true,
    city: 'Zug',
    country: 'Schweiz',
    state: 'Zug',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 695,
    pid: 91,
    number: '001-0564',
    title: 'Solothurn',
    description: '',
    informations: '',
    street: 'Gurzelngasse 7',
    zip: '4500',
    citypart: '',
    addition: '',
    fon: '032 628 28 60',
    fax: '032 628 28 61',
    commonName: '',
    xCoordinates: '47.2078836',
    yCoordinates: '7.536342',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16ccc970ac8102ce1d64a',
    appointmentTimifyType: true,
    city: 'Solothurn',
    country: 'Schweiz',
    state: 'Solothurn',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 697,
    pid: 91,
    number: '001-0566',
    title: 'Fribourg',
    description: '',
    informations: '',
    street: 'Rue de Romont 14',
    zip: '1700',
    citypart: '',
    addition: '',
    fon: '026 341 76 80',
    fax: '026 341 76 81',
    commonName: '',
    xCoordinates: '46.8045184',
    yCoordinates: '7.1548313',
    opening: false,
    opening_box: false,
    openingText:
      'Die neue Fielmann-Niederlassung in Fribourg, Rue de Romont. Kommen Sie am 26. Oktober zum großen Eröffnungsfest. ',
    openingLink: '2960',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '30038d2e-f595-417c-b5dc-0c148c461378-5ea16ba563ab66101aa900d9',
    appointmentTimifyType: true,
    city: 'Fribourg',
    country: 'Schweiz',
    state: 'Freiburg',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 699,
    pid: 91,
    number: '001-0568',
    title: 'Frauenfeld',
    description: '',
    informations: '',
    street: 'Zürcherstrasse 173',
    zip: '8500',
    citypart: '',
    addition: '',
    fon: '052 722 18 60',
    fax: '052 722 18 61',
    commonName: '',
    xCoordinates: '47.556592',
    yCoordinates: '8.8975066',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16cccd1d00e10173befc8',
    appointmentTimifyType: true,
    city: 'Frauenfeld',
    country: 'Schweiz',
    state: 'Thurgau',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 701,
    pid: 91,
    number: '001-0571',
    title: 'Genève',
    description: '',
    informations: '',
    street: "Rue de la Croix d'Or 9",
    zip: '1204',
    citypart: '',
    addition: '',
    fon: '022 544 20 20',
    fax: '022 544 20 21',
    commonName: '',
    xCoordinates: '46.2029132',
    yCoordinates: '6.1485477',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '30038d2e-f595-417c-b5dc-0c148c461378-5ea16ba563ab66101aa900e0',
    appointmentTimifyType: true,
    city: 'Genève',
    country: 'Schweiz',
    state: 'Genf',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 703,
    pid: 91,
    number: '001-0567',
    title: 'Schaffhausen',
    description: '',
    informations: '',
    street: 'Fronwagplatz 10',
    zip: '8200',
    citypart: '',
    addition: '',
    fon: '052 647 60 20',
    fax: '052 647 60 21',
    commonName: '',
    xCoordinates: '47.6989422',
    yCoordinates: '8.6355882',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16ccc970ac8102ce1d63c',
    appointmentTimifyType: true,
    city: 'Schaffhausen',
    country: 'Schweiz',
    state: 'Schaffhausen',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 705,
    pid: 91,
    number: '001-0569',
    title: 'Thun',
    description: '',
    informations: '',
    street: 'Bälliz 48',
    zip: '3600',
    citypart: '',
    addition: '',
    fon: '033 225 01 50',
    fax: '033 225 01 51',
    commonName: '',
    xCoordinates: '46.7580506',
    yCoordinates: '7.6275111',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16ccce15925100d496034',
    appointmentTimifyType: true,
    city: 'Thun',
    country: 'Schweiz',
    state: 'Bern',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 707,
    pid: 91,
    number: '001-0570',
    title: 'Lausanne',
    description: '',
    informations: '',
    street: 'Rue du Pont 22',
    zip: '1003',
    citypart: '',
    addition: '',
    fon: '021 310 84 00',
    fax: '021 310 84 01',
    commonName: '',
    xCoordinates: '46.5210099',
    yCoordinates: '6.6332518',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '30038d2e-f595-417c-b5dc-0c148c461378-5ea16ba5970ac8102ce1d61c',
    appointmentTimifyType: true,
    city: 'Lausanne',
    country: 'Schweiz',
    state: 'Waadt',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 709,
    pid: 91,
    number: '001-0572',
    title: 'Winterthur',
    description: '',
    informations: '',
    street: 'Marktgasse 72/ Schmidgasse 1',
    zip: '8400',
    citypart: '',
    addition: '',
    fon: '052 214 19 55',
    fax: '052 214 19 56',
    commonName: '',
    xCoordinates: '47.4995665',
    yCoordinates: '8.7273233',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16ccce15925100d496049',
    appointmentTimifyType: true,
    city: 'Winterthur',
    country: 'Schweiz',
    state: 'Zürich',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 711,
    pid: 91,
    number: '001-0574',
    title: 'Aarau',
    description: '',
    informations: '',
    street: 'Igelweid 1',
    zip: '5000',
    citypart: '',
    addition: '',
    fon: '062 823 35 50',
    fax: '062 823 35 51',
    commonName: '',
    xCoordinates: '47.3917024',
    yCoordinates: '8.0454986',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16cccd1d00e10173befb4',
    appointmentTimifyType: true,
    city: 'Aarau',
    country: 'Schweiz',
    state: 'Aargau',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 713,
    pid: 91,
    number: '001-0576',
    title: 'St. Gallen',
    description: '',
    informations: '',
    street: 'Multergasse 8',
    zip: '9000',
    citypart: '',
    addition: '',
    fon: '071 226 50 70',
    fax: '071 226 50 71',
    commonName: '',
    xCoordinates: '47.4246285',
    yCoordinates: '9.3757623',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16ccc970ac8102ce1d653',
    appointmentTimifyType: true,
    city: 'St. Gallen',
    country: 'Schweiz',
    state: 'St. Gallen',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 715,
    pid: 91,
    number: '001-0578',
    title: 'Chur',
    description: '',
    informations: '',
    street: 'Quaderstrasse 11',
    zip: '7001',
    citypart: 'Zentrum',
    addition: '',
    fon: '081 257 07 80',
    fax: '081 257 07 99',
    commonName: '',
    xCoordinates: '46.8524582',
    yCoordinates: '9.5324293',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16ccce15925100d496042',
    appointmentTimifyType: true,
    city: 'Chur',
    country: 'Schweiz',
    state: 'Graubünden',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 717,
    pid: 91,
    number: '001-0581',
    title: 'Olten',
    description: '',
    informations: '',
    street: 'Hauptgasse 25',
    zip: '4600',
    citypart: 'Altstadt',
    addition: '',
    fon: '062 205 22 44',
    fax: '062 205 22 45',
    commonName: '',
    xCoordinates: '47.3498512',
    yCoordinates: '7.9035004',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16ccc970ac8102ce1d64c',
    appointmentTimifyType: true,
    city: 'Olten',
    country: 'Schweiz',
    state: 'Solothurn',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 719,
    pid: 91,
    number: '001-0592',
    title: 'Offenburg',
    description: '',
    informations: '',
    street: 'Steinstraße 23',
    zip: '77652',
    citypart: '',
    addition: '',
    fon: '0781-9709680',
    fax: '0781-9709681',
    commonName: '',
    xCoordinates: '48.469419',
    yCoordinates: '7.94555',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Offenburg',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 721,
    pid: 91,
    number: '001-0573',
    title: 'Burgdorf',
    description: '',
    informations: '',
    street: 'Bahnhofstrasse 15',
    zip: '3400',
    citypart: 'Unterstadt',
    addition: '',
    fon: '034 420 34 00',
    fax: '034 420 34 01',
    commonName: '',
    xCoordinates: '47.0595627',
    yCoordinates: '7.623038',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16ccc63ab66101aa90125',
    appointmentTimifyType: true,
    city: 'Burgdorf',
    country: 'Schweiz',
    state: 'Bern',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 723,
    pid: 91,
    number: '001-0575',
    title: 'Wil',
    description: '',
    informations: '',
    street: 'Obere Bahnhofstr. 38',
    zip: '9500',
    citypart: '',
    addition: '',
    fon: '071 910 26 06',
    fax: '071 910 26 07',
    commonName: '',
    xCoordinates: '47.465501',
    yCoordinates: '9.0456681',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '4392',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '4392',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16cccc8290c0fa864613f',
    appointmentTimifyType: true,
    city: 'Wil',
    country: 'Schweiz',
    state: 'St. Gallen',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 725,
    pid: 91,
    number: '001-0577',
    title: 'Neuchâtel',
    description: '',
    informations: '',
    street: 'Grand-Rue 2',
    zip: '2000',
    citypart: '',
    addition: '',
    fon: '032 722 74 44',
    fax: '032 722 74 45',
    commonName: '',
    xCoordinates: '46.9919892',
    yCoordinates: '6.9291627',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '30038d2e-f595-417c-b5dc-0c148c461378-5ea16ba5c8290c0fa864611a',
    appointmentTimifyType: true,
    city: 'Neuchâtel',
    country: 'Schweiz',
    state: 'Neuenburg',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 727,
    pid: 91,
    number: '001-0579',
    title: 'Baden',
    description: '',
    informations: '',
    street: 'Weite Gasse 27',
    zip: '5400',
    citypart: 'Altstadt',
    addition: '',
    fon: '056 203 30 00',
    fax: '056 203 30 01',
    commonName: '',
    xCoordinates: '47.4726787',
    yCoordinates: '8.3077372',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16ccc970ac8102ce1d64b',
    appointmentTimifyType: true,
    city: 'Baden',
    country: 'Schweiz',
    state: 'Aargau',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 729,
    pid: 91,
    number: '001-0580',
    title: 'Buchs',
    description: '',
    informations: '',
    street: 'Bahnhofstrasse 39',
    zip: '9470',
    citypart: '',
    addition: '',
    fon: '081 750 52 52',
    fax: '081 750 52 53',
    commonName: '',
    xCoordinates: '47.1659593',
    yCoordinates: '9.4736979',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16d6d970ac8102ce1d69b',
    appointmentTimifyType: true,
    city: 'Buchs',
    country: 'Schweiz',
    state: 'St. Gallen',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 731,
    pid: 91,
    number: '001-0582',
    title: 'Zürich II, Oerlikon',
    description: '',
    informations: '',
    street: 'Schaffhauserstrasse 355',
    zip: '8050',
    citypart: 'Oerlikon',
    addition: '',
    fon: '043 205 11 60',
    fax: '043 205 11 61',
    commonName: 'Sternen Oerlikon',
    xCoordinates: '47.4099293',
    yCoordinates: '8.5458836',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16cccd1d00e10173befe0',
    appointmentTimifyType: true,
    city: 'Zürich',
    country: 'Schweiz',
    state: 'Zürich',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 733,
    pid: 91,
    number: '001-0591',
    title: 'Straubing',
    description: '',
    informations: '',
    street: 'Ludwigsplatz 15',
    zip: '94315',
    citypart: '',
    addition: '',
    fon: '09421-830436',
    fax: '09421-830437',
    commonName: '',
    xCoordinates: '48.882115',
    yCoordinates: '12.570825',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Straubing',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 735,
    pid: 91,
    number: '001-0593',
    title: 'Rüsselsheim',
    description: '',
    informations: '',
    street: 'Bahnhofstraße 24',
    zip: '65428',
    citypart: '',
    addition: '',
    fon: '06142-965583',
    fax: '06142-965584',
    commonName: '',
    xCoordinates: '49.9934774',
    yCoordinates: '8.4146087',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Rüsselsheim am Main',
    country: 'Deutschland',
    state: 'Hessen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 737,
    pid: 91,
    number: '001-0595',
    title: 'Westerstede',
    description: '',
    informations: '',
    street: 'Lange Straße 2',
    zip: '26655',
    citypart: '',
    addition: '',
    fon: '04488-859833',
    fax: '04488-859834',
    commonName: '',
    xCoordinates: '53.259349',
    yCoordinates: '7.919339',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Westerstede',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann Augenoptik AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 739,
    pid: 91,
    number: '001-0594',
    title: 'Northeim',
    description: '',
    informations: '',
    street: 'Breite Straße 55',
    zip: '37154',
    citypart: '',
    addition: '',
    fon: '05551-913170',
    fax: '05551-913171',
    commonName: '',
    xCoordinates: '51.705609',
    yCoordinates: '10.000196',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Northeim',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 741,
    pid: 91,
    number: '001-0596',
    title: 'Konstanz',
    description: '',
    informations: '',
    street: 'Rosgartenstraße 12',
    zip: '78462',
    citypart: '',
    addition: '',
    fon: '07531-914690',
    fax: '07531-914691',
    commonName: '',
    xCoordinates: '47.659791',
    yCoordinates: '9.174613',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Konstanz',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 743,
    pid: 91,
    number: '001-0598',
    title: 'Quedlinburg',
    description: '',
    informations: '',
    street: 'Steinbrücke 18',
    zip: '06484',
    citypart: '',
    addition: '',
    fon: '03946-705301',
    fax: '03946-705302',
    commonName: '',
    xCoordinates: '51.788013',
    yCoordinates: '11.141865',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Quedlinburg',
    country: 'Deutschland',
    state: 'Sachsen-Anhalt',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 745,
    pid: 91,
    number: '001-0701',
    title: 'Wien I, Favoritenstraße',
    description: '',
    informations: '',
    street: 'Favoritenstraße 93',
    zip: '1100',
    citypart: '10. Bezirk',
    addition: '',
    fon: '01-6066100',
    fax: '01-606610011',
    commonName: '',
    xCoordinates: '48.1794313',
    yCoordinates: '16.3763781',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '5e906fc98dd0d06fe809f4c4',
    appointmentTimifyType: true,
    city: 'Wien',
    country: 'Österreich',
    state: 'Wien',
    firmierung: 'Fielmann GmbH',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 747,
    pid: 91,
    number: '001-0703',
    title: 'Salzburg I, Europark',
    description: '',
    informations: '',
    street: 'Europastraße 1/Europark',
    zip: '5020',
    citypart: 'Kleßheim',
    addition: '',
    fon: '0662-420835',
    fax: '0662-42083511',
    commonName: 'Europark',
    xCoordinates: '47.8166034',
    yCoordinates: '13.0091411',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '5e906fc8f93a3e6fe983a917',
    appointmentTimifyType: true,
    city: 'Salzburg',
    country: 'Österreich',
    state: 'Salzburg',
    firmierung: 'Fielmann GmbH',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 749,
    pid: 91,
    number: '001-0705',
    title: 'St. Pölten',
    description: '',
    informations: '',
    street: 'Kremser Gasse 14',
    zip: '3100',
    citypart: '',
    addition: '',
    fon: '02742-21199',
    fax: '02742-21308',
    commonName: '',
    xCoordinates: '48.2062264',
    yCoordinates: '15.6249525',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '5e906fc90cf9fd6f4297695e',
    appointmentTimifyType: true,
    city: 'St. Pölten',
    country: 'Österreich',
    state: 'Niederösterreich',
    firmierung: 'Fielmann GmbH',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 751,
    pid: 91,
    number: '001-0707',
    title: 'Wien III, Mariahilfer Str.',
    description: '',
    informations: '',
    street: 'Mariahilfer Straße 76',
    zip: '1070',
    citypart: '7. Bezirk',
    addition: '',
    fon: '01-580940',
    fax: '01-5809411',
    commonName: '',
    xCoordinates: '48.1980923',
    yCoordinates: '16.3487005',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '5e906fc90cf9fd6f4297695c',
    appointmentTimifyType: true,
    city: 'Wien',
    country: 'Österreich',
    state: 'Wien',
    firmierung: 'Fielmann GmbH',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 753,
    pid: 91,
    number: '001-0709',
    title: 'Vösendorf-Süd bei Wien',
    description: '',
    informations: '',
    street: 'SCS, Galerie 275',
    zip: '2334',
    citypart: '',
    addition: '',
    fon: '01-6985611',
    fax: '01-698561111',
    commonName: 'Shopping City Süd (SCS)',
    xCoordinates: '48.107822',
    yCoordinates: '16.318392',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '5e906fca0cf9fd6f42976979',
    appointmentTimifyType: true,
    city: 'Vösendorf-Süd',
    country: 'Österreich',
    state: 'Niederösterreich',
    firmierung: 'Fielmann GmbH',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 755,
    pid: 91,
    number: '001-0710',
    title: 'Wiener Neustadt',
    description: '',
    informations: '',
    street: 'Herzog Leopold-Straße 15',
    zip: '2700',
    citypart: '',
    addition: '',
    fon: '02622-2373620',
    fax: '02622-2373611',
    commonName: '',
    xCoordinates: '47.8132075',
    yCoordinates: '16.2413708',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '5e906fc8ce5cd80ad2a0293e',
    appointmentTimifyType: true,
    city: 'Wiener Neustadt',
    country: 'Österreich',
    state: 'Niederösterreich',
    firmierung: 'Fielmann GmbH',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 757,
    pid: 91,
    number: '001-0597',
    title: 'Pforzheim',
    description: '',
    informations: '',
    street: 'Westl. Karl-Friedrich-Straße 29 - 3',
    zip: '75172',
    citypart: '',
    addition: '',
    fon: '07231-140300',
    fax: '07231-140301',
    commonName: '',
    xCoordinates: '48.8914117',
    yCoordinates: '8.6994105',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Pforzheim',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 759,
    pid: 91,
    number: '001-0599',
    title: 'Berlin XVII, Teltower Damm',
    description: '',
    informations: '',
    street: 'Teltower Damm 27',
    zip: '14169',
    citypart: 'Zehlendorf',
    addition: '',
    fon: '030-81294005',
    fax: '030-81294006',
    commonName: '',
    xCoordinates: '52.432423',
    yCoordinates: '13.259459',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Berlin',
    country: 'Deutschland',
    state: 'Berlin',
    firmierung: 'Fielmann AG & Co. Berlin-Zehlendorf OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 761,
    pid: 91,
    number: '001-0702',
    title: 'Wien II, Wagramer Straße',
    description: '',
    informations: '',
    street: 'Wagramer Str. 81',
    zip: '1220',
    citypart: '22. Bezirk',
    addition: '',
    fon: '01-2035232',
    fax: '01-2022745',
    commonName: 'Donauzentrum',
    xCoordinates: '48.242045',
    yCoordinates: '16.437635',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '5e906fca8dd0d06fe809f4e4',
    appointmentTimifyType: true,
    city: 'Wien',
    country: 'Österreich',
    state: 'Wien',
    firmierung: 'Fielmann GmbH',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 763,
    pid: 91,
    number: '001-0704',
    title: 'Pasching bei Linz',
    description: '',
    informations: '',
    street: 'Pluskaufstraße 7',
    zip: '4061',
    citypart: 'Pasching',
    addition: '',
    fon: '07229-51691',
    fax: '07229-5169111',
    commonName: 'PlusCity',
    xCoordinates: '48.242156',
    yCoordinates: '14.2278256',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '5e906fc98dd0d06fe809f4c1',
    appointmentTimifyType: true,
    city: 'Pasching bei Linz',
    country: 'Österreich',
    state: 'Oberösterreich',
    firmierung: 'Fielmann GmbH',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 765,
    pid: 91,
    number: '001-0708',
    title: 'Wien IV, Landstraßer Hauptstr.',
    description: '',
    informations: '',
    street: 'Landstraßer Hauptstraße 75 - 77',
    zip: '1030',
    citypart: '3. Bezirk',
    addition: '',
    fon: '01-718661720',
    fax: '01-718661711',
    commonName: '',
    xCoordinates: '48.2004679',
    yCoordinates: '16.3932137',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '5e906fca0cf9fd6f4297697e',
    appointmentTimifyType: true,
    city: 'Wien',
    country: 'Österreich',
    state: 'Wien',
    firmierung: 'Fielmann GmbH',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 767,
    pid: 91,
    number: '001-0711',
    title: 'Innsbruck',
    description: '',
    informations: '',
    street: 'Maria-Theresien-Straße 6',
    zip: '6020',
    citypart: 'Zentrum',
    addition: '',
    fon: '0512-5832830',
    fax: '0512-58328311',
    commonName: '',
    xCoordinates: '47.2662778',
    yCoordinates: '11.3935964',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '5e906fc88dd0d06fe809f4b2',
    appointmentTimifyType: true,
    city: 'Innsbruck',
    country: 'Österreich',
    state: 'Tirol',
    firmierung: 'Fielmann GmbH',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 769,
    pid: 91,
    number: '001-0713',
    title: 'Krems',
    description: '',
    informations: '',
    street: 'Wiener Straße 96 - 102',
    zip: '3500',
    citypart: '',
    addition: '',
    fon: '02732-796310',
    fax: '02732-7963111',
    commonName: 'Bühl-Center',
    xCoordinates: '48.4162334',
    yCoordinates: '15.620712',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '5e906fcaf93a3e6fe983a944',
    appointmentTimifyType: true,
    city: 'Krems',
    country: 'Österreich',
    state: 'Niederösterreich',
    firmierung: 'Fielmann GmbH',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 771,
    pid: 91,
    number: '001-0715',
    title: 'Bregenz',
    description: '',
    informations: '',
    street: 'Kaiserstraße 20',
    zip: '6900',
    citypart: 'Zentrum',
    addition: '',
    fon: '05574-5382112',
    fax: '05574-5382111',
    commonName: '',
    xCoordinates: '47.5034745',
    yCoordinates: '9.7460197',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '5e906fc70cf9fd6f4297693d',
    appointmentTimifyType: true,
    city: 'Bregenz',
    country: 'Österreich',
    state: 'Vorarlberg',
    firmierung: 'Fielmann GmbH',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 773,
    pid: 91,
    number: '001-0717',
    title: 'Villach',
    description: '',
    informations: '',
    street: 'Hauptplatz 21',
    zip: '9500',
    citypart: 'Zentrum',
    addition: '',
    fon: '04242-21101',
    fax: '04242-2110120',
    commonName: '',
    xCoordinates: '46.6135679',
    yCoordinates: '13.8464803',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '5e906fc8f932686fddc14648',
    appointmentTimifyType: true,
    city: 'Villach',
    country: 'Österreich',
    state: 'Kärnten',
    firmierung: 'Fielmann GmbH',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 775,
    pid: 91,
    number: '001-0712',
    title: 'Wels',
    description: '',
    informations: '',
    street: 'Bäckergasse 18',
    zip: '4600',
    citypart: 'Zentrum',
    addition: '',
    fon: '07242-211390',
    fax: '07242-21139011',
    commonName: '',
    xCoordinates: '48.1596141',
    yCoordinates: '14.0245598',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '5e906fc9f93a3e6fe983a927',
    appointmentTimifyType: true,
    city: 'Wels',
    country: 'Österreich',
    state: 'Oberösterreich',
    firmierung: 'Fielmann GmbH',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 777,
    pid: 91,
    number: '001-0716',
    title: 'Seiersberg bei Graz',
    description: '',
    informations: '',
    street: 'Shoppingcity Seiersberg 5',
    zip: '8055',
    citypart: '',
    addition: '',
    fon: '0316-241092',
    fax: '0316-241079',
    commonName: 'Shoppingcity',
    xCoordinates: '47.0121372',
    yCoordinates: '15.4116888',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '5e906fca8dd0d06fe809f4e3',
    appointmentTimifyType: true,
    city: 'Seiersberg bei Graz',
    country: 'Österreich',
    state: 'Steiermark',
    firmierung: 'Fielmann GmbH',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 779,
    pid: 91,
    number: '001-0718',
    title: 'Wien VI, Thaliastr.',
    description: '',
    informations: '',
    street: 'Thaliastr. 32',
    zip: '1160',
    citypart: '16. Bezirk',
    addition: '',
    fon: '01-40825920',
    fax: '01-408259229',
    commonName: '',
    xCoordinates: '48.2093468',
    yCoordinates: '16.3344179',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '5e906fcaf93a3e6fe983a948',
    appointmentTimifyType: true,
    city: 'Wien',
    country: 'Österreich',
    state: 'Wien',
    firmierung: 'Fielmann GmbH',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 781,
    pid: 91,
    number: '001-0721',
    title: 'Dornbirn',
    description: '',
    informations: '',
    street: 'Messestr. 2',
    zip: '6854',
    citypart: 'Schoren (6. Bezirk)',
    addition: '',
    fon: '05572-3862030',
    fax: '05572-38620329',
    commonName: 'Messepark',
    xCoordinates: '47.4126238',
    yCoordinates: '9.7113027',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '5e906fca8dd0d06fe809f4e5',
    appointmentTimifyType: true,
    city: 'Dornbirn',
    country: 'Österreich',
    state: 'Vorarlberg',
    firmierung: 'Fielmann GmbH',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 783,
    pid: 91,
    number: '001-0723',
    title: 'Klagenfurt',
    description: '',
    informations: '',
    street: 'St.-Veiter-Ring 20',
    zip: '9020',
    citypart: 'Zentrum',
    addition: '',
    fon: '0463-5142770',
    fax: '0463-51427729',
    commonName: 'City-Arkaden Klagenfurt',
    xCoordinates: '46.6279424',
    yCoordinates: '14.3094363',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '5e906fc80cf9fd6f4297694c',
    appointmentTimifyType: true,
    city: 'Klagenfurt',
    country: 'Österreich',
    state: 'Kärnten',
    firmierung: 'Fielmann GmbH',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 785,
    pid: 91,
    number: '001-0725',
    title: 'Wien VII, Shopping-Center Nord',
    description: '',
    informations: '',
    street: 'Ignaz-Köck-Straße 1 - 7',
    zip: '1210',
    citypart: 'Floridsdorf',
    addition: '',
    fon: '01-27113810',
    fax: '01-271138129',
    commonName: 'Shopping Center Nord (SCN)',
    xCoordinates: '48.2671278',
    yCoordinates: '16.3995382',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '5e906fc9f932686fddc1466c',
    appointmentTimifyType: true,
    city: 'Wien',
    country: 'Österreich',
    state: 'Wien',
    firmierung: 'Fielmann GmbH',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 787,
    pid: 91,
    number: '001-0727',
    title: 'Ried im Innkreis',
    description: '',
    informations: '',
    street: 'Hauptplatz 42',
    zip: '4910',
    citypart: 'Zentrum',
    addition: '',
    fon: '07752-267250',
    fax: '07752-2672529',
    commonName: '',
    xCoordinates: '48.2095091',
    yCoordinates: '13.4877017',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '5e906fc8ce5cd80ad2a0293d',
    appointmentTimifyType: true,
    city: 'Ried im Innkreis',
    country: 'Österreich',
    state: 'Oberösterreich',
    firmierung: 'Fielmann GmbH',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 789,
    pid: 91,
    number: '001-0729',
    title: 'Graz',
    description: '',
    informations: '',
    street: 'Herrengasse 9',
    zip: '8010',
    citypart: '',
    addition: '',
    fon: '0316-8306900',
    fax: '0316-83069029',
    commonName: '',
    xCoordinates: '47.0704513',
    yCoordinates: '15.4398713',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '5e906fc78dd0d06fe809f4a3',
    appointmentTimifyType: true,
    city: 'Graz',
    country: 'Österreich',
    state: 'Steiermark',
    firmierung: 'Fielmann GmbH',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 791,
    pid: 91,
    number: '001-0730',
    title: 'Wien IX, Auhof Center',
    description: '',
    informations: '',
    street: 'Albert Schweitzer Gasse 6',
    zip: '1140',
    citypart: '',
    addition: '',
    fon: '01-57718000',
    fax: '01-577180029',
    commonName: 'Auhof Center',
    xCoordinates: '48.2080507',
    yCoordinates: '16.2176642',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '5e906fca8dd0d06fe809f4e2',
    appointmentTimifyType: true,
    city: 'Wien',
    country: 'Österreich',
    state: 'Wien',
    firmierung: 'Fielmann GmbH',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 793,
    pid: 91,
    number: '001-0719',
    title: 'Amstetten',
    description: '',
    informations: '',
    street: 'Waidhofnerstrasse 1 - 2',
    zip: '3300',
    citypart: '',
    addition: '',
    fon: '07472-629370',
    fax: '07472-6293729',
    commonName: 'City Center',
    xCoordinates: '48.1211336',
    yCoordinates: '14.8686217',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '5e906ebff932686fddc14624',
    appointmentTimifyType: true,
    city: 'Amstetten',
    country: 'Österreich',
    state: 'Niederösterreich',
    firmierung: 'Fielmann GmbH',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 795,
    pid: 91,
    number: '001-0720',
    title: 'Baden',
    description: '',
    informations: '',
    street: 'Pfarrgasse 1',
    zip: '2500',
    citypart: '',
    addition: '',
    fon: '02252-2066740',
    fax: '02252-20667429',
    commonName: '',
    xCoordinates: '48.0087631',
    yCoordinates: '16.2341208',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '5e906fc7ce5cd80ad2a0291f',
    appointmentTimifyType: true,
    city: 'Baden',
    country: 'Österreich',
    state: 'Niederösterreich',
    firmierung: 'Fielmann GmbH',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 797,
    pid: 91,
    number: '001-0722',
    title: 'Wien V, Grinzinger Str.',
    description: '',
    informations: '',
    street: 'Grinzinger Str. 112',
    zip: '1190',
    citypart: 'Döbling',
    addition: '',
    fon: '01-31855780',
    fax: '01-318557829',
    commonName: 'Q19',
    xCoordinates: '48.2542669',
    yCoordinates: '16.3682234',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '5e906fc9f932686fddc14666',
    appointmentTimifyType: true,
    city: 'Wien',
    country: 'Österreich',
    state: 'Wien',
    firmierung: 'Fielmann GmbH',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 799,
    pid: 91,
    number: '001-0724',
    title: 'Kapfenberg',
    description: '',
    informations: '',
    street: 'Wiener Straße 35 a',
    zip: '8605',
    citypart: 'Zentrum',
    addition: '',
    fon: '03862-216910',
    fax: '03862-2169129',
    commonName: 'ece Kapfenberg',
    xCoordinates: '47.4441807',
    yCoordinates: '15.291056',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '5e906fc7f93a3e6fe983a908',
    appointmentTimifyType: true,
    city: 'Kapfenberg',
    country: 'Österreich',
    state: 'Steiermark',
    firmierung: 'Fielmann GmbH',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 801,
    pid: 91,
    number: '001-0726',
    title: 'Bürs',
    description: '',
    informations: '',
    street: 'Almteilweg 1',
    zip: '6706',
    citypart: '',
    addition: '',
    fon: '05552-310920',
    fax: '05552-3109229',
    commonName: 'Zimbapark',
    xCoordinates: '47.1506219',
    yCoordinates: '9.8124415',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '5e906fc7ce5cd80ad2a0291b',
    appointmentTimifyType: true,
    city: 'Bürs',
    country: 'Österreich',
    state: 'Vorarlberg',
    firmierung: 'Fielmann GmbH',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 803,
    pid: 91,
    number: '001-0728',
    title: 'Wien VIII, Meidling. Hauptstr.',
    description: '',
    informations: '',
    street: 'Meidlinger Hauptstraße 38',
    zip: '1120',
    citypart: '',
    addition: '',
    fon: '01-81321200',
    fax: '01-813212029',
    commonName: '',
    xCoordinates: '48.179114',
    yCoordinates: '16.3297125',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '5e906fc8ce5cd80ad2a02940',
    appointmentTimifyType: true,
    city: 'Wien',
    country: 'Österreich',
    state: 'Wien',
    firmierung: 'Fielmann GmbH',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 805,
    pid: 91,
    number: '001-0731',
    title: 'Linz I, Landstraße',
    description: '',
    informations: '',
    street: 'Landstraße 54 - 56',
    zip: '4020',
    citypart: '',
    addition: '',
    fon: '0732-7755510',
    fax: '0732-77555129',
    commonName: '',
    xCoordinates: '48.2998614',
    yCoordinates: '14.2909513',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '5e906fca0cf9fd6f4297697d',
    appointmentTimifyType: true,
    city: 'Linz',
    country: 'Österreich',
    state: 'Oberösterreich',
    firmierung: 'Fielmann GmbH',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 807,
    pid: 91,
    number: '001-0733',
    title: 'Linz II, Lentia City',
    description: '',
    informations: '',
    street: 'Blütenstraße 13 - 23',
    zip: '4040',
    citypart: '',
    addition: '',
    fon: '0732-7102490',
    fax: '0732-71024929',
    commonName: 'Lentia City',
    xCoordinates: '48.3141792',
    yCoordinates: '14.2831776',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '5e906fc8ce5cd80ad2a02942',
    appointmentTimifyType: true,
    city: 'Linz',
    country: 'Österreich',
    state: 'Oberösterreich',
    firmierung: 'Fielmann GmbH',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 809,
    pid: 91,
    number: '001-0735',
    title: 'Mödling',
    description: '',
    informations: '',
    street: 'Schrannenplatz 6',
    zip: '2340',
    citypart: '',
    addition: '',
    fon: '02236-9081720',
    fax: '02236-90817229',
    commonName: '',
    xCoordinates: '48.0852599',
    yCoordinates: '16.2833833',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '5e906fc9f932686fddc1466b',
    appointmentTimifyType: true,
    city: 'Mödling',
    country: 'Österreich',
    state: 'Niederösterreich',
    firmierung: 'Fielmann GmbH',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 811,
    pid: 91,
    number: '001-0732',
    title: 'Vöcklabruck Varena',
    description: '',
    informations: '',
    street: 'Linzer Straße 50',
    zip: '4840',
    citypart: '',
    addition: '',
    fon: '07672-205110',
    fax: '07672-2051115',
    commonName: 'VARENA',
    xCoordinates: '48.0029247',
    yCoordinates: '13.6684418',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '5e906fc9f93a3e6fe983a928',
    appointmentTimifyType: true,
    city: 'Vöcklabruck',
    country: 'Österreich',
    state: 'Oberösterreich',
    firmierung: 'Fielmann GmbH',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 813,
    pid: 91,
    number: '001-0734',
    title: 'Wörgl',
    description: '',
    informations: '',
    street: 'Bahnhofstraße 33',
    zip: '6300',
    citypart: '',
    addition: '',
    fon: '05332-901410',
    fax: '05332-9014129',
    commonName: '',
    xCoordinates: '47.4890166',
    yCoordinates: '12.0633272',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '5e906fcaf93a3e6fe983a94a',
    appointmentTimifyType: true,
    city: 'Wörgl',
    country: 'Österreich',
    state: 'Tirol',
    firmierung: 'Fielmann GmbH',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 815,
    pid: 91,
    number: '001-0736',
    title: 'Innsbruck II, SC Sillpark',
    description: '',
    informations: '',
    street: 'Museumstraße 38',
    zip: '6020',
    citypart: '',
    addition: '',
    fon: '0512-9129190',
    fax: '0512-91291929',
    commonName: 'Sillpark',
    xCoordinates: '47.2659556',
    yCoordinates: '11.4041621',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '5e906fc7f932686fddc14639',
    appointmentTimifyType: true,
    city: 'Innsbruck',
    country: 'Österreich',
    state: 'Tirol',
    firmierung: 'Fielmann GmbH',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 817,
    pid: 91,
    number: '001-0781',
    title: 'Szczecin',
    description:
      'Salon Fielmann w Szczecinie, Al. Wojska Polskiego 15 prezentuje ponad 2000 modnych okularów, znane marki i modele projektantów o międzynarodowej sławie. W korzystnej cenie, którą gwarantujemy. Udzielamy na to gwarancji zwrotu pieniędzy:\r\nOprócz okularów jednoogniskowych i progresywnych, firma Fielmann oferuje w tym salonie również duży wybór okularów przeciwsłonecznych, soczewek kontaktowych i środków do ich pielęgnacji.\r\nZapraszamy do skorzystania z porady naszych kompetentnych optyków i skorzystania z bezpłatnego badania wzroku. W każdej chwili.\r\nFielmann prowadzi w Europie ponad 700 salonów optycznych, również w Państwa okolicy.',
    informations: '',
    street: 'Al. Wojska Polskiego 15',
    zip: '70-470',
    citypart: '',
    addition: '',
    fon: '091-4320752',
    fax: '091-4320753',
    commonName: '',
    xCoordinates: '53.4275079',
    yCoordinates: '14.545421',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'baa9ca87-545c-4ceb-81bc-b2f6ebeaf89e-5d668f06552ce50f71e2855b',
    appointmentTimifyType: false,
    city: 'Szczecin',
    country: 'Polen',
    state: 'Zachodniopomorskie',
    firmierung: 'Fielmann sp. z o.o.',
    importantMessageHeader: 'Nasze standardy higieny',
    importantMessageText:
      'Drogie Klientki i drodzy Klienci! <br /><br />We współpracy z Prof. Dr. Exner (Klinika Uniwersytecka w Bonn) firma Fielmann opracowała standardy higieny w zakresie optyki okularowej i akustyki słuchu, aby chronić Państwa i swoich pracowników przed zakażeniem koronawirusem. W trybie natychmiastowym Fielmann wprowadza we wszystkich salonach procedury o potwierdzonym naukowo działaniu, aby mogli się Państwo czuć bezpiecznie.<br /><br />W ramach standardów higieny do naszych salonów jednocześnie może wejść tylko ograniczona liczba klientów. Termin wizyty mogą Państwo umówić wcześniej online, telefonicznie lub bezpośrednio w salonie.<br /><br />Przed Państwa wizytą prosimy pamiętać o zachowaniu standardów higieny Fielmann. Tymczasowo rezygnujemy z dopasowania soczewek kontaktowych i wizyt kontrolnych. W miarę możliwości przy okazji Państwa kolejnej wizyty w jednym z naszych salonów prosimy o zabranie ze sobą ochrony ochrony ust i nosa. Jeżeli mają Państwo objawy przeziębienia, uprzejmie prosimy o przeniesienie wizyty w salonie na inny termin.<br /><br />Nasz dział obsługi klienta jest do Państwa dyspozycji pod bezpłatnym numerem telefonu 800 343562.<br /><br />Dziękujemy za zrozumienie. Prosimy o siebie dbać!',
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Nasze standardy higieny',
      text:
        'Drogie Klientki i drodzy Klienci! <br /><br />We współpracy z Prof. Dr. Exner (Klinika Uniwersytecka w Bonn) firma Fielmann opracowała standardy higieny w zakresie optyki okularowej i akustyki słuchu, aby chronić Państwa i swoich pracowników przed zakażeniem koronawirusem. W trybie natychmiastowym Fielmann wprowadza we wszystkich salonach procedury o potwierdzonym naukowo działaniu, aby mogli się Państwo czuć bezpiecznie.<br /><br />W ramach standardów higieny do naszych salonów jednocześnie może wejść tylko ograniczona liczba klientów. Termin wizyty mogą Państwo umówić wcześniej online, telefonicznie lub bezpośrednio w salonie.<br /><br />Przed Państwa wizytą prosimy pamiętać o zachowaniu standardów higieny Fielmann. Tymczasowo rezygnujemy z dopasowania soczewek kontaktowych i wizyt kontrolnych. W miarę możliwości przy okazji Państwa kolejnej wizyty w jednym z naszych salonów prosimy o zabranie ze sobą ochrony ochrony ust i nosa. Jeżeli mają Państwo objawy przeziębienia, uprzejmie prosimy o przeniesienie wizyty w salonie na inny termin.<br /><br />Nasz dział obsługi klienta jest do Państwa dyspozycji pod bezpłatnym numerem telefonu 800 343562.<br /><br />Dziękujemy za zrozumienie. Prosimy o siebie dbać!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 819,
    pid: 91,
    number: '001-0783',
    title: 'Wroclaw',
    description:
      'Salon Fielmann we Wrocławiu, Galeria Dominikańska prezentuje ponad 2000 modnych okularów, znane marki i modele projektantów o międzynarodowej sławie. W korzystnej cenie, którą gwarantujemy. Udzielamy na to gwarancji zwrotu pieniędzy:\r\nOprócz okularów jednoogniskowych i progresywnych, firma Fielmann oferuje w tym salonie również duży wybór okularów przeciwsłonecznych, soczewek kontaktowych i środków do ich pielęgnacji.\r\nZapraszamy do skorzystania z porady naszych kompetentnych optyków i skorzystania z bezpłatnego badania wzroku. W każdej chwili.\r\nFielmann prowadzi w Europie ponad 700 salonów optycznych, również w Państwa okolicy.',
    informations: '',
    street: 'Pl. Dominikanski 3',
    zip: '50-159',
    citypart: '',
    addition: '',
    fon: '071-3468155',
    fax: '071-3468156',
    commonName: 'Galeria Dominikanska',
    xCoordinates: '51.1083911',
    yCoordinates: '17.0411659',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'baa9ca87-545c-4ceb-81bc-b2f6ebeaf89e-5d66903d2d27f90f73495f97',
    appointmentTimifyType: false,
    city: 'Wroclaw',
    country: 'Polen',
    state: 'Dolnoslaskie',
    firmierung: 'Fielmann sp. z o.o.',
    importantMessageHeader: 'Nasze standardy higieny',
    importantMessageText:
      'Drogie Klientki i drodzy Klienci! <br /><br />We współpracy z Prof. Dr. Exner (Klinika Uniwersytecka w Bonn) firma Fielmann opracowała standardy higieny w zakresie optyki okularowej i akustyki słuchu, aby chronić Państwa i swoich pracowników przed zakażeniem koronawirusem. W trybie natychmiastowym Fielmann wprowadza we wszystkich salonach procedury o potwierdzonym naukowo działaniu, aby mogli się Państwo czuć bezpiecznie.<br /><br />W ramach standardów higieny do naszych salonów jednocześnie może wejść tylko ograniczona liczba klientów. Termin wizyty mogą Państwo umówić wcześniej online, telefonicznie lub bezpośrednio w salonie.<br /><br />Przed Państwa wizytą prosimy pamiętać o zachowaniu standardów higieny Fielmann. Tymczasowo rezygnujemy z dopasowania soczewek kontaktowych i wizyt kontrolnych. W miarę możliwości przy okazji Państwa kolejnej wizyty w jednym z naszych salonów prosimy o zabranie ze sobą ochrony ochrony ust i nosa. Jeżeli mają Państwo objawy przeziębienia, uprzejmie prosimy o przeniesienie wizyty w salonie na inny termin.<br /><br />Nasz dział obsługi klienta jest do Państwa dyspozycji pod bezpłatnym numerem telefonu 800 343562.<br /><br />Dziękujemy za zrozumienie. Prosimy o siebie dbać!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Nasze standardy higieny',
      text:
        'Drogie Klientki i drodzy Klienci! <br /><br />We współpracy z Prof. Dr. Exner (Klinika Uniwersytecka w Bonn) firma Fielmann opracowała standardy higieny w zakresie optyki okularowej i akustyki słuchu, aby chronić Państwa i swoich pracowników przed zakażeniem koronawirusem. W trybie natychmiastowym Fielmann wprowadza we wszystkich salonach procedury o potwierdzonym naukowo działaniu, aby mogli się Państwo czuć bezpiecznie.<br /><br />W ramach standardów higieny do naszych salonów jednocześnie może wejść tylko ograniczona liczba klientów. Termin wizyty mogą Państwo umówić wcześniej online, telefonicznie lub bezpośrednio w salonie.<br /><br />Przed Państwa wizytą prosimy pamiętać o zachowaniu standardów higieny Fielmann. Tymczasowo rezygnujemy z dopasowania soczewek kontaktowych i wizyt kontrolnych. W miarę możliwości przy okazji Państwa kolejnej wizyty w jednym z naszych salonów prosimy o zabranie ze sobą ochrony ochrony ust i nosa. Jeżeli mają Państwo objawy przeziębienia, uprzejmie prosimy o przeniesienie wizyty w salonie na inny termin.<br /><br />Nasz dział obsługi klienta jest do Państwa dyspozycji pod bezpłatnym numerem telefonu 800 343562.<br /><br />Dziękujemy za zrozumienie. Prosimy o siebie dbać!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 821,
    pid: 91,
    number: '001-0785',
    title: 'Lódz II, Galeria',
    description:
      'Salon Fielmann w Łodzi, Galeria Łódzka prezentuje ponad 2000 modnych okularów, znane marki i modele projektantów o międzynarodowej sławie. W korzystnej cenie, którą gwarantujemy. Udzielamy na to gwarancji zwrotu pieniędzy:\r\nOprócz okularów jednoogniskowych i progresywnych, firma Fielmann oferuje w tym salonie również duży wybór okularów przeciwsłonecznych, soczewek kontaktowych i środków do ich pielęgnacji.\r\nZapraszamy do skorzystania z porady naszych kompetentnych optyków i skorzystania z bezpłatnego badania wzroku. W każdej chwili.\r\nFielmann prowadzi w Europie ponad 700 salonów optycznych, również w Państwa okolicy.',
    informations: '',
    street: 'Al. Józefa Pilsudskiego 15/23',
    zip: '90-307',
    citypart: '',
    addition: '',
    fon: '042-6331232',
    fax: '042-6331696',
    commonName: 'Galeria Lodzka',
    xCoordinates: '51.7593361',
    yCoordinates: '19.4653541',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'baa9ca87-545c-4ceb-81bc-b2f6ebeaf89e-5da6f5b084f7ff0f7d836f4e',
    appointmentTimifyType: false,
    city: 'Lódz',
    country: 'Polen',
    state: 'Lódzkie',
    firmierung: 'Fielmann sp. z o.o.',
    importantMessageHeader: 'Nasze standardy higieny',
    importantMessageText:
      'Drogie Klientki i drodzy Klienci! <br /><br />We współpracy z Prof. Dr. Exner (Klinika Uniwersytecka w Bonn) firma Fielmann opracowała standardy higieny w zakresie optyki okularowej i akustyki słuchu, aby chronić Państwa i swoich pracowników przed zakażeniem koronawirusem. W trybie natychmiastowym Fielmann wprowadza we wszystkich salonach procedury o potwierdzonym naukowo działaniu, aby mogli się Państwo czuć bezpiecznie.<br /><br />W ramach standardów higieny do naszych salonów jednocześnie może wejść tylko ograniczona liczba klientów. Termin wizyty mogą Państwo umówić wcześniej online, telefonicznie lub bezpośrednio w salonie.<br /><br />Przed Państwa wizytą prosimy pamiętać o zachowaniu standardów higieny Fielmann. Tymczasowo rezygnujemy z dopasowania soczewek kontaktowych i wizyt kontrolnych. W miarę możliwości przy okazji Państwa kolejnej wizyty w jednym z naszych salonów prosimy o zabranie ze sobą ochrony ochrony ust i nosa. Jeżeli mają Państwo objawy przeziębienia, uprzejmie prosimy o przeniesienie wizyty w salonie na inny termin.<br /><br />Nasz dział obsługi klienta jest do Państwa dyspozycji pod bezpłatnym numerem telefonu 800 343562.<br /><br />Dziękujemy za zrozumienie. Prosimy o siebie dbać!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Nasze standardy higieny',
      text:
        'Drogie Klientki i drodzy Klienci! <br /><br />We współpracy z Prof. Dr. Exner (Klinika Uniwersytecka w Bonn) firma Fielmann opracowała standardy higieny w zakresie optyki okularowej i akustyki słuchu, aby chronić Państwa i swoich pracowników przed zakażeniem koronawirusem. W trybie natychmiastowym Fielmann wprowadza we wszystkich salonach procedury o potwierdzonym naukowo działaniu, aby mogli się Państwo czuć bezpiecznie.<br /><br />W ramach standardów higieny do naszych salonów jednocześnie może wejść tylko ograniczona liczba klientów. Termin wizyty mogą Państwo umówić wcześniej online, telefonicznie lub bezpośrednio w salonie.<br /><br />Przed Państwa wizytą prosimy pamiętać o zachowaniu standardów higieny Fielmann. Tymczasowo rezygnujemy z dopasowania soczewek kontaktowych i wizyt kontrolnych. W miarę możliwości przy okazji Państwa kolejnej wizyty w jednym z naszych salonów prosimy o zabranie ze sobą ochrony ochrony ust i nosa. Jeżeli mają Państwo objawy przeziębienia, uprzejmie prosimy o przeniesienie wizyty w salonie na inny termin.<br /><br />Nasz dział obsługi klienta jest do Państwa dyspozycji pod bezpłatnym numerem telefonu 800 343562.<br /><br />Dziękujemy za zrozumienie. Prosimy o siebie dbać!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 823,
    pid: 91,
    number: '001-0787',
    title: 'Gdansk',
    description:
      'Salon Fielmann w Gdańsku, Galeria Bałtycka prezentuje ponad 2000 modnych okularów, znane marki i modele projektantów o międzynarodowej sławie. W korzystnej cenie, którą gwarantujemy. Udzielamy na to gwarancji zwrotu pieniędzy:\r\nOprócz okularów jednoogniskowych i progresywnych, firma Fielmann oferuje w tym salonie również duży wybór okularów przeciwsłonecznych, soczewek kontaktowych i środków do ich pielęgnacji.\r\nZapraszamy do skorzystania z porady naszych kompetentnych optyków i skorzystania z bezpłatnego badania wzroku. W każdej chwili.\r\nFielmann prowadzi w Europie ponad 700 salonów optycznych, również w Państwa okolicy.',
    informations: '',
    street: 'Al. Grunwaldzka 141',
    zip: '80-264',
    citypart: 'Wrzeszcz',
    addition: '',
    fon: '058-5247750',
    fax: '058-5247751',
    commonName: 'Galeria Baltycka',
    xCoordinates: '54.3822017',
    yCoordinates: '18.6001883',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'baa9ca87-545c-4ceb-81bc-b2f6ebeaf89e-5da6f5b08fda780f740701c3',
    appointmentTimifyType: false,
    city: 'Gdansk',
    country: 'Polen',
    state: 'Pomorskie',
    firmierung: 'Fielmann sp. z o.o.',
    importantMessageHeader: 'Nasze standardy higieny',
    importantMessageText:
      'Drogie Klientki i drodzy Klienci! <br /><br />We współpracy z Prof. Dr. Exner (Klinika Uniwersytecka w Bonn) firma Fielmann opracowała standardy higieny w zakresie optyki okularowej i akustyki słuchu, aby chronić Państwa i swoich pracowników przed zakażeniem koronawirusem. W trybie natychmiastowym Fielmann wprowadza we wszystkich salonach procedury o potwierdzonym naukowo działaniu, aby mogli się Państwo czuć bezpiecznie.<br /><br />W ramach standardów higieny do naszych salonów jednocześnie może wejść tylko ograniczona liczba klientów. Termin wizyty mogą Państwo umówić wcześniej online, telefonicznie lub bezpośrednio w salonie.<br /><br />Przed Państwa wizytą prosimy pamiętać o zachowaniu standardów higieny Fielmann. Tymczasowo rezygnujemy z dopasowania soczewek kontaktowych i wizyt kontrolnych. W miarę możliwości przy okazji Państwa kolejnej wizyty w jednym z naszych salonów prosimy o zabranie ze sobą ochrony ochrony ust i nosa. Jeżeli mają Państwo objawy przeziębienia, uprzejmie prosimy o przeniesienie wizyty w salonie na inny termin.<br /><br />Nasz dział obsługi klienta jest do Państwa dyspozycji pod bezpłatnym numerem telefonu 800 343562.<br /><br />Dziękujemy za zrozumienie. Prosimy o siebie dbać!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Nasze standardy higieny',
      text:
        'Drogie Klientki i drodzy Klienci! <br /><br />We współpracy z Prof. Dr. Exner (Klinika Uniwersytecka w Bonn) firma Fielmann opracowała standardy higieny w zakresie optyki okularowej i akustyki słuchu, aby chronić Państwa i swoich pracowników przed zakażeniem koronawirusem. W trybie natychmiastowym Fielmann wprowadza we wszystkich salonach procedury o potwierdzonym naukowo działaniu, aby mogli się Państwo czuć bezpiecznie.<br /><br />W ramach standardów higieny do naszych salonów jednocześnie może wejść tylko ograniczona liczba klientów. Termin wizyty mogą Państwo umówić wcześniej online, telefonicznie lub bezpośrednio w salonie.<br /><br />Przed Państwa wizytą prosimy pamiętać o zachowaniu standardów higieny Fielmann. Tymczasowo rezygnujemy z dopasowania soczewek kontaktowych i wizyt kontrolnych. W miarę możliwości przy okazji Państwa kolejnej wizyty w jednym z naszych salonów prosimy o zabranie ze sobą ochrony ochrony ust i nosa. Jeżeli mają Państwo objawy przeziębienia, uprzejmie prosimy o przeniesienie wizyty w salonie na inny termin.<br /><br />Nasz dział obsługi klienta jest do Państwa dyspozycji pod bezpłatnym numerem telefonu 800 343562.<br /><br />Dziękujemy za zrozumienie. Prosimy o siebie dbać!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 825,
    pid: 91,
    number: '001-0789',
    title: 'Gdynia',
    description:
      'Salon Fielmann w Gdyni, Centrum Handlowe RIVIERA prezentuje ponad 2000 modnych okularów, znane marki i modele projektantów o międzynarodowej sławie. W korzystnej cenie, którą gwarantujemy. Udzielamy na to gwarancji zwrotu pieniędzy:\r\nOprócz okularów jednoogniskowych i progresywnych, firma Fielmann oferuje w tym salonie również duży wybór okularów przeciwsłonecznych, soczewek kontaktowych i środków do ich pielęgnacji.\r\nZapraszamy do skorzystania z porady naszych kompetentnych optyków i skorzystania z bezpłatnego badania wzroku. W każdej chwili.\r\nFielmann prowadzi w Europie ponad 700 salonów optycznych, również w Państwa okolicy.',
    informations: '',
    street: 'ul. Kazimierza Górskiego 2',
    zip: '81-304',
    citypart: '',
    addition: '',
    fon: '058-7714790',
    fax: '058-7714791',
    commonName: 'Centrum Handlowe RIVIERA',
    xCoordinates: '54.5046621',
    yCoordinates: '18.5317862',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'baa9ca87-545c-4ceb-81bc-b2f6ebeaf89e-5da6f5b08fda780f740701c7',
    appointmentTimifyType: false,
    city: 'Gdynia',
    country: 'Polen',
    state: 'Pomorskie',
    firmierung: 'Fielmann sp.z.o.o.',
    importantMessageHeader: 'Nasze standardy higieny',
    importantMessageText:
      'Drogie Klientki i drodzy Klienci! <br /><br />We współpracy z Prof. Dr. Exner (Klinika Uniwersytecka w Bonn) firma Fielmann opracowała standardy higieny w zakresie optyki okularowej i akustyki słuchu, aby chronić Państwa i swoich pracowników przed zakażeniem koronawirusem. W trybie natychmiastowym Fielmann wprowadza we wszystkich salonach procedury o potwierdzonym naukowo działaniu, aby mogli się Państwo czuć bezpiecznie.<br /><br />W ramach standardów higieny do naszych salonów jednocześnie może wejść tylko ograniczona liczba klientów. Termin wizyty mogą Państwo umówić wcześniej online, telefonicznie lub bezpośrednio w salonie.<br /><br />Przed Państwa wizytą prosimy pamiętać o zachowaniu standardów higieny Fielmann. Tymczasowo rezygnujemy z dopasowania soczewek kontaktowych i wizyt kontrolnych. W miarę możliwości przy okazji Państwa kolejnej wizyty w jednym z naszych salonów prosimy o zabranie ze sobą ochrony ochrony ust i nosa. Jeżeli mają Państwo objawy przeziębienia, uprzejmie prosimy o przeniesienie wizyty w salonie na inny termin.<br /><br />Nasz dział obsługi klienta jest do Państwa dyspozycji pod bezpłatnym numerem telefonu 800 343562.<br /><br />Dziękujemy za zrozumienie. Prosimy o siebie dbać!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Nasze standardy higieny',
      text:
        'Drogie Klientki i drodzy Klienci! <br /><br />We współpracy z Prof. Dr. Exner (Klinika Uniwersytecka w Bonn) firma Fielmann opracowała standardy higieny w zakresie optyki okularowej i akustyki słuchu, aby chronić Państwa i swoich pracowników przed zakażeniem koronawirusem. W trybie natychmiastowym Fielmann wprowadza we wszystkich salonach procedury o potwierdzonym naukowo działaniu, aby mogli się Państwo czuć bezpiecznie.<br /><br />W ramach standardów higieny do naszych salonów jednocześnie może wejść tylko ograniczona liczba klientów. Termin wizyty mogą Państwo umówić wcześniej online, telefonicznie lub bezpośrednio w salonie.<br /><br />Przed Państwa wizytą prosimy pamiętać o zachowaniu standardów higieny Fielmann. Tymczasowo rezygnujemy z dopasowania soczewek kontaktowych i wizyt kontrolnych. W miarę możliwości przy okazji Państwa kolejnej wizyty w jednym z naszych salonów prosimy o zabranie ze sobą ochrony ochrony ust i nosa. Jeżeli mają Państwo objawy przeziębienia, uprzejmie prosimy o przeniesienie wizyty w salonie na inny termin.<br /><br />Nasz dział obsługi klienta jest do Państwa dyspozycji pod bezpłatnym numerem telefonu 800 343562.<br /><br />Dziękujemy za zrozumienie. Prosimy o siebie dbać!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 827,
    pid: 91,
    number: '001-0790',
    title: 'Poznan II, Galeria Pestka',
    description:
      'Salon Fielmann w Poznaniu, Galeria Pestka prezentuje ponad 2000 modnych okularów, znane marki i modele projektantów o międzynarodowej sławie. W korzystnej cenie, którą gwarantujemy. Udzielamy na to gwarancji zwrotu pieniędzy:\r\nOprócz okularów jednoogniskowych i progresywnych, firma Fielmann oferuje w tym salonie również duży wybór okularów przeciwsłonecznych, soczewek kontaktowych i środków do ich pielęgnacji.\r\nZapraszamy do skorzystania z porady naszych kompetentnych optyków i skorzystania z bezpłatnego badania wzroku. W każdej chwili.\r\nFielmann prowadzi w Europie ponad 700 salonów optycznych, również w Państwa okolicy.',
    informations: '',
    street: 'Al.Solidarności 47',
    zip: '61-696',
    citypart: 'Winiary',
    addition: '',
    fon: '061-6560590',
    fax: '061-6560591',
    commonName: 'Galeria Pestka',
    xCoordinates: '52.4375439',
    yCoordinates: '16.9182757',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'baa9ca87-545c-4ceb-81bc-b2f6ebeaf89e-5da6f5b08fda780f740701bb',
    appointmentTimifyType: false,
    city: 'Poznan',
    country: 'Polen',
    state: 'Wielkopolskie',
    firmierung: 'Fielmann sp. z o.o.',
    importantMessageHeader: 'Nasze standardy higieny',
    importantMessageText:
      'Drogie Klientki i drodzy Klienci! <br /><br />We współpracy z Prof. Dr. Exner (Klinika Uniwersytecka w Bonn) firma Fielmann opracowała standardy higieny w zakresie optyki okularowej i akustyki słuchu, aby chronić Państwa i swoich pracowników przed zakażeniem koronawirusem. W trybie natychmiastowym Fielmann wprowadza we wszystkich salonach procedury o potwierdzonym naukowo działaniu, aby mogli się Państwo czuć bezpiecznie.<br /><br />W ramach standardów higieny do naszych salonów jednocześnie może wejść tylko ograniczona liczba klientów. Termin wizyty mogą Państwo umówić wcześniej online, telefonicznie lub bezpośrednio w salonie.<br /><br />Przed Państwa wizytą prosimy pamiętać o zachowaniu standardów higieny Fielmann. Tymczasowo rezygnujemy z dopasowania soczewek kontaktowych i wizyt kontrolnych. W miarę możliwości przy okazji Państwa kolejnej wizyty w jednym z naszych salonów prosimy o zabranie ze sobą ochrony ochrony ust i nosa. Jeżeli mają Państwo objawy przeziębienia, uprzejmie prosimy o przeniesienie wizyty w salonie na inny termin.<br /><br />Nasz dział obsługi klienta jest do Państwa dyspozycji pod bezpłatnym numerem telefonu 800 343562.<br /><br />Dziękujemy za zrozumienie. Prosimy o siebie dbać!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Nasze standardy higieny',
      text:
        'Drogie Klientki i drodzy Klienci! <br /><br />We współpracy z Prof. Dr. Exner (Klinika Uniwersytecka w Bonn) firma Fielmann opracowała standardy higieny w zakresie optyki okularowej i akustyki słuchu, aby chronić Państwa i swoich pracowników przed zakażeniem koronawirusem. W trybie natychmiastowym Fielmann wprowadza we wszystkich salonach procedury o potwierdzonym naukowo działaniu, aby mogli się Państwo czuć bezpiecznie.<br /><br />W ramach standardów higieny do naszych salonów jednocześnie może wejść tylko ograniczona liczba klientów. Termin wizyty mogą Państwo umówić wcześniej online, telefonicznie lub bezpośrednio w salonie.<br /><br />Przed Państwa wizytą prosimy pamiętać o zachowaniu standardów higieny Fielmann. Tymczasowo rezygnujemy z dopasowania soczewek kontaktowych i wizyt kontrolnych. W miarę możliwości przy okazji Państwa kolejnej wizyty w jednym z naszych salonów prosimy o zabranie ze sobą ochrony ochrony ust i nosa. Jeżeli mają Państwo objawy przeziębienia, uprzejmie prosimy o przeniesienie wizyty w salonie na inny termin.<br /><br />Nasz dział obsługi klienta jest do Państwa dyspozycji pod bezpłatnym numerem telefonu 800 343562.<br /><br />Dziękujemy za zrozumienie. Prosimy o siebie dbać!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 829,
    pid: 91,
    number: '001-0779',
    title: 'Radom',
    description:
      'Salon Fielmann w Radomiu, Galeria Słoneczna prezentuje ponad 2000 modnych okularów, znane marki i modele projektantów o międzynarodowej sławie. W korzystnej cenie, którą gwarantujemy. Udzielamy na to gwarancji zwrotu pieniędzy:\r\nOprócz okularów jednoogniskowych i progresywnych, firma Fielmann oferuje w tym salonie również duży wybór okularów przeciwsłonecznych, soczewek kontaktowych i środków do ich pielęgnacji.\r\nZapraszamy do skorzystania z porady naszych kompetentnych optyków i skorzystania z bezpłatnego badania wzroku. W każdej chwili.\r\nFielmann prowadzi w Europie ponad 700 salonów optycznych, również w Państwa okolicy.',
    informations: '',
    street: 'ul. Bolesława Chrobrego 1',
    zip: '26-609',
    citypart: '',
    addition: '',
    fon: '048-3822300',
    fax: '048-3822301',
    commonName: 'Galeria Słoneczna',
    xCoordinates: '51.4062469',
    yCoordinates: '21.1552134',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'baa9ca87-545c-4ceb-81bc-b2f6ebeaf89e-5da704b684f7ff0f7d837323',
    appointmentTimifyType: false,
    city: 'Radom',
    country: 'Polen',
    state: 'Mazowieckie',
    firmierung: 'Fielmann sp. z o.o.',
    importantMessageHeader: 'Nasze standardy higieny',
    importantMessageText:
      'Drogie Klientki i drodzy Klienci! <br /><br />We współpracy z Prof. Dr. Exner (Klinika Uniwersytecka w Bonn) firma Fielmann opracowała standardy higieny w zakresie optyki okularowej i akustyki słuchu, aby chronić Państwa i swoich pracowników przed zakażeniem koronawirusem. W trybie natychmiastowym Fielmann wprowadza we wszystkich salonach procedury o potwierdzonym naukowo działaniu, aby mogli się Państwo czuć bezpiecznie.<br /><br />W ramach standardów higieny do naszych salonów jednocześnie może wejść tylko ograniczona liczba klientów. Termin wizyty mogą Państwo umówić wcześniej online, telefonicznie lub bezpośrednio w salonie.<br /><br />Przed Państwa wizytą prosimy pamiętać o zachowaniu standardów higieny Fielmann. Tymczasowo rezygnujemy z dopasowania soczewek kontaktowych i wizyt kontrolnych. W miarę możliwości przy okazji Państwa kolejnej wizyty w jednym z naszych salonów prosimy o zabranie ze sobą ochrony ochrony ust i nosa. Jeżeli mają Państwo objawy przeziębienia, uprzejmie prosimy o przeniesienie wizyty w salonie na inny termin.<br /><br />Nasz dział obsługi klienta jest do Państwa dyspozycji pod bezpłatnym numerem telefonu 800 343562.<br /><br />Dziękujemy za zrozumienie. Prosimy o siebie dbać!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Nasze standardy higieny',
      text:
        'Drogie Klientki i drodzy Klienci! <br /><br />We współpracy z Prof. Dr. Exner (Klinika Uniwersytecka w Bonn) firma Fielmann opracowała standardy higieny w zakresie optyki okularowej i akustyki słuchu, aby chronić Państwa i swoich pracowników przed zakażeniem koronawirusem. W trybie natychmiastowym Fielmann wprowadza we wszystkich salonach procedury o potwierdzonym naukowo działaniu, aby mogli się Państwo czuć bezpiecznie.<br /><br />W ramach standardów higieny do naszych salonów jednocześnie może wejść tylko ograniczona liczba klientów. Termin wizyty mogą Państwo umówić wcześniej online, telefonicznie lub bezpośrednio w salonie.<br /><br />Przed Państwa wizytą prosimy pamiętać o zachowaniu standardów higieny Fielmann. Tymczasowo rezygnujemy z dopasowania soczewek kontaktowych i wizyt kontrolnych. W miarę możliwości przy okazji Państwa kolejnej wizyty w jednym z naszych salonów prosimy o zabranie ze sobą ochrony ochrony ust i nosa. Jeżeli mają Państwo objawy przeziębienia, uprzejmie prosimy o przeniesienie wizyty w salonie na inny termin.<br /><br />Nasz dział obsługi klienta jest do Państwa dyspozycji pod bezpłatnym numerem telefonu 800 343562.<br /><br />Dziękujemy za zrozumienie. Prosimy o siebie dbać!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 831,
    pid: 91,
    number: '001-0780',
    title: 'Poznan I, ul. Sw. Marcin',
    description:
      'Salon Fielmann w Poznaniu, ul. Św. Marcin 69 prezentuje ponad 2000 modnych okularów, znane marki i modele projektantów o międzynarodowej sławie. W korzystnej cenie, którą gwarantujemy. Udzielamy na to gwarancji zwrotu pieniędzy:\r\nOprócz okularów jednoogniskowych i progresywnych, firma Fielmann oferuje w tym salonie również duży wybór okularów przeciwsłonecznych, soczewek kontaktowych i środków do ich pielęgnacji.\r\nZapraszamy do skorzystania z porady naszych kompetentnych optyków i skorzystania z bezpłatnego badania wzroku. W każdej chwili.\r\nFielmann prowadzi w Europie ponad 700 salonów optycznych, również w Państwa okolicy.',
    informations: '',
    street: 'ul. Sw. Marcin 69',
    zip: '61-808',
    citypart: '',
    addition: '',
    fon: '061-8560320',
    fax: '061-8560321',
    commonName: '',
    xCoordinates: '52.4067428',
    yCoordinates: '16.9208408',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '4104',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '4104',
    appointmentSwitchoff: false,
    appointmentTimify:
      'baa9ca87-545c-4ceb-81bc-b2f6ebeaf89e-5da6f5b00d05380f7792b398',
    appointmentTimifyType: false,
    city: 'Poznan',
    country: 'Polen',
    state: 'Wielkopolskie',
    firmierung: 'Fielmann sp. z o.o.',
    importantMessageHeader: 'Nasze standardy higieny',
    importantMessageText:
      'Drogie Klientki i drodzy Klienci! <br /><br />We współpracy z Prof. Dr. Exner (Klinika Uniwersytecka w Bonn) firma Fielmann opracowała standardy higieny w zakresie optyki okularowej i akustyki słuchu, aby chronić Państwa i swoich pracowników przed zakażeniem koronawirusem. W trybie natychmiastowym Fielmann wprowadza we wszystkich salonach procedury o potwierdzonym naukowo działaniu, aby mogli się Państwo czuć bezpiecznie.<br /><br />W ramach standardów higieny do naszych salonów jednocześnie może wejść tylko ograniczona liczba klientów. Termin wizyty mogą Państwo umówić wcześniej online, telefonicznie lub bezpośrednio w salonie.<br /><br />Przed Państwa wizytą prosimy pamiętać o zachowaniu standardów higieny Fielmann. Tymczasowo rezygnujemy z dopasowania soczewek kontaktowych i wizyt kontrolnych. W miarę możliwości przy okazji Państwa kolejnej wizyty w jednym z naszych salonów prosimy o zabranie ze sobą ochrony ochrony ust i nosa. Jeżeli mają Państwo objawy przeziębienia, uprzejmie prosimy o przeniesienie wizyty w salonie na inny termin.<br /><br />Nasz dział obsługi klienta jest do Państwa dyspozycji pod bezpłatnym numerem telefonu 800 343562.<br /><br />Dziękujemy za zrozumienie. Prosimy o siebie dbać!',
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Nasze standardy higieny',
      text:
        'Drogie Klientki i drodzy Klienci! <br /><br />We współpracy z Prof. Dr. Exner (Klinika Uniwersytecka w Bonn) firma Fielmann opracowała standardy higieny w zakresie optyki okularowej i akustyki słuchu, aby chronić Państwa i swoich pracowników przed zakażeniem koronawirusem. W trybie natychmiastowym Fielmann wprowadza we wszystkich salonach procedury o potwierdzonym naukowo działaniu, aby mogli się Państwo czuć bezpiecznie.<br /><br />W ramach standardów higieny do naszych salonów jednocześnie może wejść tylko ograniczona liczba klientów. Termin wizyty mogą Państwo umówić wcześniej online, telefonicznie lub bezpośrednio w salonie.<br /><br />Przed Państwa wizytą prosimy pamiętać o zachowaniu standardów higieny Fielmann. Tymczasowo rezygnujemy z dopasowania soczewek kontaktowych i wizyt kontrolnych. W miarę możliwości przy okazji Państwa kolejnej wizyty w jednym z naszych salonów prosimy o zabranie ze sobą ochrony ochrony ust i nosa. Jeżeli mają Państwo objawy przeziębienia, uprzejmie prosimy o przeniesienie wizyty w salonie na inny termin.<br /><br />Nasz dział obsługi klienta jest do Państwa dyspozycji pod bezpłatnym numerem telefonu 800 343562.<br /><br />Dziękujemy za zrozumienie. Prosimy o siebie dbać!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 833,
    pid: 91,
    number: '001-0782',
    title: 'Lódz I, ul. Piotrkowska',
    description:
      'Salon Fielmann w Łodzi, ul. Piotrkowska 23 prezentuje ponad 2000 modnych okularów, znane marki i modele projektantów o międzynarodowej sławie. W korzystnej cenie, którą gwarantujemy. Udzielamy na to gwarancji zwrotu pieniędzy:\r\nOprócz okularów jednoogniskowych i progresywnych, firma Fielmann oferuje w tym salonie również duży wybór okularów przeciwsłonecznych, soczewek kontaktowych i środków do ich pielęgnacji.\r\nZapraszamy do skorzystania z porady naszych kompetentnych optyków i skorzystania z bezpłatnego badania wzroku. W każdej chwili.\r\nFielmann prowadzi w Europie ponad 700 salonów optycznych, również w Państwa okolicy.',
    informations: '',
    street: 'ul. Piotrkowska 23',
    zip: '90-406',
    citypart: '',
    addition: '',
    fon: '042-6307964',
    fax: '042-6307965',
    commonName: '',
    xCoordinates: '51.7733769',
    yCoordinates: '19.45515',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'baa9ca87-545c-4ceb-81bc-b2f6ebeaf89e-5da6f5b00d05380f7792b3a5',
    appointmentTimifyType: false,
    city: 'Lódz',
    country: 'Polen',
    state: 'Lódzkie',
    firmierung: 'Fielmann sp. z o.o.',
    importantMessageHeader: 'Nasze standardy higieny',
    importantMessageText:
      'Drogie Klientki i drodzy Klienci! <br /><br />We współpracy z Prof. Dr. Exner (Klinika Uniwersytecka w Bonn) firma Fielmann opracowała standardy higieny w zakresie optyki okularowej i akustyki słuchu, aby chronić Państwa i swoich pracowników przed zakażeniem koronawirusem. W trybie natychmiastowym Fielmann wprowadza we wszystkich salonach procedury o potwierdzonym naukowo działaniu, aby mogli się Państwo czuć bezpiecznie.<br /><br />W ramach standardów higieny do naszych salonów jednocześnie może wejść tylko ograniczona liczba klientów. Termin wizyty mogą Państwo umówić wcześniej online, telefonicznie lub bezpośrednio w salonie.<br /><br />Przed Państwa wizytą prosimy pamiętać o zachowaniu standardów higieny Fielmann. Tymczasowo rezygnujemy z dopasowania soczewek kontaktowych i wizyt kontrolnych. W miarę możliwości przy okazji Państwa kolejnej wizyty w jednym z naszych salonów prosimy o zabranie ze sobą ochrony ochrony ust i nosa. Jeżeli mają Państwo objawy przeziębienia, uprzejmie prosimy o przeniesienie wizyty w salonie na inny termin.<br /><br />Nasz dział obsługi klienta jest do Państwa dyspozycji pod bezpłatnym numerem telefonu 800 343562.<br /><br />Dziękujemy za zrozumienie. Prosimy o siebie dbać!',
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Nasze standardy higieny',
      text:
        'Drogie Klientki i drodzy Klienci! <br /><br />We współpracy z Prof. Dr. Exner (Klinika Uniwersytecka w Bonn) firma Fielmann opracowała standardy higieny w zakresie optyki okularowej i akustyki słuchu, aby chronić Państwa i swoich pracowników przed zakażeniem koronawirusem. W trybie natychmiastowym Fielmann wprowadza we wszystkich salonach procedury o potwierdzonym naukowo działaniu, aby mogli się Państwo czuć bezpiecznie.<br /><br />W ramach standardów higieny do naszych salonów jednocześnie może wejść tylko ograniczona liczba klientów. Termin wizyty mogą Państwo umówić wcześniej online, telefonicznie lub bezpośrednio w salonie.<br /><br />Przed Państwa wizytą prosimy pamiętać o zachowaniu standardów higieny Fielmann. Tymczasowo rezygnujemy z dopasowania soczewek kontaktowych i wizyt kontrolnych. W miarę możliwości przy okazji Państwa kolejnej wizyty w jednym z naszych salonów prosimy o zabranie ze sobą ochrony ochrony ust i nosa. Jeżeli mają Państwo objawy przeziębienia, uprzejmie prosimy o przeniesienie wizyty w salonie na inny termin.<br /><br />Nasz dział obsługi klienta jest do Państwa dyspozycji pod bezpłatnym numerem telefonu 800 343562.<br /><br />Dziękujemy za zrozumienie. Prosimy o siebie dbać!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 835,
    pid: 91,
    number: '001-0784',
    title: 'Chorzów',
    description:
      'Salon Fielmann w Chorzowie, ul. Wolności 30 prezentuje ponad 2000 modnych okularów, znane marki i modele projektantów o międzynarodowej sławie. W korzystnej cenie, którą gwarantujemy. Udzielamy na to gwarancji zwrotu pieniędzy:\r\nOprócz okularów jednoogniskowych i progresywnych, firma Fielmann oferuje w tym salonie również duży wybór okularów przeciwsłonecznych, soczewek kontaktowych i środków do ich pielęgnacji.\r\nZapraszamy do skorzystania z porady naszych kompetentnych optyków i skorzystania z bezpłatnego badania wzroku. W każdej chwili.\r\nFielmann prowadzi w Europie ponad 700 salonów optycznych, również w Państwa okolicy.',
    informations: '',
    street: 'ul. Wolnosci 30',
    zip: '41-500',
    citypart: '',
    addition: '',
    fon: '032-3460360',
    fax: '032-3460361',
    commonName: '',
    xCoordinates: '50.2960085',
    yCoordinates: '18.9500856',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'baa9ca87-545c-4ceb-81bc-b2f6ebeaf89e-5da6f5b06f97920f6f48522e',
    appointmentTimifyType: false,
    city: 'Chorzów',
    country: 'Polen',
    state: 'Slaskie',
    firmierung: 'Fielmann sp. z o.o.',
    importantMessageHeader: 'Nasze standardy higieny',
    importantMessageText:
      'Drogie Klientki i drodzy Klienci! <br /><br />We współpracy z Prof. Dr. Exner (Klinika Uniwersytecka w Bonn) firma Fielmann opracowała standardy higieny w zakresie optyki okularowej i akustyki słuchu, aby chronić Państwa i swoich pracowników przed zakażeniem koronawirusem. W trybie natychmiastowym Fielmann wprowadza we wszystkich salonach procedury o potwierdzonym naukowo działaniu, aby mogli się Państwo czuć bezpiecznie.<br /><br />W ramach standardów higieny do naszych salonów jednocześnie może wejść tylko ograniczona liczba klientów. Termin wizyty mogą Państwo umówić wcześniej online, telefonicznie lub bezpośrednio w salonie.<br /><br />Przed Państwa wizytą prosimy pamiętać o zachowaniu standardów higieny Fielmann. Tymczasowo rezygnujemy z dopasowania soczewek kontaktowych i wizyt kontrolnych. W miarę możliwości przy okazji Państwa kolejnej wizyty w jednym z naszych salonów prosimy o zabranie ze sobą ochrony ochrony ust i nosa. Jeżeli mają Państwo objawy przeziębienia, uprzejmie prosimy o przeniesienie wizyty w salonie na inny termin.<br /><br />Nasz dział obsługi klienta jest do Państwa dyspozycji pod bezpłatnym numerem telefonu 800 343562.<br /><br />Dziękujemy za zrozumienie. Prosimy o siebie dbać!',
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Nasze standardy higieny',
      text:
        'Drogie Klientki i drodzy Klienci! <br /><br />We współpracy z Prof. Dr. Exner (Klinika Uniwersytecka w Bonn) firma Fielmann opracowała standardy higieny w zakresie optyki okularowej i akustyki słuchu, aby chronić Państwa i swoich pracowników przed zakażeniem koronawirusem. W trybie natychmiastowym Fielmann wprowadza we wszystkich salonach procedury o potwierdzonym naukowo działaniu, aby mogli się Państwo czuć bezpiecznie.<br /><br />W ramach standardów higieny do naszych salonów jednocześnie może wejść tylko ograniczona liczba klientów. Termin wizyty mogą Państwo umówić wcześniej online, telefonicznie lub bezpośrednio w salonie.<br /><br />Przed Państwa wizytą prosimy pamiętać o zachowaniu standardów higieny Fielmann. Tymczasowo rezygnujemy z dopasowania soczewek kontaktowych i wizyt kontrolnych. W miarę możliwości przy okazji Państwa kolejnej wizyty w jednym z naszych salonów prosimy o zabranie ze sobą ochrony ochrony ust i nosa. Jeżeli mają Państwo objawy przeziębienia, uprzejmie prosimy o przeniesienie wizyty w salonie na inny termin.<br /><br />Nasz dział obsługi klienta jest do Państwa dyspozycji pod bezpłatnym numerem telefonu 800 343562.<br /><br />Dziękujemy za zrozumienie. Prosimy o siebie dbać!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 837,
    pid: 91,
    number: '001-0786',
    title: 'Kraków I, Gal.Krakowska',
    description:
      'Salon Fielmann w Krakowie, Galeria Krakowska prezentuje ponad 2000 modnych okularów, znane marki i modele projektantów o międzynarodowej sławie. W korzystnej cenie, którą gwarantujemy. Udzielamy na to gwarancji zwrotu pieniędzy:\r\nOprócz okularów jednoogniskowych i progresywnych, firma Fielmann oferuje w tym salonie również duży wybór okularów przeciwsłonecznych, soczewek kontaktowych i środków do ich pielęgnacji.\r\nZapraszamy do skorzystania z porady naszych kompetentnych optyków i skorzystania z bezpłatnego badania wzroku. W każdej chwili.\r\nFielmann prowadzi w Europie ponad 700 salonów optycznych, również w Państwa okolicy.',
    informations: '',
    street: 'ul. Pawia 5',
    zip: '31-154',
    citypart: '',
    addition: '',
    fon: '012-4289150',
    fax: '012-4289151',
    commonName: 'Galeria Krakowska',
    xCoordinates: '50.0667542',
    yCoordinates: '19.9455671',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'baa9ca87-545c-4ceb-81bc-b2f6ebeaf89e-5da6f5b06f97920f6f485236',
    appointmentTimifyType: false,
    city: 'Kraków',
    country: 'Polen',
    state: 'Malopolskie',
    firmierung: 'Fielmann sp. z o.o.',
    importantMessageHeader: 'Nasze standardy higieny',
    importantMessageText:
      'Drogie Klientki i drodzy Klienci! <br /><br />We współpracy z Prof. Dr. Exner (Klinika Uniwersytecka w Bonn) firma Fielmann opracowała standardy higieny w zakresie optyki okularowej i akustyki słuchu, aby chronić Państwa i swoich pracowników przed zakażeniem koronawirusem. W trybie natychmiastowym Fielmann wprowadza we wszystkich salonach procedury o potwierdzonym naukowo działaniu, aby mogli się Państwo czuć bezpiecznie.<br /><br />W ramach standardów higieny do naszych salonów jednocześnie może wejść tylko ograniczona liczba klientów. Termin wizyty mogą Państwo umówić wcześniej online, telefonicznie lub bezpośrednio w salonie.<br /><br />Przed Państwa wizytą prosimy pamiętać o zachowaniu standardów higieny Fielmann. Tymczasowo rezygnujemy z dopasowania soczewek kontaktowych i wizyt kontrolnych. W miarę możliwości przy okazji Państwa kolejnej wizyty w jednym z naszych salonów prosimy o zabranie ze sobą ochrony ochrony ust i nosa. Jeżeli mają Państwo objawy przeziębienia, uprzejmie prosimy o przeniesienie wizyty w salonie na inny termin.<br /><br />Nasz dział obsługi klienta jest do Państwa dyspozycji pod bezpłatnym numerem telefonu 800 343562.<br /><br />Dziękujemy za zrozumienie. Prosimy o siebie dbać!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Nasze standardy higieny',
      text:
        'Drogie Klientki i drodzy Klienci! <br /><br />We współpracy z Prof. Dr. Exner (Klinika Uniwersytecka w Bonn) firma Fielmann opracowała standardy higieny w zakresie optyki okularowej i akustyki słuchu, aby chronić Państwa i swoich pracowników przed zakażeniem koronawirusem. W trybie natychmiastowym Fielmann wprowadza we wszystkich salonach procedury o potwierdzonym naukowo działaniu, aby mogli się Państwo czuć bezpiecznie.<br /><br />W ramach standardów higieny do naszych salonów jednocześnie może wejść tylko ograniczona liczba klientów. Termin wizyty mogą Państwo umówić wcześniej online, telefonicznie lub bezpośrednio w salonie.<br /><br />Przed Państwa wizytą prosimy pamiętać o zachowaniu standardów higieny Fielmann. Tymczasowo rezygnujemy z dopasowania soczewek kontaktowych i wizyt kontrolnych. W miarę możliwości przy okazji Państwa kolejnej wizyty w jednym z naszych salonów prosimy o zabranie ze sobą ochrony ochrony ust i nosa. Jeżeli mają Państwo objawy przeziębienia, uprzejmie prosimy o przeniesienie wizyty w salonie na inny termin.<br /><br />Nasz dział obsługi klienta jest do Państwa dyspozycji pod bezpłatnym numerem telefonu 800 343562.<br /><br />Dziękujemy za zrozumienie. Prosimy o siebie dbać!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 839,
    pid: 91,
    number: '001-0788',
    title: 'Gliwice',
    description:
      'Salon Fielmann w Gliwicach, ul. Wyszyńskiego 8 prezentuje ponad 2000 modnych okularów, znane marki i modele projektantów o międzynarodowej sławie. W korzystnej cenie, którą gwarantujemy. Udzielamy na to gwarancji zwrotu pieniędzy:\r\nOprócz okularów jednoogniskowych i progresywnych, firma Fielmann oferuje w tym salonie również duży wybór okularów przeciwsłonecznych, soczewek kontaktowych i środków do ich pielęgnacji.\r\nZapraszamy do skorzystania z porady naszych kompetentnych optyków i skorzystania z bezpłatnego badania wzroku. W każdej chwili.\r\nFielmann prowadzi w Europie ponad 700 salonów optycznych, również w Państwa okolicy.',
    informations: '',
    street: 'ul. Wyszynskiego 8',
    zip: '44-100',
    citypart: '',
    addition: '',
    fon: '032-3397800',
    fax: '032-3397801',
    commonName: '',
    xCoordinates: '50.295526',
    yCoordinates: '18.6672613',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'baa9ca87-545c-4ceb-81bc-b2f6ebeaf89e-5da6f5b084f7ff0f7d836f4a',
    appointmentTimifyType: false,
    city: 'Gliwice',
    country: 'Polen',
    state: 'Slaskie',
    firmierung: 'Fielmann sp.z o.o.',
    importantMessageHeader: 'Nasze standardy higieny',
    importantMessageText:
      'Drogie Klientki i drodzy Klienci! <br /><br />We współpracy z Prof. Dr. Exner (Klinika Uniwersytecka w Bonn) firma Fielmann opracowała standardy higieny w zakresie optyki okularowej i akustyki słuchu, aby chronić Państwa i swoich pracowników przed zakażeniem koronawirusem. W trybie natychmiastowym Fielmann wprowadza we wszystkich salonach procedury o potwierdzonym naukowo działaniu, aby mogli się Państwo czuć bezpiecznie.<br /><br />W ramach standardów higieny do naszych salonów jednocześnie może wejść tylko ograniczona liczba klientów. Termin wizyty mogą Państwo umówić wcześniej online, telefonicznie lub bezpośrednio w salonie.<br /><br />Przed Państwa wizytą prosimy pamiętać o zachowaniu standardów higieny Fielmann. Tymczasowo rezygnujemy z dopasowania soczewek kontaktowych i wizyt kontrolnych. W miarę możliwości przy okazji Państwa kolejnej wizyty w jednym z naszych salonów prosimy o zabranie ze sobą ochrony ochrony ust i nosa. Jeżeli mają Państwo objawy przeziębienia, uprzejmie prosimy o przeniesienie wizyty w salonie na inny termin.<br /><br />Nasz dział obsługi klienta jest do Państwa dyspozycji pod bezpłatnym numerem telefonu 800 343562.<br /><br />Dziękujemy za zrozumienie. Prosimy o siebie dbać!',
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Nasze standardy higieny',
      text:
        'Drogie Klientki i drodzy Klienci! <br /><br />We współpracy z Prof. Dr. Exner (Klinika Uniwersytecka w Bonn) firma Fielmann opracowała standardy higieny w zakresie optyki okularowej i akustyki słuchu, aby chronić Państwa i swoich pracowników przed zakażeniem koronawirusem. W trybie natychmiastowym Fielmann wprowadza we wszystkich salonach procedury o potwierdzonym naukowo działaniu, aby mogli się Państwo czuć bezpiecznie.<br /><br />W ramach standardów higieny do naszych salonów jednocześnie może wejść tylko ograniczona liczba klientów. Termin wizyty mogą Państwo umówić wcześniej online, telefonicznie lub bezpośrednio w salonie.<br /><br />Przed Państwa wizytą prosimy pamiętać o zachowaniu standardów higieny Fielmann. Tymczasowo rezygnujemy z dopasowania soczewek kontaktowych i wizyt kontrolnych. W miarę możliwości przy okazji Państwa kolejnej wizyty w jednym z naszych salonów prosimy o zabranie ze sobą ochrony ochrony ust i nosa. Jeżeli mają Państwo objawy przeziębienia, uprzejmie prosimy o przeniesienie wizyty w salonie na inny termin.<br /><br />Nasz dział obsługi klienta jest do Państwa dyspozycji pod bezpłatnym numerem telefonu 800 343562.<br /><br />Dziękujemy za zrozumienie. Prosimy o siebie dbać!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 841,
    pid: 91,
    number: '001-0791',
    title: 'Plock',
    description:
      'Salon Fielmann w Płocku, Galeria Wisła prezentuje ponad 2000 modnych okularów, znane marki i modele projektantów o międzynarodowej sławie. W korzystnej cenie, którą gwarantujemy. Udzielamy na to gwarancji zwrotu pieniędzy:\r\nOprócz okularów jednoogniskowych i progresywnych, firma Fielmann oferuje w tym salonie również duży wybór okularów przeciwsłonecznych, soczewek kontaktowych i środków do ich pielęgnacji.\r\nZapraszamy do skorzystania z porady naszych kompetentnych optyków i skorzystania z bezpłatnego badania wzroku. W każdej chwili.\r\nFielmann prowadzi w Europie ponad 700 salonów optycznych, również w Państwa okolicy.',
    informations: '',
    street: 'ul. Wyszogrodzka 144',
    zip: '09-410',
    citypart: '',
    addition: '',
    fon: '024-2663850',
    fax: '024-2663870',
    commonName: 'Galeria Wisła',
    xCoordinates: '52.5359641',
    yCoordinates: '19.7552162',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'baa9ca87-545c-4ceb-81bc-b2f6ebeaf89e-5da6f5b00d05380f7792b39d',
    appointmentTimifyType: false,
    city: 'Plock',
    country: 'Polen',
    state: 'Mazowieckie',
    firmierung: 'Fielmann sp.z o.o.',
    importantMessageHeader: 'Nasze standardy higieny',
    importantMessageText:
      'Drogie Klientki i drodzy Klienci! <br /><br />We współpracy z Prof. Dr. Exner (Klinika Uniwersytecka w Bonn) firma Fielmann opracowała standardy higieny w zakresie optyki okularowej i akustyki słuchu, aby chronić Państwa i swoich pracowników przed zakażeniem koronawirusem. W trybie natychmiastowym Fielmann wprowadza we wszystkich salonach procedury o potwierdzonym naukowo działaniu, aby mogli się Państwo czuć bezpiecznie.<br /><br />W ramach standardów higieny do naszych salonów jednocześnie może wejść tylko ograniczona liczba klientów. Termin wizyty mogą Państwo umówić wcześniej online, telefonicznie lub bezpośrednio w salonie.<br /><br />Przed Państwa wizytą prosimy pamiętać o zachowaniu standardów higieny Fielmann. Tymczasowo rezygnujemy z dopasowania soczewek kontaktowych i wizyt kontrolnych. W miarę możliwości przy okazji Państwa kolejnej wizyty w jednym z naszych salonów prosimy o zabranie ze sobą ochrony ochrony ust i nosa. Jeżeli mają Państwo objawy przeziębienia, uprzejmie prosimy o przeniesienie wizyty w salonie na inny termin.<br /><br />Nasz dział obsługi klienta jest do Państwa dyspozycji pod bezpłatnym numerem telefonu 800 343562.<br /><br />Dziękujemy za zrozumienie. Prosimy o siebie dbać!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Nasze standardy higieny',
      text:
        'Drogie Klientki i drodzy Klienci! <br /><br />We współpracy z Prof. Dr. Exner (Klinika Uniwersytecka w Bonn) firma Fielmann opracowała standardy higieny w zakresie optyki okularowej i akustyki słuchu, aby chronić Państwa i swoich pracowników przed zakażeniem koronawirusem. W trybie natychmiastowym Fielmann wprowadza we wszystkich salonach procedury o potwierdzonym naukowo działaniu, aby mogli się Państwo czuć bezpiecznie.<br /><br />W ramach standardów higieny do naszych salonów jednocześnie może wejść tylko ograniczona liczba klientów. Termin wizyty mogą Państwo umówić wcześniej online, telefonicznie lub bezpośrednio w salonie.<br /><br />Przed Państwa wizytą prosimy pamiętać o zachowaniu standardów higieny Fielmann. Tymczasowo rezygnujemy z dopasowania soczewek kontaktowych i wizyt kontrolnych. W miarę możliwości przy okazji Państwa kolejnej wizyty w jednym z naszych salonów prosimy o zabranie ze sobą ochrony ochrony ust i nosa. Jeżeli mają Państwo objawy przeziębienia, uprzejmie prosimy o przeniesienie wizyty w salonie na inny termin.<br /><br />Nasz dział obsługi klienta jest do Państwa dyspozycji pod bezpłatnym numerem telefonu 800 343562.<br /><br />Dziękujemy za zrozumienie. Prosimy o siebie dbać!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 843,
    pid: 91,
    number: '001-0793',
    title: 'Kraków II, Bonarka City Center',
    description:
      'Salon Fielmann w Krakowie, Bonarka City Center prezentuje ponad 2000 modnych okularów, znane marki i modele projektantów o międzynarodowej sławie. W korzystnej cenie, którą gwarantujemy. Udzielamy na to gwarancji zwrotu pieniędzy:\r\nOprócz okularów jednoogniskowych i progresywnych, firma Fielmann oferuje w tym salonie również duży wybór okularów przeciwsłonecznych, soczewek kontaktowych i środków do ich pielęgnacji.\r\nZapraszamy do skorzystania z porady naszych kompetentnych optyków i skorzystania z bezpłatnego badania wzroku. W każdej chwili.\r\nFielmann prowadzi w Europie ponad 700 salonów optycznych, również w Państwa okolicy.',
    informations: '',
    street: 'ul. Kamienskiego 11',
    zip: '30-644',
    citypart: '',
    addition: '',
    fon: '012-2986681',
    fax: '012-2986682',
    commonName: 'Bonarka City Center',
    xCoordinates: '50.0287104',
    yCoordinates: '19.9492124',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'baa9ca87-545c-4ceb-81bc-b2f6ebeaf89e-5da6f5b08fda780f740701d3',
    appointmentTimifyType: false,
    city: 'Kraków',
    country: 'Polen',
    state: 'Malopolskie',
    firmierung: 'Fielmann sp. z o.o.',
    importantMessageHeader: 'Nasze standardy higieny',
    importantMessageText:
      'Drogie Klientki i drodzy Klienci! <br /><br />We współpracy z Prof. Dr. Exner (Klinika Uniwersytecka w Bonn) firma Fielmann opracowała standardy higieny w zakresie optyki okularowej i akustyki słuchu, aby chronić Państwa i swoich pracowników przed zakażeniem koronawirusem. W trybie natychmiastowym Fielmann wprowadza we wszystkich salonach procedury o potwierdzonym naukowo działaniu, aby mogli się Państwo czuć bezpiecznie.<br /><br />W ramach standardów higieny do naszych salonów jednocześnie może wejść tylko ograniczona liczba klientów. Termin wizyty mogą Państwo umówić wcześniej online, telefonicznie lub bezpośrednio w salonie.<br /><br />Przed Państwa wizytą prosimy pamiętać o zachowaniu standardów higieny Fielmann. Tymczasowo rezygnujemy z dopasowania soczewek kontaktowych i wizyt kontrolnych. W miarę możliwości przy okazji Państwa kolejnej wizyty w jednym z naszych salonów prosimy o zabranie ze sobą ochrony ochrony ust i nosa. Jeżeli mają Państwo objawy przeziębienia, uprzejmie prosimy o przeniesienie wizyty w salonie na inny termin.<br /><br />Nasz dział obsługi klienta jest do Państwa dyspozycji pod bezpłatnym numerem telefonu 800 343562.<br /><br />Dziękujemy za zrozumienie. Prosimy o siebie dbać!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Nasze standardy higieny',
      text:
        'Drogie Klientki i drodzy Klienci! <br /><br />We współpracy z Prof. Dr. Exner (Klinika Uniwersytecka w Bonn) firma Fielmann opracowała standardy higieny w zakresie optyki okularowej i akustyki słuchu, aby chronić Państwa i swoich pracowników przed zakażeniem koronawirusem. W trybie natychmiastowym Fielmann wprowadza we wszystkich salonach procedury o potwierdzonym naukowo działaniu, aby mogli się Państwo czuć bezpiecznie.<br /><br />W ramach standardów higieny do naszych salonów jednocześnie może wejść tylko ograniczona liczba klientów. Termin wizyty mogą Państwo umówić wcześniej online, telefonicznie lub bezpośrednio w salonie.<br /><br />Przed Państwa wizytą prosimy pamiętać o zachowaniu standardów higieny Fielmann. Tymczasowo rezygnujemy z dopasowania soczewek kontaktowych i wizyt kontrolnych. W miarę możliwości przy okazji Państwa kolejnej wizyty w jednym z naszych salonów prosimy o zabranie ze sobą ochrony ochrony ust i nosa. Jeżeli mają Państwo objawy przeziębienia, uprzejmie prosimy o przeniesienie wizyty w salonie na inny termin.<br /><br />Nasz dział obsługi klienta jest do Państwa dyspozycji pod bezpłatnym numerem telefonu 800 343562.<br /><br />Dziękujemy za zrozumienie. Prosimy o siebie dbać!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 845,
    pid: 91,
    number: '001-0795',
    title: 'Katowice',
    description:
      'Salon Fielmann w Katowicach, ul. 3 Maja 17 prezentuje ponad 2000 modnych okularów, znane marki i modele projektantów o międzynarodowej sławie. W korzystnej cenie, którą gwarantujemy. Udzielamy na to gwarancji zwrotu pieniędzy:\r\nOprócz okularów jednoogniskowych i progresywnych, firma Fielmann oferuje w tym salonie również duży wybór okularów przeciwsłonecznych, soczewek kontaktowych i środków do ich pielęgnacji.\r\nZapraszamy do skorzystania z porady naszych kompetentnych optyków i skorzystania z bezpłatnego badania wzroku. W każdej chwili.\r\nFielmann prowadzi w Europie ponad 700 salonów optycznych, również w Państwa okolicy.',
    informations: '',
    street: 'ul. 3 Maja 17',
    zip: '40-097',
    citypart: '',
    addition: '',
    fon: '032-3549250',
    fax: '032-3549251',
    commonName: '',
    xCoordinates: '50.2596984',
    yCoordinates: '19.0174484',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'baa9ca87-545c-4ceb-81bc-b2f6ebeaf89e-5da6f5b084f7ff0f7d836f42',
    appointmentTimifyType: false,
    city: 'Katowice',
    country: 'Polen',
    state: 'Slaskie',
    firmierung: 'Fielmann sp.z o.o.',
    importantMessageHeader: 'Nasze standardy higieny',
    importantMessageText:
      'Drogie Klientki i drodzy Klienci! <br /><br />We współpracy z Prof. Dr. Exner (Klinika Uniwersytecka w Bonn) firma Fielmann opracowała standardy higieny w zakresie optyki okularowej i akustyki słuchu, aby chronić Państwa i swoich pracowników przed zakażeniem koronawirusem. W trybie natychmiastowym Fielmann wprowadza we wszystkich salonach procedury o potwierdzonym naukowo działaniu, aby mogli się Państwo czuć bezpiecznie.<br /><br />W ramach standardów higieny do naszych salonów jednocześnie może wejść tylko ograniczona liczba klientów. Termin wizyty mogą Państwo umówić wcześniej online, telefonicznie lub bezpośrednio w salonie.<br /><br />Przed Państwa wizytą prosimy pamiętać o zachowaniu standardów higieny Fielmann. Tymczasowo rezygnujemy z dopasowania soczewek kontaktowych i wizyt kontrolnych. W miarę możliwości przy okazji Państwa kolejnej wizyty w jednym z naszych salonów prosimy o zabranie ze sobą ochrony ochrony ust i nosa. Jeżeli mają Państwo objawy przeziębienia, uprzejmie prosimy o przeniesienie wizyty w salonie na inny termin.<br /><br />Nasz dział obsługi klienta jest do Państwa dyspozycji pod bezpłatnym numerem telefonu 800 343562.<br /><br />Dziękujemy za zrozumienie. Prosimy o siebie dbać!',
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Nasze standardy higieny',
      text:
        'Drogie Klientki i drodzy Klienci! <br /><br />We współpracy z Prof. Dr. Exner (Klinika Uniwersytecka w Bonn) firma Fielmann opracowała standardy higieny w zakresie optyki okularowej i akustyki słuchu, aby chronić Państwa i swoich pracowników przed zakażeniem koronawirusem. W trybie natychmiastowym Fielmann wprowadza we wszystkich salonach procedury o potwierdzonym naukowo działaniu, aby mogli się Państwo czuć bezpiecznie.<br /><br />W ramach standardów higieny do naszych salonów jednocześnie może wejść tylko ograniczona liczba klientów. Termin wizyty mogą Państwo umówić wcześniej online, telefonicznie lub bezpośrednio w salonie.<br /><br />Przed Państwa wizytą prosimy pamiętać o zachowaniu standardów higieny Fielmann. Tymczasowo rezygnujemy z dopasowania soczewek kontaktowych i wizyt kontrolnych. W miarę możliwości przy okazji Państwa kolejnej wizyty w jednym z naszych salonów prosimy o zabranie ze sobą ochrony ochrony ust i nosa. Jeżeli mają Państwo objawy przeziębienia, uprzejmie prosimy o przeniesienie wizyty w salonie na inny termin.<br /><br />Nasz dział obsługi klienta jest do Państwa dyspozycji pod bezpłatnym numerem telefonu 800 343562.<br /><br />Dziękujemy za zrozumienie. Prosimy o siebie dbać!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 847,
    pid: 91,
    number: '001-0794',
    title: 'Koszalin',
    description:
      'Salon Fielmann w Koszalinie, CH Atrium prezentuje ponad 2000 modnych okularów, znane marki i modele projektantów o międzynarodowej sławie. W korzystnej cenie, którą gwarantujemy. Udzielamy na to gwarancji zwrotu pieniędzy:\r\nOprócz okularów jednoogniskowych i progresywnych, firma Fielmann oferuje w tym salonie również duży wybór okularów przeciwsłonecznych, soczewek kontaktowych i środków do ich pielęgnacji.\r\nZapraszamy do skorzystania z porady naszych kompetentnych optyków i skorzystania z bezpłatnego badania wzroku. W każdej chwili.\r\nFielmann prowadzi w Europie ponad 700 salonów optycznych, również w Państwa okolicy.',
    informations: '',
    street: 'ul. Paderewskiego 1',
    zip: '75-736',
    citypart: '',
    addition: '',
    fon: '094-3446460',
    fax: '094-3446480',
    commonName: 'CH Forum Koszalin',
    xCoordinates: '54.1771972',
    yCoordinates: '16.2019516',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'baa9ca87-545c-4ceb-81bc-b2f6ebeaf89e-5da6f5b0e1d5846fe6e7e7bc',
    appointmentTimifyType: false,
    city: 'Koszalin',
    country: 'Polen',
    state: 'Zachodniopomorskie',
    firmierung: 'Fielmann sp. z o.o.',
    importantMessageHeader: 'Nasze standardy higieny',
    importantMessageText:
      'Drogie Klientki i drodzy Klienci! <br /><br />We współpracy z Prof. Dr. Exner (Klinika Uniwersytecka w Bonn) firma Fielmann opracowała standardy higieny w zakresie optyki okularowej i akustyki słuchu, aby chronić Państwa i swoich pracowników przed zakażeniem koronawirusem. W trybie natychmiastowym Fielmann wprowadza we wszystkich salonach procedury o potwierdzonym naukowo działaniu, aby mogli się Państwo czuć bezpiecznie.<br /><br />W ramach standardów higieny do naszych salonów jednocześnie może wejść tylko ograniczona liczba klientów. Termin wizyty mogą Państwo umówić wcześniej online, telefonicznie lub bezpośrednio w salonie.<br /><br />Przed Państwa wizytą prosimy pamiętać o zachowaniu standardów higieny Fielmann. Tymczasowo rezygnujemy z dopasowania soczewek kontaktowych i wizyt kontrolnych. W miarę możliwości przy okazji Państwa kolejnej wizyty w jednym z naszych salonów prosimy o zabranie ze sobą ochrony ochrony ust i nosa. Jeżeli mają Państwo objawy przeziębienia, uprzejmie prosimy o przeniesienie wizyty w salonie na inny termin.<br /><br />Nasz dział obsługi klienta jest do Państwa dyspozycji pod bezpłatnym numerem telefonu 800 343562.<br /><br />Dziękujemy za zrozumienie. Prosimy o siebie dbać!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Nasze standardy higieny',
      text:
        'Drogie Klientki i drodzy Klienci! <br /><br />We współpracy z Prof. Dr. Exner (Klinika Uniwersytecka w Bonn) firma Fielmann opracowała standardy higieny w zakresie optyki okularowej i akustyki słuchu, aby chronić Państwa i swoich pracowników przed zakażeniem koronawirusem. W trybie natychmiastowym Fielmann wprowadza we wszystkich salonach procedury o potwierdzonym naukowo działaniu, aby mogli się Państwo czuć bezpiecznie.<br /><br />W ramach standardów higieny do naszych salonów jednocześnie może wejść tylko ograniczona liczba klientów. Termin wizyty mogą Państwo umówić wcześniej online, telefonicznie lub bezpośrednio w salonie.<br /><br />Przed Państwa wizytą prosimy pamiętać o zachowaniu standardów higieny Fielmann. Tymczasowo rezygnujemy z dopasowania soczewek kontaktowych i wizyt kontrolnych. W miarę możliwości przy okazji Państwa kolejnej wizyty w jednym z naszych salonów prosimy o zabranie ze sobą ochrony ochrony ust i nosa. Jeżeli mają Państwo objawy przeziębienia, uprzejmie prosimy o przeniesienie wizyty w salonie na inny termin.<br /><br />Nasz dział obsługi klienta jest do Państwa dyspozycji pod bezpłatnym numerem telefonu 800 343562.<br /><br />Dziękujemy za zrozumienie. Prosimy o siebie dbać!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 849,
    pid: 91,
    number: '001-0796',
    title: 'Bytom',
    description:
      'Salon Fielmann w Bytomiu, Galeria Agora prezentuje ponad 2000 modnych okularów, znane marki i modele projektantów o międzynarodowej sławie. W korzystnej cenie, którą gwarantujemy. Udzielamy na to gwarancji zwrotu pieniędzy:\r\nOprócz okularów jednoogniskowych i progresywnych, firma Fielmann oferuje w tym salonie również duży wybór okularów przeciwsłonecznych, soczewek kontaktowych i środków do ich pielęgnacji.\r\nZapraszamy do skorzystania z porady naszych kompetentnych optyków i skorzystania z bezpłatnego badania wzroku. W każdej chwili.\r\nFielmann prowadzi w Europie ponad 700 salonów optycznych, również w Państwa okolicy.',
    informations: '',
    street: 'Plac Tadeusza Kościuszki 1',
    zip: '41-902',
    citypart: '',
    addition: '',
    fon: '032-3962855',
    fax: '032-3962856',
    commonName: 'CH Agora Bytom',
    xCoordinates: '50.3475778',
    yCoordinates: '18.9172903',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'baa9ca87-545c-4ceb-81bc-b2f6ebeaf89e-5da6f5b06f97920f6f48523e',
    appointmentTimifyType: false,
    city: 'Bytom',
    country: 'Polen',
    state: 'Slaskie',
    firmierung: 'Fielmann sp. z o.o.',
    importantMessageHeader: 'Nasze standardy higieny',
    importantMessageText:
      'Drogie Klientki i drodzy Klienci! <br /><br />We współpracy z Prof. Dr. Exner (Klinika Uniwersytecka w Bonn) firma Fielmann opracowała standardy higieny w zakresie optyki okularowej i akustyki słuchu, aby chronić Państwa i swoich pracowników przed zakażeniem koronawirusem. W trybie natychmiastowym Fielmann wprowadza we wszystkich salonach procedury o potwierdzonym naukowo działaniu, aby mogli się Państwo czuć bezpiecznie.<br /><br />W ramach standardów higieny do naszych salonów jednocześnie może wejść tylko ograniczona liczba klientów. Termin wizyty mogą Państwo umówić wcześniej online, telefonicznie lub bezpośrednio w salonie.<br /><br />Przed Państwa wizytą prosimy pamiętać o zachowaniu standardów higieny Fielmann. Tymczasowo rezygnujemy z dopasowania soczewek kontaktowych i wizyt kontrolnych. W miarę możliwości przy okazji Państwa kolejnej wizyty w jednym z naszych salonów prosimy o zabranie ze sobą ochrony ochrony ust i nosa. Jeżeli mają Państwo objawy przeziębienia, uprzejmie prosimy o przeniesienie wizyty w salonie na inny termin.<br /><br />Nasz dział obsługi klienta jest do Państwa dyspozycji pod bezpłatnym numerem telefonu 800 343562.<br /><br />Dziękujemy za zrozumienie. Prosimy o siebie dbać!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Nasze standardy higieny',
      text:
        'Drogie Klientki i drodzy Klienci! <br /><br />We współpracy z Prof. Dr. Exner (Klinika Uniwersytecka w Bonn) firma Fielmann opracowała standardy higieny w zakresie optyki okularowej i akustyki słuchu, aby chronić Państwa i swoich pracowników przed zakażeniem koronawirusem. W trybie natychmiastowym Fielmann wprowadza we wszystkich salonach procedury o potwierdzonym naukowo działaniu, aby mogli się Państwo czuć bezpiecznie.<br /><br />W ramach standardów higieny do naszych salonów jednocześnie może wejść tylko ograniczona liczba klientów. Termin wizyty mogą Państwo umówić wcześniej online, telefonicznie lub bezpośrednio w salonie.<br /><br />Przed Państwa wizytą prosimy pamiętać o zachowaniu standardów higieny Fielmann. Tymczasowo rezygnujemy z dopasowania soczewek kontaktowych i wizyt kontrolnych. W miarę możliwości przy okazji Państwa kolejnej wizyty w jednym z naszych salonów prosimy o zabranie ze sobą ochrony ochrony ust i nosa. Jeżeli mają Państwo objawy przeziębienia, uprzejmie prosimy o przeniesienie wizyty w salonie na inny termin.<br /><br />Nasz dział obsługi klienta jest do Państwa dyspozycji pod bezpłatnym numerem telefonu 800 343562.<br /><br />Dziękujemy za zrozumienie. Prosimy o siebie dbać!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 851,
    pid: 91,
    number: '001-0801',
    title: 'Kamen',
    description: '',
    informations: '',
    street: 'Weststraße 74',
    zip: '59174',
    citypart: '',
    addition: '',
    fon: '02307-240520',
    fax: '02307-240521',
    commonName: '',
    xCoordinates: '51.59227',
    yCoordinates: '7.662732',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Kamen',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 853,
    pid: 91,
    number: '001-0803',
    title: 'Landshut',
    description: '',
    informations: '',
    street: 'Altstadt 357 /Rosengasse',
    zip: '84028',
    citypart: '',
    addition: '',
    fon: '0871-921077',
    fax: '0871-921078',
    commonName: '',
    xCoordinates: '48.5369409',
    yCoordinates: '12.1527488',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Landshut',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 855,
    pid: 91,
    number: '001-0805',
    title: 'Weiden i. d. Oberpfalz',
    description: '',
    informations: '',
    street: 'Max-Reger-Str. 3',
    zip: '92637',
    citypart: '',
    addition: '',
    fon: '0961-4161851',
    fax: '0961-4161852',
    commonName: '',
    xCoordinates: '49.6746009',
    yCoordinates: '12.1586048',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Weiden in der Oberpfalz',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 857,
    pid: 91,
    number: '001-0807',
    title: 'Halle II, Neustädter Passage',
    description: '',
    informations: '',
    street: 'Neustädter Passage 16',
    zip: '06122',
    citypart: 'Neustadt',
    addition: '',
    fon: '0345-8070410',
    fax: '0345-8070411',
    commonName: '',
    xCoordinates: '51.4793138',
    yCoordinates: '11.9197022',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Halle (Saale)',
    country: 'Deutschland',
    state: 'Sachsen-Anhalt',
    firmierung: 'Fielmann AG & Co. Halle-Neustadt OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 859,
    pid: 91,
    number: '001-0810',
    title: 'Frankfurt/Main IV, Leipziger',
    description: '',
    informations: '',
    street: 'Leipziger Straße 2',
    zip: '60487',
    citypart: 'Bockenheim',
    addition: '',
    fon: '069-24795011',
    fax: '069-24795012',
    commonName: '',
    xCoordinates: '50.1204294',
    yCoordinates: '8.6500539',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Frankfurt am Main',
    country: 'Deutschland',
    state: 'Hessen',
    firmierung: 'Fielmann AG & Co. Leipziger Straße OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 861,
    pid: 91,
    number: '001-0812',
    title: 'Heidenheim',
    description: '',
    informations: '',
    street: 'Hauptstraße 19/21',
    zip: '89522',
    citypart: '',
    addition: '',
    fon: '07321-923021',
    fax: '07321-923022',
    commonName: '',
    xCoordinates: '48.6767633',
    yCoordinates: '10.1519566',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Heidenheim',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 863,
    pid: 91,
    number: '001-0814',
    title: 'Wittlich',
    description: '',
    informations: '',
    street: 'Burgstraße 13/15',
    zip: '54516',
    citypart: '',
    addition: '',
    fon: '06571-969530',
    fax: '06571-969531',
    commonName: '',
    xCoordinates: '49.98589',
    yCoordinates: '6.88816',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Wittlich',
    country: 'Deutschland',
    state: 'Rheinland-Pfalz',
    firmierung: 'Fielmann Augenoptik AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 865,
    pid: 91,
    number: '001-0797',
    title: 'Legnica',
    description:
      'Salon Fielmann w Legnicy, ul. Najświętszej Marii Panny 5d prezentuje ponad 2000 modnych okularów, znane marki i modele projektantów o międzynarodowej sławie. W korzystnej cenie, którą gwarantujemy. Udzielamy na to gwarancji zwrotu pieniędzy:\r\nOprócz okularów jednoogniskowych i progresywnych, firma Fielmann oferuje w tym salonie również duży wybór okularów przeciwsłonecznych, soczewek kontaktowych i środków do ich pielęgnacji.\r\nZapraszamy do skorzystania z porady naszych kompetentnych optyków i skorzystania z bezpłatnego badania wzroku. W każdej chwili.\r\nFielmann prowadzi w Europie ponad 700 salonów optycznych, również w Państwa okolicy.',
    informations: '',
    street: 'ul. Najswietszej Marii Panny 5d',
    zip: '59-220',
    citypart: '',
    addition: '',
    fon: '076-8509740',
    fax: '076-8509741',
    commonName: '',
    xCoordinates: '51.2088865',
    yCoordinates: '16.1637511',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'baa9ca87-545c-4ceb-81bc-b2f6ebeaf89e-5da6f5b084f7ff0f7d836f5a',
    appointmentTimifyType: false,
    city: 'Legnica',
    country: 'Polen',
    state: 'Dolnoslaskie',
    firmierung: 'Fielmann sp.z o.o.',
    importantMessageHeader: 'Nasze standardy higieny',
    importantMessageText:
      'Drogie Klientki i drodzy Klienci! <br /><br />We współpracy z Prof. Dr. Exner (Klinika Uniwersytecka w Bonn) firma Fielmann opracowała standardy higieny w zakresie optyki okularowej i akustyki słuchu, aby chronić Państwa i swoich pracowników przed zakażeniem koronawirusem. W trybie natychmiastowym Fielmann wprowadza we wszystkich salonach procedury o potwierdzonym naukowo działaniu, aby mogli się Państwo czuć bezpiecznie.<br /><br />W ramach standardów higieny do naszych salonów jednocześnie może wejść tylko ograniczona liczba klientów. Termin wizyty mogą Państwo umówić wcześniej online, telefonicznie lub bezpośrednio w salonie.<br /><br />Przed Państwa wizytą prosimy pamiętać o zachowaniu standardów higieny Fielmann. Tymczasowo rezygnujemy z dopasowania soczewek kontaktowych i wizyt kontrolnych. W miarę możliwości przy okazji Państwa kolejnej wizyty w jednym z naszych salonów prosimy o zabranie ze sobą ochrony ochrony ust i nosa. Jeżeli mają Państwo objawy przeziębienia, uprzejmie prosimy o przeniesienie wizyty w salonie na inny termin.<br /><br />Nasz dział obsługi klienta jest do Państwa dyspozycji pod bezpłatnym numerem telefonu 800 343562.<br /><br />Dziękujemy za zrozumienie. Prosimy o siebie dbać!',
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Nasze standardy higieny',
      text:
        'Drogie Klientki i drodzy Klienci! <br /><br />We współpracy z Prof. Dr. Exner (Klinika Uniwersytecka w Bonn) firma Fielmann opracowała standardy higieny w zakresie optyki okularowej i akustyki słuchu, aby chronić Państwa i swoich pracowników przed zakażeniem koronawirusem. W trybie natychmiastowym Fielmann wprowadza we wszystkich salonach procedury o potwierdzonym naukowo działaniu, aby mogli się Państwo czuć bezpiecznie.<br /><br />W ramach standardów higieny do naszych salonów jednocześnie może wejść tylko ograniczona liczba klientów. Termin wizyty mogą Państwo umówić wcześniej online, telefonicznie lub bezpośrednio w salonie.<br /><br />Przed Państwa wizytą prosimy pamiętać o zachowaniu standardów higieny Fielmann. Tymczasowo rezygnujemy z dopasowania soczewek kontaktowych i wizyt kontrolnych. W miarę możliwości przy okazji Państwa kolejnej wizyty w jednym z naszych salonów prosimy o zabranie ze sobą ochrony ochrony ust i nosa. Jeżeli mają Państwo objawy przeziębienia, uprzejmie prosimy o przeniesienie wizyty w salonie na inny termin.<br /><br />Nasz dział obsługi klienta jest do Państwa dyspozycji pod bezpłatnym numerem telefonu 800 343562.<br /><br />Dziękujemy za zrozumienie. Prosimy o siebie dbać!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 867,
    pid: 91,
    number: '001-0799',
    title: 'Częstochowa',
    description:
      'Salon Fielmann w Częstochowie, aleja Najświętszej Maryi Panny 33 prezentuje ponad 2000 modnych okularów, znane marki i modele projektantów o międzynarodowej sławie. W korzystnej cenie, którą gwarantujemy. Udzielamy na to gwarancji zwrotu pieniędzy:\r\nOprócz okularów jednoogniskowych i progresywnych, firma Fielmann oferuje w tym salonie również duży wybór okularów przeciwsłonecznych, soczewek kontaktowych i środków do ich pielęgnacji.\r\nZapraszamy do skorzystania z porady naszych kompetentnych optyków i skorzystania z bezpłatnego badania wzroku. W każdej chwili.\r\nFielmann prowadzi w Europie ponad 700 salonów optycznych, również w Państwa okolicy.',
    informations: '',
    street: 'Aleja Najświętszej Maryi Panny 33',
    zip: '42-200',
    citypart: '',
    addition: '',
    fon: '034-3890860',
    fax: '034-3890861',
    commonName: '',
    xCoordinates: '50.8116974',
    yCoordinates: '19.1167625',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'baa9ca87-545c-4ceb-81bc-b2f6ebeaf89e-5da6f5b00d05380f7792b3ac',
    appointmentTimifyType: false,
    city: 'Czestochowa',
    country: 'Polen',
    state: 'Slaskie',
    firmierung: 'Fielmann sp.z o.o.',
    importantMessageHeader: 'Nasze standardy higieny',
    importantMessageText:
      'Drogie Klientki i drodzy Klienci! <br /><br />We współpracy z Prof. Dr. Exner (Klinika Uniwersytecka w Bonn) firma Fielmann opracowała standardy higieny w zakresie optyki okularowej i akustyki słuchu, aby chronić Państwa i swoich pracowników przed zakażeniem koronawirusem. W trybie natychmiastowym Fielmann wprowadza we wszystkich salonach procedury o potwierdzonym naukowo działaniu, aby mogli się Państwo czuć bezpiecznie.<br /><br />W ramach standardów higieny do naszych salonów jednocześnie może wejść tylko ograniczona liczba klientów. Termin wizyty mogą Państwo umówić wcześniej online, telefonicznie lub bezpośrednio w salonie.<br /><br />Przed Państwa wizytą prosimy pamiętać o zachowaniu standardów higieny Fielmann. Tymczasowo rezygnujemy z dopasowania soczewek kontaktowych i wizyt kontrolnych. W miarę możliwości przy okazji Państwa kolejnej wizyty w jednym z naszych salonów prosimy o zabranie ze sobą ochrony ochrony ust i nosa. Jeżeli mają Państwo objawy przeziębienia, uprzejmie prosimy o przeniesienie wizyty w salonie na inny termin.<br /><br />Nasz dział obsługi klienta jest do Państwa dyspozycji pod bezpłatnym numerem telefonu 800 343562.<br /><br />Dziękujemy za zrozumienie. Prosimy o siebie dbać!',
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Nasze standardy higieny',
      text:
        'Drogie Klientki i drodzy Klienci! <br /><br />We współpracy z Prof. Dr. Exner (Klinika Uniwersytecka w Bonn) firma Fielmann opracowała standardy higieny w zakresie optyki okularowej i akustyki słuchu, aby chronić Państwa i swoich pracowników przed zakażeniem koronawirusem. W trybie natychmiastowym Fielmann wprowadza we wszystkich salonach procedury o potwierdzonym naukowo działaniu, aby mogli się Państwo czuć bezpiecznie.<br /><br />W ramach standardów higieny do naszych salonów jednocześnie może wejść tylko ograniczona liczba klientów. Termin wizyty mogą Państwo umówić wcześniej online, telefonicznie lub bezpośrednio w salonie.<br /><br />Przed Państwa wizytą prosimy pamiętać o zachowaniu standardów higieny Fielmann. Tymczasowo rezygnujemy z dopasowania soczewek kontaktowych i wizyt kontrolnych. W miarę możliwości przy okazji Państwa kolejnej wizyty w jednym z naszych salonów prosimy o zabranie ze sobą ochrony ochrony ust i nosa. Jeżeli mają Państwo objawy przeziębienia, uprzejmie prosimy o przeniesienie wizyty w salonie na inny termin.<br /><br />Nasz dział obsługi klienta jest do Państwa dyspozycji pod bezpłatnym numerem telefonu 800 343562.<br /><br />Dziękujemy za zrozumienie. Prosimy o siebie dbać!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 869,
    pid: 91,
    number: '001-0802',
    title: 'Bergheim',
    description: '',
    informations: '',
    street: 'Hauptstraße 35',
    zip: '50126',
    citypart: '',
    addition: '',
    fon: '02271-496542',
    fax: '02271-496543',
    commonName: '',
    xCoordinates: '50.95355929999999',
    yCoordinates: '6.6403346',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Bergheim',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 871,
    pid: 91,
    number: '001-0804',
    title: 'Forchheim',
    description: '',
    informations: '',
    street: 'Hauptstraße 45',
    zip: '91301',
    citypart: 'Zentrum',
    addition: '',
    fon: '09191-727280',
    fax: '09191-727281',
    commonName: 'Zentrum',
    xCoordinates: '49.719209',
    yCoordinates: '11.058858',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Forchheim',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 873,
    pid: 91,
    number: '001-0806',
    title: 'Kaufbeuren',
    description: '',
    informations: '',
    street: 'Schmiedgasse 5',
    zip: '87600',
    citypart: '',
    addition: '',
    fon: '08341-994246',
    fax: '08341-994247',
    commonName: '',
    xCoordinates: '47.88083',
    yCoordinates: '10.6186',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Kaufbeuren',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 875,
    pid: 91,
    number: '001-0808',
    title: 'Sonneberg',
    description: '',
    informations: '',
    street: 'Bahnhofstraße 54',
    zip: '96515',
    citypart: '',
    addition: '',
    fon: '03675-809513',
    fax: '03675-809514',
    commonName: '',
    xCoordinates: '50.35882',
    yCoordinates: '11.17222',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Sonneberg',
    country: 'Deutschland',
    state: 'Thüringen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 877,
    pid: 91,
    number: '001-0811',
    title: 'Bitterfeld',
    description: '',
    informations: '',
    street: 'Markt 9',
    zip: '06749',
    citypart: '',
    addition: '',
    fon: '03493-42389',
    fax: '03493-42395',
    commonName: '',
    xCoordinates: '51.623581',
    yCoordinates: '12.329661',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Bitterfeld',
    country: 'Deutschland',
    state: 'Sachsen-Anhalt',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 879,
    pid: 91,
    number: '001-0813',
    title: 'Weißwasser',
    description: '',
    informations: '',
    street: 'Muskauer Str. 74',
    zip: '02943',
    citypart: '',
    addition: '',
    fon: '03576-208443',
    fax: '03576-208444',
    commonName: '',
    xCoordinates: '51.5055634',
    yCoordinates: '14.6427479',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '2649',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Weißwasser',
    country: 'Deutschland',
    state: 'Sachsen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 881,
    pid: 91,
    number: '001-0815',
    title: 'Neustrelitz',
    description: '',
    informations: '',
    street: 'Strelitzer Straße 10',
    zip: '17235',
    citypart: '',
    addition: '',
    fon: '03981-200043',
    fax: '03981-200044',
    commonName: '',
    xCoordinates: '53.3616035',
    yCoordinates: '13.0655602',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Neustrelitz',
    country: 'Deutschland',
    state: 'Mecklenburg-Vorpommern',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 883,
    pid: 91,
    number: '001-0816',
    title: 'Esslingen',
    description: '',
    informations: '',
    street: 'Pliensaustraße 12',
    zip: '73728',
    citypart: '',
    addition: '',
    fon: '0711-352771',
    fax: '0711-352791',
    commonName: '',
    xCoordinates: '48.7396057',
    yCoordinates: '9.3050255',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Esslingen',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 885,
    pid: 91,
    number: '001-0818',
    title: 'Berlin XVIII, Helle Mitte',
    description: '',
    informations: '',
    street: 'Janusz-Korczak-Str. 4',
    zip: '12627',
    citypart: 'Hellersdorf',
    addition: '',
    fon: '030-99401893',
    fax: '030-99401894',
    commonName: 'Zentrum Helle Mitte',
    xCoordinates: '52.537273',
    yCoordinates: '13.602216',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Berlin',
    country: 'Deutschland',
    state: 'Berlin',
    firmierung: 'Fielmann AG & Co. Berlin-Hellersdorf OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 887,
    pid: 91,
    number: '001-0821',
    title: 'Baden-Baden',
    description: '',
    informations: '',
    street: 'Lange Straße 10',
    zip: '76530',
    citypart: '',
    addition: '',
    fon: '07221-271706',
    fax: '07221-271707',
    commonName: '',
    xCoordinates: '48.762074',
    yCoordinates: '8.239169',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Baden-Baden',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 889,
    pid: 91,
    number: '001-0823',
    title: 'Bad Hersfeld',
    description: '',
    informations: '',
    street: 'Klausstraße 6',
    zip: '36251',
    citypart: '',
    addition: '',
    fon: '06621-620929',
    fax: '06621-620930',
    commonName: '',
    xCoordinates: '50.8685897',
    yCoordinates: '9.7083666',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Bad Hersfeld',
    country: 'Deutschland',
    state: 'Hessen',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 891,
    pid: 91,
    number: '001-0825',
    title: 'Salzwedel',
    description: '',
    informations: '',
    street: 'Burgstraße 57',
    zip: '29410',
    citypart: '',
    addition: '',
    fon: '03901-477404',
    fax: '03901-477405',
    commonName: '',
    xCoordinates: '52.850846',
    yCoordinates: '11.152096',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Salzwedel',
    country: 'Deutschland',
    state: 'Sachsen-Anhalt',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 893,
    pid: 91,
    number: '001-0827',
    title: 'Osterholz-Scharmbeck',
    description: '',
    informations: '',
    street: 'Kirchenstraße 19 - 19 a',
    zip: '27711',
    citypart: '',
    addition: '',
    fon: '04791-985500',
    fax: '04791-985510',
    commonName: '',
    xCoordinates: '53.2304176',
    yCoordinates: '8.7842278',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Osterholz-Scharmbeck',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 895,
    pid: 91,
    number: '001-0829',
    title: 'Essen II, Hansastraße',
    description: '',
    informations: '',
    street: 'Hansastraße 34',
    zip: '45276',
    citypart: 'Steele',
    addition: '',
    fon: '0201-851451',
    fax: '0201-851452',
    commonName: '',
    xCoordinates: '51.4467943',
    yCoordinates: '7.0753474',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Essen',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. Essen-Steele OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 897,
    pid: 91,
    number: '001-0830',
    title: 'Naumburg',
    description: '',
    informations: '',
    street: 'Markt 15',
    zip: '06618',
    citypart: '',
    addition: '',
    fon: '03445-208970',
    fax: '03445-208974',
    commonName: '',
    xCoordinates: '51.1530486',
    yCoordinates: '11.8096168',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Naumburg',
    country: 'Deutschland',
    state: 'Sachsen-Anhalt',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 899,
    pid: 91,
    number: '001-0832',
    title: 'Wunstorf',
    description: '',
    informations: '',
    street: 'Lange Straße 40',
    zip: '31515',
    citypart: '',
    addition: '',
    fon: '05031-916910',
    fax: '05031-916911',
    commonName: '',
    xCoordinates: '52.425788',
    yCoordinates: '9.42529',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Wunstorf',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann Augenoptik AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 901,
    pid: 91,
    number: '001-0817',
    title: 'Essen III, Rüttenscheider Str.',
    description: '',
    informations: '',
    street: 'Rüttenscheider Straße 82',
    zip: '45130',
    citypart: 'Rüttenscheid',
    addition: '',
    fon: '0201-8777366',
    fax: '0201-8777367',
    commonName: '',
    xCoordinates: '51.436094',
    yCoordinates: '7.004918',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Essen',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. Essen-Rüttenscheid OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 903,
    pid: 91,
    number: '001-0819',
    title: 'Parchim',
    description: '',
    informations: '',
    street: 'Blutstraße 17',
    zip: '19370',
    citypart: '',
    addition: '',
    fon: '03871-251360',
    fax: '03871-251361',
    commonName: '',
    xCoordinates: '53.427484',
    yCoordinates: '11.845993',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Parchim',
    country: 'Deutschland',
    state: 'Mecklenburg-Vorpommern',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 905,
    pid: 91,
    number: '001-0820',
    title: 'Kleve',
    description: '',
    informations: '',
    street: 'Große Straße 90',
    zip: '47533',
    citypart: '',
    addition: '',
    fon: '02821-12398',
    fax: '02821-12668',
    commonName: '',
    xCoordinates: '51.787127',
    yCoordinates: '6.137832',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Kleve',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 907,
    pid: 91,
    number: '001-0822',
    title: 'Backnang',
    description: '',
    informations: '',
    street: 'Uhlandstraße 3',
    zip: '71522',
    citypart: '',
    addition: '',
    fon: '07191-950957',
    fax: '07191-950958',
    commonName: '',
    xCoordinates: '48.946745',
    yCoordinates: '9.429589',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Backnang',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 909,
    pid: 91,
    number: '001-0824',
    title: 'Köln VI, Neusser Straße',
    description: '',
    informations: '',
    street: 'Neusser Straße 215',
    zip: '50733',
    citypart: 'Nippes',
    addition: '',
    fon: '0221-9726921',
    fax: '0221-9726922',
    commonName: '',
    xCoordinates: '50.9616811',
    yCoordinates: '6.9540946',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Köln',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 911,
    pid: 91,
    number: '001-0826',
    title: 'Freital',
    description: '',
    informations: '',
    street: 'Dresdner Straße 93',
    zip: '01705',
    citypart: 'Potschappel',
    addition: '',
    fon: '0351-6492052',
    fax: '0351-6492055',
    commonName: '',
    xCoordinates: '51.009462',
    yCoordinates: '13.660626',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Freital',
    country: 'Deutschland',
    state: 'Sachsen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 913,
    pid: 91,
    number: '001-0828',
    title: 'Walsrode',
    description: '',
    informations: '',
    street: 'Moorstraße 66',
    zip: '29664',
    citypart: '',
    addition: '',
    fon: '05161-912900',
    fax: '05161-912901',
    commonName: '',
    xCoordinates: '52.8627681',
    yCoordinates: '9.5894676',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Walsrode',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 915,
    pid: 91,
    number: '001-0831',
    title: 'Leipzig III, Paunsdorfer Allee',
    description: '',
    informations: '',
    street: 'Paunsdorfer Allee 1',
    zip: '04329',
    citypart: 'Paunsdorf',
    addition: '',
    fon: '0341-2512243',
    fax: '0341-2512253',
    commonName: 'Paunsdorf-Center',
    xCoordinates: '51.348873',
    yCoordinates: '12.477268',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Leipzig',
    country: 'Deutschland',
    state: 'Sachsen',
    firmierung: 'Fielmann AG & Co. Paunsdorf-Center OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 917,
    pid: 91,
    number: '001-0833',
    title: 'Bremen IV, Pappelstraße',
    description: '',
    informations: '',
    street: 'Pappelstraße 131',
    zip: '28199',
    citypart: 'Neustadt',
    addition: '',
    fon: '0421-5091373',
    fax: '0421-5091374',
    commonName: '',
    xCoordinates: '53.0664253',
    yCoordinates: '8.7933136',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Bremen',
    country: 'Deutschland',
    state: 'Bremen',
    firmierung: 'Fielmann AG & Co. oHG Bremen-Neustadt',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 919,
    pid: 91,
    number: '001-0834',
    title: 'Celle',
    description: '',
    informations: '',
    street: 'Zöllnerstraße 34',
    zip: '29221',
    citypart: '',
    addition: '',
    fon: '05141-740327',
    fax: '05141-740328',
    commonName: '',
    xCoordinates: '52.624264',
    yCoordinates: '10.083448',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Celle',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 921,
    pid: 91,
    number: '001-0836',
    title: 'Bonn II, Theaterplatz',
    description: '',
    informations: '',
    street: 'Theaterplatz 6',
    zip: '53177',
    citypart: 'Bad-Godesberg',
    addition: '',
    fon: '0228-3680186',
    fax: '0228-3680187',
    commonName: '',
    xCoordinates: '50.684159',
    yCoordinates: '7.154435',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Bonn',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. Bonn-Bad Godesberg OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 923,
    pid: 91,
    number: '001-0838',
    title: 'Bensheim',
    description: '',
    informations: '',
    street: 'Hauptstraße 20 - 26',
    zip: '64625',
    citypart: '',
    addition: '',
    fon: '06251-610929',
    fax: '06251-610882',
    commonName: '',
    xCoordinates: '49.68187',
    yCoordinates: '8.62228',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Bensheim',
    country: 'Deutschland',
    state: 'Hessen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 925,
    pid: 91,
    number: '001-0843',
    title: 'Weißenfels',
    description: '',
    informations: '',
    street: 'Jüdenstraße 17',
    zip: '06667',
    citypart: '',
    addition: '',
    fon: '03443-33335-0',
    fax: '03443-33335-29',
    commonName: '',
    xCoordinates: '51.2012446',
    yCoordinates: '11.970115',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Weißenfels',
    country: 'Deutschland',
    state: 'Sachsen-Anhalt',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 927,
    pid: 91,
    number: '001-0845',
    title: 'Merseburg',
    description: '',
    informations: '',
    street: 'Gotthardstraße 27',
    zip: '06217',
    citypart: '',
    addition: '',
    fon: '03461-231480',
    fax: '03461-231481',
    commonName: '',
    xCoordinates: '51.354602',
    yCoordinates: '11.995397',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Merseburg',
    country: 'Deutschland',
    state: 'Sachsen-Anhalt',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 929,
    pid: 91,
    number: '001-0847',
    title: 'Ulm',
    description: '',
    informations: '',
    street: 'Neue Straße 71',
    zip: '89073',
    citypart: '',
    addition: '',
    fon: '0731-6026344',
    fax: '0731-6026345',
    commonName: '',
    xCoordinates: '48.397656',
    yCoordinates: '9.9922104',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Ulm',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann Augenoptik AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 931,
    pid: 91,
    number: '001-0849',
    title: 'Rosenheim',
    description: '',
    informations: '',
    street: 'Max-Josefs-Platz 5',
    zip: '83022',
    citypart: '',
    addition: '',
    fon: '08031-396914',
    fax: '08031-396913',
    commonName: '',
    xCoordinates: '47.855418',
    yCoordinates: '12.126412',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Rosenheim',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 933,
    pid: 91,
    number: '001-0850',
    title: 'Siegen II, Am Bahnhof',
    description: '',
    informations: '',
    street: 'Am Bahnhof 40',
    zip: '57072',
    citypart: '',
    addition: '',
    fon: '0271-2382123',
    fax: '0271-2382124',
    commonName: 'City-Galerie',
    xCoordinates: '50.8745417',
    yCoordinates: '8.0156831',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Siegen',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. oHG City-Galerie',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 935,
    pid: 91,
    number: '001-0854',
    title: 'Saarbrücken',
    description: '',
    informations: '',
    street: 'Bahnhofstraße 61-63',
    zip: '66111',
    citypart: '',
    addition: '',
    fon: '0681-9388931',
    fax: '0681-9388932',
    commonName: '',
    xCoordinates: '49.2361815',
    yCoordinates: '6.9931023',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Saarbrücken',
    country: 'Deutschland',
    state: 'Saarland',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 937,
    pid: 91,
    number: '001-0835',
    title: 'Heilbronn',
    description: '',
    informations: '',
    street: 'Fleiner Straße 28',
    zip: '74072',
    citypart: '',
    addition: '',
    fon: '07131-676080',
    fax: '07131-676081',
    commonName: '',
    xCoordinates: '49.1408',
    yCoordinates: '9.21929',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Heilbronn',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 939,
    pid: 91,
    number: '001-0837',
    title: 'Marburg',
    description: '',
    informations: '',
    street: 'Markt 13',
    zip: '35037',
    citypart: 'Zentrum-Oberstadt',
    addition: '',
    fon: '06421-924737',
    fax: '06421-924736',
    commonName: '',
    xCoordinates: '50.809162',
    yCoordinates: '8.770545',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Marburg',
    country: 'Deutschland',
    state: 'Hessen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 941,
    pid: 91,
    number: '001-0839',
    title: 'Bottrop',
    description: '',
    informations: '',
    street: 'Hochstraße 39',
    zip: '46236',
    citypart: '',
    addition: '',
    fon: '02041-687936',
    fax: '02041-687937',
    commonName: '',
    xCoordinates: '51.5209986',
    yCoordinates: '6.9242269',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Bottrop',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 943,
    pid: 91,
    number: '001-0840',
    title: 'Neubrandenburg II, Marktplatz',
    description: '',
    informations: '',
    street: 'Marktplatz 2',
    zip: '17033',
    citypart: '',
    addition: '',
    fon: '0395-5823099',
    fax: '0395-5823105',
    commonName: 'Marktplatz-Center',
    xCoordinates: '53.5573835',
    yCoordinates: '13.2595059',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Neubrandenburg',
    country: 'Deutschland',
    state: 'Mecklenburg-Vorpommern',
    firmierung: 'Fielmann AG & Co. oHG Marktplatz-Center',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 945,
    pid: 91,
    number: '001-0842',
    title: 'Kempten',
    description: '',
    informations: '',
    street: 'Fischerstraße 28',
    zip: '87435',
    citypart: '',
    addition: '',
    fon: '0831-5209139',
    fax: '0831-5209140',
    commonName: '',
    xCoordinates: '47.7259534',
    yCoordinates: '10.3154268',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Kempten',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 947,
    pid: 91,
    number: '001-0844',
    title: 'Ettlingen II, Leopoldstr.',
    description: '',
    informations: '',
    street: 'Leopoldstr. 13',
    zip: '76275',
    citypart: '',
    addition: '',
    fon: '07243-535870',
    fax: '07243-535871',
    commonName: '',
    xCoordinates: '48.94033',
    yCoordinates: '8.40794',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Ettlingen',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 949,
    pid: 91,
    number: '001-0846',
    title: 'Amberg',
    description: '',
    informations: '',
    street: 'Georgenstraße 22',
    zip: '92224',
    citypart: '',
    addition: '',
    fon: '09621-420531',
    fax: '09621-420532',
    commonName: '',
    xCoordinates: '49.444794',
    yCoordinates: '11.855262',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Amberg',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann Augenoptik AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 951,
    pid: 91,
    number: '001-0848',
    title: 'Berlin XX, Am Borsigturm',
    description: '',
    informations: '',
    street: 'Am Borsigturm 2',
    zip: '13507',
    citypart: 'Tegel',
    addition: '',
    fon: '030-43402163',
    fax: '030-43402164',
    commonName: 'Hallen am Borsigturm',
    xCoordinates: '52.585359',
    yCoordinates: '13.286356',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Berlin',
    country: 'Deutschland',
    state: 'Berlin',
    firmierung: 'Fielmann AG & Co. oHG Tegel',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 953,
    pid: 91,
    number: '001-0851',
    title: 'Wetzlar',
    description: '',
    informations: '',
    street: 'Bahnhofstraße 8',
    zip: '35576',
    citypart: '',
    addition: '',
    fon: '06441-443367',
    fax: '06441-443368',
    commonName: '',
    xCoordinates: '50.5618033',
    yCoordinates: '8.5023953',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Wetzlar',
    country: 'Deutschland',
    state: 'Hessen',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 955,
    pid: 91,
    number: '001-0856',
    title: 'Speyer',
    description: '',
    informations: '',
    street: 'Maximilianstraße 31',
    zip: '67346',
    citypart: '',
    addition: '',
    fon: '06232-623453',
    fax: '06232-623473',
    commonName: '',
    xCoordinates: '49.317353',
    yCoordinates: '8.435643',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Speyer',
    country: 'Deutschland',
    state: 'Rheinland-Pfalz',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 957,
    pid: 91,
    number: '001-0858',
    title: 'Essen IV, Limbecker Str.',
    description: '',
    informations: '',
    street: 'Limbecker Straße 74',
    zip: '45127',
    citypart: 'Stadtkern',
    addition: '',
    fon: '0201-8277660',
    fax: '0201-8277677',
    commonName: '',
    xCoordinates: '51.45788',
    yCoordinates: '7.00783',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Essen',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. Zentrum KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 959,
    pid: 91,
    number: '001-0861',
    title: 'Frechen',
    description: '',
    informations: '',
    street: 'Hauptstraße 102',
    zip: '50226',
    citypart: '',
    addition: '',
    fon: '02234-27560',
    fax: '02234-275611',
    commonName: '',
    xCoordinates: '50.9096',
    yCoordinates: '6.8094101',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Frechen',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 961,
    pid: 91,
    number: '001-0865',
    title: 'Velbert',
    description: '',
    informations: '',
    street: 'Friedrichstraße 149',
    zip: '42551',
    citypart: '',
    addition: '',
    fon: '02051-312170',
    fax: '02051-312171',
    commonName: '',
    xCoordinates: '51.3401434',
    yCoordinates: '7.0460833',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Velbert',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 963,
    pid: 91,
    number: '001-0867',
    title: 'Bad Kreuznach',
    description: '',
    informations: '',
    street: 'Mannheimer Straße 153 - 155',
    zip: '55543',
    citypart: '',
    addition: '',
    fon: '0671-9200509',
    fax: '0671-9200514',
    commonName: '',
    xCoordinates: '49.84277',
    yCoordinates: '7.86163',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Bad Kreuznach',
    country: 'Deutschland',
    state: 'Rheinland-Pfalz',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 965,
    pid: 91,
    number: '001-0869',
    title: 'Dessau II, Kavalierstr.',
    description: '',
    informations: '',
    street: 'Kavalierstraße 49',
    zip: '06844',
    citypart: 'Dessau',
    addition: '',
    fon: '0340-8507760',
    fax: '0340-8507770',
    commonName: 'Rathaus-Center',
    xCoordinates: '51.833995',
    yCoordinates: '12.24395',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Dessau-Roßlau',
    country: 'Deutschland',
    state: 'Sachsen-Anhalt',
    firmierung: 'Fielmann AG & Co. oHG Kavalierstraße',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 967,
    pid: 91,
    number: '001-0870',
    title: 'Lüneburg II, Große Bäckerstr.',
    description: '',
    informations: '',
    street: 'Große Bäckerstraße 2 - 4',
    zip: '21335',
    citypart: '',
    addition: '',
    fon: '04131-220980',
    fax: '04131-2209811',
    commonName: '',
    xCoordinates: '53.2498',
    yCoordinates: '10.40874',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Lüneburg',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 969,
    pid: 91,
    number: '001-0872',
    title: 'Gießen',
    description: '',
    informations: '',
    street: 'Seltersweg 61',
    zip: '35390',
    citypart: '',
    addition: '',
    fon: '0641-972680',
    fax: '0641-9726810',
    commonName: '',
    xCoordinates: '50.583298',
    yCoordinates: '8.672063',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Gießen',
    country: 'Deutschland',
    state: 'Hessen',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 971,
    pid: 91,
    number: '001-0874',
    title: 'Wuppertal V, City-Arkaden',
    description: '',
    informations: '',
    street: 'Alte Freiheit 9',
    zip: '42103',
    citypart: 'Elberfeld',
    addition: '',
    fon: '0202-9464810',
    fax: '0202-9464820',
    commonName: 'City-Arkaden',
    xCoordinates: '51.256794',
    yCoordinates: '7.149063',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Wuppertal',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. City-Arkaden OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 973,
    pid: 91,
    number: '001-0855',
    title: 'Aschaffenburg I, Herstallstr.',
    description: '',
    informations: '',
    street: 'Herstallstraße 37',
    zip: '63739',
    citypart: '',
    addition: '',
    fon: '06021-4426630',
    fax: '06021-4426633',
    commonName: '',
    xCoordinates: '49.9761459',
    yCoordinates: '9.1472505',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Aschaffenburg',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 975,
    pid: 91,
    number: '001-0857',
    title: 'Passau',
    description: '',
    informations: '',
    street: 'Grabengasse 2',
    zip: '94032',
    citypart: '',
    addition: '',
    fon: '0851-9347582',
    fax: '0851-9347583',
    commonName: '',
    xCoordinates: '48.574784',
    yCoordinates: '13.460442',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Passau',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 977,
    pid: 91,
    number: '001-0859',
    title: 'Dormagen',
    description: '',
    informations: '',
    street: 'Kölner Straße 107',
    zip: '41539',
    citypart: '',
    addition: '',
    fon: '02133-450052',
    fax: '02133-450188',
    commonName: '',
    xCoordinates: '51.0942457',
    yCoordinates: '6.8414523',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Dormagen',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 979,
    pid: 91,
    number: '001-0860',
    title: 'München VI, Ollenhauerstraße',
    description: '',
    informations: '',
    street: 'Ollenhauerstraße 6',
    zip: '81737',
    citypart: 'Neuperlach',
    addition: '',
    fon: '089-63849883',
    fax: '089-63849887',
    commonName: 'Perlacher Einkaufspassagen PEP',
    xCoordinates: '48.101442',
    yCoordinates: '11.644654',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'München',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. oHG München PEP',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 981,
    pid: 91,
    number: '001-0862',
    title: 'Rostock II, Lütten Klein',
    description: '',
    informations: '',
    street: 'Warnowallee 31 b',
    zip: '18107',
    citypart: 'Lütten-Klein',
    addition: '',
    fon: '0381-207460',
    fax: '0381-2074611',
    commonName: '',
    xCoordinates: '54.1387958',
    yCoordinates: '12.0552635',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '4100',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '4100',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Rostock',
    country: 'Deutschland',
    state: 'Mecklenburg-Vorpommern',
    firmierung: 'Fielmann AG & Co. oHG Lütten Klein',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 983,
    pid: 91,
    number: '001-0864',
    title: 'Deggendorf',
    description: '',
    informations: '',
    street: 'Rosengasse 1',
    zip: '94469',
    citypart: '',
    addition: '',
    fon: '0991-3719130',
    fax: '0991-3719133',
    commonName: '',
    xCoordinates: '48.832209',
    yCoordinates: '12.962513',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Deggendorf',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 985,
    pid: 91,
    number: '001-0866',
    title: 'Castrop-Rauxel',
    description: '',
    informations: '',
    street: 'Münsterstraße 4',
    zip: '44575',
    citypart: '',
    addition: '',
    fon: '02305-9472-0',
    fax: '02305-9472-11',
    commonName: '',
    xCoordinates: '51.5498967',
    yCoordinates: '7.3096191',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Castrop-Rauxel',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 987,
    pid: 91,
    number: '001-0868',
    title: 'Garmisch-Partenkirchen',
    description: '',
    informations: '',
    street: 'Am Kurpark 11',
    zip: '82467',
    citypart: '',
    addition: '',
    fon: '08821-9432560',
    fax: '08821-9432570',
    commonName: '',
    xCoordinates: '47.49406',
    yCoordinates: '11.090676',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Garmisch-Partenkirchen',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 989,
    pid: 91,
    number: '001-0871',
    title: 'Friedrichshafen',
    description: '',
    informations: '',
    street: 'Karlstraße 47',
    zip: '88045',
    citypart: '',
    addition: '',
    fon: '07541-3879130',
    fax: '07541-3879140',
    commonName: '',
    xCoordinates: '47.650049',
    yCoordinates: '9.481379',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Friedrichshafen',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 991,
    pid: 91,
    number: '001-0876',
    title: 'Eutin',
    description: '',
    informations: '',
    street: 'Peterstr. 3',
    zip: '23701',
    citypart: '',
    addition: '',
    fon: '04521-409992',
    fax: '04521-776563',
    commonName: '',
    xCoordinates: '54.1359273',
    yCoordinates: '10.6161397',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Eutin',
    country: 'Deutschland',
    state: 'Schleswig-Holstein',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 993,
    pid: 91,
    number: '001-0878',
    title: 'Neumarkt i. d. OPf.',
    description: '',
    informations: '',
    street: 'Obere Marktstraße 32',
    zip: '92318',
    citypart: '',
    addition: '',
    fon: '09181-26370',
    fax: '09181-263729',
    commonName: '',
    xCoordinates: '49.279273',
    yCoordinates: '11.45922',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Neumarkt i. d. OPf.',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 995,
    pid: 91,
    number: '001-0881',
    title: 'Böblingen',
    description: '',
    informations: '',
    street: 'Wolfgang-Brumme-Allee 27',
    zip: '71034',
    citypart: '',
    addition: '',
    fon: '07031-4104910',
    fax: '07031-4104911',
    commonName: 'Mercaden',
    xCoordinates: '48.687263',
    yCoordinates: '9.006122',
    opening: false,
    opening_box: false,
    openingText: '<p></p>',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Böblingen',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 997,
    pid: 91,
    number: '001-0883',
    title: 'Hamburg-Harburg III',
    description: '',
    informations: '',
    street: 'Lüneburger Straße 23',
    zip: '21073',
    citypart: 'Harburg',
    addition: '',
    fon: '040-779777',
    fax: '040-779134',
    commonName: '',
    xCoordinates: '53.458625',
    yCoordinates: '9.982811',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Hamburg',
    country: 'Deutschland',
    state: 'Hamburg',
    firmierung: 'Fielmann Augenoptik AG & Co. oHG Harburg-City',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 999,
    pid: 91,
    number: '001-0885',
    title: 'Bonn III, Remigiusstr.',
    description: '',
    informations: '',
    street: 'Remigiusstr. 16',
    zip: '53111',
    citypart: 'Zentrum',
    addition: '',
    fon: '0228-9082410',
    fax: '0228-9082411',
    commonName: '',
    xCoordinates: '50.73471',
    yCoordinates: '7.10061',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Bonn',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1001,
    pid: 91,
    number: '001-0887',
    title: 'Heinsberg',
    description: '',
    informations: '',
    street: 'Hochstraße 129',
    zip: '52525',
    citypart: '',
    addition: '',
    fon: '02452-977490',
    fax: '02452-9774929',
    commonName: '',
    xCoordinates: '51.0633985',
    yCoordinates: '6.0963709',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Heinsberg',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1003,
    pid: 91,
    number: '001-0889',
    title: 'Bergisch Gladbach',
    description: '',
    informations: '',
    street: 'Hauptstraße 142',
    zip: '51465',
    citypart: '',
    addition: '',
    fon: '02202-2396-0',
    fax: '02202-2396-29',
    commonName: '',
    xCoordinates: '50.9904646',
    yCoordinates: '7.1258486',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Bergisch Gladbach',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1005,
    pid: 91,
    number: '001-0890',
    title: 'Mönchengladbach IV, Hindenburg',
    description: '',
    informations: '',
    street: 'Hindenburgstraße 104',
    zip: '41061',
    citypart: '',
    addition: '',
    fon: '02161-181073',
    fax: '02161-16028',
    commonName: '',
    xCoordinates: '51.19448',
    yCoordinates: '6.43986',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Mönchengladbach',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. oHG Hindenburgstraße',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1007,
    pid: 91,
    number: '001-0892',
    title: 'Bielefeld IV, Hauptstr.',
    description: '',
    informations: '',
    street: 'Hauptstraße 73',
    zip: '33647',
    citypart: 'Brackwede',
    addition: '',
    fon: '0521-16473-0',
    fax: '0521-16473-29',
    commonName: '',
    xCoordinates: '51.9896205',
    yCoordinates: '8.5118367',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Bielefeld',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. Brackwede KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1009,
    pid: 91,
    number: '001-0873',
    title: 'Euskirchen',
    description: '',
    informations: '',
    street: 'Neustr. 41',
    zip: '53879',
    citypart: '',
    addition: '',
    fon: '02251-6503310',
    fax: '02251-6503320',
    commonName: '',
    xCoordinates: '50.6603787',
    yCoordinates: '6.7902',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Euskirchen',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1011,
    pid: 91,
    number: '001-0875',
    title: 'Freising',
    description: '',
    informations: '',
    street: 'Untere Hauptstraße 35',
    zip: '85354',
    citypart: '',
    addition: '',
    fon: '08161-14180',
    fax: '08161-141810',
    commonName: '',
    xCoordinates: '48.4016317',
    yCoordinates: '11.747724',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Freising',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1013,
    pid: 91,
    number: '001-0877',
    title: 'Arnsberg-Neheim',
    description: '',
    informations: '',
    street: 'Hauptstraße 33',
    zip: '59755',
    citypart: 'Neheim',
    addition: '',
    fon: '02932-4759610',
    fax: '02932-4759611',
    commonName: '',
    xCoordinates: '51.450706',
    yCoordinates: '7.965611',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Arnsberg',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1015,
    pid: 91,
    number: '001-0879',
    title: 'München VII, Plinganserstr.',
    description: '',
    informations: '',
    street: 'Plinganserstraße 51',
    zip: '81369',
    citypart: 'Sendling',
    addition: '',
    fon: '089-76757510',
    fax: '089-76757511',
    commonName: 'Harras',
    xCoordinates: '48.116831',
    yCoordinates: '11.540374',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'München',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. oHG Sendling',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1017,
    pid: 91,
    number: '001-0880',
    title: 'Hilden',
    description: '',
    informations: '',
    street: 'Mittelstr. 49-51',
    zip: '40721',
    citypart: '',
    addition: '',
    fon: '02103-258810',
    fax: '02103-258811',
    commonName: '',
    xCoordinates: '51.1689808',
    yCoordinates: '6.935613',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Hilden',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1019,
    pid: 91,
    number: '001-0882',
    title: 'Waiblingen',
    description: '',
    informations: '',
    street: 'Kurze Straße 40',
    zip: '71332',
    citypart: '',
    addition: '',
    fon: '07151-98629-0',
    fax: '07151-98629-10',
    commonName: '',
    xCoordinates: '48.8312147',
    yCoordinates: '9.3154899',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Waiblingen',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1021,
    pid: 91,
    number: '001-0884',
    title: 'Bad Salzuflen',
    description: '',
    informations: '',
    street: 'Lange Straße 45',
    zip: '32105',
    citypart: '',
    addition: '',
    fon: '05222-36963-0',
    fax: '05222-36963-29',
    commonName: '',
    xCoordinates: '52.084837',
    yCoordinates: '8.745307',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Bad Salzuflen',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1023,
    pid: 91,
    number: '001-0886',
    title: 'Balingen',
    description: '',
    informations: '',
    street: 'Friedrichstraße 55',
    zip: '72336',
    citypart: '',
    addition: '',
    fon: '07433-904630',
    fax: '07433-9046329',
    commonName: '',
    xCoordinates: '48.2719952',
    yCoordinates: '8.8509833',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Balingen',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & Co. KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1025,
    pid: 91,
    number: '001-0888',
    title: 'Tübingen',
    description: '',
    informations: '',
    street: 'Kirchgasse 11',
    zip: '72070',
    citypart: '',
    addition: '',
    fon: '07071-989810',
    fax: '07071-9898129',
    commonName: '',
    xCoordinates: '48.520401',
    yCoordinates: '9.05509',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Tübingen',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & Co. KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1027,
    pid: 91,
    number: '001-0894',
    title: 'Kirchheim unter Teck',
    description: '',
    informations: '',
    street: 'Marktstraße 41',
    zip: '73230',
    citypart: '',
    addition: '',
    fon: '07021-72660',
    fax: '07021-726629',
    commonName: '',
    xCoordinates: '48.6470559',
    yCoordinates: '9.4515246',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Kirchheim unter Teck',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1029,
    pid: 91,
    number: '001-0896',
    title: 'Berlin XXI, Wilmersdorf',
    description: '',
    informations: '',
    street: 'Wilmersdorfer Straße 57',
    zip: '10627',
    citypart: 'Charlottenburg',
    addition: '',
    fon: '030-315191-30',
    fax: '030-315191-59',
    commonName: '',
    xCoordinates: '52.5076915',
    yCoordinates: '13.3059639',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Berlin',
    country: 'Deutschland',
    state: 'Berlin',
    firmierung: 'Fielmann AG & Co. Wilmersdorf KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1031,
    pid: 91,
    number: '001-0898',
    title: 'Bingen am Rhein',
    description: '',
    informations: '',
    street: 'Speisemarkt 9',
    zip: '55411',
    citypart: '',
    addition: '',
    fon: '06721-1871-0',
    fax: '06721-1871-29',
    commonName: '',
    xCoordinates: '49.9675919',
    yCoordinates: '7.8953089',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Bingen am Rhein',
    country: 'Deutschland',
    state: 'Rheinland-Pfalz',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1033,
    pid: 91,
    number: '001-0902',
    title: 'Bad Oeynhausen',
    description: '',
    informations: '',
    street: 'Mindener Straße 22',
    zip: '32547',
    citypart: '',
    addition: '',
    fon: '05731-869570',
    fax: '05731-8695729',
    commonName: 'Werre-Park',
    xCoordinates: '52.2125387',
    yCoordinates: '8.812332',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Bad Oeynhausen',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1035,
    pid: 91,
    number: '001-0904',
    title: 'Schwabach',
    description: '',
    informations: '',
    street: 'Königsplatz 25',
    zip: '91126',
    citypart: '',
    addition: '',
    fon: '09122-87820',
    fax: '09122-878229',
    commonName: '',
    xCoordinates: '49.328747',
    yCoordinates: '11.020765',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Schwabach',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1037,
    pid: 91,
    number: '001-0906',
    title: 'Bad Mergentheim',
    description: '',
    informations: '',
    street: 'Marktplatz 7',
    zip: '97980',
    citypart: '',
    addition: '',
    fon: '07931-93230',
    fax: '07931-932329',
    commonName: '',
    xCoordinates: '49.490792',
    yCoordinates: '9.773295',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Bad Mergentheim',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1039,
    pid: 91,
    number: '001-0908',
    title: 'Berlin XXII, Badstrasse',
    description: '',
    informations: '',
    street: 'Badstraße 4',
    zip: '13357',
    citypart: 'Wedding',
    addition: '',
    fon: '030-49301130',
    fax: '030-49301159',
    commonName: 'Gesundbrunnen-Center',
    xCoordinates: '52.549615',
    yCoordinates: '13.387904',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Berlin',
    country: 'Deutschland',
    state: 'Berlin',
    firmierung: 'Fielmann AG & Co. Gesundbrunnen-Center KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1041,
    pid: 91,
    number: '001-0911',
    title: 'Neu-Isenburg',
    description: '',
    informations: '',
    street: 'Hermesstraße 4',
    zip: '63263',
    citypart: '',
    addition: '',
    fon: '06102-372610',
    fax: '06102-3726129',
    commonName: 'Isenburg-Zentrum',
    xCoordinates: '50.0494949',
    yCoordinates: '8.6979267',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Neu-Isenburg',
    country: 'Deutschland',
    state: 'Hessen',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1043,
    pid: 91,
    number: '001-0913',
    title: 'Darmstadt III, Ludwigsplatz',
    description: '',
    informations: '',
    street: 'Ludwigsplatz 1a',
    zip: '64283',
    citypart: 'Zentrum',
    addition: '',
    fon: '06151-396890',
    fax: '06151-3968929',
    commonName: '',
    xCoordinates: '49.8708395',
    yCoordinates: '8.653889',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Darmstadt',
    country: 'Deutschland',
    state: 'Hessen',
    firmierung: 'Fielmann AG & Co. oHG Ludwigsplatz',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1045,
    pid: 91,
    number: '001-0891',
    title: 'Plön',
    description: '',
    informations: '',
    street: 'Lange Straße 7',
    zip: '24306',
    citypart: '',
    addition: '',
    fon: '04522-5031-0',
    fax: '04522-5031-29',
    commonName: '',
    xCoordinates: '54.158087',
    yCoordinates: '10.415317',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Plön',
    country: 'Deutschland',
    state: 'Schleswig-Holstein',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1047,
    pid: 91,
    number: '001-0893',
    title: 'München VIII, Hanauer Str',
    description: '',
    informations: '',
    street: 'Hanauer Straße 68',
    zip: '80993',
    citypart: 'Moosach',
    addition: '',
    fon: '089-143472-0',
    fax: '089-143472-29',
    commonName: 'Olympia Einkaufszentrum OEZ',
    xCoordinates: '48.183277',
    yCoordinates: '11.533015',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'München',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. oHG München OEZ',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1049,
    pid: 91,
    number: '001-0895',
    title: 'Bietigheim-Bissingen',
    description: '',
    informations: '',
    street: 'Hauptstraße 41',
    zip: '74321',
    citypart: '',
    addition: '',
    fon: '07142-9105-30',
    fax: '07142-9105-50',
    commonName: '',
    xCoordinates: '48.959409',
    yCoordinates: '9.127434',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Bietigheim-Bissingen',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1051,
    pid: 91,
    number: '001-0897',
    title: 'Augsburg III, Willy-Brandt-Pl.',
    description: '',
    informations: '',
    street: 'Willy-Brandt-Platz 1',
    zip: '86153',
    citypart: '',
    addition: '',
    fon: '0821-56706-60',
    fax: '0821-56706-89',
    commonName: 'City-Galerie',
    xCoordinates: '48.365914',
    yCoordinates: '10.906116',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Augsburg',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. oHG City-Galerie',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1053,
    pid: 91,
    number: '001-0899',
    title: 'Sonthofen',
    description: '',
    informations: '',
    street: 'Bahnhofstraße 3',
    zip: '87527',
    citypart: '',
    addition: '',
    fon: '08321-675400',
    fax: '08321-6754029',
    commonName: '',
    xCoordinates: '47.51185',
    yCoordinates: '10.27828',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Sonthofen',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1055,
    pid: 91,
    number: '001-0901',
    title: 'Herne II, Bahnhofstraße',
    description: '',
    informations: '',
    street: 'Bahnhofstr. 58',
    zip: '44623',
    citypart: 'Zentrum',
    addition: '',
    fon: '02323-14768-0',
    fax: '02323-14768-29',
    commonName: '',
    xCoordinates: '51.5408974',
    yCoordinates: '7.2224713',
    opening: false,
    opening_box: false,
    openingText: '\r\n',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Herne',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. oHG im Centrum',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1057,
    pid: 91,
    number: '001-0903',
    title: 'Köln VII, Kalker Hauptstr',
    description: '',
    informations: '',
    street: 'Kalker Hauptstraße 55',
    zip: '51103',
    citypart: 'Kalk',
    addition: '',
    fon: '0221-4602320',
    fax: '0221-46023229',
    commonName: 'Köln-Arcaden',
    xCoordinates: '50.938541',
    yCoordinates: '6.998527',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Köln',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. oHG Kalk',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1059,
    pid: 91,
    number: '001-0905',
    title: 'Freiburg III, K.-Joseph-Str.',
    description: '',
    informations: '',
    street: 'Kaiser-Joseph-Str. 193',
    zip: '79098',
    citypart: '',
    addition: '',
    fon: '0761-1506380',
    fax: '0761-15063899',
    commonName: '',
    xCoordinates: '47.995271',
    yCoordinates: '7.850561',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Freiburg im Breisgau',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1061,
    pid: 91,
    number: '001-0907',
    title: 'Sindelfingen, Stern Center',
    description: '',
    informations: '',
    street: 'Mercedesstraße 12',
    zip: '71063',
    citypart: '',
    addition: '',
    fon: '07031-81731-0',
    fax: '07031-81731-29',
    commonName: 'Stern Center',
    xCoordinates: '48.706128',
    yCoordinates: '8.999395',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Sindelfingen',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & Co. Stern Center OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1063,
    pid: 91,
    number: '001-0915',
    title: 'Bad Neuenahr-Ahrweiler',
    description: '',
    informations: '',
    street: 'Poststraße 12',
    zip: '53474',
    citypart: '',
    addition: '',
    fon: '02641-914860',
    fax: '02641-9148629',
    commonName: '',
    xCoordinates: '50.5451956',
    yCoordinates: '7.1374845',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Bad Neuenahr-Ahrweiler',
    country: 'Deutschland',
    state: 'Rheinland-Pfalz',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1065,
    pid: 91,
    number: '001-0917',
    title: 'Lübbecke',
    description: '',
    informations: '',
    street: 'Lange Straße 26',
    zip: '32312',
    citypart: '',
    addition: '',
    fon: '05741-236430',
    fax: '05741-2364329',
    commonName: '',
    xCoordinates: '52.30419',
    yCoordinates: '8.61697',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Lübbecke',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1067,
    pid: 91,
    number: '001-0919',
    title: 'Karlsruhe II, Westl. Kaiserstr',
    description: '',
    informations: '',
    street: 'Kaiserstraße 163',
    zip: '76133',
    citypart: '',
    addition: '',
    fon: '0721-5704590',
    fax: '0721-57045929',
    commonName: '',
    xCoordinates: '49.0095335',
    yCoordinates: '8.4007433',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Karlsruhe',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & Co. Westliche Kaiserstraße KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1069,
    pid: 91,
    number: '001-0920',
    title: 'Freudenstadt',
    description: '',
    informations: '',
    street: 'Loßburger Straße 13',
    zip: '72250',
    citypart: '',
    addition: '',
    fon: '07441-860560',
    fax: '07441-8605629',
    commonName: '',
    xCoordinates: '48.4619501',
    yCoordinates: '8.4128778',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Freudenstadt',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1071,
    pid: 91,
    number: '001-0922',
    title: 'Meiningen',
    description: '',
    informations: '',
    street: 'Georgstraße 24',
    zip: '98617',
    citypart: '',
    addition: '',
    fon: '03693-885870',
    fax: '03693-8858729',
    commonName: '',
    xCoordinates: '50.568973',
    yCoordinates: '10.4152073',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Meiningen',
    country: 'Deutschland',
    state: 'Thüringen',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1073,
    pid: 91,
    number: '001-0924',
    title: 'Bad Saulgau',
    description: '',
    informations: '',
    street: 'Hauptstraße 72',
    zip: '88348',
    citypart: '',
    addition: '',
    fon: '07581-483640',
    fax: '07581-4836429',
    commonName: '',
    xCoordinates: '48.0168892',
    yCoordinates: '9.5001503',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Bad Saulgau',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1075,
    pid: 91,
    number: '001-0926',
    title: 'Überlingen',
    description: '',
    informations: '',
    street: 'Münsterstraße 25',
    zip: '88662',
    citypart: '',
    addition: '',
    fon: '07551-3011930',
    fax: '07551-3011959',
    commonName: '',
    xCoordinates: '47.76664',
    yCoordinates: '9.16129',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Überlingen',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1077,
    pid: 91,
    number: '001-0928',
    title: 'Limburg',
    description: '',
    informations: '',
    street: 'Werner-Senger-Str. 2',
    zip: '65549',
    citypart: '',
    addition: '',
    fon: '06431-219420',
    fax: '06431-2194229',
    commonName: '',
    xCoordinates: '50.3870974',
    yCoordinates: '8.0628871',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Limburg',
    country: 'Deutschland',
    state: 'Hessen',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1079,
    pid: 91,
    number: '001-0931',
    title: 'Neuburg a.d. Donau',
    description: '',
    informations: '',
    street: 'Färberstr. 4',
    zip: '86633',
    citypart: '',
    addition: '',
    fon: '08431-536500',
    fax: '08431-5365029',
    commonName: '',
    xCoordinates: '48.7343122',
    yCoordinates: '11.1824463',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Neuburg a.d. Donau',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1081,
    pid: 91,
    number: '001-0909',
    title: 'Ravensburg',
    description: '',
    informations: '',
    street: 'Bachstraße 8',
    zip: '88214',
    citypart: '',
    addition: '',
    fon: '0751-9771830',
    fax: '0751-9771859',
    commonName: '',
    xCoordinates: '47.7820277',
    yCoordinates: '9.6122621',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Ravensburg',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1083,
    pid: 91,
    number: '001-0910',
    title: 'Brühl',
    description: '',
    informations: '',
    street: 'Markt 3 - 5',
    zip: '50321',
    citypart: '',
    addition: '',
    fon: '02232-209930',
    fax: '02232-209959',
    commonName: '',
    xCoordinates: '50.82818',
    yCoordinates: '6.90438',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Brühl',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1085,
    pid: 91,
    number: '001-0914',
    title: 'Dachau',
    description: '',
    informations: '',
    street: 'Münchner Straße 42 a',
    zip: '85221',
    citypart: '',
    addition: '',
    fon: '08131-9076860',
    fax: '08131-9076889',
    commonName: '',
    xCoordinates: '48.253751',
    yCoordinates: '11.4401658',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Dachau',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1087,
    pid: 91,
    number: '001-0916',
    title: 'Köln VIII, Chorweiler',
    description: '',
    informations: '',
    street: 'Mailänder Passage 1',
    zip: '50765',
    citypart: 'Chorweiler',
    addition: '',
    fon: '0221-5343980',
    fax: '0221-53439829',
    commonName: 'City-Center',
    xCoordinates: '51.021734',
    yCoordinates: '6.896915',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Köln',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. Chorweiler KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1089,
    pid: 91,
    number: '001-0918',
    title: 'Leipzig IV, Allee Center',
    description: '',
    informations: '',
    street: 'Ludwigsburger Straße 9',
    zip: '04209',
    citypart: 'Grünau',
    addition: '',
    fon: '0341-4205170',
    fax: '0341-42051729',
    commonName: 'Allee-Center',
    xCoordinates: '51.319216',
    yCoordinates: '12.290289',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Leipzig',
    country: 'Deutschland',
    state: 'Sachsen',
    firmierung: 'Fielmann AG & Co. oHG Allee-Center',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1091,
    pid: 91,
    number: '001-0921',
    title: 'Görlitz II, Berliner Str.',
    description: '',
    informations: '',
    street: 'Berliner Straße 61',
    zip: '02826',
    citypart: '',
    addition: '',
    fon: '03581-764640',
    fax: '03581-7646429',
    commonName: '',
    xCoordinates: '51.15136',
    yCoordinates: '14.9841871',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Görlitz',
    country: 'Deutschland',
    state: 'Sachsen',
    firmierung: 'Fielmann AG & Co. Centrum OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1093,
    pid: 91,
    number: '001-0923',
    title: 'Berlin XXIII, Märk. Zentrum',
    description: '',
    informations: '',
    street: 'Wilhelmsruher Damm 136',
    zip: '13439',
    citypart: 'Reinickendorf',
    addition: '',
    fon: '030-4072826-0',
    fax: '030-4072826-29',
    commonName: 'Märkisches Zentrum',
    xCoordinates: '52.597449',
    yCoordinates: '13.355014',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Berlin',
    country: 'Deutschland',
    state: 'Berlin',
    firmierung: 'Fielmann AG & Co. Märkisches Zentrum KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1095,
    pid: 91,
    number: '001-0925',
    title: 'Aue',
    description: '',
    informations: '',
    street: 'Wettinerstraße 2',
    zip: '08280',
    citypart: '',
    addition: '',
    fon: '03771-24651-0',
    fax: '03771-24651-29',
    commonName: '',
    xCoordinates: '50.586342',
    yCoordinates: '12.701508',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Aue',
    country: 'Deutschland',
    state: 'Sachsen',
    firmierung: 'Fielmann AG & Co. KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1097,
    pid: 91,
    number: '001-0927',
    title: 'Weinheim II, Hauptstr.',
    description: '',
    informations: '',
    street: 'Hauptstraße 75',
    zip: '69469',
    citypart: '',
    addition: '',
    fon: '06201-843270',
    fax: '06201-8432729',
    commonName: '',
    xCoordinates: '49.54857',
    yCoordinates: '8.67231',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Weinheim',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1099,
    pid: 91,
    number: '001-0933',
    title: 'Goch',
    description: '',
    informations: '',
    street: 'Voßstraße 20',
    zip: '47574',
    citypart: '',
    addition: '',
    fon: '02823-976490',
    fax: '02823-9764929',
    commonName: '',
    xCoordinates: '51.6751157',
    yCoordinates: '6.1569032',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Goch',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1101,
    pid: 91,
    number: '001-0935',
    title: 'Oberursel',
    description: '',
    informations: '',
    street: 'Vorstadt 11 a',
    zip: '61440',
    citypart: '',
    addition: '',
    fon: '06171-695930',
    fax: '06171-6959329',
    commonName: '',
    xCoordinates: '50.2013076',
    yCoordinates: '8.5818454',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Oberursel',
    country: 'Deutschland',
    state: 'Hessen',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1103,
    pid: 91,
    number: '001-0937',
    title: 'Chemnitz II, Vita-Center',
    description: '',
    informations: '',
    street: 'Wladimir-Sagorski-Straße 22',
    zip: '09122',
    citypart: 'Chemnitz-Süd',
    addition: '',
    fon: '0371-2781650',
    fax: '0371-27816529',
    commonName: 'Vita-Center',
    xCoordinates: '50.7957099',
    yCoordinates: '12.8898198',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Chemnitz',
    country: 'Deutschland',
    state: 'Sachsen',
    firmierung: 'Fielmann AG & Co. Vita-Center KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1105,
    pid: 91,
    number: '001-0939',
    title: 'Rottenburg',
    description: '',
    informations: '',
    street: 'Marktplatz 23',
    zip: '72108',
    citypart: '',
    addition: '',
    fon: '07472-936650',
    fax: '07472-9366529',
    commonName: '',
    xCoordinates: '48.476838',
    yCoordinates: '8.933524',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Rottenburg',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1107,
    pid: 91,
    number: '001-0942',
    title: 'Enschede',
    description: '',
    informations: '',
    street: 'Kalanderstraat 17',
    zip: '7511 HW',
    citypart: '',
    addition: '',
    fon: '053-4342676',
    fax: '053-4349001',
    commonName: '',
    xCoordinates: '52.2187897',
    yCoordinates: '6.8988859',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Enschede',
    country: 'Niederlande',
    state: 'Overijssel',
    firmierung: 'Fielmann B.V.',
    importantMessageHeader: '',
    importantMessageText: '',
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: []
  },
  {
    uid: 1109,
    pid: 91,
    number: '001-0944',
    title: 'Emmen',
    description: '',
    informations: '',
    street: 'Picassopassage 74',
    zip: '7811 DP',
    citypart: '',
    addition: '',
    fon: '0591-670850',
    fax: '0591-670860',
    commonName: '',
    xCoordinates: '52.7833451',
    yCoordinates: '6.8950467',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Emmen',
    country: 'Niederlande',
    state: 'Drenthe',
    firmierung: 'Fielmann B.V.',
    importantMessageHeader: '',
    importantMessageText: '',
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: []
  },
  {
    uid: 1111,
    pid: 91,
    number: '001-0971',
    title: 'Luxemburg',
    description: '',
    informations: '',
    street: '9-11 Grand-Rue',
    zip: '1661',
    citypart: '',
    addition: '',
    fon: '26267100',
    fax: '262671029',
    commonName: '',
    xCoordinates: '49.6117863',
    yCoordinates: '6.1317955',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Luxemburg',
    country: 'Luxemburg',
    state: 'LU-L',
    firmierung: 'Fielmann GmbH',
    importantMessageHeader: '',
    importantMessageText: '',
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: []
  },
  {
    uid: 1113,
    pid: 91,
    number: '001-2001',
    title: 'Bad Reichenhall',
    description: '',
    informations: '',
    street: 'Ludwigstraße 20',
    zip: '83435',
    citypart: '',
    addition: '',
    fon: '08651-76255-0',
    fax: '08651-76255-29',
    commonName: '',
    xCoordinates: '47.724301',
    yCoordinates: '12.879166',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Bad Reichenhall',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1115,
    pid: 91,
    number: '001-2003',
    title: 'Bitburg',
    description: '',
    informations: '',
    street: 'Hauptstraße 33',
    zip: '54634',
    citypart: '',
    addition: '',
    fon: '06561-946400',
    fax: '06561-9464029',
    commonName: '',
    xCoordinates: '49.9736211',
    yCoordinates: '6.5243881',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Bitburg',
    country: 'Deutschland',
    state: 'Rheinland-Pfalz',
    firmierung: 'Fielmann Augenoptik AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1117,
    pid: 91,
    number: '001-0929',
    title: 'Traunstein',
    description: '',
    informations: '',
    street: 'Maximilianstraße 17',
    zip: '83278',
    citypart: '',
    addition: '',
    fon: '0861-1661510',
    fax: '0861-16615129',
    commonName: '',
    xCoordinates: '47.86812',
    yCoordinates: '12.6455881',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Traunstein',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1119,
    pid: 91,
    number: '001-0930',
    title: 'Reichenbach im Vogtland',
    description: '',
    informations: '',
    street: 'Zwickauer Straße 14',
    zip: '08468',
    citypart: '',
    addition: '',
    fon: '03765-386940',
    fax: '03765-3869429',
    commonName: '',
    xCoordinates: '50.623397',
    yCoordinates: '12.303036',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Reichenbach im Vogtland',
    country: 'Deutschland',
    state: 'Sachsen',
    firmierung: 'Fielmann AG & Co. KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1121,
    pid: 91,
    number: '001-0932',
    title: 'Arnstadt',
    description: '',
    informations: '',
    street: 'Erfurter Str. 11',
    zip: '99310',
    citypart: '',
    addition: '',
    fon: '03628-58866-60',
    fax: '03628-58866-89',
    commonName: '',
    xCoordinates: '50.8343506',
    yCoordinates: '10.9474232',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Arnstadt',
    country: 'Deutschland',
    state: 'Thüringen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1123,
    pid: 91,
    number: '001-0934',
    title: 'Auerbach/Vogtl.',
    description: '',
    informations: '',
    street: 'Nicolaistraße 15',
    zip: '08209',
    citypart: '',
    addition: '',
    fon: '03744-183560',
    fax: '03744-1835629',
    commonName: '',
    xCoordinates: '50.508601',
    yCoordinates: '12.3994474',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Auerbach-Vogtl.',
    country: 'Deutschland',
    state: 'Sachsen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1125,
    pid: 91,
    number: '001-0936',
    title: 'Oberhausen II, Sterkrade',
    description: '',
    informations: '',
    street: 'Bahnhofstraße 40',
    zip: '46145',
    citypart: 'Sterkrade',
    addition: '',
    fon: '0208-666408',
    fax: '0208-666503',
    commonName: '',
    xCoordinates: '51.5146327',
    yCoordinates: '6.8466131',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Oberhausen',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. oHG Sterkrade',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1127,
    pid: 91,
    number: '001-0938',
    title: 'Ehingen',
    description: '',
    informations: '',
    street: 'Hauptstraße 57',
    zip: '89584',
    citypart: '',
    addition: '',
    fon: '07391-587600',
    fax: '07391-5876029',
    commonName: '',
    xCoordinates: '48.2838243',
    yCoordinates: '9.7276941',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Ehingen',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1129,
    pid: 91,
    number: '001-0943',
    title: 'Nijmegen',
    description: '',
    informations: '',
    street: 'Broerstraat 31',
    zip: '6511 KL',
    citypart: '',
    addition: '',
    fon: '024-3823270',
    fax: '024-3823271',
    commonName: '',
    xCoordinates: '51.8461462',
    yCoordinates: '5.8644558',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Nijmegen',
    country: 'Niederlande',
    state: 'Gelderland',
    firmierung: 'Fielmann B.V.',
    importantMessageHeader: '',
    importantMessageText: '',
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: []
  },
  {
    uid: 1131,
    pid: 91,
    number: '001-0970',
    title: 'Esch sur Alzette',
    description: '',
    informations: '',
    street: '13, rue de l Alzette',
    zip: '4011',
    citypart: '',
    addition: '',
    fon: '2653700',
    fax: '26537029',
    commonName: '',
    xCoordinates: '49.4953764',
    yCoordinates: '5.984631',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Esch sur Alzette',
    country: 'Luxemburg',
    state: 'LU-L',
    firmierung: 'Fielmann GmbH',
    importantMessageHeader: '',
    importantMessageText: '',
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: []
  },
  {
    uid: 1133,
    pid: 91,
    number: '001-2000',
    title: 'Braunschweig II, Schloss-Ark.',
    description: '',
    informations: '',
    street: 'Platz am Ritterbrunnen 1',
    zip: '38100',
    citypart: '',
    addition: '',
    fon: '0531-1206650',
    fax: '0531-12066529',
    commonName: 'Schloss-Arkaden',
    xCoordinates: '52.263847',
    yCoordinates: '10.52884',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Braunschweig',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann AG & Co. Schloss-Arkaden OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1135,
    pid: 91,
    number: '001-2005',
    title: 'Crailsheim',
    description: '',
    informations: '',
    street: 'Karlstraße 10',
    zip: '74564',
    citypart: '',
    addition: '',
    fon: '07951-29790-0',
    fax: '07951-29790-29',
    commonName: '',
    xCoordinates: '49.13716',
    yCoordinates: '10.07223',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Crailsheim',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1137,
    pid: 91,
    number: '001-2007',
    title: 'Emsdetten',
    description: '',
    informations: '',
    street: 'Kirchstraße 6',
    zip: '48282',
    citypart: '',
    addition: '',
    fon: '02572-150490',
    fax: '02572-1504929',
    commonName: '',
    xCoordinates: '52.174564',
    yCoordinates: '7.528356',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Emsdetten',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1139,
    pid: 91,
    number: '001-2009',
    title: 'Haltern am See',
    description: '',
    informations: '',
    street: 'Rekumer Straße 9',
    zip: '45721',
    citypart: '',
    addition: '',
    fon: '02364-506980',
    fax: '02364-5069829',
    commonName: '',
    xCoordinates: '51.743289',
    yCoordinates: '7.186411',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Haltern am See',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1141,
    pid: 91,
    number: '001-2010',
    title: 'Villingen II, In der Muslen',
    description: '',
    informations: '',
    street: 'In der Muslen 35',
    zip: '78054',
    citypart: 'Schwenningen',
    addition: '',
    fon: '07720-993330',
    fax: '07720-9933329',
    commonName: '',
    xCoordinates: '48.062725',
    yCoordinates: '8.535175',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Villingen-Schwenningen',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & Co. Schwenningen KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1143,
    pid: 91,
    number: '001-2014',
    title: 'Weilheim i.OB',
    description: '',
    informations: '',
    street: 'Marienplatz 12',
    zip: '82362',
    citypart: '',
    addition: '',
    fon: '0881-9277180',
    fax: '0881-92771829',
    commonName: '',
    xCoordinates: '47.8395864',
    yCoordinates: '11.1419928',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Weilheim in Oberbayern',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1145,
    pid: 91,
    number: '001-2016',
    title: 'Hannover V, E.-August-Platz',
    description: '',
    informations: '',
    street: 'Ernst-August-Platz 2',
    zip: '30159',
    citypart: 'Zentrum',
    addition: '',
    fon: '0511-12313860',
    fax: '0511-12313889',
    commonName: 'Ernst-August-Galerie',
    xCoordinates: '52.377371',
    yCoordinates: '9.737255',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Hannover',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann AG & Co. Ernst-August-Galerie KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1147,
    pid: 91,
    number: '001-2018',
    title: 'Mosbach',
    description: '',
    informations: '',
    street: 'Hauptstraße 31',
    zip: '74821',
    citypart: '',
    addition: '',
    fon: '06261-939750',
    fax: '06261-9397529',
    commonName: '',
    xCoordinates: '49.352271',
    yCoordinates: '9.145644',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Mosbach',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1149,
    pid: 91,
    number: '001-2023',
    title: 'Biberach an der Riß',
    description: '',
    informations: '',
    street: 'Marktplatz 3 - 5',
    zip: '88400',
    citypart: '',
    addition: '',
    fon: '07351-18016-0',
    fax: '07351-18016-29',
    commonName: '',
    xCoordinates: '48.0983743',
    yCoordinates: '9.788733',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Biberach an der Riß',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1151,
    pid: 91,
    number: '001-2025',
    title: 'Rinteln',
    description: '',
    informations: '',
    street: 'Weserstraße 19',
    zip: '31737',
    citypart: '',
    addition: '',
    fon: '05751-924610',
    fax: '05751-9246129',
    commonName: '',
    xCoordinates: '52.187762',
    yCoordinates: '9.080367',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Rinteln',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1153,
    pid: 91,
    number: '001-2002',
    title: 'Mayen',
    description: '',
    informations: '',
    street: 'Neustraße 2',
    zip: '56727',
    citypart: '',
    addition: '',
    fon: '02651-705840',
    fax: '02651-7058429',
    commonName: '',
    xCoordinates: '50.328723',
    yCoordinates: '7.222454',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Mayen',
    country: 'Deutschland',
    state: 'Rheinland-Pfalz',
    firmierung: 'Fielmann Augenoptik AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1155,
    pid: 91,
    number: '001-2004',
    title: 'Herrenberg im Gäu',
    description: '',
    informations: '',
    street: 'Bronngasse 6 - 8',
    zip: '71083',
    citypart: '',
    addition: '',
    fon: '07032-9557530',
    fax: '07032-9557559',
    commonName: '',
    xCoordinates: '48.59605',
    yCoordinates: '8.86945',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Herrenberg im Gäu',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1157,
    pid: 91,
    number: '001-2006',
    title: 'Geislingen a.d. Steige',
    description: '',
    informations: '',
    street: 'Hauptstraße 23',
    zip: '73312',
    citypart: '',
    addition: '',
    fon: '07331-442990',
    fax: '07331-4429929',
    commonName: '',
    xCoordinates: '48.614035',
    yCoordinates: '9.843622',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Geislingen an der Steige',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & Co. KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1159,
    pid: 91,
    number: '001-2008',
    title: 'Wildau',
    description: '',
    informations: '',
    street: 'Chausseestraße 1',
    zip: '15745',
    citypart: '',
    addition: '',
    fon: '03375-52880-0',
    fax: '03375-52880-29',
    commonName: 'A10-Center',
    xCoordinates: '52.314147',
    yCoordinates: '13.609495',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Wildau',
    country: 'Deutschland',
    state: 'Brandenburg',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1161,
    pid: 91,
    number: '001-2011',
    title: 'Herborn',
    description: '',
    informations: '',
    street: 'Hauptstraße 60',
    zip: '35745',
    citypart: '',
    addition: '',
    fon: '02772-57234-0',
    fax: '02772-57234-29',
    commonName: '',
    xCoordinates: '50.68276',
    yCoordinates: '8.303673',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Herborn',
    country: 'Deutschland',
    state: 'Hessen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1163,
    pid: 91,
    number: '001-2013',
    title: 'Berlin XXIV, Grunerstr. Alexa',
    description: '',
    informations: '',
    street: 'Grunerstraße 20',
    zip: '10179',
    citypart: 'Mitte',
    addition: '',
    fon: '030-27878890',
    fax: '030-278788929',
    commonName: 'Alexa',
    xCoordinates: '52.519942',
    yCoordinates: '13.414735',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Berlin',
    country: 'Deutschland',
    state: 'Berlin',
    firmierung: 'Fielmann AG & Co. im Alexa KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1165,
    pid: 91,
    number: '001-2015',
    title: 'Marktredwitz',
    description: '',
    informations: '',
    street: 'Markt 20',
    zip: '95615',
    citypart: '',
    addition: '',
    fon: '09231-97348-0',
    fax: '09231-97348-29',
    commonName: '',
    xCoordinates: '50.000794',
    yCoordinates: '12.0849',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Marktredwitz',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1167,
    pid: 91,
    number: '001-2017',
    title: 'Schwetzingen',
    description: '',
    informations: '',
    street: 'Mannheimer Straße 18',
    zip: '68723',
    citypart: '',
    addition: '',
    fon: '06202-978790',
    fax: '06202-9787929',
    commonName: '',
    xCoordinates: '49.385607',
    yCoordinates: '8.574122',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Schwetzingen',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & Co. KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1169,
    pid: 91,
    number: '001-2019',
    title: 'Ludwigsburg II, Center',
    description: '',
    informations: '',
    street: 'Heinkelstraße 1 - 11',
    zip: '71634',
    citypart: '',
    addition: '',
    fon: '07141-24238-0',
    fax: '07141-24238-29',
    commonName: 'Breuningerland',
    xCoordinates: '48.9214137',
    yCoordinates: '9.1517807',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Ludwigsburg',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & Co im Center OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1171,
    pid: 91,
    number: '001-2027',
    title: 'Wiesloch',
    description: '',
    informations: '',
    street: 'Hauptstraße 105',
    zip: '69168',
    citypart: '',
    addition: '',
    fon: '06222-38910-0',
    fax: '06222-38910-29',
    commonName: '',
    xCoordinates: '49.294078',
    yCoordinates: '8.697533',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Wiesloch',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1173,
    pid: 91,
    number: '001-2029',
    title: 'Köthen',
    description: '',
    informations: '',
    street: 'Schalaunische Straße 38',
    zip: '06366',
    citypart: '',
    addition: '',
    fon: '03496-51267-0',
    fax: '03496-51267-29',
    commonName: '',
    xCoordinates: '51.751246',
    yCoordinates: '11.977567',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Köthen',
    country: 'Deutschland',
    state: 'Sachsen-Anhalt',
    firmierung: 'Fielmann AG & Co. KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1175,
    pid: 91,
    number: '001-2030',
    title: 'Gelnhausen',
    description: '',
    informations: '',
    street: 'Im Ziegelhaus 12',
    zip: '63571',
    citypart: '',
    addition: '',
    fon: '06051-91645-0',
    fax: '06051-91645-29',
    commonName: '',
    xCoordinates: '50.1985',
    yCoordinates: '9.190732',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Gelnhausen',
    country: 'Deutschland',
    state: 'Hessen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1177,
    pid: 91,
    number: '001-2032',
    title: 'Kempen',
    description: '',
    informations: '',
    street: 'Engerstraße 14',
    zip: '47906',
    citypart: '',
    addition: '',
    fon: '02152-95921-0',
    fax: '02152-95921-29',
    commonName: '',
    xCoordinates: '51.364477',
    yCoordinates: '6.421743',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Kempen',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1179,
    pid: 91,
    number: '001-2034',
    title: 'Oldenburg II, Lange Straße',
    description: '',
    informations: '',
    street: 'Lange Straße 12',
    zip: '26122',
    citypart: '',
    addition: '',
    fon: '0441-219732-0',
    fax: '0441-219732-29',
    commonName: '',
    xCoordinates: '53.1422582',
    yCoordinates: '8.2137655',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Oldenburg',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann AG & Co. im Centrum KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1181,
    pid: 91,
    number: '001-2036',
    title: 'Geesthacht',
    description: '',
    informations: '',
    street: 'Bergedorfer Straße 45',
    zip: '21502',
    citypart: '',
    addition: '',
    fon: '04152-87711-30',
    fax: '04152-87711-59',
    commonName: '',
    xCoordinates: '53.434628',
    yCoordinates: '10.371299',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Geesthacht',
    country: 'Deutschland',
    state: 'Schleswig-Holstein',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1183,
    pid: 91,
    number: '001-2038',
    title: 'Korbach',
    description: '',
    informations: '',
    street: 'Bahnhofstraße 10',
    zip: '34497',
    citypart: '',
    addition: '',
    fon: '05631-50262-0',
    fax: '05631-50262-29',
    commonName: '',
    xCoordinates: '51.277209',
    yCoordinates: '8.872733',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Korbach',
    country: 'Deutschland',
    state: 'Hessen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1185,
    pid: 91,
    number: '001-2041',
    title: 'Lauf an der Pegnitz',
    description: '',
    informations: '',
    street: 'Marktplatz 53',
    zip: '91207',
    citypart: '',
    addition: '',
    fon: '09123-980860',
    fax: '09123-9808629',
    commonName: '',
    xCoordinates: '49.511274',
    yCoordinates: '11.28039',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Lauf an der Pegnitz',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1187,
    pid: 91,
    number: '001-2043',
    title: 'Dingolfing',
    description: '',
    informations: '',
    street: 'BGR-Josef-Zinnbauer-Straße 2',
    zip: '84130',
    citypart: '',
    addition: '',
    fon: '08731-3270770',
    fax: '08731-3270799',
    commonName: '',
    xCoordinates: '48.6287672',
    yCoordinates: '12.4984226',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Dingolfing',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1189,
    pid: 91,
    number: '001-2020',
    title: 'Bad Tölz',
    description: '',
    informations: '',
    street: 'Marktstraße 57',
    zip: '83646',
    citypart: '',
    addition: '',
    fon: '08041-799830',
    fax: '08041-7998329',
    commonName: '',
    xCoordinates: '47.761285',
    yCoordinates: '11.561239',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Bad Tölz',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1191,
    pid: 91,
    number: '001-2022',
    title: 'Barsinghausen',
    description: '',
    informations: '',
    street: 'Marktstraße 8',
    zip: '30890',
    citypart: '',
    addition: '',
    fon: '05105-58446-0',
    fax: '05105-58446-29',
    commonName: '',
    xCoordinates: '52.301787',
    yCoordinates: '9.46353',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Barsinghausen',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1193,
    pid: 91,
    number: '001-2024',
    title: 'Bad Segeberg',
    description: '',
    informations: '',
    street: 'Kurhausstr. 5',
    zip: '23795',
    citypart: '',
    addition: '',
    fon: '04551-908400',
    fax: '04551-9084029',
    commonName: '',
    xCoordinates: '53.93702',
    yCoordinates: '10.3074301',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Bad Segeberg',
    country: 'Deutschland',
    state: 'Schleswig-Holstein',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1195,
    pid: 91,
    number: '001-2026',
    title: 'Berlin XXV, Linden-Center',
    description: '',
    informations: '',
    street: 'Prerower Platz 1',
    zip: '13051',
    citypart: 'Hohenschönhausen',
    addition: '',
    fon: '030-92403090',
    fax: '030-924030929',
    commonName: 'Linden-Center',
    xCoordinates: '52.564411',
    yCoordinates: '13.506818',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Berlin',
    country: 'Deutschland',
    state: 'Berlin',
    firmierung: 'Fielmann AG & Co. Linden-Center OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1197,
    pid: 91,
    number: '001-2028',
    title: 'Krefeld II, Neumarkt',
    description: '',
    informations: '',
    street: 'Hochstraße 65',
    zip: '47798',
    citypart: '',
    addition: '',
    fon: '02151-56505-0',
    fax: '02151-56505-29',
    commonName: '',
    xCoordinates: '51.330962',
    yCoordinates: '6.563476',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Krefeld',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. Neumarkt OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1199,
    pid: 91,
    number: '001-2031',
    title: 'Waldshut-Tiengen',
    description: '',
    informations: '',
    street: 'Kaiserstraße 52 - 54',
    zip: '79761',
    citypart: '',
    addition: '',
    fon: '07751-89674-0',
    fax: '07751-89674-29',
    commonName: '',
    xCoordinates: '47.62293',
    yCoordinates: '8.21242',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Waldshut-Tiengen',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1201,
    pid: 91,
    number: '001-2033',
    title: 'Calw',
    description: '',
    informations: '',
    street: 'Lederstraße 36',
    zip: '75365',
    citypart: '',
    addition: '',
    fon: '07051-790260',
    fax: '07051-7902629',
    commonName: '',
    xCoordinates: '48.715644',
    yCoordinates: '8.738786',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Calw',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1203,
    pid: 91,
    number: '001-2035',
    title: 'Marl',
    description: '',
    informations: '',
    street: 'Bergstraße 228',
    zip: '45768',
    citypart: '',
    addition: '',
    fon: '02365-69810-0',
    fax: '02365-69810-29',
    commonName: 'Marler Stern',
    xCoordinates: '51.65432',
    yCoordinates: '7.096484',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Marl',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1205,
    pid: 91,
    number: '001-2037',
    title: 'Kamp-Lintfort',
    description: '',
    informations: '',
    street: 'Moerser Straße 222',
    zip: '47475',
    citypart: '',
    addition: '',
    fon: '02842-90824-0',
    fax: '02842-90824-29',
    commonName: '',
    xCoordinates: '51.5024684',
    yCoordinates: '6.5453666',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Kamp-Lintfort',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1207,
    pid: 91,
    number: '001-2045',
    title: 'München IX , Riem Arcaden',
    description: '',
    informations: '',
    street: 'Willy-Brandt-Platz 5',
    zip: '81829',
    citypart: 'Riem',
    addition: '',
    fon: '089-4200189-0',
    fax: '089-4200189-29',
    commonName: 'Riem Arcaden',
    xCoordinates: '48.132176',
    yCoordinates: '11.691689',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'München',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. Riem Arcaden KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1209,
    pid: 91,
    number: '001-2047',
    title: 'Hamburg-Eppendorf I',
    description: '',
    informations: '',
    street: 'Eppendorfer Landstraße 77',
    zip: '20249',
    citypart: 'Eppendorf',
    addition: '',
    fon: '040-5480392-0',
    fax: '040-5480392-29',
    commonName: '',
    xCoordinates: '53.590647',
    yCoordinates: '9.986539',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Hamburg',
    country: 'Deutschland',
    state: 'Hamburg',
    firmierung: 'Fielmann AG & Co. Eppendorf KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1211,
    pid: 91,
    number: '001-2049',
    title: 'Warendorf',
    description: '',
    informations: '',
    street: 'Münsterstraße 15',
    zip: '48231',
    citypart: '',
    addition: '',
    fon: '02581-928960',
    fax: '02581-9289629',
    commonName: '',
    xCoordinates: '51.9525243',
    yCoordinates: '7.9877619',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Warendorf',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1213,
    pid: 91,
    number: '001-2050',
    title: 'Borken',
    description: '',
    informations: '',
    street: 'Markt 5',
    zip: '46325',
    citypart: '',
    addition: '',
    fon: '02861-808550',
    fax: '02861-8085529',
    commonName: '',
    xCoordinates: '51.843608',
    yCoordinates: '6.858666',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Borken',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann Augenoptik AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1215,
    pid: 91,
    number: '001-2052',
    title: 'Weißenburg in Bayern',
    description: '',
    informations: '',
    street: 'Luitpoldstraße 18',
    zip: '91781',
    citypart: '',
    addition: '',
    fon: '09141-87337-0',
    fax: '09141-87337-29',
    commonName: '',
    xCoordinates: '49.030638',
    yCoordinates: '10.973537',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Weißenburg in Bayern',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1217,
    pid: 91,
    number: '001-2054',
    title: 'Aachen III, Kugelbrunnen',
    description: '',
    informations: '',
    street: 'Adalbertstraße 49',
    zip: '52062',
    citypart: 'Zentrum',
    addition: '',
    fon: '0241-160692-0',
    fax: '0241-160692-29',
    commonName: '',
    xCoordinates: '50.7751847',
    yCoordinates: '6.0910607',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Aachen',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. am Kugelbrunnen KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1219,
    pid: 91,
    number: '001-2056',
    title: 'Weiterstadt',
    description: '',
    informations: '',
    street: 'Gutenbergstraße 5',
    zip: '64331',
    citypart: '',
    addition: '',
    fon: '06151-39738-0',
    fax: '06151-39738-29',
    commonName: 'LOOP 5 Shopping Center',
    xCoordinates: '49.892015',
    yCoordinates: '8.606743',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Weiterstadt',
    country: 'Deutschland',
    state: 'Hessen',
    firmierung: 'Fielmann AG & Co. KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1221,
    pid: 91,
    number: '001-2058',
    title: 'Hamburg-Osdorf, Elbe-EKZ',
    description: '',
    informations: '',
    street: 'Osdorfer Landstraße 131',
    zip: '22609',
    citypart: 'Osdorf',
    addition: '',
    fon: '040-8797291-0',
    fax: '040-8797291-29',
    commonName: 'Elbe-Einkaufszentrum EEZ',
    xCoordinates: '53.570215',
    yCoordinates: '9.861736',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Hamburg',
    country: 'Deutschland',
    state: 'Hamburg',
    firmierung: 'Fielmann AG & Co. im Elbe-Einkaufszentrum OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1223,
    pid: 91,
    number: '001-2061',
    title: 'Nagold',
    description: '',
    informations: '',
    street: 'Turmstraße 21',
    zip: '72202',
    citypart: '',
    addition: '',
    fon: '07452-60055-0',
    fax: '07452-60055-29',
    commonName: '',
    xCoordinates: '48.551757',
    yCoordinates: '8.723795',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Nagold',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1225,
    pid: 91,
    number: '001-2039',
    title: 'Heidelberg',
    description: '',
    informations: '',
    street: 'Hauptstraße 77',
    zip: '69117',
    citypart: '',
    addition: '',
    fon: '06221-180900',
    fax: '06221-1809029',
    commonName: '',
    xCoordinates: '49.41109',
    yCoordinates: '8.70109',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Heidelberg',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & Co. KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1227,
    pid: 91,
    number: '001-2040',
    title: 'Bremen V, Roland-Center',
    description: '',
    informations: '',
    street: 'Alter Dorfweg 30 - 50',
    zip: '28259',
    citypart: 'Huchting',
    addition: '',
    fon: '0421-8983000',
    fax: '0421-89830029',
    commonName: 'Roland-Center',
    xCoordinates: '53.0467476',
    yCoordinates: '8.7439739',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Bremen',
    country: 'Deutschland',
    state: 'Bremen',
    firmierung: 'Fielmann AG & Co. Roland-Center KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1229,
    pid: 91,
    number: '001-2042',
    title: 'Eschwege',
    description: '',
    informations: '',
    street: 'Stad 19',
    zip: '37269',
    citypart: '',
    addition: '',
    fon: '05651-33546-0',
    fax: '05651-33546-29',
    commonName: '',
    xCoordinates: '51.187613',
    yCoordinates: '10.055273',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Eschwege',
    country: 'Deutschland',
    state: 'Hessen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1231,
    pid: 91,
    number: '001-2044',
    title: 'Aschaffenburg II, City Galerie',
    description: '',
    informations: '',
    street: 'Goldbacher Straße 2',
    zip: '63739',
    citypart: '',
    addition: '',
    fon: '06021-44872-0',
    fax: '06021-44872-29',
    commonName: 'City Galerie',
    xCoordinates: '49.978103',
    yCoordinates: '9.150699',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Aschaffenburg',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. City Galerie OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1233,
    pid: 91,
    number: '001-2046',
    title: 'Schwandorf',
    description: '',
    informations: '',
    street: 'Friedrich-Ebert-Straße 11',
    zip: '92421',
    citypart: '',
    addition: '',
    fon: '09431-75442-30',
    fax: '09431-75442-59',
    commonName: '',
    xCoordinates: '49.32882',
    yCoordinates: '12.107892',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Schwandorf',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1235,
    pid: 91,
    number: '001-2048',
    title: 'Bad Kissingen',
    description: '',
    informations: '',
    street: 'Ludwigstraße 10',
    zip: '97688',
    citypart: '',
    addition: '',
    fon: '0971-785486-0',
    fax: '0971-785486-29',
    commonName: '',
    xCoordinates: '50.198825',
    yCoordinates: '10.076693',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Bad Kissingen',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1237,
    pid: 91,
    number: '001-2051',
    title: 'Hamburg-Barmbek III, EKZ',
    description: '',
    informations: '',
    street: 'Hamburger Straße 19 - 47',
    zip: '22083',
    citypart: 'Barmbek-Süd',
    addition: '',
    fon: '040-2076978-0',
    fax: '040-2076978-29',
    commonName: 'Hamburger Meile',
    xCoordinates: '53.572499',
    yCoordinates: '10.029735',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Hamburg',
    country: 'Deutschland',
    state: 'Hamburg',
    firmierung: 'Fielmann AG & Co. EKZ Hamburger Straße KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1241,
    pid: 91,
    number: '001-2055',
    title: 'Wildeshausen',
    description: '',
    informations: '',
    street: 'Westerstraße 28',
    zip: '27793',
    citypart: '',
    addition: '',
    fon: '04431-73812-0',
    fax: '04431-73812-29',
    commonName: '',
    xCoordinates: '52.896345',
    yCoordinates: '8.43538',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Wildeshausen',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann Augenoptik AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1243,
    pid: 91,
    number: '001-2063',
    title: 'Bruchsal',
    description: '',
    informations: '',
    street: 'Kaiserstraße 50',
    zip: '76646',
    citypart: '',
    addition: '',
    fon: '07251-36967-0',
    fax: '07251-36967-29',
    commonName: '',
    xCoordinates: '49.12481',
    yCoordinates: '8.597683',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Bruchsal',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1245,
    pid: 91,
    number: '001-2065',
    title: 'Fürstenfeldbruck',
    description: '',
    informations: '',
    street: 'Hauptstraße 14',
    zip: '82256',
    citypart: '',
    addition: '',
    fon: '08141-34707-0',
    fax: '08141-34707-29',
    commonName: '',
    xCoordinates: '48.178224',
    yCoordinates: '11.255368',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Fürstenfeldbruck',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1247,
    pid: 91,
    number: '001-2067',
    title: 'Saalfeld/Saale',
    description: '',
    informations: '',
    street: 'Obere Straße 1',
    zip: '07318',
    citypart: '',
    addition: '',
    fon: '03671-45555-0',
    fax: '03671-45555-29',
    commonName: '',
    xCoordinates: '50.647182',
    yCoordinates: '11.360788',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Saalfeld- Saale',
    country: 'Deutschland',
    state: 'Thüringen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1249,
    pid: 91,
    number: '001-2070',
    title: 'Sulzbach',
    description: '',
    informations: '',
    street: 'Main-Taunus-Zentrum',
    zip: '65843',
    citypart: '',
    addition: '',
    fon: '069-2578829-0',
    fax: '069-2578829-29',
    commonName: '',
    xCoordinates: '50.119739',
    yCoordinates: '8.526512',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Sulzbach',
    country: 'Deutschland',
    state: 'Hessen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1251,
    pid: 91,
    number: '001-2072',
    title: 'Alzey',
    description: '',
    informations: '',
    street: 'Antoniterstraße 26',
    zip: '55232',
    citypart: '',
    addition: '',
    fon: '06731-54878-0',
    fax: '06731-54878-29',
    commonName: '',
    xCoordinates: '49.746689',
    yCoordinates: '8.113006',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Alzey',
    country: 'Deutschland',
    state: 'Rheinland-Pfalz',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1253,
    pid: 91,
    number: '001-2074',
    title: 'Tuttlingen (HA)',
    description:
      'Fielmann bietet in seiner Niederlassung Tuttlingen, Bahnhofstraße 19, eine große Auswahl moderner Hörsysteme. Auf alle Hörsysteme gewährt Fielmann die Geld-zurück-Garantie. \r\nLassen Sie sich von einem unserer kompetenten Hörgeräteakustiker beraten oder einen kostenlosen Hörtest vornehmen. Jederzeit. \r\nHörgeräte: Fielmann. Jetzt bereits mehr als 100x in Europa.',
    informations: '',
    street: 'Bahnhofstraße 19',
    zip: '78532',
    citypart: '',
    addition: '',
    fon: '07461-3426',
    fax: '07461-968530',
    commonName: '',
    xCoordinates: '47.98382',
    yCoordinates: '8.81645',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Tuttlingen',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann Augenoptik AG & Co.oHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1255,
    pid: 91,
    number: '001-2076',
    title: 'Langenhagen',
    description: '',
    informations: '',
    street: 'Marktplatz 7',
    zip: '30853',
    citypart: '',
    addition: '',
    fon: '0511-779537-0',
    fax: '0511-779537-29',
    commonName: 'City Center Langenhagen',
    xCoordinates: '52.440212',
    yCoordinates: '9.738124',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Langenhagen',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1257,
    pid: 91,
    number: '001-2078',
    title: 'Dallgow-Döberitz',
    description: '',
    informations: '',
    street: 'Döberitzer Weg 3',
    zip: '14624',
    citypart: '',
    addition: '',
    fon: '03322-12641-0',
    fax: '03322-12641-29',
    commonName: 'Havel Park',
    xCoordinates: '52.530505',
    yCoordinates: '13.084115',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Dallgow-Döberitz',
    country: 'Deutschland',
    state: 'Brandenburg',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1259,
    pid: 91,
    number: '001-2083',
    title: 'Frankfurt am Main V',
    description: '',
    informations: '',
    street: 'Borsigallee 26',
    zip: '60388',
    citypart: 'Bergen-Enkheim',
    addition: '',
    fon: '06109-50410-0',
    fax: '06109-50410-29',
    commonName: 'Hessen-Center',
    xCoordinates: '50.140088',
    yCoordinates: '8.751723',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Frankfurt am Main',
    country: 'Deutschland',
    state: 'Hessen',
    firmierung: 'Fielmann AG & Co. Hessen-Center OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1261,
    pid: 91,
    number: '001-2057',
    title: 'Stadthagen',
    description: '',
    informations: '',
    street: 'Obernstraße 9',
    zip: '31655',
    citypart: '',
    addition: '',
    fon: '05721-93338-0',
    fax: '05721-93338-29',
    commonName: '',
    xCoordinates: '52.322776',
    yCoordinates: '9.205348',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Stadthagen',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1263,
    pid: 91,
    number: '001-2059',
    title: 'Ludwigshafen II, Rhein-Galerie',
    description: '',
    informations: '',
    street: 'Im Zollhof 4',
    zip: '67061',
    citypart: '',
    addition: '',
    fon: '0621-591466-0',
    fax: '0621-591466-29',
    commonName: 'Rhein Galerie',
    xCoordinates: '49.486518',
    yCoordinates: '8.446587',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Ludwigshafen',
    country: 'Deutschland',
    state: 'Rheinland-Pfalz',
    firmierung: 'Fielmann AG & Co. Rhein-Galerie OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1265,
    pid: 91,
    number: '001-2060',
    title: 'Starnberg',
    description: '',
    informations: '',
    street: 'Wittelsbacher Straße 5',
    zip: '82319',
    citypart: '',
    addition: '',
    fon: '08151-4449660',
    fax: '08151-4449669',
    commonName: '',
    xCoordinates: '47.997515',
    yCoordinates: '11.342058',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Starnberg',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1267,
    pid: 91,
    number: '001-2062',
    title: 'Regensburg II, Donau EKZ',
    description: '',
    informations: '',
    street: 'Weichser Weg 5',
    zip: '93059',
    citypart: '',
    addition: '',
    fon: '0941-46453430',
    fax: '0941-46453459',
    commonName: 'Donau-Einkaufszentrum',
    xCoordinates: '49.025866',
    yCoordinates: '12.11631',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Regensburg',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. im Donau-Einkaufszentrum OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1269,
    pid: 91,
    number: '001-2064',
    title: 'Erding',
    description: '',
    informations: '',
    street: 'Lange Zeile 15',
    zip: '85435',
    citypart: '',
    addition: '',
    fon: '08122-22728-0',
    fax: '08122-22728-29',
    commonName: '',
    xCoordinates: '48.30806',
    yCoordinates: '11.90825',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Erding',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1271,
    pid: 91,
    number: '001-2066',
    title: 'Grimma',
    description: '',
    informations: '',
    street: 'Lange Straße 56',
    zip: '04668',
    citypart: '',
    addition: '',
    fon: '03437-70821-0',
    fax: '03437-70821-29',
    commonName: '',
    xCoordinates: '51.23584',
    yCoordinates: '12.72812',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Grimma',
    country: 'Deutschland',
    state: 'Sachsen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1273,
    pid: 91,
    number: '001-2068',
    title: 'Alsfeld',
    description: '',
    informations: '',
    street: 'Mainzer Gasse 5',
    zip: '36304',
    citypart: '',
    addition: '',
    fon: '06631-776360',
    fax: '06631-7763629',
    commonName: '',
    xCoordinates: '50.750482',
    yCoordinates: '9.270481',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Alsfeld',
    country: 'Deutschland',
    state: 'Hessen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1275,
    pid: 91,
    number: '001-2071',
    title: 'Warburg',
    description: '',
    informations: '',
    street: 'Hauptstraße 54',
    zip: '34414',
    citypart: '',
    addition: '',
    fon: '05641-40597-0',
    fax: '05641-40597-29',
    commonName: '',
    xCoordinates: '51.489026',
    yCoordinates: '9.148529',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Warburg',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1277,
    pid: 91,
    number: '001-2073',
    title: 'Siegburg',
    description: '',
    informations: '',
    street: 'Kaiserstraße 34',
    zip: '53721',
    citypart: '',
    addition: '',
    fon: '02241-23929-0',
    fax: '02241-23929-29',
    commonName: '',
    xCoordinates: '50.798717',
    yCoordinates: '7.20786',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Siegburg',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1279,
    pid: 91,
    number: '001-2075',
    title: 'Köln XI, Schildergasse',
    description: '',
    informations: '',
    street: 'Schildergasse 78 - 82',
    zip: '50667',
    citypart: '',
    addition: '',
    fon: '0221-2725340',
    fax: '0221-27253429',
    commonName: '',
    xCoordinates: '50.936596',
    yCoordinates: '6.952252',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Köln',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. Schildergasse OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1281,
    pid: 91,
    number: '001-2077',
    title: 'Ingolstadt II, EKZ Westpark',
    description: '',
    informations: '',
    street: 'Am Westpark 6',
    zip: '85057',
    citypart: '',
    addition: '',
    fon: '0841-142818-0',
    fax: '0841-142818-29',
    commonName: 'Westpark',
    xCoordinates: '48.7736616',
    yCoordinates: '11.3903523',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Ingolstadt',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. EKZ Westpark OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1283,
    pid: 91,
    number: '001-2079',
    title: 'Dresden III, Kaufpark',
    description: '',
    informations: '',
    street: 'Dohnaer Straße 246',
    zip: '01239',
    citypart: '',
    addition: '',
    fon: '0351-259334-0',
    fax: '0351-259334-29',
    commonName: 'Kaufpark Dresden-Nickern',
    xCoordinates: '50.997037',
    yCoordinates: '13.798895',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Dresden',
    country: 'Deutschland',
    state: 'Sachsen',
    firmierung: 'Fielmann AG & Co. Kaufpark OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1285,
    pid: 91,
    number: '001-2080',
    title: 'Koblenz II, Mittelrhein',
    description: '',
    informations: '',
    street: 'Zentralplatz 2',
    zip: '56068',
    citypart: '',
    addition: '',
    fon: '0261-914420-0',
    fax: '0261-914420-29',
    commonName: 'Forum Mittelrhein',
    xCoordinates: '50.358677',
    yCoordinates: '7.596416',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Koblenz',
    country: 'Deutschland',
    state: 'Rheinland-Pfalz',
    firmierung: 'Fielmann AG & Co. Forum Mittelrhein OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1287,
    pid: 91,
    number: '001-2082',
    title: 'Soltau',
    description: '',
    informations: '',
    street: 'Marktstraße 12',
    zip: '29614',
    citypart: '',
    addition: '',
    fon: '05191-96897-0',
    fax: '05191-96897-29',
    commonName: '',
    xCoordinates: '52.985591',
    yCoordinates: '9.839581',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Soltau',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1289,
    pid: 91,
    number: '001-2084',
    title: 'Mülheim III',
    description: '',
    informations: '',
    street: 'Humboldtring 13',
    zip: '45472',
    citypart: '',
    addition: '',
    fon: '0208-882670-0',
    fax: '0208-882670-29',
    commonName: 'Rhein-Ruhr-Zentrum',
    xCoordinates: '51.437044',
    yCoordinates: '6.954585',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Mülheim an der Ruhr',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. RheinRuhrZentrum OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1291,
    pid: 91,
    number: '001-2086',
    title: 'Erkelenz',
    description: '',
    informations: '',
    street: 'Kölner Straße 14 b',
    zip: '41812',
    citypart: '',
    addition: '',
    fon: '02431-945220',
    fax: '02431-9452229',
    commonName: '',
    xCoordinates: '51.0787762',
    yCoordinates: '6.3181557',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Erkelenz',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1293,
    pid: 91,
    number: '001-2088',
    title: 'Radebeul',
    description: '',
    informations: '',
    street: 'Hauptstraße 27',
    zip: '01445',
    citypart: '',
    addition: '',
    fon: '0351-447233-0',
    fax: '0351-447233-29',
    commonName: '',
    xCoordinates: '51.101606',
    yCoordinates: '13.680876',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Radebeul',
    country: 'Deutschland',
    state: 'Sachsen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1295,
    pid: 91,
    number: '001-2091',
    title: 'St. Ingbert',
    description: '',
    informations: '',
    street: 'Kaiserstraße 57',
    zip: '66386',
    citypart: '',
    addition: '',
    fon: '06894-92144-0',
    fax: '06894-92144-29',
    commonName: '',
    xCoordinates: '49.2787891',
    yCoordinates: '7.1166417',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'St. Ingbert',
    country: 'Deutschland',
    state: 'Saarland',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1297,
    pid: 91,
    number: '001-2085',
    title: 'Erfurt II',
    description: '',
    informations: '',
    street: 'Nordhäuser Straße 73 t',
    zip: '99091',
    citypart: '',
    addition: '',
    fon: '0361-555987-0',
    fax: '0361-555987-29',
    commonName: 'Thüringen-Park',
    xCoordinates: '51.0083924',
    yCoordinates: '10.9934996',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Erfurt',
    country: 'Deutschland',
    state: 'Thüringen',
    firmierung: 'Fielmann AG & Co. Thüringen-Park OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1299,
    pid: 91,
    number: '001-2090',
    title: 'Berlin XXVI, Gropius Passagen',
    description: '',
    informations: '',
    street: 'Johannisthaler Chaussee 317',
    zip: '12351',
    citypart: 'Gropiusstadt',
    addition: '',
    fon: '030-6706697-0',
    fax: '030-6706697-29',
    commonName: 'Gropius Passagen',
    xCoordinates: '52.428924',
    yCoordinates: '13.45638',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Berlin',
    country: 'Deutschland',
    state: 'Berlin',
    firmierung: 'Fielmann AG & Co. Gropius Passagen OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1301,
    pid: 91,
    number: '001-2096',
    title: 'Schwäbisch Hall',
    description: '',
    informations: '',
    street: 'Schwatzbühlgasse 6 - 8',
    zip: '74523',
    citypart: '',
    addition: '',
    fon: '0791-946693-0',
    fax: '0791-946693-29',
    commonName: '',
    xCoordinates: '49.11264',
    yCoordinates: '9.73467',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Schwäbisch Hall',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1303,
    pid: 91,
    number: '001-5592',
    title: 'Basel IV',
    description: '',
    informations: '',
    street: 'Hochbergerstrasse 70',
    zip: '4057',
    citypart: 'Kleinhüningen',
    addition: '',
    fon: '061 633 20 50',
    fax: '061 633 20 59',
    commonName: 'Stücki Park',
    xCoordinates: '47.579952',
    yCoordinates: '7.5957171',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16ccc63ab66101aa90135',
    appointmentTimifyType: true,
    city: 'Basel',
    country: 'Schweiz',
    state: 'Basel-Stadt',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1305,
    pid: 91,
    number: '001-5598',
    title: 'Bülach',
    description: '',
    informations: '',
    street: 'Bahnhofstrasse 11',
    zip: '8180',
    citypart: '',
    addition: '',
    fon: '044 864 84 48',
    fax: '044 864 84 49',
    commonName: '',
    xCoordinates: '47.5201382',
    yCoordinates: '8.539854',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16ccce15925100d49604a',
    appointmentTimifyType: true,
    city: 'Bülach',
    country: 'Schweiz',
    state: 'Zürich',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1307,
    pid: 91,
    number: '001-2095',
    title: 'Merzig',
    description: '',
    informations: '',
    street: 'Poststraße 25',
    zip: '66663',
    citypart: '',
    addition: '',
    fon: '06861-93926-0',
    fax: '06861-93926-29',
    commonName: '',
    xCoordinates: '49.443345',
    yCoordinates: '6.63797',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Merzig',
    country: 'Deutschland',
    state: 'Saarland',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1309,
    pid: 91,
    number: '001-5591',
    title: 'Rapperswil',
    description: '',
    informations: '',
    street: 'Untere Bahnhofstrasse 11',
    zip: '8640',
    citypart: '',
    addition: '',
    fon: '055 220 13 60',
    fax: '055 220 13 89',
    commonName: '',
    xCoordinates: '47.2256137',
    yCoordinates: '8.8177974',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16ccc63ab66101aa90142',
    appointmentTimifyType: true,
    city: 'Rapperswil',
    country: 'Schweiz',
    state: 'St. Gallen',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1311,
    pid: 91,
    number: '001-5593',
    title: 'Spreitenbach',
    description: '',
    informations: '',
    street: 'Shopping-Center',
    zip: '8957',
    citypart: '',
    addition: '',
    fon: '056 418 20 30',
    fax: '056 418 20 31',
    commonName: 'Shoppi Tivoli',
    xCoordinates: '47.4216259',
    yCoordinates: '8.3688048',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16cccc8290c0fa8646149',
    appointmentTimifyType: true,
    city: 'Spreitenbach',
    country: 'Schweiz',
    state: 'Aargau',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1313,
    pid: 91,
    number: '001-5597',
    title: 'Thalwil',
    description: '',
    informations: '',
    street: 'Gotthardstrasse 16b',
    zip: '8800',
    citypart: '',
    addition: '',
    fon: '043 336 90 80',
    fax: '043 336 90 81',
    commonName: '',
    xCoordinates: '47.2950761',
    yCoordinates: '8.5647096',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16cccd1d00e10173befc4',
    appointmentTimifyType: true,
    city: 'Thalwil',
    country: 'Schweiz',
    state: 'Zürich',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1923,
    pid: 91,
    number: '001-5594',
    title: 'Brig',
    description: '',
    informations: '',
    street: 'Kantonsstrasse 58',
    zip: '3902',
    citypart: '',
    addition: '',
    fon: '027 922 46 00',
    fax: '027 922 46 01',
    commonName: 'Simplon Center',
    xCoordinates: '46.3089003',
    yCoordinates: '7.9693461',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16ccc63ab66101aa90120',
    appointmentTimifyType: true,
    city: 'Brig-Glis',
    country: 'Schweiz',
    state: 'Wallis',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1925,
    pid: 91,
    number: '001-2089',
    title: 'Stuttgart III, Milaneo',
    description: '',
    informations: '',
    street: 'Mailänder Platz 7',
    zip: '70173',
    citypart: '',
    addition: '',
    fon: '0711-585325-0',
    fax: '0711-585325-29',
    commonName: 'Milaneo',
    xCoordinates: '48.791115',
    yCoordinates: '9.1837',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Stuttgart',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & Co. EKZ Milaneo OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1927,
    pid: 91,
    number: '001-2087',
    title: 'Mühldorf am Inn',
    description: '',
    informations: '',
    street: 'Stadtplatz 27',
    zip: '84453',
    citypart: '',
    addition: '',
    fon: '08631-98598-0',
    fax: '08631-98598-29',
    commonName: '',
    xCoordinates: '48.2420972',
    yCoordinates: '12.5237097',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Mühldorf am Inn',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1929,
    pid: 91,
    number: '001-2094',
    title: 'Burgdorf',
    description: '',
    informations: '',
    street: 'Poststraße 1',
    zip: '31303',
    citypart: '',
    addition: '',
    fon: '05136-87886-0',
    fax: '05136-87886-29',
    commonName: '',
    xCoordinates: '52.446403',
    yCoordinates: '10.00872',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Burgdorf',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1935,
    pid: 91,
    number: '001-2098',
    title: 'Friedberg (Hessen)',
    description: '',
    informations: '',
    street: 'Kaiserstraße 105',
    zip: '61169',
    citypart: '',
    addition: '',
    fon: '06031-68439-0',
    fax: '06031-68439-29',
    commonName: '',
    xCoordinates: '50.3363742',
    yCoordinates: '8.7530678',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Friedberg (Hessen)',
    country: 'Deutschland',
    state: 'Hessen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1937,
    pid: 91,
    number: '001-2099',
    title: 'Cham',
    description: '',
    informations: '',
    street: 'Marktplatz 12',
    zip: '93413',
    citypart: '',
    addition: '',
    fon: '09971-99638-0',
    fax: '09971-99638-29',
    commonName: '',
    xCoordinates: '49.217219',
    yCoordinates: '12.666713',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Cham',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1939,
    pid: 91,
    number: '001-5599',
    title: 'Sursee',
    description: '',
    informations: '',
    street: 'Bahnhofstrasse 29',
    zip: '6210',
    citypart: '',
    addition: '',
    fon: '041 925 27 50',
    fax: '041 925 27 51',
    commonName: '',
    xCoordinates: '47.1723234',
    yCoordinates: '8.102818',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16cccc8290c0fa864614e',
    appointmentTimifyType: true,
    city: 'Sursee',
    country: 'Schweiz',
    state: 'Luzern',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1941,
    pid: 91,
    number: '001-5596',
    title: 'Lugano',
    description: '',
    informations: '',
    street: 'Via Pietro Peri 4',
    zip: '6900',
    citypart: '',
    addition: '',
    fon: '091 911 95 30',
    fax: '091 911 95 31',
    commonName: '',
    xCoordinates: '46.0054209',
    yCoordinates: '8.9508758',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '8df10cf1-ef64-41d5-89df-5bec88f4bb35-5ea1694c970ac8102ce1d5e9',
    appointmentTimifyType: true,
    city: 'Lugano',
    country: 'Schweiz',
    state: 'Tessin',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1943,
    pid: 91,
    number: '001-2069',
    title: 'Neu-Ulm',
    description: '',
    informations: '',
    street: 'Bahnhofstraße 1/2',
    zip: '89231',
    citypart: '',
    addition: '',
    fon: '0731-206043-0',
    fax: '0731-206043-29',
    commonName: 'Glacis-Galerie',
    xCoordinates: '48.394323',
    yCoordinates: '10.005167',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Neu-Ulm',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. Glacis-Galerie OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1945,
    pid: 91,
    number: '001-2093',
    title: 'Nördlingen',
    description: '',
    informations: '',
    street: 'Schrannenstraße 1',
    zip: '86720',
    citypart: '',
    addition: '',
    fon: '09081-27240-0',
    fax: '09081-27240-29',
    commonName: '',
    xCoordinates: '48.8510099',
    yCoordinates: '10.489969',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Nördlingen',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1947,
    pid: 91,
    number: '001-2092',
    title: 'Bernau',
    description: '',
    informations: '',
    street: 'Börnicker Chaussee 1 - 2',
    zip: '16321',
    citypart: '',
    addition: '',
    fon: '03338-70447-0',
    fax: '03338-70447-29',
    commonName: 'Bahnhofs-Passage',
    xCoordinates: '52.6772292',
    yCoordinates: '13.5970828',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Bernau',
    country: 'Deutschland',
    state: 'Brandenburg',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1949,
    pid: 91,
    number: '001-0706',
    title: 'Salzburg II, Alpenstr.',
    description: '',
    informations: '',
    street: 'Alpenstraße 114',
    zip: '5020',
    citypart: '',
    addition: '',
    fon: '0662-904160-0',
    fax: '0662-904160-29',
    commonName: 'Shopping Arena',
    xCoordinates: '47.7744499',
    yCoordinates: '13.0681999',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '5e906fc9f932686fddc1466d',
    appointmentTimifyType: true,
    city: 'Salzburg',
    country: 'Österreich',
    state: 'Salzburg',
    firmierung: 'Fielmann GmbH',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1951,
    pid: 91,
    number: '001-5595',
    title: 'Liestal',
    description: '',
    informations: '',
    street: 'Rathausstrasse 59',
    zip: '4410',
    citypart: '',
    addition: '',
    fon: '061 926 86 20',
    fax: '061 926 86 21',
    commonName: '',
    xCoordinates: '47.4835456',
    yCoordinates: '7.7355285',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16cccc8290c0fa864614f',
    appointmentTimifyType: true,
    city: 'Liestal',
    country: 'Schweiz',
    state: 'Basel-Landschaft',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1952,
    pid: 91,
    number: '001-2101',
    title: 'Olpe',
    description: '',
    informations: '',
    street: 'Martinstraße 29',
    zip: '57462',
    citypart: '',
    addition: '',
    fon: '02761-943420',
    fax: '02761-9434229',
    commonName: '',
    xCoordinates: '51.0296641',
    yCoordinates: '7.8487996',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Olpe',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1955,
    pid: 91,
    number: '001-2097',
    title: 'Osterode (Harz)',
    description: '',
    informations: '',
    street: 'Kornmarkt 17',
    zip: '37520',
    citypart: '',
    addition: '',
    fon: '05522-86852-0',
    fax: '05522-86852-29',
    commonName: '',
    xCoordinates: '51.727794',
    yCoordinates: '10.250305',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Osterode am Harz',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1958,
    pid: 91,
    number: '001-0156',
    title: 'Duisburg II, Königstr.',
    description: '',
    informations: '',
    street: 'Königstraße 50',
    zip: '47051',
    citypart: 'Zentrum',
    addition: '',
    fon: '0203-25673',
    fax: '0203-287132',
    commonName: '',
    xCoordinates: '51.432387',
    yCoordinates: '6.770205',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Duisburg',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. im Centrum OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1959,
    pid: 91,
    number: '001-2102',
    title: 'Jülich',
    description: '',
    informations: '',
    street: 'Kölnstraße 14',
    zip: '52428',
    citypart: '',
    addition: '',
    fon: '02461-931410',
    fax: '02461-9314129',
    commonName: '',
    xCoordinates: '50.922297',
    yCoordinates: '6.359967',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Jülich',
    country: 'Deutschland',
    state: 'Nordrhein-Westfalen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1961,
    pid: 91,
    number: '001-5200',
    title: 'Bolzano',
    description: '',
    informations: '',
    street: 'Via Museo, 15',
    zip: '39100',
    citypart: '',
    addition: '',
    fon: '0471-327798',
    fax: '0471-327799',
    commonName: '',
    xCoordinates: '46.4996406',
    yCoordinates: '11.3514472',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '106e546e-d252-461e-9c3a-b7ea6efe54b7-5dd7f27c1aa47650e8f39865',
    appointmentTimifyType: false,
    city: 'Bolzano',
    country: 'Italien',
    state: 'Trentino-Alto Adige',
    firmierung: 'Fielmann Srl',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente, <br /><br />Fielmann ha definito degli standard di igiene per l'ottica, per proteggere te e i nostri collaboratori dal Coronavirus. Queste misure hanno fondamento scientifico. Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori.<br /><br />Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store. Il controllo e l'applicazione delle lenti a contatto sono temporaneamente sospesi.<br /><br />Se possibile, porta con te la tua mascherina durante la tua visita in store. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro Servizio Clienti è disponibile al numero 800 792 992.<br /><br />Ti ringraziamo per la comprensione. Ci si rivede, finalmente!",
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente, <br /><br />Fielmann ha definito degli standard di igiene per l'ottica, per proteggere te e i nostri collaboratori dal Coronavirus. Queste misure hanno fondamento scientifico. Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori.<br /><br />Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store. Il controllo e l'applicazione delle lenti a contatto sono temporaneamente sospesi.<br /><br />Se possibile, porta con te la tua mascherina durante la tua visita in store. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro Servizio Clienti è disponibile al numero 800 792 992.<br /><br />Ti ringraziamo per la comprensione. Ci si rivede, finalmente!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1963,
    pid: 91,
    number: '001-2103',
    title: 'Lohr am Main',
    description: '',
    informations: '',
    street: 'Hauptstraße 37',
    zip: '97816',
    citypart: '',
    addition: '',
    fon: '09352-60578-0',
    fax: '09352-60578-29',
    commonName: '',
    xCoordinates: '49.9948',
    yCoordinates: '9.57321',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Lohr am Main',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1964,
    pid: 91,
    number: '001-5600',
    title: 'Sion',
    description: '',
    informations: '',
    street: 'Avenue du Midi 10',
    zip: '1950',
    citypart: '',
    addition: '',
    fon: '027 324 43 50',
    fax: '027 324 43 51',
    commonName: '',
    xCoordinates: '46.2306673',
    yCoordinates: '7.3591349',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '30038d2e-f595-417c-b5dc-0c148c461378-5ea16ba5d1d00e10173bef96',
    appointmentTimifyType: true,
    city: 'Sion',
    country: 'Schweiz',
    state: 'Wallis',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1966,
    pid: 91,
    number: '001-0778',
    title: 'Bydgoszcz',
    description:
      'Salon Fielmann w Bydgoszczy, Galeria Zielone Arkady prezentuje ponad 2000 modnych okularów, znane marki i modele projektantów o międzynarodowej sławie. W korzystnej cenie, którą gwarantujemy. Udzielamy na to gwarancji zwrotu pieniędzy:\r\nOprócz okularów jednoogniskowych i progresywnych, firma Fielmann oferuje w tym salonie również duży wybór okularów przeciwsłonecznych, soczewek kontaktowych i środków do ich pielęgnacji.\r\nZapraszamy do skorzystania z porady naszych kompetentnych optyków i skorzystania z bezpłatnego badania wzroku. W każdej chwili.\r\nFielmann prowadzi w Europie ponad 700 salonów optycznych, również w Państwa okolicy.',
    informations: '',
    street: 'ul. Wojska Polskiego 1',
    zip: '85-171',
    citypart: 'Oddział w Bydgoszczy',
    addition: '',
    fon: '052-3302071',
    fax: '052-3302070',
    commonName: 'Galeria Zielone Arkady',
    xCoordinates: '53.1159182',
    yCoordinates: '18.0096556',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'baa9ca87-545c-4ceb-81bc-b2f6ebeaf89e-5da6f5b0e1d5846fe6e7e7bb',
    appointmentTimifyType: false,
    city: 'Bydgoszcz',
    country: 'Polen',
    state: '',
    firmierung: 'Fielmann Sp. z o.o.',
    importantMessageHeader: 'Nasze standardy higieny',
    importantMessageText:
      'Drogie Klientki i drodzy Klienci! <br /><br />We współpracy z Prof. Dr. Exner (Klinika Uniwersytecka w Bonn) firma Fielmann opracowała standardy higieny w zakresie optyki okularowej i akustyki słuchu, aby chronić Państwa i swoich pracowników przed zakażeniem koronawirusem. W trybie natychmiastowym Fielmann wprowadza we wszystkich salonach procedury o potwierdzonym naukowo działaniu, aby mogli się Państwo czuć bezpiecznie.<br /><br />W ramach standardów higieny do naszych salonów jednocześnie może wejść tylko ograniczona liczba klientów. Termin wizyty mogą Państwo umówić wcześniej online, telefonicznie lub bezpośrednio w salonie.<br /><br />Przed Państwa wizytą prosimy pamiętać o zachowaniu standardów higieny Fielmann. Tymczasowo rezygnujemy z dopasowania soczewek kontaktowych i wizyt kontrolnych. W miarę możliwości przy okazji Państwa kolejnej wizyty w jednym z naszych salonów prosimy o zabranie ze sobą ochrony ochrony ust i nosa. Jeżeli mają Państwo objawy przeziębienia, uprzejmie prosimy o przeniesienie wizyty w salonie na inny termin.<br /><br />Nasz dział obsługi klienta jest do Państwa dyspozycji pod bezpłatnym numerem telefonu 800 343562.<br /><br />Dziękujemy za zrozumienie. Prosimy o siebie dbać!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Nasze standardy higieny',
      text:
        'Drogie Klientki i drodzy Klienci! <br /><br />We współpracy z Prof. Dr. Exner (Klinika Uniwersytecka w Bonn) firma Fielmann opracowała standardy higieny w zakresie optyki okularowej i akustyki słuchu, aby chronić Państwa i swoich pracowników przed zakażeniem koronawirusem. W trybie natychmiastowym Fielmann wprowadza we wszystkich salonach procedury o potwierdzonym naukowo działaniu, aby mogli się Państwo czuć bezpiecznie.<br /><br />W ramach standardów higieny do naszych salonów jednocześnie może wejść tylko ograniczona liczba klientów. Termin wizyty mogą Państwo umówić wcześniej online, telefonicznie lub bezpośrednio w salonie.<br /><br />Przed Państwa wizytą prosimy pamiętać o zachowaniu standardów higieny Fielmann. Tymczasowo rezygnujemy z dopasowania soczewek kontaktowych i wizyt kontrolnych. W miarę możliwości przy okazji Państwa kolejnej wizyty w jednym z naszych salonów prosimy o zabranie ze sobą ochrony ochrony ust i nosa. Jeżeli mają Państwo objawy przeziębienia, uprzejmie prosimy o przeniesienie wizyty w salonie na inny termin.<br /><br />Nasz dział obsługi klienta jest do Państwa dyspozycji pod bezpłatnym numerem telefonu 800 343562.<br /><br />Dziękujemy za zrozumienie. Prosimy o siebie dbać!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1972,
    pid: 91,
    number: '001-5601',
    title: 'Schwyz',
    description: '',
    informations: '',
    street: 'Herrengasse 20',
    zip: '6430',
    citypart: '',
    addition: '',
    fon: '041 818 20 90',
    fax: '041 818 20 91',
    commonName: '',
    xCoordinates: '47.022097',
    yCoordinates: '8.6514897',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16cccd1d00e10173befe7',
    appointmentTimifyType: true,
    city: 'Schwyz',
    country: 'Schweiz',
    state: 'Schwyz',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1979,
    pid: 91,
    number: '001-0737',
    title: 'Oberwart',
    description: '',
    informations: '',
    street: 'Wienerstraße 8 a',
    zip: '7400',
    citypart: '',
    addition: '',
    fon: '03352-31725-0',
    fax: '03352-31725-29',
    commonName: '',
    xCoordinates: '47.2895444',
    yCoordinates: '16.209929',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '5e906fc8ce5cd80ad2a02961',
    appointmentTimifyType: true,
    city: 'Oberwart',
    country: 'Österreich',
    state: '',
    firmierung: 'Fielmann GmbH',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1980,
    pid: 91,
    number: '001-2105',
    title: 'Wangen',
    description: '',
    informations: '',
    street: 'Herrenstraße 28',
    zip: '88239',
    citypart: '',
    addition: '',
    fon: '07522-97758-0',
    fax: '07522-97758-29',
    commonName: '',
    xCoordinates: '47.6873051',
    yCoordinates: '9.8339345',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Wangen im Allgäu',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1981,
    pid: 91,
    number: '001-2104',
    title: 'Pfarrkirchen',
    description: '',
    informations: '',
    street: 'Stadtplatz 25',
    zip: '84347',
    citypart: '',
    addition: '',
    fon: '08561-98964-0',
    fax: '08561-98964-29',
    commonName: '',
    xCoordinates: '48.4323774',
    yCoordinates: '12.9393846',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Pfarrkirchen',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1982,
    pid: 91,
    number: '001-5201',
    title: 'Bressanone',
    description: '',
    informations: '',
    street: 'Via Portici Minori, 1',
    zip: '39042',
    citypart: '',
    addition: '',
    fon: '0472-251670',
    fax: '0472-251671',
    commonName: '',
    xCoordinates: '46.7163974',
    yCoordinates: '11.6555882',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '106e546e-d252-461e-9c3a-b7ea6efe54b7-5dd7f27c70b4830ee4ccf3bf',
    appointmentTimifyType: false,
    city: 'Bressanone',
    country: 'Italien',
    state: 'Trentino-Alto Adige',
    firmierung: 'Fielmann Srl',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente, <br /><br />Fielmann ha definito degli standard di igiene per l'ottica, per proteggere te e i nostri collaboratori dal Coronavirus. Queste misure hanno fondamento scientifico. Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori.<br /><br />Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store. Il controllo e l'applicazione delle lenti a contatto sono temporaneamente sospesi.<br /><br />Se possibile, porta con te la tua mascherina durante la tua visita in store. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro Servizio Clienti è disponibile al numero 800 792 992.<br /><br />Ti ringraziamo per la comprensione. Ci si rivede, finalmente!",
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente, <br /><br />Fielmann ha definito degli standard di igiene per l'ottica, per proteggere te e i nostri collaboratori dal Coronavirus. Queste misure hanno fondamento scientifico. Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori.<br /><br />Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store. Il controllo e l'applicazione delle lenti a contatto sono temporaneamente sospesi.<br /><br />Se possibile, porta con te la tua mascherina durante la tua visita in store. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro Servizio Clienti è disponibile al numero 800 792 992.<br /><br />Ti ringraziamo per la comprensione. Ci si rivede, finalmente!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1985,
    pid: 91,
    number: '001-0558',
    title: 'Basel I, Marktplatz',
    description: '',
    informations: '',
    street: 'Marktplatz 16',
    zip: '4001',
    citypart: '',
    addition: '',
    fon: '061 266 16 20',
    fax: '061 266 16 29',
    commonName: '',
    xCoordinates: '47.5576234',
    yCoordinates: '7.588105',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16ccc970ac8102ce1d643',
    appointmentTimifyType: true,
    city: 'Basel',
    country: 'Schweiz',
    state: 'Basel-Stadt',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Nos normes d’hygiène',
    importantMessageText:
      'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Nos normes d’hygiène',
      text:
        'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1988,
    pid: 91,
    number: '001-0558',
    title: 'Basel I, Marktplatz',
    description: '',
    informations: '',
    street: 'Marktplatz 16',
    zip: '4001',
    citypart: '',
    addition: '',
    fon: '061 266 16 20',
    fax: '061 266 16 29',
    commonName: '',
    xCoordinates: '47.5576234',
    yCoordinates: '7.588105',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16ccc970ac8102ce1d643',
    appointmentTimifyType: true,
    city: 'Basel',
    country: 'Schweiz',
    state: 'Basel-Stadt',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1991,
    pid: 91,
    number: '001-0561',
    title: 'Zürich I, Bahnhofstraße',
    description: '',
    informations: '',
    street: 'Bahnhofstrasse 83',
    zip: '8001',
    citypart: 'Zentrum',
    addition: '',
    fon: '043 888 67 67',
    fax: '043 888 67 68',
    commonName: '',
    xCoordinates: '47.3760735',
    yCoordinates: '8.5391153',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16cccc8290c0fa864616f',
    appointmentTimifyType: true,
    city: 'Zürich',
    country: 'Schweiz',
    state: 'Zürich',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Nos normes d’hygiène',
    importantMessageText:
      'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Nos normes d’hygiène',
      text:
        'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1994,
    pid: 91,
    number: '001-0561',
    title: 'Zürich I, Bahnhofstraße',
    description: '',
    informations: '',
    street: 'Bahnhofstrasse 83',
    zip: '8001',
    citypart: 'Zentrum',
    addition: '',
    fon: '043 888 67 67',
    fax: '043 888 67 68',
    commonName: '',
    xCoordinates: '47.3760735',
    yCoordinates: '8.5391153',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16cccc8290c0fa864616f',
    appointmentTimifyType: true,
    city: 'Zürich',
    country: 'Schweiz',
    state: 'Zürich',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 1997,
    pid: 91,
    number: '001-0563',
    title: 'Langenthal',
    description: '',
    informations: '',
    street: 'Marktgasse 17',
    zip: '4900',
    citypart: '',
    addition: '',
    fon: '062 923 99 10',
    fax: '062 923 99 11',
    commonName: '',
    xCoordinates: '47.2116303',
    yCoordinates: '7.7889618',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16e6a63ab66101aa9018c',
    appointmentTimifyType: true,
    city: 'Langenthal',
    country: 'Schweiz',
    state: 'Bern',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Nos normes d’hygiène',
    importantMessageText:
      'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Nos normes d’hygiène',
      text:
        'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2000,
    pid: 91,
    number: '001-0563',
    title: 'Langenthal',
    description: '',
    informations: '',
    street: 'Marktgasse 17',
    zip: '4900',
    citypart: '',
    addition: '',
    fon: '062 923 99 10',
    fax: '062 923 99 11',
    commonName: '',
    xCoordinates: '47.2116303',
    yCoordinates: '7.7889618',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16e6a63ab66101aa9018c',
    appointmentTimifyType: true,
    city: 'Langenthal',
    country: 'Schweiz',
    state: 'Bern',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2003,
    pid: 91,
    number: '001-0565',
    title: 'Biel',
    description: '',
    informations: '',
    street: 'Nidaugasse 14',
    zip: '2502',
    citypart: '',
    addition: '',
    fon: '032 321 75 90',
    fax: '032 321 75 91',
    commonName: '',
    xCoordinates: '47.1398499',
    yCoordinates: '7.2454661',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16ccc63ab66101aa90114',
    appointmentTimifyType: true,
    city: 'Biel',
    country: 'Schweiz',
    state: 'Bern',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Nos normes d’hygiène',
    importantMessageText:
      'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Nos normes d’hygiène',
      text:
        'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2006,
    pid: 91,
    number: '001-0565',
    title: 'Biel',
    description: '',
    informations: '',
    street: 'Nidaugasse 14',
    zip: '2502',
    citypart: '',
    addition: '',
    fon: '032 321 75 90',
    fax: '032 321 75 91',
    commonName: '',
    xCoordinates: '47.1398499',
    yCoordinates: '7.2454661',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16ccc63ab66101aa90114',
    appointmentTimifyType: true,
    city: 'Biel',
    country: 'Schweiz',
    state: 'Bern',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2009,
    pid: 91,
    number: '001-0557',
    title: 'Luzern I, Weggisgasse',
    description: '',
    informations: '',
    street: 'Weggisgasse 36-38',
    zip: '6004',
    citypart: '',
    addition: '',
    fon: '041 417 44 50',
    fax: '041 417 44 51',
    commonName: '',
    xCoordinates: '47.0527427',
    yCoordinates: '8.3054608',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16cccc8290c0fa8646139',
    appointmentTimifyType: true,
    city: 'Luzern',
    country: 'Schweiz',
    state: 'Luzern',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Nos normes d’hygiène',
    importantMessageText:
      'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Nos normes d’hygiène',
      text:
        'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2012,
    pid: 91,
    number: '001-0557',
    title: 'Luzern I, Weggisgasse',
    description: '',
    informations: '',
    street: 'Weggisgasse 36-38',
    zip: '6004',
    citypart: '',
    addition: '',
    fon: '041 417 44 50',
    fax: '041 417 44 51',
    commonName: '',
    xCoordinates: '47.0527427',
    yCoordinates: '8.3054608',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16cccc8290c0fa8646139',
    appointmentTimifyType: true,
    city: 'Luzern',
    country: 'Schweiz',
    state: 'Luzern',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2015,
    pid: 91,
    number: '001-0559',
    title: 'Zofingen',
    description: '',
    informations: '',
    street: 'Vordere Hauptgasse 16',
    zip: '4800',
    citypart: 'Altstadt',
    addition: '',
    fon: '062 785 20 00',
    fax: '062 785 20 01',
    commonName: '',
    xCoordinates: '47.2900291',
    yCoordinates: '7.9440145',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16cccd1d00e10173befb9',
    appointmentTimifyType: true,
    city: 'Zofingen',
    country: 'Schweiz',
    state: 'Aargau',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Nos normes d’hygiène',
    importantMessageText:
      'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Nos normes d’hygiène',
      text:
        'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2018,
    pid: 91,
    number: '001-0559',
    title: 'Zofingen',
    description: '',
    informations: '',
    street: 'Vordere Hauptgasse 16',
    zip: '4800',
    citypart: 'Altstadt',
    addition: '',
    fon: '062 785 20 00',
    fax: '062 785 20 01',
    commonName: '',
    xCoordinates: '47.2900291',
    yCoordinates: '7.9440145',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16cccd1d00e10173befb9',
    appointmentTimifyType: true,
    city: 'Zofingen',
    country: 'Schweiz',
    state: 'Aargau',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2021,
    pid: 91,
    number: '001-0560',
    title: 'Bern I, Waisenhausplatz',
    description: '',
    informations: '',
    street: 'Waisenhausplatz 1',
    zip: '3011',
    citypart: '',
    addition: '',
    fon: '031 310 28 50',
    fax: '031 310 28 51',
    commonName: '',
    xCoordinates: '46.9483302',
    yCoordinates: '7.4438604',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16ccce15925100d496033',
    appointmentTimifyType: true,
    city: 'Bern',
    country: 'Schweiz',
    state: 'Bern',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Nos normes d’hygiène',
    importantMessageText:
      'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Nos normes d’hygiène',
      text:
        'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2024,
    pid: 91,
    number: '001-0560',
    title: 'Bern I, Waisenhausplatz',
    description: '',
    informations: '',
    street: 'Waisenhausplatz 1',
    zip: '3011',
    citypart: '',
    addition: '',
    fon: '031 310 28 50',
    fax: '031 310 28 51',
    commonName: '',
    xCoordinates: '46.9483302',
    yCoordinates: '7.4438604',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16ccce15925100d496033',
    appointmentTimifyType: true,
    city: 'Bern',
    country: 'Schweiz',
    state: 'Bern',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2027,
    pid: 91,
    number: '001-0562',
    title: 'Zug',
    description: '',
    informations: '',
    street: 'Bahnhofstrasse 32',
    zip: '6300',
    citypart: '',
    addition: '',
    fon: '041 728 07 50',
    fax: '041 728 07 51',
    commonName: '',
    xCoordinates: '47.1705309',
    yCoordinates: '8.516064',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16ccc63ab66101aa90110',
    appointmentTimifyType: true,
    city: 'Zug',
    country: 'Schweiz',
    state: 'Zug',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Nos normes d’hygiène',
    importantMessageText:
      'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Nos normes d’hygiène',
      text:
        'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2030,
    pid: 91,
    number: '001-0562',
    title: 'Zug',
    description: '',
    informations: '',
    street: 'Bahnhofstrasse 32',
    zip: '6300',
    citypart: '',
    addition: '',
    fon: '041 728 07 50',
    fax: '041 728 07 51',
    commonName: '',
    xCoordinates: '47.1705309',
    yCoordinates: '8.516064',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16ccc63ab66101aa90110',
    appointmentTimifyType: true,
    city: 'Zug',
    country: 'Schweiz',
    state: 'Zug',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2033,
    pid: 91,
    number: '001-0564',
    title: 'Solothurn',
    description: '',
    informations: '',
    street: 'Gurzelngasse 7',
    zip: '4500',
    citypart: '',
    addition: '',
    fon: '032 628 28 60',
    fax: '032 628 28 61',
    commonName: '',
    xCoordinates: '47.2078836',
    yCoordinates: '7.536342',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16ccc970ac8102ce1d64a',
    appointmentTimifyType: true,
    city: 'Solothurn',
    country: 'Schweiz',
    state: 'Solothurn',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Nos normes d’hygiène',
    importantMessageText:
      'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Nos normes d’hygiène',
      text:
        'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2036,
    pid: 91,
    number: '001-0564',
    title: 'Solothurn',
    description: '',
    informations: '',
    street: 'Gurzelngasse 7',
    zip: '4500',
    citypart: '',
    addition: '',
    fon: '032 628 28 60',
    fax: '032 628 28 61',
    commonName: '',
    xCoordinates: '47.2078836',
    yCoordinates: '7.536342',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16ccc970ac8102ce1d64a',
    appointmentTimifyType: true,
    city: 'Solothurn',
    country: 'Schweiz',
    state: 'Solothurn',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2039,
    pid: 91,
    number: '001-0566',
    title: 'Fribourg',
    description: '',
    informations: '',
    street: 'Rue de Romont 14',
    zip: '1700',
    citypart: '',
    addition: '',
    fon: '026 341 76 80',
    fax: '026 341 76 81',
    commonName: '',
    xCoordinates: '46.8045184',
    yCoordinates: '7.1548313',
    opening: false,
    opening_box: false,
    openingText:
      "La nouvelle succursale Fielmann de Fribourg, Rue de Romont. Venez le 26 octobre pour la grande fête d'ouverture. ",
    openingLink: '2960',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '30038d2e-f595-417c-b5dc-0c148c461378-5ea16ba563ab66101aa900d9',
    appointmentTimifyType: true,
    city: 'Fribourg',
    country: 'Schweiz',
    state: 'Freiburg',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Nos normes d’hygiène',
    importantMessageText:
      'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Nos normes d’hygiène',
      text:
        'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2042,
    pid: 91,
    number: '001-0566',
    title: 'Fribourg',
    description: '',
    informations: '',
    street: 'Rue de Romont 14',
    zip: '1700',
    citypart: '',
    addition: '',
    fon: '026 341 76 80',
    fax: '026 341 76 81',
    commonName: '',
    xCoordinates: '46.8045184',
    yCoordinates: '7.1548313',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '30038d2e-f595-417c-b5dc-0c148c461378-5ea16ba563ab66101aa900d9',
    appointmentTimifyType: true,
    city: 'Fribourg',
    country: 'Schweiz',
    state: 'Freiburg',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2045,
    pid: 91,
    number: '001-0568',
    title: 'Frauenfeld',
    description: '',
    informations: '',
    street: 'Zürcherstrasse 173',
    zip: '8500',
    citypart: '',
    addition: '',
    fon: '052 722 18 60',
    fax: '052 722 18 61',
    commonName: '',
    xCoordinates: '47.556592',
    yCoordinates: '8.8975066',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16cccd1d00e10173befc8',
    appointmentTimifyType: true,
    city: 'Frauenfeld',
    country: 'Schweiz',
    state: 'Thurgau',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Nos normes d’hygiène',
    importantMessageText:
      'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Nos normes d’hygiène',
      text:
        'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2048,
    pid: 91,
    number: '001-0568',
    title: 'Frauenfeld',
    description: '',
    informations: '',
    street: 'Zürcherstrasse 173',
    zip: '8500',
    citypart: '',
    addition: '',
    fon: '052 722 18 60',
    fax: '052 722 18 61',
    commonName: '',
    xCoordinates: '47.556592',
    yCoordinates: '8.8975066',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16cccd1d00e10173befc8',
    appointmentTimifyType: true,
    city: 'Frauenfeld',
    country: 'Schweiz',
    state: 'Thurgau',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2051,
    pid: 91,
    number: '001-0571',
    title: 'Genève',
    description: '',
    informations: '',
    street: "Rue de la Croix d'Or 9",
    zip: '1204',
    citypart: '',
    addition: '',
    fon: '022 544 20 20',
    fax: '022 544 20 21',
    commonName: '',
    xCoordinates: '46.2029132',
    yCoordinates: '6.1485477',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '30038d2e-f595-417c-b5dc-0c148c461378-5ea16ba563ab66101aa900e0',
    appointmentTimifyType: true,
    city: 'Genève',
    country: 'Schweiz',
    state: 'Genf',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Nos normes d’hygiène',
    importantMessageText:
      'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Nos normes d’hygiène',
      text:
        'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2054,
    pid: 91,
    number: '001-0571',
    title: 'Genève',
    description: '',
    informations: '',
    street: "Rue de la Croix d'Or 9",
    zip: '1204',
    citypart: '',
    addition: '',
    fon: '022 544 20 20',
    fax: '022 544 20 21',
    commonName: '',
    xCoordinates: '46.2029132',
    yCoordinates: '6.1485477',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '30038d2e-f595-417c-b5dc-0c148c461378-5ea16ba563ab66101aa900e0',
    appointmentTimifyType: true,
    city: 'Genève',
    country: 'Schweiz',
    state: 'Genf',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2057,
    pid: 91,
    number: '001-0567',
    title: 'Schaffhausen',
    description: '',
    informations: '',
    street: 'Fronwagplatz 10',
    zip: '8200',
    citypart: '',
    addition: '',
    fon: '052 647 60 20',
    fax: '052 647 60 21',
    commonName: '',
    xCoordinates: '47.6989422',
    yCoordinates: '8.6355882',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16ccc970ac8102ce1d63c',
    appointmentTimifyType: true,
    city: 'Schaffhausen',
    country: 'Schweiz',
    state: 'Schaffhausen',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Nos normes d’hygiène',
    importantMessageText:
      'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Nos normes d’hygiène',
      text:
        'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2060,
    pid: 91,
    number: '001-0567',
    title: 'Schaffhausen',
    description: '',
    informations: '',
    street: 'Fronwagplatz 10',
    zip: '8200',
    citypart: '',
    addition: '',
    fon: '052 647 60 20',
    fax: '052 647 60 21',
    commonName: '',
    xCoordinates: '47.6989422',
    yCoordinates: '8.6355882',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16ccc970ac8102ce1d63c',
    appointmentTimifyType: true,
    city: 'Schaffhausen',
    country: 'Schweiz',
    state: 'Schaffhausen',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2063,
    pid: 91,
    number: '001-0569',
    title: 'Thun',
    description: '',
    informations: '',
    street: 'Bälliz 48',
    zip: '3600',
    citypart: '',
    addition: '',
    fon: '033 225 01 50',
    fax: '033 225 01 51',
    commonName: '',
    xCoordinates: '46.7580506',
    yCoordinates: '7.6275111',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16ccce15925100d496034',
    appointmentTimifyType: true,
    city: 'Thun',
    country: 'Schweiz',
    state: 'Bern',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Nos normes d’hygiène',
    importantMessageText:
      'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Nos normes d’hygiène',
      text:
        'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2066,
    pid: 91,
    number: '001-0569',
    title: 'Thun',
    description: '',
    informations: '',
    street: 'Bälliz 48',
    zip: '3600',
    citypart: '',
    addition: '',
    fon: '033 225 01 50',
    fax: '033 225 01 51',
    commonName: '',
    xCoordinates: '46.7580506',
    yCoordinates: '7.6275111',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16ccce15925100d496034',
    appointmentTimifyType: true,
    city: 'Thun',
    country: 'Schweiz',
    state: 'Bern',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2069,
    pid: 91,
    number: '001-0570',
    title: 'Lausanne',
    description: '',
    informations: '',
    street: 'Rue du Pont 22',
    zip: '1003',
    citypart: '',
    addition: '',
    fon: '021 310 84 00',
    fax: '021 310 84 01',
    commonName: '',
    xCoordinates: '46.5210099',
    yCoordinates: '6.6332518',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '30038d2e-f595-417c-b5dc-0c148c461378-5ea16ba5970ac8102ce1d61c',
    appointmentTimifyType: true,
    city: 'Lausanne',
    country: 'Schweiz',
    state: 'Waadt',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Nos normes d’hygiène',
    importantMessageText:
      'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Nos normes d’hygiène',
      text:
        'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2072,
    pid: 91,
    number: '001-0570',
    title: 'Lausanne',
    description: '',
    informations: '',
    street: 'Rue du Pont 22',
    zip: '1003',
    citypart: '',
    addition: '',
    fon: '021 310 84 00',
    fax: '021 310 84 01',
    commonName: '',
    xCoordinates: '46.5210099',
    yCoordinates: '6.6332518',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '30038d2e-f595-417c-b5dc-0c148c461378-5ea16ba5970ac8102ce1d61c',
    appointmentTimifyType: true,
    city: 'Lausanne',
    country: 'Schweiz',
    state: 'Waadt',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2075,
    pid: 91,
    number: '001-0572',
    title: 'Winterthur',
    description: '',
    informations: '',
    street: 'Marktgasse 72/ Schmidgasse 1',
    zip: '8400',
    citypart: '',
    addition: '',
    fon: '052 214 19 55',
    fax: '052 214 19 56',
    commonName: '',
    xCoordinates: '47.4995665',
    yCoordinates: '8.7273233',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16ccce15925100d496049',
    appointmentTimifyType: true,
    city: 'Winterthur',
    country: 'Schweiz',
    state: 'Zürich',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Nos normes d’hygiène',
    importantMessageText:
      'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Nos normes d’hygiène',
      text:
        'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2078,
    pid: 91,
    number: '001-0572',
    title: 'Winterthur',
    description: '',
    informations: '',
    street: 'Marktgasse 72/ Schmidgasse 1',
    zip: '8400',
    citypart: '',
    addition: '',
    fon: '052 214 19 55',
    fax: '052 214 19 56',
    commonName: '',
    xCoordinates: '47.4995665',
    yCoordinates: '8.7273233',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16ccce15925100d496049',
    appointmentTimifyType: true,
    city: 'Winterthur',
    country: 'Schweiz',
    state: 'Zürich',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2081,
    pid: 91,
    number: '001-0574',
    title: 'Aarau',
    description: '',
    informations: '',
    street: 'Igelweid 1',
    zip: '5000',
    citypart: '',
    addition: '',
    fon: '062 823 35 50',
    fax: '062 823 35 51',
    commonName: '',
    xCoordinates: '47.3917024',
    yCoordinates: '8.0454986',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16cccd1d00e10173befb4',
    appointmentTimifyType: true,
    city: 'Aarau',
    country: 'Schweiz',
    state: 'Aargau',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Nos normes d’hygiène',
    importantMessageText:
      'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Nos normes d’hygiène',
      text:
        'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2084,
    pid: 91,
    number: '001-0574',
    title: 'Aarau',
    description: '',
    informations: '',
    street: 'Igelweid 1',
    zip: '5000',
    citypart: '',
    addition: '',
    fon: '062 823 35 50',
    fax: '062 823 35 51',
    commonName: '',
    xCoordinates: '47.3917024',
    yCoordinates: '8.0454986',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16cccd1d00e10173befb4',
    appointmentTimifyType: true,
    city: 'Aarau',
    country: 'Schweiz',
    state: 'Aargau',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2087,
    pid: 91,
    number: '001-0576',
    title: 'St. Gallen',
    description: '',
    informations: '',
    street: 'Multergasse 8',
    zip: '9000',
    citypart: '',
    addition: '',
    fon: '071 226 50 70',
    fax: '071 226 50 71',
    commonName: '',
    xCoordinates: '47.4246285',
    yCoordinates: '9.3757623',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16ccc970ac8102ce1d653',
    appointmentTimifyType: true,
    city: 'St. Gallen',
    country: 'Schweiz',
    state: 'St. Gallen',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Nos normes d’hygiène',
    importantMessageText:
      'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Nos normes d’hygiène',
      text:
        'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2090,
    pid: 91,
    number: '001-0576',
    title: 'St. Gallen',
    description: '',
    informations: '',
    street: 'Multergasse 8',
    zip: '9000',
    citypart: '',
    addition: '',
    fon: '071 226 50 70',
    fax: '071 226 50 71',
    commonName: '',
    xCoordinates: '47.4246285',
    yCoordinates: '9.3757623',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16ccc970ac8102ce1d653',
    appointmentTimifyType: true,
    city: 'St. Gallen',
    country: 'Schweiz',
    state: 'St. Gallen',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2093,
    pid: 91,
    number: '001-0578',
    title: 'Chur',
    description: '',
    informations: '',
    street: 'Quaderstrasse 11',
    zip: '7001',
    citypart: 'Zentrum',
    addition: '',
    fon: '081 257 07 80',
    fax: '081 257 07 99',
    commonName: '',
    xCoordinates: '46.8524582',
    yCoordinates: '9.5324293',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16ccce15925100d496042',
    appointmentTimifyType: true,
    city: 'Chur',
    country: 'Schweiz',
    state: 'Graubünden',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Nos normes d’hygiène',
    importantMessageText:
      'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Nos normes d’hygiène',
      text:
        'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2096,
    pid: 91,
    number: '001-0578',
    title: 'Chur',
    description: '',
    informations: '',
    street: 'Quaderstrasse 11',
    zip: '7001',
    citypart: 'Zentrum',
    addition: '',
    fon: '081 257 07 80',
    fax: '081 257 07 99',
    commonName: '',
    xCoordinates: '46.8524582',
    yCoordinates: '9.5324293',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16ccce15925100d496042',
    appointmentTimifyType: true,
    city: 'Chur',
    country: 'Schweiz',
    state: 'Graubünden',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2099,
    pid: 91,
    number: '001-0581',
    title: 'Olten',
    description: '',
    informations: '',
    street: 'Hauptgasse 25',
    zip: '4600',
    citypart: 'Altstadt',
    addition: '',
    fon: '062 205 22 44',
    fax: '062 205 22 45',
    commonName: '',
    xCoordinates: '47.3498512',
    yCoordinates: '7.9035004',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16ccc970ac8102ce1d64c',
    appointmentTimifyType: true,
    city: 'Olten',
    country: 'Schweiz',
    state: 'Solothurn',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Nos normes d’hygiène',
    importantMessageText:
      'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Nos normes d’hygiène',
      text:
        'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2102,
    pid: 91,
    number: '001-0581',
    title: 'Olten',
    description: '',
    informations: '',
    street: 'Hauptgasse 25',
    zip: '4600',
    citypart: 'Altstadt',
    addition: '',
    fon: '062 205 22 44',
    fax: '062 205 22 45',
    commonName: '',
    xCoordinates: '47.3498512',
    yCoordinates: '7.9035004',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16ccc970ac8102ce1d64c',
    appointmentTimifyType: true,
    city: 'Olten',
    country: 'Schweiz',
    state: 'Solothurn',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2105,
    pid: 91,
    number: '001-0573',
    title: 'Burgdorf',
    description: '',
    informations: '',
    street: 'Bahnhofstrasse 15',
    zip: '3400',
    citypart: 'Unterstadt',
    addition: '',
    fon: '034 420 34 00',
    fax: '034 420 34 01',
    commonName: '',
    xCoordinates: '47.0595627',
    yCoordinates: '7.623038',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16ccc63ab66101aa90125',
    appointmentTimifyType: true,
    city: 'Burgdorf',
    country: 'Schweiz',
    state: 'Bern',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Nos normes d’hygiène',
    importantMessageText:
      'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Nos normes d’hygiène',
      text:
        'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2108,
    pid: 91,
    number: '001-0573',
    title: 'Burgdorf',
    description: '',
    informations: '',
    street: 'Bahnhofstrasse 15',
    zip: '3400',
    citypart: 'Unterstadt',
    addition: '',
    fon: '034 420 34 00',
    fax: '034 420 34 01',
    commonName: '',
    xCoordinates: '47.0595627',
    yCoordinates: '7.623038',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16ccc63ab66101aa90125',
    appointmentTimifyType: true,
    city: 'Burgdorf',
    country: 'Schweiz',
    state: 'Bern',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2111,
    pid: 91,
    number: '001-0575',
    title: 'Wil',
    description: '',
    informations: '',
    street: 'Obere Bahnhofstr. 38',
    zip: '9500',
    citypart: '',
    addition: '',
    fon: '071 910 26 06',
    fax: '071 910 26 07',
    commonName: '',
    xCoordinates: '47.465501',
    yCoordinates: '9.0456681',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16cccc8290c0fa864613f',
    appointmentTimifyType: true,
    city: 'Wil',
    country: 'Schweiz',
    state: 'St. Gallen',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Nos normes d’hygiène',
    importantMessageText:
      'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Nos normes d’hygiène',
      text:
        'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2114,
    pid: 91,
    number: '001-0575',
    title: 'Wil',
    description: '',
    informations: '',
    street: 'Obere Bahnhofstr. 38',
    zip: '9500',
    citypart: '',
    addition: '',
    fon: '071 910 26 06',
    fax: '071 910 26 07',
    commonName: '',
    xCoordinates: '47.465501',
    yCoordinates: '9.0456681',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16cccc8290c0fa864613f',
    appointmentTimifyType: true,
    city: 'Wil',
    country: 'Schweiz',
    state: 'St. Gallen',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2117,
    pid: 91,
    number: '001-0577',
    title: 'Neuchâtel',
    description: '',
    informations: '',
    street: 'Grand-Rue 2',
    zip: '2000',
    citypart: '',
    addition: '',
    fon: '032 722 74 44',
    fax: '032 722 74 45',
    commonName: '',
    xCoordinates: '46.9919892',
    yCoordinates: '6.9291627',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '30038d2e-f595-417c-b5dc-0c148c461378-5ea16ba5c8290c0fa864611a',
    appointmentTimifyType: true,
    city: 'Neuchâtel',
    country: 'Schweiz',
    state: 'Neuenburg',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Nos normes d’hygiène',
    importantMessageText:
      'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Nos normes d’hygiène',
      text:
        'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2120,
    pid: 91,
    number: '001-0577',
    title: 'Neuchâtel',
    description: '',
    informations: '',
    street: 'Grand-Rue 2',
    zip: '2000',
    citypart: '',
    addition: '',
    fon: '032 722 74 44',
    fax: '032 722 74 45',
    commonName: '',
    xCoordinates: '46.9919892',
    yCoordinates: '6.9291627',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '30038d2e-f595-417c-b5dc-0c148c461378-5ea16ba5c8290c0fa864611a',
    appointmentTimifyType: true,
    city: 'Neuchâtel',
    country: 'Schweiz',
    state: 'Neuenburg',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2123,
    pid: 91,
    number: '001-0579',
    title: 'Baden',
    description: '',
    informations: '',
    street: 'Weite Gasse 27',
    zip: '5400',
    citypart: 'Altstadt',
    addition: '',
    fon: '056 203 30 00',
    fax: '056 203 30 01',
    commonName: '',
    xCoordinates: '47.4726787',
    yCoordinates: '8.3077372',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16ccc970ac8102ce1d64b',
    appointmentTimifyType: true,
    city: 'Baden',
    country: 'Schweiz',
    state: 'Aargau',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Nos normes d’hygiène',
    importantMessageText:
      'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Nos normes d’hygiène',
      text:
        'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2126,
    pid: 91,
    number: '001-0579',
    title: 'Baden',
    description: '',
    informations: '',
    street: 'Weite Gasse 27',
    zip: '5400',
    citypart: 'Altstadt',
    addition: '',
    fon: '056 203 30 00',
    fax: '056 203 30 01',
    commonName: '',
    xCoordinates: '47.4726787',
    yCoordinates: '8.3077372',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16ccc970ac8102ce1d64b',
    appointmentTimifyType: true,
    city: 'Baden',
    country: 'Schweiz',
    state: 'Aargau',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2129,
    pid: 91,
    number: '001-0580',
    title: 'Buchs',
    description: '',
    informations: '',
    street: 'Bahnhofstrasse 39',
    zip: '9470',
    citypart: '',
    addition: '',
    fon: '081 750 52 52',
    fax: '081 750 52 53',
    commonName: '',
    xCoordinates: '47.1659593',
    yCoordinates: '9.4736979',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16d6d970ac8102ce1d69b',
    appointmentTimifyType: true,
    city: 'Buchs',
    country: 'Schweiz',
    state: 'St. Gallen',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Nos normes d’hygiène',
    importantMessageText:
      'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Nos normes d’hygiène',
      text:
        'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2132,
    pid: 91,
    number: '001-0580',
    title: 'Buchs',
    description: '',
    informations: '',
    street: 'Bahnhofstrasse 39',
    zip: '9470',
    citypart: '',
    addition: '',
    fon: '081 750 52 52',
    fax: '081 750 52 53',
    commonName: '',
    xCoordinates: '47.1659593',
    yCoordinates: '9.4736979',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16d6d970ac8102ce1d69b',
    appointmentTimifyType: true,
    city: 'Buchs',
    country: 'Schweiz',
    state: 'St. Gallen',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2135,
    pid: 91,
    number: '001-0582',
    title: 'Zürich II, Oerlikon',
    description: '',
    informations: '',
    street: 'Schaffhauserstrasse 355',
    zip: '8050',
    citypart: 'Oerlikon',
    addition: '',
    fon: '043 205 11 60',
    fax: '043 205 11 61',
    commonName: 'Sternen Oerlikon',
    xCoordinates: '47.4099293',
    yCoordinates: '8.5458836',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16cccd1d00e10173befe0',
    appointmentTimifyType: true,
    city: 'Zürich',
    country: 'Schweiz',
    state: 'Zürich',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Nos normes d’hygiène',
    importantMessageText:
      'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Nos normes d’hygiène',
      text:
        'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2138,
    pid: 91,
    number: '001-0582',
    title: 'Zürich II, Oerlikon',
    description: '',
    informations: '',
    street: 'Schaffhauserstrasse 355',
    zip: '8050',
    citypart: 'Oerlikon',
    addition: '',
    fon: '043 205 11 60',
    fax: '043 205 11 61',
    commonName: 'Sternen Oerlikon',
    xCoordinates: '47.4099293',
    yCoordinates: '8.5458836',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16cccd1d00e10173befe0',
    appointmentTimifyType: true,
    city: 'Zürich',
    country: 'Schweiz',
    state: 'Zürich',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2141,
    pid: 91,
    number: '001-5591',
    title: 'Rapperswil',
    description: '',
    informations: '',
    street: 'Untere Bahnhofstrasse 11',
    zip: '8640',
    citypart: '',
    addition: '',
    fon: '055 220 13 60',
    fax: '055 220 13 89',
    commonName: '',
    xCoordinates: '47.2256137',
    yCoordinates: '8.8177974',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16ccc63ab66101aa90142',
    appointmentTimifyType: true,
    city: 'Rapperswil',
    country: 'Schweiz',
    state: 'St. Gallen',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Nos normes d’hygiène',
    importantMessageText:
      'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Nos normes d’hygiène',
      text:
        'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2144,
    pid: 91,
    number: '001-5591',
    title: 'Rapperswil',
    description: '',
    informations: '',
    street: 'Untere Bahnhofstrasse 11',
    zip: '8640',
    citypart: '',
    addition: '',
    fon: '055 220 13 60',
    fax: '055 220 13 89',
    commonName: '',
    xCoordinates: '47.2256137',
    yCoordinates: '8.8177974',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16ccc63ab66101aa90142',
    appointmentTimifyType: true,
    city: 'Rapperswil',
    country: 'Schweiz',
    state: 'St. Gallen',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2147,
    pid: 91,
    number: '001-5593',
    title: 'Spreitenbach',
    description: '',
    informations: '',
    street: 'Shopping-Center',
    zip: '8957',
    citypart: '',
    addition: '',
    fon: '056 418 20 30',
    fax: '056 418 20 31',
    commonName: 'Shoppi Tivoli',
    xCoordinates: '47.4216259',
    yCoordinates: '8.3688048',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16cccc8290c0fa8646149',
    appointmentTimifyType: true,
    city: 'Spreitenbach',
    country: 'Schweiz',
    state: 'Aargau',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Nos normes d’hygiène',
    importantMessageText:
      'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Nos normes d’hygiène',
      text:
        'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2150,
    pid: 91,
    number: '001-5593',
    title: 'Spreitenbach',
    description: '',
    informations: '',
    street: 'Shopping-Center',
    zip: '8957',
    citypart: '',
    addition: '',
    fon: '056 418 20 30',
    fax: '056 418 20 31',
    commonName: 'Shoppi Tivoli',
    xCoordinates: '47.4216259',
    yCoordinates: '8.3688048',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16cccc8290c0fa8646149',
    appointmentTimifyType: true,
    city: 'Spreitenbach',
    country: 'Schweiz',
    state: 'Aargau',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2159,
    pid: 91,
    number: '001-2106',
    title: 'Hann. Münden',
    description: '',
    informations: '',
    street: 'Lange Straße 34',
    zip: '34346',
    citypart: '',
    addition: '',
    fon: '05541-90930-0',
    fax: '05541-90930-29',
    commonName: '',
    xCoordinates: '51.41744',
    yCoordinates: '9.65153',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Hann. Münden',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2163,
    pid: 91,
    number: '001-5592',
    title: 'Basel IV',
    description: '',
    informations: '',
    street: 'Hochbergerstrasse 70',
    zip: '4057',
    citypart: 'Kleinhüningen',
    addition: '',
    fon: '061 633 20 50',
    fax: '061 633 20 59',
    commonName: 'Stücki Park',
    xCoordinates: '47.579952',
    yCoordinates: '7.5957171',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16ccc63ab66101aa90135',
    appointmentTimifyType: true,
    city: 'Basel',
    country: 'Schweiz',
    state: 'Basel-Stadt',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Nos normes d’hygiène',
    importantMessageText:
      'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Nos normes d’hygiène',
      text:
        'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2167,
    pid: 91,
    number: '001-5592',
    title: 'Basel IV',
    description: '',
    informations: '',
    street: 'Hochbergerstrasse 70',
    zip: '4057',
    citypart: 'Kleinhüningen',
    addition: '',
    fon: '061 633 20 50',
    fax: '061 633 20 59',
    commonName: 'Stücki Park',
    xCoordinates: '47.579952',
    yCoordinates: '7.5957171',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16ccc63ab66101aa90135',
    appointmentTimifyType: true,
    city: 'Basel',
    country: 'Schweiz',
    state: 'Basel-Stadt',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2171,
    pid: 91,
    number: '001-5594',
    title: 'Brig',
    description: '',
    informations: '',
    street: 'Kantonsstrasse 58',
    zip: '3902',
    citypart: '',
    addition: '',
    fon: '027 922 46 00',
    fax: '027 922 46 01',
    commonName: 'Simplon Center',
    xCoordinates: '46.3089003',
    yCoordinates: '7.9693461',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16ccc63ab66101aa90120',
    appointmentTimifyType: true,
    city: 'Brig-Glis',
    country: 'Schweiz',
    state: 'Wallis',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Nos normes d’hygiène',
    importantMessageText:
      'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Nos normes d’hygiène',
      text:
        'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2175,
    pid: 91,
    number: '001-5594',
    title: 'Brig',
    description: '',
    informations: '',
    street: 'Kantonsstrasse 58',
    zip: '3902',
    citypart: '',
    addition: '',
    fon: '027 922 46 00',
    fax: '027 922 46 01',
    commonName: 'Simplon Center',
    xCoordinates: '46.3089003',
    yCoordinates: '7.9693461',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16ccc63ab66101aa90120',
    appointmentTimifyType: true,
    city: 'Brig-Glis',
    country: 'Schweiz',
    state: 'Wallis',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2179,
    pid: 91,
    number: '001-5595',
    title: 'Liestal',
    description: '',
    informations: '',
    street: 'Rathausstrasse 59',
    zip: '4410',
    citypart: '',
    addition: '',
    fon: '061 926 86 20',
    fax: '061 926 86 21',
    commonName: '',
    xCoordinates: '47.4835456',
    yCoordinates: '7.7355285',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16cccc8290c0fa864614f',
    appointmentTimifyType: true,
    city: 'Liestal',
    country: 'Schweiz',
    state: 'Basel-Landschaft',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Nos normes d’hygiène',
    importantMessageText:
      'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Nos normes d’hygiène',
      text:
        'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2183,
    pid: 91,
    number: '001-5595',
    title: 'Liestal',
    description: '',
    informations: '',
    street: 'Rathausstrasse 59',
    zip: '4410',
    citypart: '',
    addition: '',
    fon: '061 926 86 20',
    fax: '061 926 86 21',
    commonName: '',
    xCoordinates: '47.4835456',
    yCoordinates: '7.7355285',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16cccc8290c0fa864614f',
    appointmentTimifyType: true,
    city: 'Liestal',
    country: 'Schweiz',
    state: 'Basel-Landschaft',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2187,
    pid: 91,
    number: '001-5596',
    title: 'Lugano',
    description: '',
    informations: '',
    street: 'Via Pietro Peri 4',
    zip: '6900',
    citypart: '',
    addition: '',
    fon: '091 911 95 30',
    fax: '091 911 95 31',
    commonName: '',
    xCoordinates: '46.0054209',
    yCoordinates: '8.9508758',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '8df10cf1-ef64-41d5-89df-5bec88f4bb35-5ea1694c970ac8102ce1d5e9',
    appointmentTimifyType: true,
    city: 'Lugano',
    country: 'Schweiz',
    state: 'Tessin',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Nos normes d’hygiène',
    importantMessageText:
      'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Nos normes d’hygiène',
      text:
        'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2191,
    pid: 91,
    number: '001-5596',
    title: 'Lugano',
    description: '',
    informations: '',
    street: 'Via Pietro Peri 4',
    zip: '6900',
    citypart: '',
    addition: '',
    fon: '091 911 95 30',
    fax: '091 911 95 31',
    commonName: '',
    xCoordinates: '46.0054209',
    yCoordinates: '8.9508758',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '8df10cf1-ef64-41d5-89df-5bec88f4bb35-5ea1694c970ac8102ce1d5e9',
    appointmentTimifyType: true,
    city: 'Lugano',
    country: 'Schweiz',
    state: 'Tessin',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2195,
    pid: 91,
    number: '001-5598',
    title: 'Bülach',
    description: '',
    informations: '',
    street: 'Bahnhofstrasse 11',
    zip: '8180',
    citypart: '',
    addition: '',
    fon: '044 864 84 48',
    fax: '044 864 84 49',
    commonName: '',
    xCoordinates: '47.5201399',
    yCoordinates: '8.5399271',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16ccce15925100d49604a',
    appointmentTimifyType: true,
    city: 'Bülach',
    country: 'Schweiz',
    state: 'Zürich',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Nos normes d’hygiène',
    importantMessageText:
      'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Nos normes d’hygiène',
      text:
        'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2199,
    pid: 91,
    number: '001-5598',
    title: 'Bülach',
    description: '',
    informations: '',
    street: 'Bahnhofstrasse 11',
    zip: '8180',
    citypart: '',
    addition: '',
    fon: '044 864 84 48',
    fax: '044 864 84 49',
    commonName: '',
    xCoordinates: '47.5201399',
    yCoordinates: '8.5399271',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16ccce15925100d49604a',
    appointmentTimifyType: true,
    city: 'Bülach',
    country: 'Schweiz',
    state: 'Zürich',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2203,
    pid: 91,
    number: '001-5600',
    title: 'Sion',
    description: '',
    informations: '',
    street: 'Avenue du Midi 10',
    zip: '1950',
    citypart: '',
    addition: '',
    fon: '027 324 43 50',
    fax: '027 324 43 51',
    commonName: '',
    xCoordinates: '46.2306673',
    yCoordinates: '7.3591349',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '30038d2e-f595-417c-b5dc-0c148c461378-5ea16ba5d1d00e10173bef96',
    appointmentTimifyType: true,
    city: 'Sion',
    country: 'Schweiz',
    state: 'Wallis',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Nos normes d’hygiène',
    importantMessageText:
      'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Nos normes d’hygiène',
      text:
        'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2207,
    pid: 91,
    number: '001-5600',
    title: 'Sion',
    description: '',
    informations: '',
    street: 'Avenue du Midi 10',
    zip: '1950',
    citypart: '',
    addition: '',
    fon: '027 324 43 50',
    fax: '027 324 43 51',
    commonName: '',
    xCoordinates: '46.2306673',
    yCoordinates: '7.3591349',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '30038d2e-f595-417c-b5dc-0c148c461378-5ea16ba5d1d00e10173bef96',
    appointmentTimifyType: true,
    city: 'Sion',
    country: 'Schweiz',
    state: 'Wallis',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2211,
    pid: 91,
    number: '001-5597',
    title: 'Thalwil',
    description: '',
    informations: '',
    street: 'Gotthardstrasse 16b',
    zip: '8800',
    citypart: '',
    addition: '',
    fon: '043 336 90 80',
    fax: '043 336 90 81',
    commonName: '',
    xCoordinates: '47.2950125',
    yCoordinates: '8.5648301',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16cccd1d00e10173befc4',
    appointmentTimifyType: true,
    city: 'Thalwil',
    country: 'Schweiz',
    state: 'Zürich',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Nos normes d’hygiène',
    importantMessageText:
      'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Nos normes d’hygiène',
      text:
        'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2215,
    pid: 91,
    number: '001-5597',
    title: 'Thalwil',
    description: '',
    informations: '',
    street: 'Gotthardstrasse 16b',
    zip: '8800',
    citypart: '',
    addition: '',
    fon: '043 336 90 80',
    fax: '043 336 90 81',
    commonName: '',
    xCoordinates: '47.2950125',
    yCoordinates: '8.5648301',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16cccd1d00e10173befc4',
    appointmentTimifyType: true,
    city: 'Thalwil',
    country: 'Schweiz',
    state: 'Zürich',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2219,
    pid: 91,
    number: '001-5599',
    title: 'Sursee',
    description: '',
    informations: '',
    street: 'Bahnhofstrasse 29',
    zip: '6210',
    citypart: '',
    addition: '',
    fon: '041 925 27 50',
    fax: '041 925 27 51',
    commonName: '',
    xCoordinates: '47.1723234',
    yCoordinates: '8.102818',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16cccc8290c0fa864614e',
    appointmentTimifyType: true,
    city: 'Sursee',
    country: 'Schweiz',
    state: 'Luzern',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Nos normes d’hygiène',
    importantMessageText:
      'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Nos normes d’hygiène',
      text:
        'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2223,
    pid: 91,
    number: '001-5599',
    title: 'Sursee',
    description: '',
    informations: '',
    street: 'Bahnhofstrasse 29',
    zip: '6210',
    citypart: '',
    addition: '',
    fon: '041 925 27 50',
    fax: '041 925 27 51',
    commonName: '',
    xCoordinates: '47.1723234',
    yCoordinates: '8.102818',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16cccc8290c0fa864614e',
    appointmentTimifyType: true,
    city: 'Sursee',
    country: 'Schweiz',
    state: 'Luzern',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2227,
    pid: 91,
    number: '001-5601',
    title: 'Schwyz',
    description: '',
    informations: '',
    street: 'Herrengasse 20',
    zip: '6430',
    citypart: '',
    addition: '',
    fon: '041 818 20 90',
    fax: '041 818 20 91',
    commonName: '',
    xCoordinates: '47.0221447',
    yCoordinates: '8.6515717',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16cccd1d00e10173befe7',
    appointmentTimifyType: true,
    city: 'Schwyz',
    country: 'Schweiz',
    state: 'Schwyz',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Nos normes d’hygiène',
    importantMessageText:
      'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Nos normes d’hygiène',
      text:
        'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2231,
    pid: 91,
    number: '001-5601',
    title: 'Schwyz',
    description: '',
    informations: '',
    street: 'Herrengasse 20',
    zip: '6430',
    citypart: '',
    addition: '',
    fon: '041 818 20 90',
    fax: '041 818 20 91',
    commonName: '',
    xCoordinates: '47.0221447',
    yCoordinates: '8.6515717',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16cccd1d00e10173befe7',
    appointmentTimifyType: true,
    city: 'Schwyz',
    country: 'Schweiz',
    state: 'Schwyz',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2235,
    pid: 91,
    number: '001-0738',
    title: 'Leoben',
    description: '',
    informations: '',
    street: 'Dominikanergasse 1/ Hauptplatz 18',
    zip: '8700',
    citypart: '',
    addition: '',
    fon: '03842-909200',
    fax: '03842-9092029',
    commonName: '',
    xCoordinates: '47.3815861',
    yCoordinates: '15.0943824',
    opening: false,
    opening_box: false,
    openingText:
      'Die neue Fielmann-Niederlassung in Leoben, Dominikanergasse 1. Kommen Sie am 8. Dezember zum großen Eröffnungsfest. ',
    openingLink: '2657',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '5e906fc8ce5cd80ad2a02941',
    appointmentTimifyType: true,
    city: 'Leoben',
    country: 'Österreich',
    state: 'Steiermark',
    firmierung: 'Fielmann GmbH',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2237,
    pid: 91,
    number: '001-5200',
    title: 'Bolzano',
    description: '',
    informations: '',
    street: 'Via Museo, 15',
    zip: '39100',
    citypart: '',
    addition: '',
    fon: '0471-327798',
    fax: '0471-327799',
    commonName: '',
    xCoordinates: '46.4996406',
    yCoordinates: '11.3514472',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '106e546e-d252-461e-9c3a-b7ea6efe54b7-5dd7f27c1aa47650e8f39865',
    appointmentTimifyType: false,
    city: 'Bolzano',
    country: 'Italien',
    state: 'Trentino-Alto Adige',
    firmierung: 'Fielmann Srl',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat Hygienestandards für die Augenoptik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 800 792 992 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat Hygienestandards für die Augenoptik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 800 792 992 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2238,
    pid: 91,
    number: '001-5201',
    title: 'Bressanone',
    description: '',
    informations: '',
    street: 'Via Portici Minori, 1',
    zip: '39042',
    citypart: '',
    addition: '',
    fon: '0472-251670',
    fax: '0472-251671',
    commonName: '',
    xCoordinates: '46.7163974',
    yCoordinates: '11.6555882',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '106e546e-d252-461e-9c3a-b7ea6efe54b7-5dd7f27c70b4830ee4ccf3bf',
    appointmentTimifyType: false,
    city: 'Bressanone',
    country: 'Italien',
    state: 'Trentino-Alto Adige',
    firmierung: 'Fielmann Srl',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat Hygienestandards für die Augenoptik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 800 792 992 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat Hygienestandards für die Augenoptik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 800 792 992 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2241,
    pid: 91,
    number: '001-5203',
    title: 'Merano',
    description: '',
    informations: '',
    street: 'Via Cassa di Risparmio, 9',
    zip: '39012',
    citypart: '',
    addition: '',
    fon: '0473-090700',
    fax: '0473-090701',
    commonName: '',
    xCoordinates: '46.6709876',
    yCoordinates: '11.1617794',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '106e546e-d252-461e-9c3a-b7ea6efe54b7-5d7915cc2d27f90f734b4d1c',
    appointmentTimifyType: false,
    city: 'Merano',
    country: 'Italien',
    state: 'Trentino-Alto Adige',
    firmierung: 'Fielmann Srl',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente, <br /><br />Fielmann ha definito degli standard di igiene per l'ottica, per proteggere te e i nostri collaboratori dal Coronavirus. Queste misure hanno fondamento scientifico. Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori.<br /><br />Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store. Il controllo e l'applicazione delle lenti a contatto sono temporaneamente sospesi.<br /><br />Se possibile, porta con te la tua mascherina durante la tua visita in store. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro Servizio Clienti è disponibile al numero 800 792 992.<br /><br />Ti ringraziamo per la comprensione. Ci si rivede, finalmente!",
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente, <br /><br />Fielmann ha definito degli standard di igiene per l'ottica, per proteggere te e i nostri collaboratori dal Coronavirus. Queste misure hanno fondamento scientifico. Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori.<br /><br />Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store. Il controllo e l'applicazione delle lenti a contatto sono temporaneamente sospesi.<br /><br />Se possibile, porta con te la tua mascherina durante la tua visita in store. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro Servizio Clienti è disponibile al numero 800 792 992.<br /><br />Ti ringraziamo per la comprensione. Ci si rivede, finalmente!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2245,
    pid: 91,
    number: '001-5203',
    title: 'Merano',
    description: '',
    informations: '',
    street: 'Via Cassa di Risparmio, 9',
    zip: '39012',
    citypart: '',
    addition: '',
    fon: '0473-090700',
    fax: '0473-090701',
    commonName: '',
    xCoordinates: '46.6709876',
    yCoordinates: '11.1617794',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '106e546e-d252-461e-9c3a-b7ea6efe54b7-5d7915cc2d27f90f734b4d1c',
    appointmentTimifyType: false,
    city: 'Merano',
    country: 'Italien',
    state: 'Trentino-Alto Adige',
    firmierung: 'Fielmann Srl',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat Hygienestandards für die Augenoptik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 800 792 992 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat Hygienestandards für die Augenoptik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 800 792 992 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2249,
    pid: 91,
    number: '001-5205',
    title: 'Vicenza',
    description: '',
    informations: '',
    street: 'Corso Andrea Palladio, 78',
    zip: '36100',
    citypart: '',
    addition: '',
    fon: '0444-042222',
    fax: '0444-042224',
    commonName: '',
    xCoordinates: '45.5473431',
    yCoordinates: '11.5445476',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '106e546e-d252-461e-9c3a-b7ea6efe54b7-5dd7f27ced69b00f98efd17b',
    appointmentTimifyType: false,
    city: 'Vicenza',
    country: 'Italien',
    state: 'Veneto',
    firmierung: 'Fielmann Srl',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente, <br /><br />Fielmann ha definito degli standard di igiene per l'ottica, per proteggere te e i nostri collaboratori dal Coronavirus. Queste misure hanno fondamento scientifico. Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori.<br /><br />Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store. Il controllo e l'applicazione delle lenti a contatto sono temporaneamente sospesi.<br /><br />Se possibile, porta con te la tua mascherina durante la tua visita in store. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro Servizio Clienti è disponibile al numero 800 792 992.<br /><br />Ti ringraziamo per la comprensione. Ci si rivede, finalmente!",
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente, <br /><br />Fielmann ha definito degli standard di igiene per l'ottica, per proteggere te e i nostri collaboratori dal Coronavirus. Queste misure hanno fondamento scientifico. Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori.<br /><br />Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store. Il controllo e l'applicazione delle lenti a contatto sono temporaneamente sospesi.<br /><br />Se possibile, porta con te la tua mascherina durante la tua visita in store. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro Servizio Clienti è disponibile al numero 800 792 992.<br /><br />Ti ringraziamo per la comprensione. Ci si rivede, finalmente!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2254,
    pid: 91,
    number: '001-5205',
    title: 'Vicenza',
    description: '',
    informations: '',
    street: 'Corso Andrea Palladio, 78',
    zip: '36100',
    citypart: '',
    addition: '',
    fon: '0444-042222',
    fax: '0444-042224',
    commonName: '',
    xCoordinates: '45.5473431',
    yCoordinates: '11.5445476',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '106e546e-d252-461e-9c3a-b7ea6efe54b7-5dd7f27ced69b00f98efd17b',
    appointmentTimifyType: false,
    city: 'Vicenza',
    country: 'Italien',
    state: 'Veneto',
    firmierung: 'Fielmann Srl',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat Hygienestandards für die Augenoptik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 800 792 992 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat Hygienestandards für die Augenoptik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 800 792 992 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2263,
    pid: 91,
    number: '001-0971',
    title: 'Luxemburg',
    description: '',
    informations: '',
    street: '9-11 Grand-Rue',
    zip: '1661',
    citypart: '',
    addition: '',
    fon: '26267100',
    fax: '262671029',
    commonName: '',
    xCoordinates: '49.6117863',
    yCoordinates: '6.1317955',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Luxemburg',
    country: 'Luxemburg',
    state: 'LU-L',
    firmierung: 'Fielmann GmbH',
    importantMessageHeader: '',
    importantMessageText: '',
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: []
  },
  {
    uid: 2267,
    pid: 91,
    number: '001-0970',
    title: 'Esch sur Alzette',
    description: '',
    informations: '',
    street: '13, rue de l Alzette',
    zip: '4011',
    citypart: '',
    addition: '',
    fon: '2653700',
    fax: '26537029',
    commonName: '',
    xCoordinates: '49.4953764',
    yCoordinates: '5.984631',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Esch sur Alzette',
    country: 'Luxemburg',
    state: 'LU-L',
    firmierung: 'Fielmann GmbH',
    importantMessageHeader: '',
    importantMessageText: '',
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: []
  },
  {
    uid: 2271,
    pid: 91,
    number: '001-5206',
    title: 'Verona II, ADIGEO',
    description: '',
    informations: '',
    street: 'Viale delle Nazioni, 1',
    zip: '37121',
    citypart: '',
    addition: '',
    fon: '045-4642666',
    fax: '045-4642667',
    commonName: 'Adigeo',
    xCoordinates: '45.4064128',
    yCoordinates: '10.9766337',
    opening: false,
    opening_box: false,
    openingText:
      'La nuova filiale a Verona, centro commerciale Adigeo. Vieni a trovarci il 30 marzo dalle ore 9.00 per la grande festa di inaugurazione. ',
    openingLink: '2749',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '106e546e-d252-461e-9c3a-b7ea6efe54b7-5dd7f27c8fda780f740bd27c',
    appointmentTimifyType: false,
    city: 'Verona',
    country: 'Italien',
    state: 'Veneto',
    firmierung: 'Fielmann Srl',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente, <br /><br />Fielmann ha definito degli standard di igiene per l'ottica, per proteggere te e i nostri collaboratori dal Coronavirus. Queste misure hanno fondamento scientifico. Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori.<br /><br />Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store. Il controllo e l'applicazione delle lenti a contatto sono temporaneamente sospesi.<br /><br />Se possibile, porta con te la tua mascherina durante la tua visita in store. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro Servizio Clienti è disponibile al numero 800 792 992.<br /><br />Ti ringraziamo per la comprensione. Ci si rivede, finalmente!",
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente, <br /><br />Fielmann ha definito degli standard di igiene per l'ottica, per proteggere te e i nostri collaboratori dal Coronavirus. Queste misure hanno fondamento scientifico. Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori.<br /><br />Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store. Il controllo e l'applicazione delle lenti a contatto sono temporaneamente sospesi.<br /><br />Se possibile, porta con te la tua mascherina durante la tua visita in store. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro Servizio Clienti è disponibile al numero 800 792 992.<br /><br />Ti ringraziamo per la comprensione. Ci si rivede, finalmente!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2275,
    pid: 91,
    number: '001-5206',
    title: 'Verona II, ADIGEO',
    description: '',
    informations: '',
    street: 'Viale delle Nazioni, 1',
    zip: '37121',
    citypart: '',
    addition: '',
    fon: '045-4642666',
    fax: '045-4642667',
    commonName: 'Adigeo',
    xCoordinates: '45.4064128',
    yCoordinates: '10.9766337',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '106e546e-d252-461e-9c3a-b7ea6efe54b7-5dd7f27c8fda780f740bd27c',
    appointmentTimifyType: false,
    city: 'Verona',
    country: 'Italien',
    state: 'Veneto',
    firmierung: 'Fielmann Srl',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat Hygienestandards für die Augenoptik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 800 792 992 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat Hygienestandards für die Augenoptik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 800 792 992 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2278,
    pid: 91,
    number: '001-5202',
    title: 'Trento',
    description: '',
    informations: '',
    street: 'Via Oss Mazzurana, 24',
    zip: '38122',
    citypart: '',
    addition: '',
    fon: '0461-1826833',
    fax: '0461-1826834',
    commonName: '',
    xCoordinates: '46.0691657',
    yCoordinates: '11.1223466',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '106e546e-d252-461e-9c3a-b7ea6efe54b7-5dd7f27c8fda780f740bd27a',
    appointmentTimifyType: false,
    city: 'Trento',
    country: 'Italien',
    state: 'Trentino-Alto Adige',
    firmierung: 'Fielmann Srl',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente, <br /><br />Fielmann ha definito degli standard di igiene per l'ottica, per proteggere te e i nostri collaboratori dal Coronavirus. Queste misure hanno fondamento scientifico. Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori.<br /><br />Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store. Il controllo e l'applicazione delle lenti a contatto sono temporaneamente sospesi.<br /><br />Se possibile, porta con te la tua mascherina durante la tua visita in store. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro Servizio Clienti è disponibile al numero 800 792 992.<br /><br />Ti ringraziamo per la comprensione. Ci si rivede, finalmente!",
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente, <br /><br />Fielmann ha definito degli standard di igiene per l'ottica, per proteggere te e i nostri collaboratori dal Coronavirus. Queste misure hanno fondamento scientifico. Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori.<br /><br />Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store. Il controllo e l'applicazione delle lenti a contatto sono temporaneamente sospesi.<br /><br />Se possibile, porta con te la tua mascherina durante la tua visita in store. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro Servizio Clienti è disponibile al numero 800 792 992.<br /><br />Ti ringraziamo per la comprensione. Ci si rivede, finalmente!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2282,
    pid: 91,
    number: '001-5202',
    title: 'Trento',
    description: '',
    informations: '',
    street: 'Via Oss Mazzurana, 24',
    zip: '38122',
    citypart: '',
    addition: '',
    fon: '0461-1826833',
    fax: '0461-1826834',
    commonName: '',
    xCoordinates: '46.0691657',
    yCoordinates: '11.1223466',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '106e546e-d252-461e-9c3a-b7ea6efe54b7-5dd7f27c8fda780f740bd27a',
    appointmentTimifyType: false,
    city: 'Trento',
    country: 'Italien',
    state: 'Trentino-Alto Adige',
    firmierung: 'Fielmann Srl',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat Hygienestandards für die Augenoptik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 800 792 992 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat Hygienestandards für die Augenoptik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 800 792 992 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2283,
    pid: 91,
    number: '001-2107',
    title: 'Bramsche',
    description: '',
    informations: '',
    street: 'Große Straße 31',
    zip: '49565',
    citypart: '',
    addition: '',
    fon: '05461-937130',
    fax: '05461-9371329',
    commonName: '',
    xCoordinates: '52.4103694',
    yCoordinates: '7.9792277',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Bramsche',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2289,
    pid: 91,
    number: '001-5208',
    title: 'Varese',
    description: '',
    informations: '',
    street: 'Corso Matteotti, 22',
    zip: '21100',
    citypart: '',
    addition: '',
    fon: '0332-1765233',
    fax: '0332-1765234',
    commonName: '',
    xCoordinates: '45.8184338',
    yCoordinates: '8.8263804',
    opening: false,
    opening_box: false,
    openingText:
      'La nuova filiale a Varese, Corso Matteotti 22/24. Vieni a trovarci il 25 maggio dalle ore 9.30 per la grande inaugurazione.',
    openingLink: '2815',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '106e546e-d252-461e-9c3a-b7ea6efe54b7-5dd7f27ced69b00f98efd17c',
    appointmentTimifyType: false,
    city: 'Varese',
    country: 'Italien',
    state: 'Lombardia',
    firmierung: 'Fielmann Srl',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente, <br /><br />Fielmann ha definito degli standard di igiene per l'ottica, per proteggere te e i nostri collaboratori dal Coronavirus. Queste misure hanno fondamento scientifico. Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori.<br /><br />Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store. Il controllo e l'applicazione delle lenti a contatto sono temporaneamente sospesi.<br /><br />Se possibile, porta con te la tua mascherina durante la tua visita in store. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro Servizio Clienti è disponibile al numero 800 792 992.<br /><br />Ti ringraziamo per la comprensione. Ci si rivede, finalmente!",
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente, <br /><br />Fielmann ha definito degli standard di igiene per l'ottica, per proteggere te e i nostri collaboratori dal Coronavirus. Queste misure hanno fondamento scientifico. Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori.<br /><br />Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store. Il controllo e l'applicazione delle lenti a contatto sono temporaneamente sospesi.<br /><br />Se possibile, porta con te la tua mascherina durante la tua visita in store. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro Servizio Clienti è disponibile al numero 800 792 992.<br /><br />Ti ringraziamo per la comprensione. Ci si rivede, finalmente!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2293,
    pid: 91,
    number: '001-5208',
    title: 'Varese',
    description: '',
    informations: '',
    street: 'Corso Matteotti, 22',
    zip: '21100',
    citypart: '',
    addition: '',
    fon: '0332-1765233',
    fax: '0332-1765234',
    commonName: '',
    xCoordinates: '45.8184338',
    yCoordinates: '8.8263804',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '106e546e-d252-461e-9c3a-b7ea6efe54b7-5dd7f27ced69b00f98efd17c',
    appointmentTimifyType: false,
    city: 'Varese',
    country: 'Italien',
    state: 'Lombardia',
    firmierung: 'Fielmann Srl',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat Hygienestandards für die Augenoptik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 800 792 992 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat Hygienestandards für die Augenoptik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 800 792 992 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2295,
    pid: 91,
    number: '001-5207',
    title: 'Piacenza',
    description: '',
    informations: '',
    street: 'Via XX Settembre, 114',
    zip: '29121',
    citypart: '',
    addition: '',
    fon: '0523-1749677',
    fax: '0523-1749678',
    commonName: '',
    xCoordinates: '45.0511869',
    yCoordinates: '9.6961502',
    opening: false,
    opening_box: false,
    openingText:
      'La nuova filiali a Piacenza, Via XX Settembre 11. Vieni a trovarci il 29 giugno dalle ore 9.30 per la grande inaugurazione.',
    openingLink: '2843',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '106e546e-d252-461e-9c3a-b7ea6efe54b7-5dd7f27c70b4830ee4ccf3bc',
    appointmentTimifyType: false,
    city: 'Piacenza',
    country: 'Italien',
    state: 'Emilia-Romagna',
    firmierung: 'Fielmann Srl',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente, <br /><br />Fielmann ha definito degli standard di igiene per l'ottica, per proteggere te e i nostri collaboratori dal Coronavirus. Queste misure hanno fondamento scientifico. Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori.<br /><br />Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store. Il controllo e l'applicazione delle lenti a contatto sono temporaneamente sospesi.<br /><br />Se possibile, porta con te la tua mascherina durante la tua visita in store. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro Servizio Clienti è disponibile al numero 800 792 992.<br /><br />Ti ringraziamo per la comprensione. Ci si rivede, finalmente!",
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente, <br /><br />Fielmann ha definito degli standard di igiene per l'ottica, per proteggere te e i nostri collaboratori dal Coronavirus. Queste misure hanno fondamento scientifico. Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori.<br /><br />Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store. Il controllo e l'applicazione delle lenti a contatto sono temporaneamente sospesi.<br /><br />Se possibile, porta con te la tua mascherina durante la tua visita in store. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro Servizio Clienti è disponibile al numero 800 792 992.<br /><br />Ti ringraziamo per la comprensione. Ci si rivede, finalmente!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2299,
    pid: 91,
    number: '001-5207',
    title: 'Piacenza',
    description: '',
    informations: '',
    street: 'Via XX Settembre, 114',
    zip: '29121',
    citypart: '',
    addition: '',
    fon: '0523-1749677',
    fax: '0523-1749678',
    commonName: '',
    xCoordinates: '45.0511869',
    yCoordinates: '9.6961502',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '106e546e-d252-461e-9c3a-b7ea6efe54b7-5dd7f27c70b4830ee4ccf3bc',
    appointmentTimifyType: false,
    city: 'Piacenza',
    country: 'Italien',
    state: 'Emilia-Romagna',
    firmierung: 'Fielmann Srl',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat Hygienestandards für die Augenoptik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 800 792 992 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat Hygienestandards für die Augenoptik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 800 792 992 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2303,
    pid: 91,
    number: '001-2109',
    title: 'Bad Neustadt an der Saale',
    description: '',
    informations: '',
    street: 'Hohnstraße 14',
    zip: '97616',
    citypart: '',
    addition: '',
    fon: '09771-630160',
    fax: '09771-6301629',
    commonName: '',
    xCoordinates: '50.3217404',
    yCoordinates: '10.2153621',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Bad Neustadt an der Saale',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2307,
    pid: 91,
    number: '001-5603',
    title: 'Bellinzona',
    description: '',
    informations: '',
    street: 'Via Nosetto 3',
    zip: '6500',
    citypart: '',
    addition: '',
    fon: '091 820 08 30',
    fax: '091 820 08 31',
    commonName: '',
    xCoordinates: '46.1911763',
    yCoordinates: '9.0228415',
    opening: false,
    opening_box: false,
    openingText:
      'Die neue Fielmann-Niederlassung in Bellinzona, Via Nosetto 3.\r\nKommen Sie am 6. Juli zum großen Eröffnungsfest.',
    openingLink: '2859',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '8df10cf1-ef64-41d5-89df-5bec88f4bb35-5ea1694ce15925100d495fd7',
    appointmentTimifyType: true,
    city: 'Bellinzona',
    country: 'Schweiz',
    state: 'Tessin',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2311,
    pid: 91,
    number: '001-5603',
    title: 'Bellinzona',
    description: '',
    informations: '',
    street: 'Via Nosetto 3',
    zip: '6500',
    citypart: '',
    addition: '',
    fon: '091 820 08 30',
    fax: '091 820 08 31',
    commonName: '',
    xCoordinates: '46.1911763',
    yCoordinates: '9.0228415',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '8df10cf1-ef64-41d5-89df-5bec88f4bb35-5ea1694ce15925100d495fd7',
    appointmentTimifyType: true,
    city: 'Bellinzona',
    country: 'Schweiz',
    state: 'Tessin',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Nos normes d’hygiène',
    importantMessageText:
      'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Nos normes d’hygiène',
      text:
        'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2315,
    pid: 91,
    number: '001-5603',
    title: 'Bellinzona',
    description: '',
    informations: '',
    street: 'Via Nosetto 3',
    zip: '6500',
    citypart: '',
    addition: '',
    fon: '091 820 08 30',
    fax: '091 820 08 31',
    commonName: '',
    xCoordinates: '46.1911763',
    yCoordinates: '9.0228415',
    opening: false,
    opening_box: false,
    openingText:
      'Nuova filiale Fielmann a Bellinzona, Via Nosetto 3. Venite a trovarci il 6 luglio per la grande festa di inaugurazione.',
    openingLink: '2859',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '8df10cf1-ef64-41d5-89df-5bec88f4bb35-5ea1694ce15925100d495fd7',
    appointmentTimifyType: true,
    city: 'Bellinzona',
    country: 'Schweiz',
    state: 'Tessin',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2319,
    pid: 91,
    number: '001-2111',
    title: 'Bad Dürkheim',
    description: '',
    informations: '',
    street: 'Stadtplatz 6',
    zip: '67098',
    citypart: '',
    addition: '',
    fon: '06322-94916-0',
    fax: '06322-94916-29',
    commonName: '',
    xCoordinates: '49.46027',
    yCoordinates: '8.16708',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Bad Dürkheim',
    country: 'Deutschland',
    state: 'Rheinland-Pfalz',
    firmierung: 'Fielmann AG & CO. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2323,
    pid: 91,
    number: '001-2110',
    title: 'Kassel II, Frankfurter Straße',
    description: '',
    informations: '',
    street: 'Frankfurter Straße 225',
    zip: '34134',
    citypart: '',
    addition: '',
    fon: '0561-820924-0',
    fax: '0561-820924-29',
    commonName: 'DEZ',
    xCoordinates: '51.2878178',
    yCoordinates: '9.4749444',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Kassel',
    country: 'Deutschland',
    state: 'Hessen',
    firmierung: 'Fielmann AG & Co. im DEZ OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2324,
    pid: 91,
    number: '001-5602',
    title: 'Uster',
    description: '',
    informations: '',
    street: 'Zürichstrasse 20',
    zip: '8610',
    citypart: '',
    addition: '',
    fon: '044 525 25 90',
    fax: '044 525 25 91',
    commonName: 'Illuster',
    xCoordinates: '47.3499086',
    yCoordinates: '8.714587',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16ccc63ab66101aa90143',
    appointmentTimifyType: true,
    city: 'Uster',
    country: 'Schweiz',
    state: 'Zürich',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2328,
    pid: 91,
    number: '001-5602',
    title: 'Uster',
    description: '',
    informations: '',
    street: 'Zürichstrasse 20',
    zip: '8610',
    citypart: '',
    addition: '',
    fon: '044 525 25 90',
    fax: '044 525 25 91',
    commonName: 'Illuster',
    xCoordinates: '47.3499086',
    yCoordinates: '8.714587',
    opening: false,
    opening_box: true,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16ccc63ab66101aa90143',
    appointmentTimifyType: true,
    city: 'Uster',
    country: 'Schweiz',
    state: 'Zürich',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Nos normes d’hygiène',
    importantMessageText:
      'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Nos normes d’hygiène',
      text:
        'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2332,
    pid: 91,
    number: '001-5602',
    title: 'Uster',
    description: '',
    informations: '',
    street: 'Zürichstrasse 20',
    zip: '8610',
    citypart: '',
    addition: '',
    fon: '044 525 25 90',
    fax: '044 525 25 91',
    commonName: 'Illuster',
    xCoordinates: '47.3499086',
    yCoordinates: '8.714587',
    opening: false,
    opening_box: true,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      'c775de64-7966-405a-b591-004c755e16d6-5ea16ccc63ab66101aa90143',
    appointmentTimifyType: true,
    city: 'Uster',
    country: 'Schweiz',
    state: 'Zürich',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2336,
    pid: 91,
    number: '001-2112',
    title: 'Achern',
    description: '',
    informations: '',
    street: 'Hauptstraße 16',
    zip: '77855',
    citypart: '',
    addition: '',
    fon: '07841-606970',
    fax: '07841-6069729',
    commonName: '',
    xCoordinates: '48.6293485',
    yCoordinates: '8.0701759',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Achern',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2340,
    pid: 91,
    number: '001-5209',
    title: 'Brescia',
    description: '',
    informations: '',
    street: 'Corso Giuseppe Zanardelli, 8',
    zip: '25121',
    citypart: '',
    addition: '',
    fon: '030-7825920',
    fax: '030-7825922',
    commonName: '',
    xCoordinates: '45.5368737',
    yCoordinates: '10.2199433',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '106e546e-d252-461e-9c3a-b7ea6efe54b7-5dd7f27c1aa47650e8f39886',
    appointmentTimifyType: false,
    city: 'Brescia',
    country: 'Italien',
    state: 'Lombardia',
    firmierung: 'Fielmann Srl',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente, <br /><br />Fielmann ha definito degli standard di igiene per l'ottica, per proteggere te e i nostri collaboratori dal Coronavirus. Queste misure hanno fondamento scientifico. Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori.<br /><br />Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store. Il controllo e l'applicazione delle lenti a contatto sono temporaneamente sospesi.<br /><br />Se possibile, porta con te la tua mascherina durante la tua visita in store. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro Servizio Clienti è disponibile al numero 800 792 992.<br /><br />Ti ringraziamo per la comprensione. Ci si rivede, finalmente!",
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente, <br /><br />Fielmann ha definito degli standard di igiene per l'ottica, per proteggere te e i nostri collaboratori dal Coronavirus. Queste misure hanno fondamento scientifico. Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori.<br /><br />Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store. Il controllo e l'applicazione delle lenti a contatto sono temporaneamente sospesi.<br /><br />Se possibile, porta con te la tua mascherina durante la tua visita in store. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro Servizio Clienti è disponibile al numero 800 792 992.<br /><br />Ti ringraziamo per la comprensione. Ci si rivede, finalmente!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2344,
    pid: 91,
    number: '001-5209',
    title: 'Brescia',
    description: '',
    informations: '',
    street: 'Corso Giuseppe Zanardelli, 8',
    zip: '25121',
    citypart: '',
    addition: '',
    fon: '030-7825920',
    fax: '030-7825922',
    commonName: '',
    xCoordinates: '45.5368737',
    yCoordinates: '10.2199433',
    opening: false,
    opening_box: true,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '106e546e-d252-461e-9c3a-b7ea6efe54b7-5dd7f27c1aa47650e8f39886',
    appointmentTimifyType: false,
    city: 'Brescia',
    country: 'Italien',
    state: 'Lombardia',
    firmierung: 'Fielmann Srl',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat Hygienestandards für die Augenoptik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 800 792 992 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat Hygienestandards für die Augenoptik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 800 792 992 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2348,
    pid: 91,
    number: '001-2108',
    title: 'Melle',
    description: '',
    informations: '',
    street: 'Markt 12',
    zip: '49324',
    citypart: '',
    addition: '',
    fon: '05422-92106-0',
    fax: '05422-92106-29',
    commonName: '',
    xCoordinates: '52.2032791',
    yCoordinates: '8.3364514',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Melle',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2352,
    pid: 91,
    number: '001-5210',
    title: 'Bergamo',
    description: '',
    informations: '',
    street: 'Via XX Settembre, 89',
    zip: '24122',
    citypart: '',
    addition: '',
    fon: '035-0787030',
    fax: '035-0787039',
    commonName: '',
    xCoordinates: '45.6928477',
    yCoordinates: '9.6659908',
    opening: false,
    opening_box: false,
    openingText:
      ' Vieni a trovarci il 14 dicembre dalle ore 9.30 per la grande inaugurazione. ',
    openingLink: '3004',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '106e546e-d252-461e-9c3a-b7ea6efe54b7-5dd7f27cce2b710f8b134a9a',
    appointmentTimifyType: false,
    city: 'Bergamo',
    country: 'Italien',
    state: 'Lombardia',
    firmierung: 'Fielmann Srl',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente, <br /><br />Fielmann ha definito degli standard di igiene per l'ottica, per proteggere te e i nostri collaboratori dal Coronavirus. Queste misure hanno fondamento scientifico. Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori.<br /><br />Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store. Il controllo e l'applicazione delle lenti a contatto sono temporaneamente sospesi.<br /><br />Se possibile, porta con te la tua mascherina durante la tua visita in store. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro Servizio Clienti è disponibile al numero 800 792 992.<br /><br />Ti ringraziamo per la comprensione. Ci si rivede, finalmente!",
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente, <br /><br />Fielmann ha definito degli standard di igiene per l'ottica, per proteggere te e i nostri collaboratori dal Coronavirus. Queste misure hanno fondamento scientifico. Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori.<br /><br />Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store. Il controllo e l'applicazione delle lenti a contatto sono temporaneamente sospesi.<br /><br />Se possibile, porta con te la tua mascherina durante la tua visita in store. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro Servizio Clienti è disponibile al numero 800 792 992.<br /><br />Ti ringraziamo per la comprensione. Ci si rivede, finalmente!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2356,
    pid: 91,
    number: '001-5210',
    title: 'Bergamo',
    description: '',
    informations: '',
    street: 'Via XX Settembre, 89',
    zip: '24122',
    citypart: '',
    addition: '',
    fon: '035-0787030',
    fax: '035-0787039',
    commonName: '',
    xCoordinates: '45.6928477',
    yCoordinates: '9.6659908',
    opening: false,
    opening_box: true,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '106e546e-d252-461e-9c3a-b7ea6efe54b7-5dd7f27cce2b710f8b134a9a',
    appointmentTimifyType: false,
    city: 'Bergamo',
    country: 'Italien',
    state: 'Lombardia',
    firmierung: 'Fielmann Srl',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat Hygienestandards für die Augenoptik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 800 792 992 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat Hygienestandards für die Augenoptik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 800 792 992 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2357,
    pid: 91,
    number: '001-5204',
    title: 'Verona I, Via Mazzini',
    description: '',
    informations: '',
    street: 'Via Mazzini, 64',
    zip: '37121',
    citypart: '',
    addition: '',
    fon: '045-4642131',
    fax: '045-4642132',
    commonName: '',
    xCoordinates: '45.4403551',
    yCoordinates: '10.9943902',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '106e546e-d252-461e-9c3a-b7ea6efe54b7-5dd7f27c1aa47650e8f39861',
    appointmentTimifyType: false,
    city: 'Verona',
    country: 'Italien',
    state: 'Veneto',
    firmierung: 'Fielmann Srl',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente, <br /><br />Fielmann ha definito degli standard di igiene per l'ottica, per proteggere te e i nostri collaboratori dal Coronavirus. Queste misure hanno fondamento scientifico. Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori.<br /><br />Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store. Il controllo e l'applicazione delle lenti a contatto sono temporaneamente sospesi.<br /><br />Se possibile, porta con te la tua mascherina durante la tua visita in store. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro Servizio Clienti è disponibile al numero 800 792 992.<br /><br />Ti ringraziamo per la comprensione. Ci si rivede, finalmente!",
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente, <br /><br />Fielmann ha definito degli standard di igiene per l'ottica, per proteggere te e i nostri collaboratori dal Coronavirus. Queste misure hanno fondamento scientifico. Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori.<br /><br />Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store. Il controllo e l'applicazione delle lenti a contatto sono temporaneamente sospesi.<br /><br />Se possibile, porta con te la tua mascherina durante la tua visita in store. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro Servizio Clienti è disponibile al numero 800 792 992.<br /><br />Ti ringraziamo per la comprensione. Ci si rivede, finalmente!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2361,
    pid: 91,
    number: '001-5204',
    title: 'Verona I, Via Mazzini',
    description: '',
    informations: '',
    street: 'Via Mazzini, 64',
    zip: '37121',
    citypart: '',
    addition: '',
    fon: '045-4642131',
    fax: '045-4642132',
    commonName: '',
    xCoordinates: '45.4403551',
    yCoordinates: '10.9943902',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '106e546e-d252-461e-9c3a-b7ea6efe54b7-5dd7f27c1aa47650e8f39861',
    appointmentTimifyType: false,
    city: 'Verona',
    country: 'Italien',
    state: 'Veneto',
    firmierung: 'Fielmann Srl',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat Hygienestandards für die Augenoptik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 800 792 992 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat Hygienestandards für die Augenoptik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 800 792 992 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2366,
    pid: 91,
    number: '001-5211',
    title: 'Como',
    description: '',
    informations: '',
    street: 'Via Vittorio Emanuele II, 70',
    zip: '22100',
    citypart: '',
    addition: '',
    fon: '031-4060440',
    fax: '031-4060441',
    commonName: '',
    xCoordinates: '45.8103654',
    yCoordinates: '9.084313',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '106e546e-d252-461e-9c3a-b7ea6efe54b7-5dd7f27c70b4830ee4ccf3e0',
    appointmentTimifyType: false,
    city: 'Como',
    country: 'Italien',
    state: 'Lombardia',
    firmierung: 'Fielmann Srl',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente, <br /><br />Fielmann ha definito degli standard di igiene per l'ottica, per proteggere te e i nostri collaboratori dal Coronavirus. Queste misure hanno fondamento scientifico. Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori.<br /><br />Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store. Il controllo e l'applicazione delle lenti a contatto sono temporaneamente sospesi.<br /><br />Se possibile, porta con te la tua mascherina durante la tua visita in store. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro Servizio Clienti è disponibile al numero 800 792 992.<br /><br />Ti ringraziamo per la comprensione. Ci si rivede, finalmente!",
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente, <br /><br />Fielmann ha definito degli standard di igiene per l'ottica, per proteggere te e i nostri collaboratori dal Coronavirus. Queste misure hanno fondamento scientifico. Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori.<br /><br />Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store. Il controllo e l'applicazione delle lenti a contatto sono temporaneamente sospesi.<br /><br />Se possibile, porta con te la tua mascherina durante la tua visita in store. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro Servizio Clienti è disponibile al numero 800 792 992.<br /><br />Ti ringraziamo per la comprensione. Ci si rivede, finalmente!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2370,
    pid: 91,
    number: '001-5211',
    title: 'Como',
    description: '',
    informations: '',
    street: 'Via Vittorio Emanuele II, 70',
    zip: '22100',
    citypart: '',
    addition: '',
    fon: '031-4060440',
    fax: '031-4060441',
    commonName: '',
    xCoordinates: '45.8103654',
    yCoordinates: '9.084313',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '106e546e-d252-461e-9c3a-b7ea6efe54b7-5dd7f27c70b4830ee4ccf3e0',
    appointmentTimifyType: false,
    city: 'Como',
    country: 'Italien',
    state: 'Lombardia',
    firmierung: 'Fielmann Srl',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat Hygienestandards für die Augenoptik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 800 792 992 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat Hygienestandards für die Augenoptik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 800 792 992 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2373,
    pid: 91,
    number: '001-5604',
    title: 'Bulle',
    description: '',
    informations: '',
    street: 'Grand Rue 23',
    zip: '1630',
    citypart: '',
    addition: '',
    fon: '026 555 02 90',
    fax: '026 555 02 91',
    commonName: '',
    xCoordinates: '46.6188437',
    yCoordinates: '7.0572644',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '30038d2e-f595-417c-b5dc-0c148c461378-5ea16ba5e15925100d496006',
    appointmentTimifyType: true,
    city: 'Bulle',
    country: 'Schweiz',
    state: 'Freiburg',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Massnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 805 905 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2377,
    pid: 91,
    number: '001-5604',
    title: 'Bulle',
    description: '',
    informations: '',
    street: 'Grand Rue 23',
    zip: '1630',
    citypart: '',
    addition: '',
    fon: '026 555 02 90',
    fax: '026 555 02 91',
    commonName: '',
    xCoordinates: '46.6188437',
    yCoordinates: '7.0572644',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '30038d2e-f595-417c-b5dc-0c148c461378-5ea16ba5e15925100d496006',
    appointmentTimifyType: true,
    city: 'Bulle',
    country: 'Schweiz',
    state: 'Freiburg',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'Nos normes d’hygiène',
    importantMessageText:
      'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Nos normes d’hygiène',
      text:
        'Chères clientes, chers clients,<br /><br />En collaboration avec le Prof. Dr. Exner (CHU de Bonn), Fielmann a défini des normes d’hygiène pour l’optique et l’acoustique auditive afin de vous protéger ainsi que de protéger nos collaborateurs d’une infection au coronavirus. Fielmann applique désormais ces mesures reposant sur des bases scientifiques dans toutes ses succursales et crée ainsi un cadre vous permettant de vous sentir en sécurité.<br /><br />Dans le cadre des normes d’hygiène, seul un nombre limité de clients sont autorisés à entrer dans nos succursales à la fois. Dans la mesure du possible, veuillez prendre rendez-vous au préalable en ligne, par téléphone ou directement dans votre succursale et respectez les mesures d’hygiène de Fielmann pendant votre visite.<br /><br />Veuillez, dans la mesure du possible, apporter un masque de protection bouche-nez lorsque vous vous rendez dans l’une de nos succursales. Dans le cas où vous présenteriez les symptômes d’un état grippal, nous vous prions de ne pas vous rendre à la succursale pour l’instant.<br /><br />Vous pouvez contacter notre service à la clientèle au numéro suivant: 0800 805 905.<br /><br />Nous vous remercions pour votre compréhension. Restez en bonne santé.',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2381,
    pid: 91,
    number: '001-5604',
    title: 'Bulle',
    description: '',
    informations: '',
    street: 'Grand Rue 23',
    zip: '1630',
    citypart: '',
    addition: '',
    fon: '026 555 02 90',
    fax: '026 555 02 91',
    commonName: '',
    xCoordinates: '46.6188437',
    yCoordinates: '7.0572644',
    opening: false,
    opening_box: true,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '30038d2e-f595-417c-b5dc-0c148c461378-5ea16ba5e15925100d496006',
    appointmentTimifyType: true,
    city: 'Bulle',
    country: 'Schweiz',
    state: 'Freiburg',
    firmierung: 'Fielmann AG',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente<br /><br />Insieme al Prof. Dr. Exner (Clinica universitaria di Bonn), Fielmann ha stabilito degli standard di igiene per ottici e audioprotesisti, per proteggere te e i nostri collaboratori dall'infezione da Coronavirus. Queste misure hanno fondamento scientifico.<br /><br />Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori. Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store.<br /><br />Per la tua prossima visita in store porta con te la tua mascherina. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro servizio clienti è a tua disposizione al numero 0800 805 905.<br /><br />Ti ringraziamo per la comprensione. Prenditi cura di te!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2385,
    pid: 91,
    number: '001-5213',
    title: 'Cremona',
    description: '',
    informations: '',
    street: 'Corso Campi, 70',
    zip: '26100',
    citypart: '',
    addition: '',
    fon: '0372-1879570',
    fax: '0372-1879571',
    commonName: '',
    xCoordinates: '45.1360781',
    yCoordinates: '10.0211621',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '106e546e-d252-461e-9c3a-b7ea6efe54b7-5dd7f27cce2b710f8b134ab9',
    appointmentTimifyType: false,
    city: 'Cremona',
    country: 'Italien',
    state: 'Lombardia',
    firmierung: 'Fielmann Srl',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente, <br /><br />Fielmann ha definito degli standard di igiene per l'ottica, per proteggere te e i nostri collaboratori dal Coronavirus. Queste misure hanno fondamento scientifico. Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori.<br /><br />Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store. Il controllo e l'applicazione delle lenti a contatto sono temporaneamente sospesi.<br /><br />Se possibile, porta con te la tua mascherina durante la tua visita in store. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro Servizio Clienti è disponibile al numero 800 792 992.<br /><br />Ti ringraziamo per la comprensione. Ci si rivede, finalmente!",
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente, <br /><br />Fielmann ha definito degli standard di igiene per l'ottica, per proteggere te e i nostri collaboratori dal Coronavirus. Queste misure hanno fondamento scientifico. Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori.<br /><br />Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store. Il controllo e l'applicazione delle lenti a contatto sono temporaneamente sospesi.<br /><br />Se possibile, porta con te la tua mascherina durante la tua visita in store. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro Servizio Clienti è disponibile al numero 800 792 992.<br /><br />Ti ringraziamo per la comprensione. Ci si rivede, finalmente!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2389,
    pid: 91,
    number: '001-5213',
    title: 'Cremona',
    description: '',
    informations: '',
    street: 'Corso Campi, 70',
    zip: '26100',
    citypart: '',
    addition: '',
    fon: '0372-1879570',
    fax: '0372-1879571',
    commonName: '',
    xCoordinates: '45.1360781',
    yCoordinates: '10.0211621',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '106e546e-d252-461e-9c3a-b7ea6efe54b7-5dd7f27cce2b710f8b134ab9',
    appointmentTimifyType: false,
    city: 'Cremona',
    country: 'Italien',
    state: 'Lombardia',
    firmierung: 'Fielmann Srl',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat Hygienestandards für die Augenoptik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 800 792 992 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat Hygienestandards für die Augenoptik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 800 792 992 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2392,
    pid: 91,
    number: '001-5214',
    title: 'Alessandria',
    description: '',
    informations: '',
    street: 'Corso Roma, 30',
    zip: '15121',
    citypart: '',
    addition: '',
    fon: '0131-1678120',
    fax: '0131-1678121',
    commonName: '',
    xCoordinates: '44.9125833',
    yCoordinates: '8.6133636',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '106e546e-d252-461e-9c3a-b7ea6efe54b7-5dd7f27c70b4830ee4ccf3dd',
    appointmentTimifyType: false,
    city: 'Alessandria',
    country: 'Italien',
    state: 'Piemonte',
    firmierung: 'Fielmann Srl',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente, <br /><br />Fielmann ha definito degli standard di igiene per l'ottica, per proteggere te e i nostri collaboratori dal Coronavirus. Queste misure hanno fondamento scientifico. Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori.<br /><br />Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store. Il controllo e l'applicazione delle lenti a contatto sono temporaneamente sospesi.<br /><br />Se possibile, porta con te la tua mascherina durante la tua visita in store. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro Servizio Clienti è disponibile al numero 800 792 992.<br /><br />Ti ringraziamo per la comprensione. Ci si rivede, finalmente!",
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente, <br /><br />Fielmann ha definito degli standard di igiene per l'ottica, per proteggere te e i nostri collaboratori dal Coronavirus. Queste misure hanno fondamento scientifico. Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori.<br /><br />Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store. Il controllo e l'applicazione delle lenti a contatto sono temporaneamente sospesi.<br /><br />Se possibile, porta con te la tua mascherina durante la tua visita in store. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro Servizio Clienti è disponibile al numero 800 792 992.<br /><br />Ti ringraziamo per la comprensione. Ci si rivede, finalmente!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2396,
    pid: 91,
    number: '001-5214',
    title: 'Alessandria',
    description: '',
    informations: '',
    street: 'Corso Roma, 30',
    zip: '15121',
    citypart: '',
    addition: '',
    fon: '0131-1678120',
    fax: '0131-1678121',
    commonName: '',
    xCoordinates: '44.9125833',
    yCoordinates: '8.6133636',
    opening: false,
    opening_box: true,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '106e546e-d252-461e-9c3a-b7ea6efe54b7-5dd7f27c70b4830ee4ccf3dd',
    appointmentTimifyType: false,
    city: 'Alessandria',
    country: 'Italien',
    state: 'Piemonte',
    firmierung: 'Fielmann Srl',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat Hygienestandards für die Augenoptik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 800 792 992 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat Hygienestandards für die Augenoptik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 800 792 992 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2400,
    pid: 91,
    number: '001-5216',
    title: 'Ravenna',
    description: '',
    informations: '',
    street: 'Via Cavour, 83',
    zip: '48121',
    citypart: '',
    addition: '',
    fon: '0544-1675420',
    fax: '0544-1675421',
    commonName: '',
    xCoordinates: '44.4193751',
    yCoordinates: '12.1958959',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '106e546e-d252-461e-9c3a-b7ea6efe54b7-5dd7f27c8fda780f740bd29a',
    appointmentTimifyType: false,
    city: 'Ravenna',
    country: 'Italien',
    state: 'Emilia-Romagna',
    firmierung: 'Fielmann Srl',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente, <br /><br />Fielmann ha definito degli standard di igiene per l'ottica, per proteggere te e i nostri collaboratori dal Coronavirus. Queste misure hanno fondamento scientifico. Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori.<br /><br />Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store. Il controllo e l'applicazione delle lenti a contatto sono temporaneamente sospesi.<br /><br />Se possibile, porta con te la tua mascherina durante la tua visita in store. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro Servizio Clienti è disponibile al numero 800 792 992.<br /><br />Ti ringraziamo per la comprensione. Ci si rivede, finalmente!",
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente, <br /><br />Fielmann ha definito degli standard di igiene per l'ottica, per proteggere te e i nostri collaboratori dal Coronavirus. Queste misure hanno fondamento scientifico. Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori.<br /><br />Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store. Il controllo e l'applicazione delle lenti a contatto sono temporaneamente sospesi.<br /><br />Se possibile, porta con te la tua mascherina durante la tua visita in store. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro Servizio Clienti è disponibile al numero 800 792 992.<br /><br />Ti ringraziamo per la comprensione. Ci si rivede, finalmente!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2404,
    pid: 91,
    number: '001-5216',
    title: 'Ravenna',
    description: '',
    informations: '',
    street: 'Via Cavour, 83',
    zip: '48121',
    citypart: '',
    addition: '',
    fon: '0544-1675420',
    fax: '0544-1675421',
    commonName: '',
    xCoordinates: '44.4193751',
    yCoordinates: '12.1958959',
    opening: false,
    opening_box: true,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '106e546e-d252-461e-9c3a-b7ea6efe54b7-5dd7f27c8fda780f740bd29a',
    appointmentTimifyType: false,
    city: 'Ravenna',
    country: 'Italien',
    state: 'Emilia-Romagna',
    firmierung: 'Fielmann Srl',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat Hygienestandards für die Augenoptik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 800 792 992 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat Hygienestandards für die Augenoptik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 800 792 992 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2408,
    pid: 91,
    number: '001-2113',
    title: 'Landsberg am Lech',
    description: '',
    informations: '',
    street: 'Hubert-von-Herkomer-Straße 19/20',
    zip: '86899',
    citypart: '',
    addition: '',
    fon: '08191-6639310',
    fax: '08191-6639339',
    commonName: '',
    xCoordinates: '48.0487379',
    yCoordinates: '10.8767075',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Landsberg am Lech',
    country: 'Deutschland',
    state: 'Bayern',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2412,
    pid: 91,
    number: '001-5212',
    title: 'Novara',
    description: '',
    informations: '',
    street: 'Corso Giuseppe Mazzini, 1',
    zip: '28100',
    citypart: '',
    addition: '',
    fon: '0321-1647120',
    fax: '0321-1647121',
    commonName: '',
    xCoordinates: '45.446759',
    yCoordinates: '8.6222217',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '106e546e-d252-461e-9c3a-b7ea6efe54b7-5dd7f27c1aa47650e8f3987f',
    appointmentTimifyType: false,
    city: 'Novara',
    country: 'Italien',
    state: 'Piemonte',
    firmierung: 'Fielmann Srl',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente, <br /><br />Fielmann ha definito degli standard di igiene per l'ottica, per proteggere te e i nostri collaboratori dal Coronavirus. Queste misure hanno fondamento scientifico. Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori.<br /><br />Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store. Il controllo e l'applicazione delle lenti a contatto sono temporaneamente sospesi.<br /><br />Se possibile, porta con te la tua mascherina durante la tua visita in store. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro Servizio Clienti è disponibile al numero 800 792 992.<br /><br />Ti ringraziamo per la comprensione. Ci si rivede, finalmente!",
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente, <br /><br />Fielmann ha definito degli standard di igiene per l'ottica, per proteggere te e i nostri collaboratori dal Coronavirus. Queste misure hanno fondamento scientifico. Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori.<br /><br />Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store. Il controllo e l'applicazione delle lenti a contatto sono temporaneamente sospesi.<br /><br />Se possibile, porta con te la tua mascherina durante la tua visita in store. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro Servizio Clienti è disponibile al numero 800 792 992.<br /><br />Ti ringraziamo per la comprensione. Ci si rivede, finalmente!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2416,
    pid: 91,
    number: '001-5212',
    title: 'Novara',
    description: '',
    informations: '',
    street: 'Corso Giuseppe Mazzini, 1',
    zip: '28100',
    citypart: '',
    addition: '',
    fon: '0321-1647120',
    fax: '0321-1647121',
    commonName: '',
    xCoordinates: '45.446759',
    yCoordinates: '8.6222217',
    opening: false,
    opening_box: true,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '106e546e-d252-461e-9c3a-b7ea6efe54b7-5dd7f27c1aa47650e8f3987f',
    appointmentTimifyType: false,
    city: 'Novara',
    country: 'Italien',
    state: 'Piemonte',
    firmierung: 'Fielmann Srl',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat Hygienestandards für die Augenoptik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 800 792 992 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat Hygienestandards für die Augenoptik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 800 792 992 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2417,
    pid: 91,
    number: '001-5217',
    title: 'Pordenone',
    description: '',
    informations: '',
    street: 'Corso Vittorio Emanuele II, 11',
    zip: '33170',
    citypart: '',
    addition: '',
    fon: '0434-1607010',
    fax: '0434-1607011',
    commonName: '',
    xCoordinates: '45.9578449',
    yCoordinates: '12.659099',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '106e546e-d252-461e-9c3a-b7ea6efe54b7-5dd7f27cce2b710f8b134aa2',
    appointmentTimifyType: false,
    city: 'Pordenone',
    country: 'Italien',
    state: 'Friuli-Venezia Giulia',
    firmierung: 'Fielmann Srl',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente, <br /><br />Fielmann ha definito degli standard di igiene per l'ottica, per proteggere te e i nostri collaboratori dal Coronavirus. Queste misure hanno fondamento scientifico. Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori.<br /><br />Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store. Il controllo e l'applicazione delle lenti a contatto sono temporaneamente sospesi.<br /><br />Se possibile, porta con te la tua mascherina durante la tua visita in store. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro Servizio Clienti è disponibile al numero 800 792 992.<br /><br />Ti ringraziamo per la comprensione. Ci si rivede, finalmente!",
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente, <br /><br />Fielmann ha definito degli standard di igiene per l'ottica, per proteggere te e i nostri collaboratori dal Coronavirus. Queste misure hanno fondamento scientifico. Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori.<br /><br />Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store. Il controllo e l'applicazione delle lenti a contatto sono temporaneamente sospesi.<br /><br />Se possibile, porta con te la tua mascherina durante la tua visita in store. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro Servizio Clienti è disponibile al numero 800 792 992.<br /><br />Ti ringraziamo per la comprensione. Ci si rivede, finalmente!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2421,
    pid: 91,
    number: '001-5217',
    title: 'Pordenone',
    description: '',
    informations: '',
    street: 'Corso Vittorio Emanuele II, 11',
    zip: '33170',
    citypart: '',
    addition: '',
    fon: '0434-1607010',
    fax: '0434-1607011',
    commonName: '',
    xCoordinates: '45.9578449',
    yCoordinates: '12.659099',
    opening: false,
    opening_box: true,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '106e546e-d252-461e-9c3a-b7ea6efe54b7-5dd7f27cce2b710f8b134aa2',
    appointmentTimifyType: false,
    city: 'Pordenone',
    country: 'Italien',
    state: 'Friuli-Venezia Giulia',
    firmierung: 'Fielmann Srl',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat Hygienestandards für die Augenoptik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 800 792 992 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat Hygienestandards für die Augenoptik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 800 792 992 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2427,
    pid: 91,
    number: '001-5218',
    title: 'Pavia',
    description: '',
    informations: '',
    street: 'Corso Cavour, 22',
    zip: '27100',
    citypart: '',
    addition: '',
    fon: '0382-1516200',
    fax: '0382-1516201',
    commonName: '',
    xCoordinates: '45.1860716',
    yCoordinates: '9.1516171',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '106e546e-d252-461e-9c3a-b7ea6efe54b7-5dd7f27c1aa47650e8f39882',
    appointmentTimifyType: false,
    city: 'Pavia',
    country: 'Italien',
    state: 'Lombardia',
    firmierung: 'Fielmann Srl',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente, <br /><br />Fielmann ha definito degli standard di igiene per l'ottica, per proteggere te e i nostri collaboratori dal Coronavirus. Queste misure hanno fondamento scientifico. Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori.<br /><br />Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store. Il controllo e l'applicazione delle lenti a contatto sono temporaneamente sospesi.<br /><br />Se possibile, porta con te la tua mascherina durante la tua visita in store. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro Servizio Clienti è disponibile al numero 800 792 992.<br /><br />Ti ringraziamo per la comprensione. Ci si rivede, finalmente!",
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente, <br /><br />Fielmann ha definito degli standard di igiene per l'ottica, per proteggere te e i nostri collaboratori dal Coronavirus. Queste misure hanno fondamento scientifico. Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori.<br /><br />Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store. Il controllo e l'applicazione delle lenti a contatto sono temporaneamente sospesi.<br /><br />Se possibile, porta con te la tua mascherina durante la tua visita in store. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro Servizio Clienti è disponibile al numero 800 792 992.<br /><br />Ti ringraziamo per la comprensione. Ci si rivede, finalmente!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2431,
    pid: 91,
    number: '001-5218',
    title: 'Pavia',
    description: '',
    informations: '',
    street: 'Corso Cavour, 22',
    zip: '27100',
    citypart: '',
    addition: '',
    fon: '0382-1516200',
    fax: '0382-1516201',
    commonName: '',
    xCoordinates: '45.1860716',
    yCoordinates: '9.1516171',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '106e546e-d252-461e-9c3a-b7ea6efe54b7-5dd7f27c1aa47650e8f39882',
    appointmentTimifyType: false,
    city: 'Pavia',
    country: 'Italien',
    state: 'Lombardia',
    firmierung: 'Fielmann Srl',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat Hygienestandards für die Augenoptik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 800 792 992 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat Hygienestandards für die Augenoptik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 800 792 992 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2435,
    pid: 91,
    number: '001-0739',
    title: 'Leibnitz',
    description: '',
    informations: '',
    street: 'Hauptplatz 14',
    zip: '8430',
    citypart: '',
    addition: '',
    fon: '03452-908180',
    fax: '03452-9081829',
    commonName: '',
    xCoordinates: '46.7820107',
    yCoordinates: '15.5393085',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '5e906fc8f932686fddc1464b',
    appointmentTimifyType: true,
    city: 'Leibnitz',
    country: 'Österreich',
    state: 'Steiermark',
    firmierung: 'Fielmann GmbH',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) Hygienestandards für die Augenoptik und Hörakustik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Sie haben die Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin zu vereinbaren.<br /><br />Bitte beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an. Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 34356266 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2439,
    pid: 91,
    number: '001-5215',
    title: 'Monza',
    description: '',
    informations: '',
    street: 'Via Italia, 9',
    zip: '20900',
    citypart: '',
    addition: '',
    fon: '039-2252300',
    fax: '039-2252301',
    commonName: '',
    xCoordinates: '45.5836608',
    yCoordinates: '9.2747029',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '106e546e-d252-461e-9c3a-b7ea6efe54b7-5dd7f27cce2b710f8b134aba',
    appointmentTimifyType: false,
    city: 'Monza',
    country: 'Italien',
    state: 'Lombardia',
    firmierung: 'Fielmann Srl',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente, <br /><br />Fielmann ha definito degli standard di igiene per l'ottica, per proteggere te e i nostri collaboratori dal Coronavirus. Queste misure hanno fondamento scientifico. Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori.<br /><br />Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store. Il controllo e l'applicazione delle lenti a contatto sono temporaneamente sospesi.<br /><br />Se possibile, porta con te la tua mascherina durante la tua visita in store. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro Servizio Clienti è disponibile al numero 800 792 992.<br /><br />Ti ringraziamo per la comprensione. Ci si rivede, finalmente!",
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente, <br /><br />Fielmann ha definito degli standard di igiene per l'ottica, per proteggere te e i nostri collaboratori dal Coronavirus. Queste misure hanno fondamento scientifico. Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori.<br /><br />Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store. Il controllo e l'applicazione delle lenti a contatto sono temporaneamente sospesi.<br /><br />Se possibile, porta con te la tua mascherina durante la tua visita in store. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro Servizio Clienti è disponibile al numero 800 792 992.<br /><br />Ti ringraziamo per la comprensione. Ci si rivede, finalmente!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2443,
    pid: 91,
    number: '001-5215',
    title: 'Monza',
    description: '',
    informations: '',
    street: 'Via Italia, 9',
    zip: '20900',
    citypart: '',
    addition: '',
    fon: '039-2252300',
    fax: '039-2252301',
    commonName: '',
    xCoordinates: '45.5836608',
    yCoordinates: '9.2747029',
    opening: false,
    opening_box: true,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '106e546e-d252-461e-9c3a-b7ea6efe54b7-5dd7f27cce2b710f8b134aba',
    appointmentTimifyType: false,
    city: 'Monza',
    country: 'Italien',
    state: 'Lombardia',
    firmierung: 'Fielmann Srl',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat Hygienestandards für die Augenoptik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 800 792 992 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat Hygienestandards für die Augenoptik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 800 792 992 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2447,
    pid: 91,
    number: '001-5221',
    title: 'Reggio Emilia',
    description: '',
    informations: '',
    street: 'Via Emilia Santo Stefano, 1e',
    zip: '42121',
    citypart: '',
    addition: '',
    fon: '0522-1361110',
    fax: '0522-1361111',
    commonName: '',
    xCoordinates: '44.6988158',
    yCoordinates: '10.6305948',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '106e546e-d252-461e-9c3a-b7ea6efe54b7-5dd7f27ced69b00f98efd18a',
    appointmentTimifyType: false,
    city: 'Reggio Emilia',
    country: 'Italien',
    state: 'Emilia-Romagna',
    firmierung: 'Fielmann Srl',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente, <br /><br />Fielmann ha definito degli standard di igiene per l'ottica, per proteggere te e i nostri collaboratori dal Coronavirus. Queste misure hanno fondamento scientifico. Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori.<br /><br />Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store. Il controllo e l'applicazione delle lenti a contatto sono temporaneamente sospesi.<br /><br />Se possibile, porta con te la tua mascherina durante la tua visita in store. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro Servizio Clienti è disponibile al numero 800 792 992.<br /><br />Ti ringraziamo per la comprensione. Ci si rivede, finalmente!",
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente, <br /><br />Fielmann ha definito degli standard di igiene per l'ottica, per proteggere te e i nostri collaboratori dal Coronavirus. Queste misure hanno fondamento scientifico. Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori.<br /><br />Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store. Il controllo e l'applicazione delle lenti a contatto sono temporaneamente sospesi.<br /><br />Se possibile, porta con te la tua mascherina durante la tua visita in store. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro Servizio Clienti è disponibile al numero 800 792 992.<br /><br />Ti ringraziamo per la comprensione. Ci si rivede, finalmente!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2451,
    pid: 91,
    number: '001-5221',
    title: 'Reggio Emilia',
    description: '',
    informations: '',
    street: 'Via Emilia Santo Stefano, 1e',
    zip: '42121',
    citypart: '',
    addition: '',
    fon: '0522-1361110',
    fax: '0522-1361111',
    commonName: '',
    xCoordinates: '44.6988158',
    yCoordinates: '10.6305948',
    opening: false,
    opening_box: true,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '106e546e-d252-461e-9c3a-b7ea6efe54b7-5dd7f27ced69b00f98efd18a',
    appointmentTimifyType: false,
    city: 'Reggio Emilia',
    country: 'Italien',
    state: 'Emilia-Romagna',
    firmierung: 'Fielmann Srl',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat Hygienestandards für die Augenoptik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 800 792 992 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat Hygienestandards für die Augenoptik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 800 792 992 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2453,
    pid: 91,
    number: '001-5220',
    title: 'Modena',
    description: '',
    informations: '',
    street: 'Via Emilia, 98',
    zip: '41121',
    citypart: '',
    addition: '',
    fon: '059-7116110',
    fax: '059-7116111',
    commonName: '',
    xCoordinates: '44.6459592',
    yCoordinates: '10.9275317',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '106e546e-d252-461e-9c3a-b7ea6efe54b7-5dd7f27ced69b00f98efd18d',
    appointmentTimifyType: false,
    city: 'Modena',
    country: 'Italien',
    state: 'Emilia-Romagna',
    firmierung: 'Fielmann Srl',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente, <br /><br />Fielmann ha definito degli standard di igiene per l'ottica, per proteggere te e i nostri collaboratori dal Coronavirus. Queste misure hanno fondamento scientifico. Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori.<br /><br />Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store. Il controllo e l'applicazione delle lenti a contatto sono temporaneamente sospesi.<br /><br />Se possibile, porta con te la tua mascherina durante la tua visita in store. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro Servizio Clienti è disponibile al numero 800 792 992.<br /><br />Ti ringraziamo per la comprensione. Ci si rivede, finalmente!",
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente, <br /><br />Fielmann ha definito degli standard di igiene per l'ottica, per proteggere te e i nostri collaboratori dal Coronavirus. Queste misure hanno fondamento scientifico. Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori.<br /><br />Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store. Il controllo e l'applicazione delle lenti a contatto sono temporaneamente sospesi.<br /><br />Se possibile, porta con te la tua mascherina durante la tua visita in store. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro Servizio Clienti è disponibile al numero 800 792 992.<br /><br />Ti ringraziamo per la comprensione. Ci si rivede, finalmente!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2457,
    pid: 91,
    number: '001-5220',
    title: 'Modena',
    description: '',
    informations: '',
    street: 'Via Emilia, 98',
    zip: '41121',
    citypart: '',
    addition: '',
    fon: '059-7116110',
    fax: '059-7116111',
    commonName: '',
    xCoordinates: '44.6459592',
    yCoordinates: '10.9275317',
    opening: false,
    opening_box: true,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '106e546e-d252-461e-9c3a-b7ea6efe54b7-5dd7f27ced69b00f98efd18d',
    appointmentTimifyType: false,
    city: 'Modena',
    country: 'Italien',
    state: 'Emilia-Romagna',
    firmierung: 'Fielmann Srl',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat Hygienestandards für die Augenoptik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 800 792 992 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat Hygienestandards für die Augenoptik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 800 792 992 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2460,
    pid: 91,
    number: '001-5219',
    title: 'Bassano del Grappa',
    description: '',
    informations: '',
    street: 'Via Roma, 16',
    zip: '36061',
    citypart: '',
    addition: '',
    fon: '042-41759630',
    fax: '042-41759631',
    commonName: '',
    xCoordinates: '45.7662543',
    yCoordinates: '11.7346827',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '106e546e-d252-461e-9c3a-b7ea6efe54b7-5dd7f27c8fda780f740bd299',
    appointmentTimifyType: false,
    city: 'Bassano del Grappa',
    country: 'Italien',
    state: 'Veneto',
    firmierung: 'Fielmann Srl',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente, <br /><br />Fielmann ha definito degli standard di igiene per l'ottica, per proteggere te e i nostri collaboratori dal Coronavirus. Queste misure hanno fondamento scientifico. Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori.<br /><br />Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store. Il controllo e l'applicazione delle lenti a contatto sono temporaneamente sospesi.<br /><br />Se possibile, porta con te la tua mascherina durante la tua visita in store. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro Servizio Clienti è disponibile al numero 800 792 992.<br /><br />Ti ringraziamo per la comprensione. Ci si rivede, finalmente!",
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente, <br /><br />Fielmann ha definito degli standard di igiene per l'ottica, per proteggere te e i nostri collaboratori dal Coronavirus. Queste misure hanno fondamento scientifico. Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori.<br /><br />Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store. Il controllo e l'applicazione delle lenti a contatto sono temporaneamente sospesi.<br /><br />Se possibile, porta con te la tua mascherina durante la tua visita in store. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro Servizio Clienti è disponibile al numero 800 792 992.<br /><br />Ti ringraziamo per la comprensione. Ci si rivede, finalmente!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2464,
    pid: 91,
    number: '001-5219',
    title: 'Bassano del Grappa',
    description: '',
    informations: '',
    street: 'Via Roma, 16',
    zip: '36061',
    citypart: '',
    addition: '',
    fon: '042-41759630',
    fax: '042-41759631',
    commonName: '',
    xCoordinates: '45.7662543',
    yCoordinates: '11.7346827',
    opening: false,
    opening_box: true,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '106e546e-d252-461e-9c3a-b7ea6efe54b7-5dd7f27c8fda780f740bd299',
    appointmentTimifyType: false,
    city: 'Bassano del Grappa',
    country: 'Italien',
    state: 'Veneto',
    firmierung: 'Fielmann Srl',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat Hygienestandards für die Augenoptik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 800 792 992 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat Hygienestandards für die Augenoptik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 800 792 992 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2468,
    pid: 91,
    number: '001-0972',
    title: 'Luxemburg II',
    description: '',
    informations: '',
    street: '25 Boulevard F.W.Raiffeisen',
    zip: '2411',
    citypart: '',
    addition: '',
    fon: '26187722',
    fax: '27125348',
    commonName: "Cloche d' Or",
    xCoordinates: '49.587317',
    yCoordinates: '6.11733',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Luxembourg',
    country: 'Luxemburg',
    state: 'LU-L',
    firmierung: 'Fielmann GmbH',
    importantMessageHeader: '',
    importantMessageText: '',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: []
  },
  {
    uid: 2472,
    pid: 91,
    number: '001-2114',
    title: 'Nürtingen',
    description: '',
    informations: '',
    street: 'Kirchstr. 12',
    zip: '72622',
    citypart: '',
    addition: '',
    fon: '07022-909710',
    fax: '07022-9097129',
    commonName: '',
    xCoordinates: '48.62658',
    yCoordinates: '9.33777',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Nürtingen',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & Co.OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2476,
    pid: 91,
    number: '001-0972',
    title: 'Luxemburg II',
    description: '',
    informations: '',
    street: '25 Boulevard F.W.Raiffeisen',
    zip: '2411',
    citypart: '',
    addition: '',
    fon: '26187722',
    fax: '27125348',
    commonName: "Cloche d' Or",
    xCoordinates: '49.587317',
    yCoordinates: '6.11733',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Luxembourg',
    country: 'Luxemburg',
    state: 'LU-L',
    firmierung: 'Fielmann GmbH',
    importantMessageHeader: '',
    importantMessageText: '',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: []
  },
  {
    uid: 2481,
    pid: 91,
    number: '001-5222',
    title: 'Treviso',
    description: '',
    informations: '',
    street: 'Piazza Indipendenza, 11',
    zip: '31100',
    citypart: '',
    addition: '',
    fon: '0422-1488170',
    fax: '0422-1488171',
    commonName: '',
    xCoordinates: '45.6655374',
    yCoordinates: '12.2464151',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '106e546e-d252-461e-9c3a-b7ea6efe54b7-5dd7f27c70b4830ee4ccf3dc',
    appointmentTimifyType: false,
    city: 'Treviso',
    country: 'Italien',
    state: 'Veneto',
    firmierung: 'Fielmann Srl',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente, <br /><br />Fielmann ha definito degli standard di igiene per l'ottica, per proteggere te e i nostri collaboratori dal Coronavirus. Queste misure hanno fondamento scientifico. Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori.<br /><br />Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store. Il controllo e l'applicazione delle lenti a contatto sono temporaneamente sospesi.<br /><br />Se possibile, porta con te la tua mascherina durante la tua visita in store. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro Servizio Clienti è disponibile al numero 800 792 992.<br /><br />Ti ringraziamo per la comprensione. Ci si rivede, finalmente!",
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente, <br /><br />Fielmann ha definito degli standard di igiene per l'ottica, per proteggere te e i nostri collaboratori dal Coronavirus. Queste misure hanno fondamento scientifico. Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori.<br /><br />Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store. Il controllo e l'applicazione delle lenti a contatto sono temporaneamente sospesi.<br /><br />Se possibile, porta con te la tua mascherina durante la tua visita in store. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro Servizio Clienti è disponibile al numero 800 792 992.<br /><br />Ti ringraziamo per la comprensione. Ci si rivede, finalmente!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2485,
    pid: 91,
    number: '001-5222',
    title: 'Treviso',
    description: '',
    informations: '',
    street: 'Piazza Indipendenza, 11',
    zip: '31100',
    citypart: '',
    addition: '',
    fon: '0422-1488170',
    fax: '0422-1488171',
    commonName: '',
    xCoordinates: '45.6655374',
    yCoordinates: '12.2464151',
    opening: false,
    opening_box: true,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '106e546e-d252-461e-9c3a-b7ea6efe54b7-5dd7f27c70b4830ee4ccf3dc',
    appointmentTimifyType: false,
    city: 'Treviso',
    country: 'Italien',
    state: 'Veneto',
    firmierung: 'Fielmann Srl',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat Hygienestandards für die Augenoptik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 800 792 992 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat Hygienestandards für die Augenoptik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 800 792 992 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2488,
    pid: 91,
    number: '001-2115',
    title: 'Mühlacker',
    description: '',
    informations: '',
    street: 'Bahnhofstraße 80',
    zip: '75417',
    citypart: '',
    addition: '',
    fon: '07041-40973-0',
    fax: '07041-40973-29',
    commonName: '',
    xCoordinates: '48.9511598',
    yCoordinates: '8.8423388',
    opening: false,
    opening_box: true,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Mühlacker',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & Co. OHG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2496,
    pid: 91,
    number: '001-5225',
    title: 'Udine',
    description: '',
    informations: '',
    street: 'Via Rialto, 13',
    zip: '33100',
    citypart: '',
    addition: '',
    fon: '0432-1487200',
    fax: '0432-1487201',
    commonName: '',
    xCoordinates: '46.0626568',
    yCoordinates: '13.2340453',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '4136',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '4136',
    appointmentSwitchoff: false,
    appointmentTimify:
      '106e546e-d252-461e-9c3a-b7ea6efe54b7-5dd7f27cce2b710f8b134ad0',
    appointmentTimifyType: false,
    city: 'Udine',
    country: 'Italien',
    state: 'Friuli-Venezia Giulia',
    firmierung: 'Fielmann Srl',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente, <br /><br />Fielmann ha definito degli standard di igiene per l'ottica, per proteggere te e i nostri collaboratori dal Coronavirus. Queste misure hanno fondamento scientifico. Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori.<br /><br />Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store. Il controllo e l'applicazione delle lenti a contatto sono temporaneamente sospesi.<br /><br />Se possibile, porta con te la tua mascherina durante la tua visita in store. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro Servizio Clienti è disponibile al numero 800 792 992.<br /><br />Ti ringraziamo per la comprensione. Ci si rivede, finalmente!",
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente, <br /><br />Fielmann ha definito degli standard di igiene per l'ottica, per proteggere te e i nostri collaboratori dal Coronavirus. Queste misure hanno fondamento scientifico. Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori.<br /><br />Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store. Il controllo e l'applicazione delle lenti a contatto sono temporaneamente sospesi.<br /><br />Se possibile, porta con te la tua mascherina durante la tua visita in store. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro Servizio Clienti è disponibile al numero 800 792 992.<br /><br />Ti ringraziamo per la comprensione. Ci si rivede, finalmente!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2500,
    pid: 91,
    number: '001-5225',
    title: 'Udine',
    description: '',
    informations: '',
    street: 'Via Rialto, 13',
    zip: '33100',
    citypart: '',
    addition: '',
    fon: '0432-1487200',
    fax: '0432-1487201',
    commonName: '',
    xCoordinates: '46.0626568',
    yCoordinates: '13.2340453',
    opening: false,
    opening_box: true,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '106e546e-d252-461e-9c3a-b7ea6efe54b7-5dd7f27cce2b710f8b134ad0',
    appointmentTimifyType: false,
    city: 'Udine',
    country: 'Italien',
    state: 'Friuli-Venezia Giulia',
    firmierung: 'Fielmann Srl',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat Hygienestandards für die Augenoptik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 800 792 992 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat Hygienestandards für die Augenoptik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 800 792 992 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2504,
    pid: 91,
    number: '001-5223',
    title: 'Lucca',
    description: '',
    informations: '',
    street: 'Via Fillungo, 30',
    zip: '55100',
    citypart: '',
    addition: '',
    fon: '0583-1528180',
    fax: '0583-1528185',
    commonName: '',
    xCoordinates: '43.8435676',
    yCoordinates: '10.5041407',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '4340',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '4340',
    appointmentSwitchoff: false,
    appointmentTimify:
      '106e546e-d252-461e-9c3a-b7ea6efe54b7-5dd7f27c8fda780f740bd29e',
    appointmentTimifyType: false,
    city: 'Lucca',
    country: 'Italien',
    state: '',
    firmierung: 'Fielmann Srl',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente, <br /><br />Fielmann ha definito degli standard di igiene per l'ottica, per proteggere te e i nostri collaboratori dal Coronavirus. Queste misure hanno fondamento scientifico. Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori.<br /><br />Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store. Il controllo e l'applicazione delle lenti a contatto sono temporaneamente sospesi.<br /><br />Se possibile, porta con te la tua mascherina durante la tua visita in store. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro Servizio Clienti è disponibile al numero 800 792 992.<br /><br />Ti ringraziamo per la comprensione. Ci si rivede, finalmente!",
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente, <br /><br />Fielmann ha definito degli standard di igiene per l'ottica, per proteggere te e i nostri collaboratori dal Coronavirus. Queste misure hanno fondamento scientifico. Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori.<br /><br />Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store. Il controllo e l'applicazione delle lenti a contatto sono temporaneamente sospesi.<br /><br />Se possibile, porta con te la tua mascherina durante la tua visita in store. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro Servizio Clienti è disponibile al numero 800 792 992.<br /><br />Ti ringraziamo per la comprensione. Ci si rivede, finalmente!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2508,
    pid: 91,
    number: '001-5223',
    title: 'Lucca',
    description: '',
    informations: '',
    street: 'Via Fillungo, 30',
    zip: '55100',
    citypart: '',
    addition: '',
    fon: '0583-1528180',
    fax: '0583-1528185',
    commonName: '',
    xCoordinates: '43.8435676',
    yCoordinates: '10.5041407',
    opening: false,
    opening_box: true,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '106e546e-d252-461e-9c3a-b7ea6efe54b7-5dd7f27c8fda780f740bd29e',
    appointmentTimifyType: false,
    city: 'Lucca',
    country: 'Italien',
    state: '',
    firmierung: 'Fielmann Srl',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat Hygienestandards für die Augenoptik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 800 792 992 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat Hygienestandards für die Augenoptik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 800 792 992 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2512,
    pid: 91,
    number: '001-5224',
    title: 'Ferrara',
    description: '',
    informations: '',
    street: 'Via Canonica, 1',
    zip: '44121',
    citypart: '',
    addition: '',
    fon: '0532-1777030',
    fax: '0532-1777035',
    commonName: '',
    xCoordinates: '44.8353382',
    yCoordinates: '11.6216994',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '4356',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '4356',
    appointmentSwitchoff: false,
    appointmentTimify:
      '106e546e-d252-461e-9c3a-b7ea6efe54b7-5dd7f27cce2b710f8b134abe',
    appointmentTimifyType: false,
    city: 'Ferrara',
    country: 'Italien',
    state: 'Emilia-Romagna',
    firmierung: 'Fielmann Srl',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente, <br /><br />Fielmann ha definito degli standard di igiene per l'ottica, per proteggere te e i nostri collaboratori dal Coronavirus. Queste misure hanno fondamento scientifico. Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori.<br /><br />Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store. Il controllo e l'applicazione delle lenti a contatto sono temporaneamente sospesi.<br /><br />Se possibile, porta con te la tua mascherina durante la tua visita in store. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro Servizio Clienti è disponibile al numero 800 792 992.<br /><br />Ti ringraziamo per la comprensione. Ci si rivede, finalmente!",
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente, <br /><br />Fielmann ha definito degli standard di igiene per l'ottica, per proteggere te e i nostri collaboratori dal Coronavirus. Queste misure hanno fondamento scientifico. Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori.<br /><br />Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store. Il controllo e l'applicazione delle lenti a contatto sono temporaneamente sospesi.<br /><br />Se possibile, porta con te la tua mascherina durante la tua visita in store. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro Servizio Clienti è disponibile al numero 800 792 992.<br /><br />Ti ringraziamo per la comprensione. Ci si rivede, finalmente!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2516,
    pid: 91,
    number: '001-5224',
    title: 'Ferrara',
    description: '',
    informations: '',
    street: 'Via Canonica, 1',
    zip: '44121',
    citypart: '',
    addition: '',
    fon: '0532-1777030',
    fax: '0532-1777035',
    commonName: '',
    xCoordinates: '44.8353382',
    yCoordinates: '11.6216994',
    opening: false,
    opening_box: true,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '106e546e-d252-461e-9c3a-b7ea6efe54b7-5dd7f27cce2b710f8b134abe',
    appointmentTimifyType: false,
    city: 'Ferrara',
    country: 'Italien',
    state: 'Emilia-Romagna',
    firmierung: 'Fielmann Srl',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat Hygienestandards für die Augenoptik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 800 792 992 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat Hygienestandards für die Augenoptik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 800 792 992 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2520,
    pid: 91,
    number: '001-5227',
    title: 'Milano II, Corso Vercelli',
    description: '',
    informations: '',
    street: 'Corso Vercelli, 13',
    zip: '20144',
    citypart: '',
    addition: '',
    fon: '02-35923650',
    fax: '02-35923655',
    commonName: '',
    xCoordinates: '45.4663117',
    yCoordinates: '9.162825',
    opening: false,
    opening_box: false,
    openingText: '',
    openingLink: '4400',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '4400',
    appointmentSwitchoff: false,
    appointmentTimify:
      '106e546e-d252-461e-9c3a-b7ea6efe54b7-5dd7f27c1aa47650e8f39895',
    appointmentTimifyType: false,
    city: 'Milano',
    country: 'Italien',
    state: 'Lombardia',
    firmierung: 'Fielmann Srl',
    importantMessageHeader: 'I nostri standard di igiene',
    importantMessageText:
      "Gentile cliente, <br /><br />Fielmann ha definito degli standard di igiene per l'ottica, per proteggere te e i nostri collaboratori dal Coronavirus. Queste misure hanno fondamento scientifico. Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori.<br /><br />Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store. Il controllo e l'applicazione delle lenti a contatto sono temporaneamente sospesi.<br /><br />Se possibile, porta con te la tua mascherina durante la tua visita in store. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro Servizio Clienti è disponibile al numero 800 792 992.<br /><br />Ti ringraziamo per la comprensione. Ci si rivede, finalmente!",
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'I nostri standard di igiene',
      text:
        "Gentile cliente, <br /><br />Fielmann ha definito degli standard di igiene per l'ottica, per proteggere te e i nostri collaboratori dal Coronavirus. Queste misure hanno fondamento scientifico. Fielmann le ha adottate tempestivamente in tutti gli store e sono il presupposto per far sentire al sicuro i clienti e i nostri collaboratori.<br /><br />Nel rispetto degli standard di igiene, al momento possiamo accogliere in store un numero limitato di clienti per volta. Se possibile, ti preghiamo di fissare in anticipo un appuntamento online, per telefono o direttamente in store. Prima di venirci a trovare, ti chiediamo di visionare le norme igieniche Fielmann e rispettarle durante la tua visita in store. Il controllo e l'applicazione delle lenti a contatto sono temporaneamente sospesi.<br /><br />Se possibile, porta con te la tua mascherina durante la tua visita in store. Se hai sintomi da raffreddore, ti preghiamo di rimandare la tua visita in store.<br /><br />Il nostro Servizio Clienti è disponibile al numero 800 792 992.<br /><br />Ti ringraziamo per la comprensione. Ci si rivede, finalmente!",
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2524,
    pid: 91,
    number: '001-5227',
    title: 'Milano II, Corso Vercelli',
    description: '',
    informations: '',
    street: 'Corso Vercelli, 13',
    zip: '20144',
    citypart: '',
    addition: '',
    fon: '02-35923650',
    fax: '02-35923655',
    commonName: '',
    xCoordinates: '45.4663117',
    yCoordinates: '9.162825',
    opening: false,
    opening_box: true,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify:
      '106e546e-d252-461e-9c3a-b7ea6efe54b7-5dd7f27c1aa47650e8f39895',
    appointmentTimifyType: false,
    city: 'Milano',
    country: 'Italien',
    state: 'Lombardia',
    firmierung: 'Fielmann Srl',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat Hygienestandards für die Augenoptik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 800 792 992 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat Hygienestandards für die Augenoptik definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 800 792 992 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2526,
    pid: 91,
    number: '001-2117',
    title: 'Friesoythe',
    description: '',
    informations: '',
    street: 'Lange Straße 16',
    zip: '26169',
    citypart: '',
    addition: '',
    fon: '04491-9239830',
    fax: '04491-9239829',
    commonName: '',
    xCoordinates: '53.0213313',
    yCoordinates: '7.8575677',
    opening: false,
    opening_box: true,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Friesoythe',
    country: 'Deutschland',
    state: 'Niedersachsen',
    firmierung: 'Fielmann AG & CO. KG',
    importantMessageHeader: 'Unsere Hygienestandards',
    importantMessageText:
      'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
    currentlyClosed: true,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: {
      header: 'Unsere Hygienestandards',
      text:
        'Liebe Kundinnen und Kunden,<br /><br />Fielmann hat gemeinsam mit Prof. Dr. Exner (Universitätsklinikum Bonn) <a href="/hygiene-standards-fuer-augenoptischen-einzelhandel/">Hygienestandards für die Augenoptik und Hörakustik</a> definiert, um Sie und unsere Mitarbeiter vor einer Coronavirus-Infektion zu schützen. Die wissenschaftlich fundierten Maßnahmen wendet Fielmann ab sofort in allen Niederlassungen an und schafft Voraussetzungen, damit Sie sich sicher fühlen können.<br /><br />Im Rahmen der Hygienestandards ist der Zutritt zu unseren Niederlassungen nur für eine begrenzte Anzahl an Kunden gleichzeitig möglich. Bitte vereinbaren Sie nach Möglichkeit vorab online, per Telefon oder direkt in Ihrer Niederlassung einen Termin und beachten Sie vor Ihrem Besuch die Hygienevorgaben von Fielmann. Kontaktlinsenanpassungen und -nachkontrollen bieten wir vorübergehend nicht an.<br /><br />Bitte bringen Sie nach Möglichkeiten einen Mund-Nasen-Schutz bei Ihrem nächsten Besuch einer unserer Niederlassungen mit. Sollten Sie Erkältungssymptome haben, bitten wir Sie höflich, unsere Niederlassung derzeit nicht zu besuchen.<br /><br />Unser Kundenservice steht Ihnen unter 0800 3435626 zur Verfügung.<br /><br />Wir danken für Ihr Verständnis. Bleiben Sie gesund!',
      isEmergency: 0,
      emergencyTextOverride: ''
    }
  },
  {
    uid: 2530,
    pid: 91,
    number: '001-2118',
    title: 'Bad Säckingen',
    description: '',
    informations: '',
    street: 'Bahnhofplatz 1',
    zip: '79713',
    citypart: '',
    addition: '',
    fon: '07761-9261143',
    fax: '07761-9261593',
    commonName: '',
    xCoordinates: '47.5549694',
    yCoordinates: '7.9482226',
    opening: false,
    opening_box: true,
    openingText: '',
    openingLink: '',
    openingTeaser: false,
    openingTeaserText: '',
    openingTeaserLink: '',
    appointmentSwitchoff: false,
    appointmentTimify: '',
    appointmentTimifyType: false,
    city: 'Bad Säckingen',
    country: 'Deutschland',
    state: 'Baden-Württemberg',
    firmierung: 'Fielmann AG & CO. KG',
    importantMessageHeader: '',
    importantMessageText: '',
    currentlyClosed: false,
    emergencyTextOverride: '',
    closedTodayBySpecialOpenings: false,
    emergencyData: []
  }
]
