import { useFocusEffect } from '@react-navigation/native'
import React, { useState } from 'react'
import { View } from 'react-native'
import AssessmentButton from '../components/Assessment/AssessmentButton'
import PlainSurface from '../components/Surfaces/PlainSurface'
import Hexagon from '../components/templates/Hexagon'
import {
  Paragraph,
  Headline,
  moderateScale,
  ImageChri
} from '../scaling/ScaledUI'

import { useAssessment } from '../hooks/user/assessment'
import DefaultScreen from '../components/screens/DefaultScreen'

export default () => {
  const { getLastAssessmentDates } = useAssessment()

  const [lastAssessments, setLastAssessments] = useState({})

  useFocusEffect(
    React.useCallback(() => {
      getLastAssessmentDates().then(setLastAssessments)
    }, [])
  )

  return (
    <DefaultScreen>
      <Hexagon>
        <View
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            marginHorizontal: moderateScale(10, 2)
          }}
        >
          <View style={{ marginTop: 50 }} />
          <ImageChri />
          <PlainSurface style={{ width: '100%' }}>
            <Headline>Los geht's</Headline>
            <Paragraph>
              In den folgenden Schritten werden wir auf Ihre Bedürfnisse
              eingehen, um Ihrem Fachoptiker die Arbeit zu erleichtern.
            </Paragraph>
            <Paragraph style={{ marginBottom: moderateScale(10, 2.5) }}>
              Wählen Sie einen der Beratungsbereiche:
            </Paragraph>
            <AssessmentButton
              navigateTo='AssessmentGlasses'
              doneDate={lastAssessments.GLASSES}
            >
              Brillengläser
            </AssessmentButton>
            <AssessmentButton
              navigateTo='AssessmentFrame'
              doneDate={lastAssessments.FRAME}
            >
              Brillenfassung
            </AssessmentButton>
            <AssessmentButton
              navigateTo='AssessmentLenses'
              doneDate={lastAssessments.LENSES}
            >
              Kontaktlinsen
            </AssessmentButton>
          </PlainSurface>
        </View>
      </Hexagon>
    </DefaultScreen>
  )
}
