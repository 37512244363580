/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateUserMetadata = /* GraphQL */ `
  subscription OnCreateUserMetadata($id: ID) {
    onCreateUserMetadata(id: $id) {
      id
      localOpticianId
      profilePictureId
      createdAt
      updatedAt
    }
  }
`
export const onUpdateUserMetadata = /* GraphQL */ `
  subscription OnUpdateUserMetadata($id: ID) {
    onUpdateUserMetadata(id: $id) {
      id
      localOpticianId
      profilePictureId
      createdAt
      updatedAt
    }
  }
`
export const onDeleteUserMetadata = /* GraphQL */ `
  subscription OnDeleteUserMetadata($id: ID) {
    onDeleteUserMetadata(id: $id) {
      id
      localOpticianId
      profilePictureId
      createdAt
      updatedAt
    }
  }
`
export const onCreateAssessment = /* GraphQL */ `
  subscription OnCreateAssessment {
    onCreateAssessment {
      beginTimestamp
      endTimestamp
      userId
      assessmentData
      type
      version
      createdAt
      updatedAt
    }
  }
`
export const onUpdateAssessment = /* GraphQL */ `
  subscription OnUpdateAssessment {
    onUpdateAssessment {
      beginTimestamp
      endTimestamp
      userId
      assessmentData
      type
      version
      createdAt
      updatedAt
    }
  }
`
export const onDeleteAssessment = /* GraphQL */ `
  subscription OnDeleteAssessment {
    onDeleteAssessment {
      beginTimestamp
      endTimestamp
      userId
      assessmentData
      type
      version
      createdAt
      updatedAt
    }
  }
`
export const onCreatePassportUpload = /* GraphQL */ `
  subscription OnCreatePassportUpload {
    onCreatePassportUpload {
      userId
      uploadTimestamp
      fileName
      status
      processedByUserId
      processedTimestamp
      createdAt
      updatedAt
    }
  }
`
export const onUpdatePassportUpload = /* GraphQL */ `
  subscription OnUpdatePassportUpload {
    onUpdatePassportUpload {
      userId
      uploadTimestamp
      fileName
      status
      processedByUserId
      processedTimestamp
      createdAt
      updatedAt
    }
  }
`
export const onDeletePassportUpload = /* GraphQL */ `
  subscription OnDeletePassportUpload {
    onDeletePassportUpload {
      userId
      uploadTimestamp
      fileName
      status
      processedByUserId
      processedTimestamp
      createdAt
      updatedAt
    }
  }
`
export const onCreatePassport = /* GraphQL */ `
  subscription OnCreatePassport {
    onCreatePassport {
      userId
      createdTimestamp
      createdByUserId
      passportUpload {
        items {
          userId
          uploadTimestamp
          fileName
          status
          processedByUserId
          processedTimestamp
          createdAt
          updatedAt
        }
        nextToken
      }
      originalTimestamp
      metaData
      rightData {
        sphere
        cylinder
        axis
        add
        prism
        base
        pd
        height
        diameter
        radius
      }
      leftData {
        sphere
        cylinder
        axis
        add
        prism
        base
        pd
        height
        diameter
        radius
      }
      type
      createdAt
      updatedAt
    }
  }
`
export const onUpdatePassport = /* GraphQL */ `
  subscription OnUpdatePassport {
    onUpdatePassport {
      userId
      createdTimestamp
      createdByUserId
      passportUpload {
        items {
          userId
          uploadTimestamp
          fileName
          status
          processedByUserId
          processedTimestamp
          createdAt
          updatedAt
        }
        nextToken
      }
      originalTimestamp
      metaData
      rightData {
        sphere
        cylinder
        axis
        add
        prism
        base
        pd
        height
        diameter
        radius
      }
      leftData {
        sphere
        cylinder
        axis
        add
        prism
        base
        pd
        height
        diameter
        radius
      }
      type
      createdAt
      updatedAt
    }
  }
`
export const onDeletePassport = /* GraphQL */ `
  subscription OnDeletePassport {
    onDeletePassport {
      userId
      createdTimestamp
      createdByUserId
      passportUpload {
        items {
          userId
          uploadTimestamp
          fileName
          status
          processedByUserId
          processedTimestamp
          createdAt
          updatedAt
        }
        nextToken
      }
      originalTimestamp
      metaData
      rightData {
        sphere
        cylinder
        axis
        add
        prism
        base
        pd
        height
        diameter
        radius
      }
      leftData {
        sphere
        cylinder
        axis
        add
        prism
        base
        pd
        height
        diameter
        radius
      }
      type
      createdAt
      updatedAt
    }
  }
`
