import { useNavigation, useRoute } from '@react-navigation/native'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Button, Text } from 'react-native-paper'
import { FormWizardContext } from './FormWizardContext'
import { useForm } from 'react-final-form'
import { scale, moderateScale } from '../scaling/ScaledUI'
import { View } from 'react-native'

const PushNext = ({ onPushDone }) => {
  const route = useRoute()
  const { screens } = useContext(FormWizardContext)
  const index = +route.params.index
  const navigation = useNavigation()
  const form = useForm()

  useEffect(() => {
    let values
    for (let i = index + 1; i < screens.length; i++) {
      if (screens[i].props.condition && !values) {
        values = form.getState().values
      }
      if (!screens[i].props.condition || screens[i].props.condition(values)) {
        navigation.push('wizard', { index: i })
        return onPushDone()
      }
    }

    form.submit()
  }, [index, screens])

  return null
}

export default ({ onPress, children }) => {
  const [pushNext, setPushNext] = useState(false)
  const [valid, setValid] = useState(false)
  const form = useForm()

  useEffect(() => {
    const unsubscribe = form.subscribe(
      formState => {
        setValid(formState.valid)
      },
      {
        valid: true
      }
    )

    return () => {
      unsubscribe()
    }
  }, [])

  const handlePress = useCallback(() => {
    setPushNext(true)
  }, [])

  const handlePushDone = useCallback(() => {
    setPushNext(false)
  }, [])

  return (
    <>
      <Button
        mode='contained'
        disabled={pushNext || !valid}
        style={{ margin: scale(12), marginBottom: scale(24) }}
        onPress={handlePress}
      >
        <Text
          style={{
            textAlign: 'center',
            color: '#ffffff',
            fontSize: scale(16),
            lineHeight: moderateScale(30, 2)
          }}
        >
          {children}
        </Text>
      </Button>
      {pushNext && <PushNext onPushDone={handlePushDone} />}
    </>
  )
}
