import React, { useState } from 'react'
import { Field } from 'react-final-form'
import { Image, ScrollView, View } from 'react-native'
import OpticianAvatar from '../../assets/chri-avatar.jpg'
import PlainSurface from '../components/Surfaces/PlainSurface'
import { scale, Headline, Text, Button } from '../scaling/ScaledUI'
import { Portal } from 'react-native-paper'
import InfoModal from '../form-wizard/InfoModal'

export default ({ id, head, description, fieldProps, children, info }) => {
  const component = React.Children.only(children)

  const [infoVisible, setInfoVisible] = useState(false)

  return (
    <ScrollView
      style={{
        paddingHorizontal: scale(12),
        alignSelf: 'stretch'
      }}
    >
      <PlainSurface>
        <View
          style={{
            flexDirection: 'row',
            marginBottom: 16,
            alignItems: 'center'
          }}
        >
          <Image
            style={{
              width: scale(60),
              height: scale(60),
              borderRadius: scale(4),
              marginRight: scale(12)
            }}
            source={OpticianAvatar}
          />
          <Headline style={{ flex: 1 }}>{head}</Headline>
        </View>
        {description && <Text style={{ marginBottom: 8 }}>{description}</Text>}
        <Field name={id} {...fieldProps}>
          {props => {
            return React.cloneElement(component, props)
          }}
        </Field>
        {info && info !== '' && (
          <View style={{ flex: 1, marginTop: 8 }}>
            <Button
              icon='information-outline'
              style={{ marginTop: 6 }}
              onPress={() => setInfoVisible(true)}
            >
              Information
            </Button>
            <Portal>
              <InfoModal
                info={info}
                visible={infoVisible}
                onDismiss={() => setInfoVisible(false)}
              />
            </Portal>
          </View>
        )}
      </PlainSurface>
    </ScrollView>
  )
}
