import React, { Component } from 'react'
import { View } from 'react-native'
// import { Portal } from 'react-native-paper'
import Carousel from 'react-native-snap-carousel'
import GlassPreview from './GlassPreview'
import {
  moderateScale,
  verticalScale,
  scale
} from '../../scaling/ScaledUI'
// import InfoModal from '../../../form-wizard/InfoModal'

export default class carousel extends Component {
  // habs als ohne dem Component schreibweise ned zum laufen bracht :/
  constructor (props) {
    super(props)
    this.state = {
      slider1ActiveSlide: 0,
      data: (props.data && props.data[0]) ? props.data : [{}, {}],
      h: (verticalScale(320) - 320) * 0.75 + 320,
      w: scale(350),
      infoVisible: (props.data && props.data[0] && props.data[0].Message),
      info: getInfo()
    }
    function getInfo () {
      if (props.data && props.data[0] && props.data[0].Message) return props.data[0].Message
      // else if(props.data[0] === "Diverse Hersteller") "Da Sie ein Standardglas gewählt haben, sollte Ihr Optiker Ihnen ein Standardglas von ein"
      else return undefined
    }
  }

  componentWillUpdate (nextProps, nextState) {
    // console.log(JSON.stringify(nextProps, null, 4))
    nextState.data = nextProps.data
  }

  render () {
    return (
      <>
        {/* <Portal>
          <InfoModal
            info={this.state.info}
            visible={this.state.infoVisible}
            onDismiss={() => this.setState({ infoVisible: false })}
          />
        </Portal> */}
        <View style={{ width: '100%', height: this.state.h, alignItems: 'center' }}>
          <Carousel
            style={{
              backgroundColor: '#eeeeee',
              alignSelf: 'center',
              alignContent: 'center'
            }}
            layout='default'
            ref={c => {
              this._carousel = c
            }}
            data={this.state.data || [{}, {}]}
            sliderWidth={this.state.w}
            itemWidth={this.state.w - scale(100)}
            enableSnap
            inactiveSlideScale={0.92}
            inactiveSlideOpacity={0.5}
            onSnapToItem={index => this.setState({ slider1ActiveSlide: index })}
            renderItem={({ item }) => (
              <GlassPreview
                glass={item}
                height={300}
                width={this.state.w - scale(100)}
                padding={scale(10)}
                imagePadding={moderateScale(165, 0.65)}
              />
            )}
          />
        </View>
      </>
    )
  }
}
