import React from 'react'
import { StyleSheet, View, Platform } from 'react-native'
import maxWidth from '../../styles/maxWidth'

export default ({ children, style }) => {
  return (
    <View style={[styles.outer]}>
      <View style={[styles.inner, style]}>{children}</View>
    </View>
  )
}

const styles = StyleSheet.create({
  outer: {
    flex: 1,
    width: '100%',
    maxWidth: Platform.OS !== 'web' ? undefined : maxWidth.screen,
    alignSelf: 'center',
    alignItems: 'center'
  },
  inner: {
    flex: 1,
    width: '100%',
    maxWidth: Platform.OS !== 'web' ? undefined : maxWidth.content,
    justifyContent: 'center',
    alignItems: 'center'
  }
})
