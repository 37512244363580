import { DefaultTheme } from 'react-native-paper'

const theme = {
  ...DefaultTheme,
  roundness: 4,
  colors: {
    ...DefaultTheme.colors,
    primary: '#9a305e',
    accent: '#d15237',
    background: '#fcfbfa',
    // custom
    foreground: 'white',
    borders: '#dcdbda',
    footer: '#611e3b'
  }
}

export default theme
