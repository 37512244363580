export default {
  prefixes: ['https://vispecs.com'],
  config: {
    screens: {
      Landing: '',
      Login: 'login',
      Legal: 'legal',
      // Store: 'store',
      Survey: 'survey',
      GlassSurvey: 'survey/glasses',
      FrameSurvey: 'survey/frame',
      LenseSurvey: 'survey/contact-lenses',
      Map: 'map',
      Recommendation: 'recommendation',
      // Passport: 'passport',  // removed because it makes at the moment no sense that it's deep linked
      Contact: 'contact',
      // Services: 'services',
      Home: 'home',
      Download: 'download',
      Profile: 'profile'
    }
  }
}
