import React from 'react'
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap
} from 'react-google-maps'

export default withScriptjs(
  withGoogleMap(props => {
    return (
      <GoogleMap defaultZoom={12} defaultCenter={props.defaultCenter} onClick={props.onClick}>
        {props.markers}
      </GoogleMap>
    )
  })
)
