import {
  Text,
  Paragraph,
  Subheading,
  Headline,
  Button,
  IconButton,
  TextInput
} from 'react-native-paper'
import IntegerInput from './IntegerInput'
import ImageChri from './ImageChri'

// disable scaling in web
const scale = val => {
  return val
}
const moderateScale = val => {
  return val
}
const verticalScale = val => {
  return val
}

export {
  Text,
  Paragraph,
  Subheading,
  Headline,
  Button,
  IconButton,
  ImageChri,
  TextInput,
  IntegerInput,
  scale,
  moderateScale,
  verticalScale
}
