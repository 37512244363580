import { useFocusEffect } from '@react-navigation/native'
import { useHeaderHeight } from '@react-navigation/stack'
import React from 'react'
import {
  Keyboard,
  Platform,
  ScrollView,
  StatusBar,
  TouchableWithoutFeedback,
  View
} from 'react-native'
import theme from '../../styles/theme'
import { scale } from 'react-native-size-matters'
// import { scale } from '../../scaling/ScaledUI'

export default ({ scrollable, ignoreHeader, noPadding, children }) => {
  const headerHeight = useHeaderHeight()

  useFocusEffect(
    React.useCallback(() => {
      Platform.OS === 'android' &&
        StatusBar.setBackgroundColor(theme.colors.background)
      StatusBar.setBarStyle('dark-content')
    })
  )

  const ViewComponent = scrollable ? ScrollView : View

  return (
    <TouchableWithoutFeedback
      onPress={Keyboard.dismiss} // eslint-disable-line react/jsx-handler-names
      accessible={false}
    >
      <ViewComponent
        style={{
          flex: 1,
          paddingHorizontal: noPadding ? 0 : scale(12),
          marginBottom: noPadding ? 0 : scale(12),
          marginTop: ignoreHeader ? 0 : headerHeight
        }}
      >
        {children}
      </ViewComponent>
    </TouchableWithoutFeedback>
  )
}
