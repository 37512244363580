import React, { useEffect, useState } from 'react'
import { Image, TouchableHighlight, View } from 'react-native'
import theme from '../../styles/theme'
import _ from 'lodash'
import { scale, moderateScale } from '../../scaling/ScaledUI'
import { Text } from 'react-native-paper'

export default props => {
  const { style, options, input, radio = false } = props
  const { value, onChange } = input

  const rowLength = 3
  const [rows, setRows] = useState([])
  const [entrySize, setEntrySize] = useState(0)

  useEffect(() => {
    const newRows = []
    for (let i = 0; i < options.length; i += rowLength) {
      newRows.push(options.slice(i, i + rowLength))
    }
    setRows(newRows)
  }, [options])

  const handlePress = ({ id }) => {
    const newActive = _.xor(radio ? [] : value, [id])
    onChange(newActive)
  }

  const handleSize = e => {
    setEntrySize(Math.floor(e.nativeEvent.layout.width))
  }

  return (
    <View style={{ marginHorizontal: -8, ...style }}>
      {rows.map((row, i) => (
        <View key={i} style={{ flexDirection: 'row' }}>
          {row.map(entry => {
            return (
              <TouchableHighlight
                key={entry.id}
                underlayColor={theme.colors.borders}
                style={{
                  flex: 1,
                  borderColor: value.includes(entry.id)
                    ? theme.colors.primary
                    : theme.colors.borders,
                  borderWidth: scale(1),
                  borderRadius: scale(4),
                  margin: scale(8)
                }}
                onPress={() => handlePress(entry)}
                onLayout={i === 0 ? handleSize : null} // only in first row
              >
                <View
                  style={{
                    marginHorizontal: scale(6),
                    alignItems: 'center'
                  }}
                >
                  <Image
                    source={entry.source}
                    resizeMode='contain'
                    style={{
                      width: entrySize,
                      height: scale(70)
                    }}
                  />
                  {entry.text && (
                    <Text
                      style={{
                        margin: scale(4),
                        fontSize: moderateScale(10.5, 0.8)
                      }}
                    >
                      {entry.text}
                    </Text>
                  )}
                </View>
              </TouchableHighlight>
            )
          })}
        </View>
      ))}
    </View>
  )
}
