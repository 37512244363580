import React, { createContext, useContext, useState, useEffect } from 'react'

const useProvideLensesEvaluation = () => {
  const [evaluatedLenses, setEvaluatedLenses] = useState()
  const [lenseData, setLenseData] = useState(getLenseData)

  useEffect(() => {
    getLenseData()
  }, [])

  const evaluateLenses = (assessmentData, lensesPassport) => {
    // console.log(JSON.stringify({ assessmentData, lensesPassport, lenseData }, null, 4))
    if (lenseData && assessmentData && lensesPassport)setEvaluatedLenses(getLenses(assessmentData, lensesPassport, lenseData))
  }

  // const detectType = (pass) => {

  // }

  const getLenses = (assessmentData, lensesPassport, LenseData) => {
    const cycle = assessmentData.lensesCycle
    const tier = (cycle !== 'weekly' && (assessmentData.displayDuration > 3 || assessmentData.lensesDuration > 4 || assessmentData.lensesProblem.length > 0)) ? 'premiumTier' : 'standardTier'
    const type = 'regular'
    // const type = detectType(lensesPassport) // disabled due to Lorenz having keine Ahnung about Optimetrie
    return crawlLenseData(LenseData, cycle, tier, type)
    // {"hasLenses":"no","lensesCycle":"daily","lensesProblem":["burning"],"lensesDuration":10,"displayDuration":10}
  }

  const crawlLenseData = (LenseData, cycle, tier, type) => {
    if (!LenseData || !LenseData[cycle] || !LenseData[cycle][tier] || !LenseData[cycle][tier][type]) {
      return null
    } else {
      return LenseData[cycle][tier][type]
    }
  }

  function getLenseData () {
    // eslint-disable-next-line
    fetch(
      'https://vispecs-glasses.s3.eu-central-1.amazonaws.com/assessment-lenses_v1.json'
    )
      .then(response => {
        return response.json()
      })
      .then(responseJson => {
        setLenseData(responseJson)
      })
  }

  return { evaluatedLenses, evaluateLenses }
}

const LensesEvaluationContext = createContext()

export const LensesEvaluationProvider = ({ children }) => {
  const value = useProvideLensesEvaluation()

  return <LensesEvaluationContext.Provider value={value}>{children}</LensesEvaluationContext.Provider>
}

export const useLensesEvaluation = () => useContext(LensesEvaluationContext)
