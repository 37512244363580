import React from 'react'
import { StyleSheet, Platform } from 'react-native'
import BaseScreen from './BaseScreen'
import NavScreen from './NavScreen'

export default ({ children, fullscreen, background, isLanding }) => {
  return (
    <>
      {Platform.OS === 'web' ? (<NavScreen fullscreen={fullscreen} background={background} isLanding={isLanding}>{children}</NavScreen>) : (<BaseScreen style={styles.defaultScreen}>{children}</BaseScreen>)}
    </>
  )
}

const styles = StyleSheet.create({
  defaultScreen: {
    justifyContent: 'flex-start',
    alignItems: 'stretch'
  }
})
