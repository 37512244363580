import React from 'react'
import { Text, TouchableOpacity, View } from 'react-native'
import { useLinkProps } from '@react-navigation/native'

export default ({ children, to, align }) => {
  const { onPress } = useLinkProps({ to: to })
  return (
    <TouchableOpacity
      style={{
        height: 70,
        flexDirection: 'row',
        alignItems: 'center'
      }}
      onPress={onPress}
    >
      <Text
        style={{
          fontSize: 22,
          fontWeight: '400',
          color: '#333',
          alignSelf: 'center',
          marginHorizontal: 20
        }}
      >
        {children}
      </Text>
    </TouchableOpacity>
  )
}
