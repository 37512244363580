import React, { useEffect } from 'react'
import { Button, Text } from 'react-native-paper'
import { useUser } from '../hooks/user'
import DefaultScreen from '../components/screens/DefaultScreen'
import { useLinkProps } from '@react-navigation/native'
import { View } from 'react-native'
import UnderConstruction from '../components/general/UnderConstruction'

export default () => {
  const { signIn, loadingAuth } = useUser()
  const { onPress } = useLinkProps({ to: '/home' })

  useEffect(() => {
    signIn()
    // onPress()
  }, [])

  return (
    <DefaultScreen>
      <View style={{ alignItems: 'center', alignSelf: 'center', flexDirection: 'row', flex: 1 }}>
        <Text>login page</Text>
        <Button disabled={loadingAuth} onPress={signIn}>
        Sign In Testuser
        </Button>
      </View>
      <UnderConstruction />
    </DefaultScreen>
  )
}
