import React from 'react'
import { Paragraph, Divider } from 'react-native-paper'
import UnderConstruction from '../../components/general/UnderConstruction'

export default ({ type }) => {
  return (
    <>
      <Paragraph style={{ paddingHorizontal: 60 }}>Um Ihnen Kontaktlinsen empfehlen zu können, müssen Sie einen Kontaktlinsenpass erstellt und die Kontaktlinsen-Bedarfsanalyse erledigt haben.</Paragraph>
      <Divider />
      <UnderConstruction />
    </>
  )
}
