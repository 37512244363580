import React, { useState } from 'react'
import { Image, ImageBackground, View } from 'react-native'
import Background from '../../../assets/tinted-base.jpg'
import Foreground from '../../../assets/tinted-frame.png'
import theme from '../../styles/theme'
import RadioInput from './RadioInput'
import Slider from '@react-native-community/slider'
import { Subheading, scale } from '../../scaling/ScaledUI'

export default props => {
  const { options, steps, input } = props
  const { value, onChange } = input
  const [option, rangeValue] = value
  const [containerWidth, setContainerWidth] = useState(0)
  const [ratio, setRatio] = useState(2 / 3)
  const [localRangePositionValue, setLocalRangePositionValue] = useState(
    rangeValue
  )
  const [localRangeValue, setLocalRangeValue] = useState(rangeValue)

  const handleSize = e => {
    setContainerWidth(e.nativeEvent.layout.width)
    const { width, height } = Image.resolveAssetSource(Background)
    setRatio(height / width)
  }

  const setOption = newOption => {
    onChange([newOption, rangeValue])
  }

  const setRangeValue = newRangeValue => {
    setLocalRangePositionValue(steps[newRangeValue])
    setLocalRangeValue(newRangeValue)
    onChange([option, steps[newRangeValue]])
  }

  function getStepPercent () {
    let pos = 0
    if (localRangeValue !== null && localRangeValue >= 0) pos = localRangeValue
    if (isNaN(steps[pos])) pos = 1
    // console.log(localRangeValue + ', ' + steps[pos])
    return (steps[pos] * 100).toFixed(0) + '%'
  }

  return (
    <View onLayout={handleSize}>
      <RadioInput
        options={options}
        input={{ value: option, onChange: setOption }}
      />
      <View
        style={{
          flexDirection: 'column',
          alignItems: 'center',
          marginBottom: scale(8),
          marginHorizontal: scale(24)
        }}
      >
        <View
          style={{
            flex: 1,
            flexDirection: 'column',
            width: '100%',
            alignItems: 'center'
          }}
        >
          <Subheading
            style={{
              color: theme.colors.primary,
              lineHeight: scale(35),
              alignSelf: 'center'
            }}
          >
            {getStepPercent()}
          </Subheading>
        </View>
        <Slider
          style={{ flex: 1, height: scale(25), width: '100%' }}
          value={localRangeValue}
          // onValueChange={setLocalRangeValue}
          onSlidingComplete={setRangeValue}
          minimumTrackTintColor={theme.colors.primary}
          minimumValue={0}
          maximumValue={steps.length - 1}
          step={1}
          // thumbImage={require('../../../assets/slider_hollow_100x100.png')}
          thumbTintColor={theme.colors.primary}
        />
      </View>
      <ImageBackground
        source={Background}
        style={{
          width: containerWidth,
          height: containerWidth * ratio
        }}
      >
        <View
          style={{
            width: containerWidth,
            height: containerWidth * ratio,
            backgroundColor: options.find(o => o.id === option).color,
            opacity: (localRangePositionValue / 0.85) * 0.5
          }}
        />
        <View
          style={{
            zIndex: 2,
            position: 'relative',
            top: -containerWidth * ratio,
            width: containerWidth,
            height: containerWidth * ratio,
            backgroundColor: 'black',
            opacity: (localRangePositionValue / 0.85) * 0.5
          }}
        />
        <Image
          source={Foreground}
          style={{
            zIndex: 3,
            position: 'relative',
            top: -2 * containerWidth * ratio,
            width: containerWidth,
            height: containerWidth * ratio
          }}
        />
      </ImageBackground>
      <View
        style={{
          flexDirection: 'row',
          marginVertical: scale(8),
          marginHorizontal: scale(24)
        }}
      >
        {/* <Button style={{ flex: 1 }}>
          <Text style={{ color: theme.colors.primary, lineHeight: scale(60) }}>
            {options[step].text}
          </Text>
        </Button> */}
      </View>
    </View>
  )
}
