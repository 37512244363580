import React from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'
import { IconButton, Text } from 'react-native-paper'
import { scale } from '../../scaling/ScaledUI'
import theme from '../../styles/theme'

export default props => {
  const { options, selected, onPress, disabled } = props

  return options.map(option => {
    return (
      <TouchableOpacity
        onPress={e => {
          onPress(e, option.id)
        }}
        key={option.id}
        style={styles.container}
      >
        <Text style={styles.label}>{option.text}</Text>
        {option.price && <Text style={styles.priceLabel}>+{option.price}</Text>}
        <IconButton
          icon={
            selected.includes(option.id)
              ? 'check-circle-outline'
              : 'checkbox-blank-circle-outline'
          }
          disabled={disabled || false}
          size={scale(25)}
          style={styles.checkbox}
          color={
            selected.includes(option.id)
              ? theme.colors.primary
              : theme.colors.borders
          }
        />
      </TouchableOpacity>
    )
  })
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: scale(42),
    paddingHorizontal: scale(12)
  },
  label: {
    fontSize: scale(15),
    flex: 1
  },
  priceLabel: {
    fontSize: scale(15),
    flex: 1,
    marginLeft: scale(8),
    color: theme.colors.primary
  },
  checkbox: {
    marginHorizontal: scale(-10)
  }
})
