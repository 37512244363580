import { createMaterialBottomTabNavigator } from '@react-navigation/material-bottom-tabs'
import React, { useEffect } from 'react'
import Icon from 'react-native-vector-icons/MaterialCommunityIcons'
import theme from '../styles/theme'
import GlassesEvaluation from '../screens/Evaluation/GlassesEvaluation'
import LensesEvaluation from '../screens/Evaluation/LensesEvaluation'
import GlassEvaluationUnavailable from '../screens/Evaluation/GlassEvaluationUnavailable'
import UnderConstruction from '../components/general/UnderConstruction'
import { useAssessment } from '../hooks/user/assessment'
import LensesEvaluationUnavailable from '../screens/Evaluation/LensesEvaluationUnavailable'
import { usePassport } from '../hooks/user/passport'

const Tab = createMaterialBottomTabNavigator()

export default () => {
  const { lensesAssessment, glassAssessment, fetchGlassAssessment, fetchLensesAssessment } = useAssessment()
  const { fetchLensesPassport, lensesPassport } = usePassport()

  useEffect(() => {
    fetchLensesAssessment()
    fetchGlassAssessment()
    fetchLensesPassport()
  }, [])

  const checkRequirements = (component, type) => {
    if (type === 'Lenses') {
      if (lensesAssessment && lensesPassport) { return component } else return LensesEvaluationUnavailable
    }
    if (type === 'Glasses') {
      if (glassAssessment) return component
      else return GlassEvaluationUnavailable
    }
  }

  return (
    <Tab.Navigator
      initialRouteName='Glasses'
      barStyle={{
        backgroundColor: theme.colors.background
      }}
      activeColor={theme.colors.primary}
    >
      <Tab.Screen
        name='Glasses'
        component={checkRequirements(GlassesEvaluation, 'Glasses')}
        options={({ route }) => ({
          tabBarLabel: 'Brillenglas',
          tabBarIcon: ({ color }) => (
            <Icon
              name='glasses'
              color={color}
              size={24}
            />
          )
        })}
      />
      <Tab.Screen
        name='Frame'
        component={UnderConstruction}
        options={{
          tabBarLabel: 'Fassung',
          tabBarIcon: ({ color }) => (
            <Icon
              name='printer-3d'
              color={color}
              size={24}
            />
          )
        }}
      />
      <Tab.Screen
        name='Lenses'
        component={checkRequirements(LensesEvaluation, 'Lenses')}
        options={{
          tabBarLabel: 'Kontaktlinsen',
          tabBarIcon: ({ color }) => (
            <Icon
              name='eye-plus-outline'
              color={color}
              size={24}
            />
          )
        }}
      />
    </Tab.Navigator>
  )
}
