import { API, graphqlOperation } from '@aws-amplify/api'
import { useLinkTo } from '@react-navigation/native'
import React, { useCallback } from 'react'
import FormScreen from '../../../form-wizard/FormScreen'
import FormWizard from '../../../form-wizard/FormWizard'
import MultilineTextInput from '../../../form-wizard/inputs/MultilineTextInput'
import PassportDataInput from '../../../form-wizard/inputs/PassportDataInput'
import RadioInput from '../../../form-wizard/inputs/RadioInput'
import Question from '../../../form-wizard/Question'
import * as mutations from '../../../graphql/mutations'
import { useUser } from '../../../hooks/user'

export default props => {
  const { userAttributes } = useUser()

  const linkTo = useLinkTo()

  const handleSubmit = useCallback(data => {
    const rightData = {
      sphere: data.sphere[0],
      cylinder: data.cylinder[0],
      axis: data.axis[0],
      add: (data.add[0] === null || data.add[0] === 0) ? null : data.add[0],
      prism: null,
      base: null,
      pd: null,
      height: null,
      diameter: data.diameter[0],
      radius: data.radius[0]
    }

    const leftData = {
      sphere: data.sphere[1],
      cylinder: data.cylinder[1],
      axis: data.axis[1],
      add: (data.add[1] === null || data.add[1] === 0) ? null : data.add[1],
      prism: null,
      base: null,
      pd: null,
      height: null,
      diameter: data.diameter[1],
      radius: data.radius[1]
    }

    API.graphql(
      graphqlOperation(mutations.createPassport, {
        input: {
          userId: userAttributes.sub,
          createdTimestamp: Date.now(),
          createdByUserId: userAttributes.sub,
          metaData: data.comments,
          rightData,
          leftData,
          type: data.type
        }
      })
    )
      .then(() => {
        linkTo('/home')
      })
      .catch(console.error)
  }, [])

  return (
    <FormWizard onSubmit={handleSubmit}>
      <FormScreen
        title='Kontaktlinsenpass digitalisieren'
      >
        <Question
          id='radius'
          head='Radius / Basiskurve (BC / r / rad)'

          fieldProps={{
            validate: value => (value ? undefined : 'required')
          }}
        >
          <PassportDataInput
            initialValue={
              props.passport
                ? [
                  props.passport.rightData.radius,
                  props.passport.leftData.radius
                ]
                : [8, 8]
            }
            min={7.0}
            max={9.5}
            decimalPlaces={1}
            steps={0.1}
            // description='Dieser Wert steht meistens an erster Stelle.'
            image={require('../../../../assets/Passport/Kontaktlinsenpass/Kontaktlinsenpass_BC.jpg')}
            info='Der Radius beschreibt welche Krümmung die Kontaktlinse
          besitzt und liegt meistens zwischen 8,0 und 9,0.'
          />
        </Question>
      </FormScreen>

      <FormScreen
        title='Kontaktlinsenpass digitalisieren'
      >
        <Question
          id='sphere'
          head='Sphäre (SPH / S / P)'

          fieldProps={{
            validate: value => (value ? undefined : 'required')
          }}
        >
          <PassportDataInput
            initialValue={
              props.passport !== undefined
                ? [
                  props.passport.rightData.sphere,
                  props.passport.leftData.sphere
                ]
                : [0, 0]
            }
            min={-20}
            max={20}
            decimalPlaces={2}
            steps={0.25}
            // description='Die Sphäre steht im Kontaktlinsenpass an zweiter Stelle.'
            image={require('../../../../assets/Passport/Kontaktlinsenpass/Kontaktlinsenpass_SPH.jpg')}
            info='Die Sphäre beschreibt die Stärke des Brillenglases oder der
          Kontaktlinse. Üblicherweise wird dieser Wert mit den Vorzeichen
          +/- angegeben. Handelt es sich um das Vorzeichen + sind Sie
          Weitsichtig. Steht vor dem sphärischen Wert ein – sind Sie Kurzsichtig.
          Sollte es der Fall sein dass kein Vorzeichen angegeben ist, handelt
          es sich in den Meisten Fällen um einen + Wert.'
          />
        </Question>
      </FormScreen>

      <FormScreen
        title='Kontaktlinsenpass digitalisieren'
      >
        <Question
          id='cylinder'
          head='Zylinder (ZYL / CYL)'

          fieldProps={{
            validate: value => (value ? undefined : 'required')
          }}
        >
          <PassportDataInput
            initialValue={
              props.passport
                ? [
                  props.passport.rightData.cylinder,
                  props.passport.leftData.cylinder
                ]
                : [0, 0]
            }
            min={-8}
            max={8}
            decimalPlaces={2}
            steps={0.25}
            // description='Der Zylinder steht an dritter Stelle.'
            image={require('../../../../assets/Passport/Kontaktlinsenpass/Kontaktlinsenpass_CYL.jpg')}
            info='Der Zylinder ist der Wert der die Höher der Hornhautverkrümmung
          beschreibt. Bei einer Horhautverkrümmung handelt es sich um zwei
          unterschiedlich Starke Radien auf Ihrer Hornhautoberfläche.
          Diese Differenz kann mit einem dioptrischen Wert ausgeglichen werden.
          Dieser Wert kann ebenso in + oder – angegeben werden. Hier beschreibt
          das Vorzeichen jedoch nicht die Art der Fehlsichtigkeit.'
          />
        </Question>
      </FormScreen>

      <FormScreen
        title='Kontaktlinsenpass digitalisieren'
      >
        <Question
          id='axis'
          head='Achse (A / Ach / Axis)'

          fieldProps={{
            validate: value => (value ? undefined : 'required')
          }}
        >
          <PassportDataInput
            initialValue={
              props.passport
                ? [props.passport.rightData.axis, props.passport.leftData.axis]
                : [100, 100]
            }
            min={0}
            max={180}
            decimalPlaces={0}
            steps={1}
            // description='Die Achse steht an vierter Stelle.'
            image={require('../../../../assets/Passport/Kontaktlinsenpass/Kontaktlinsenpass_A.jpg')}
            info='Die Achse steht in dringender Verbindung mit dem Wert
          des ZYL / CYL. Ist keine Hornhautverkrümmung vorhanden
          wird auch der Wert der Achse unrelevant. Die Achse
          beschreibt die Achsenlage auf welcher die Hornhautverkrümmung
          herrscht und somit auf wie viel Grad diese korrigiert werden muss.
          Dieser Wert liegt immer zwischen 0° - 180°.'
          />
        </Question>
      </FormScreen>

      <FormScreen
        title='Kontaktlinsenpass digitalisieren'
      >
        <Question
          id='add'
          head='Addition (ADD)'
          fieldProps={{
            validate: value => (value ? undefined : 'required')
          }}
        >
          <PassportDataInput
            initialValue={
              props.passport
                ? [props.passport.rightData.add, props.passport.leftData.add]
                : [2.0, 2.0]
            }
            min={0}
            max={3.0}
            decimalPlaces={2}
            steps={0.25}
            // description='Steht meistens an fünfter im Kontaktlinsenpass Stelle.'
            optionalText='Mein Kontaktlinsenpass enthält diesen Wert nicht'
            image={require('../../../../assets/Passport/Kontaktlinsenpass/Kontaktlinsenpass_Add.jpg')}info='Die Addition (Nahzusatz) ist jener Wert welcher benötigt
            wird damit Sie auch in der Nähe scharf sehen können.
            (z.B.: beim Lesen) Auch dieser wird in Dioptrie angegeben
            und ist immer mit dem Vorzeichen +. In fast allen Fällen
            ist dieser Wert für beide Augen ident und somit oft nur
            einmal auf einem Kontaktlinsenpass angeführt.'
          />
        </Question>
      </FormScreen>

      <FormScreen
        title='Kontaktlinsenpass digitalisieren'
      >
        <Question
          id='diameter'
          head='Durchmesser / Diagonale (DIA / Ø)'

          fieldProps={{
            validate: value => (value ? undefined : 'required')
          }}
        >
          <PassportDataInput
            initialValue={
              props.passport !== undefined
                ? [
                  props.passport.rightData.diameter,
                  props.passport.leftData.diameter
                ]
                : [10, 10]
            }
            min={8}
            max={16}
            decimalPlaces={1}
            steps={0.1}
            // description='Dieser Wert steht an der letzen Stelle.'
            image={require('../../../../assets/Passport/Kontaktlinsenpass/Kontaktlinsenpass_DIA.jpg')}info='Dieser Wert Beschreibt die Größe der Kontaktlinse
            bzw. den Durchmesser. Dieser Wert kann je nach
            Kontaktlinse zwischen 7,0 und 17,0 ausmachen.'
          />
        </Question>
      </FormScreen>

      <FormScreen title='Kontaktlinsenpass digitalisieren'>
        <Question
          id='type'
          head='Wie oft wechseln Sie Ihre Kontaktlinsen?'
          fieldProps={{
            validate: value => (value ? undefined : 'required')
          }}
        >
          <RadioInput
            options={[
              { id: 'LENSESDAILY', text: 'Täglich' },
              { id: 'LENSESWEEKLY', text: 'Wöchentlich' },
              { id: 'LENSESMONTHLY', text: 'Monatlich' },
              { id: 'LENSES', text: 'Keine Angabe' }
            ]}
          />
        </Question>
      </FormScreen>

      <FormScreen title='Kontaktlinsenpass digitalisieren'>
        <Question
          id='comments'
          head='Möchten Sie noch eine Anmerkung hinzufügen?'
        >
          <MultilineTextInput initialValue={
            props.passport && props.passport.metaData !== null
              ? props.passport.metaData
              : ''
          }
          />
        </Question>
      </FormScreen>
    </FormWizard>
  )
}
